<template>
	<vg-modal :title="$t('signer-bon-de-sortie')"
		:width="'80vw'"
		:height="'100vh'"
		@close="$emit('close')">
		<template #body>
			<vg-files-pdf-viewer v-if="!showSignature"
				:pdf="pdfLink" />
			<div v-else>
				<vg-input :title="$t('nom-signataire')"
					v-model="nomSignataire" />
				<vg-signature-pad v-if="nomSignataire && nomSignataire.length!=0"
					@save="onSaveSignature"
					@close="showSignature=false;"/>
			</div>
			<vg-files-progress-upload-bar v-if="VgFilesMixins.uploadState"
				:transferredBytes="VgFilesMixins.uploadState.bytesTransferred"
				:totalBytes="VgFilesMixins.uploadState.totalBytes"/>
		</template>
		<template #footer>
			<vg-button type="default-success"
				@click="$emit('close')">
				{{$t("annuler")}}
			</vg-button>
			<vg-button v-if="!showSignature"
				type="danger"
				@click="showSignature=true;">
				{{$t("signer")}}
			</vg-button>
		</template>
	</vg-modal>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgModal from "src/components/Vg/VgModal.vue";
	import VgInput from "src/components/Vg/VgInput.vue";
	import VgSignaturePad from "src/components/Vg/VgSignaturePad.vue";
	import VgFilesPdfViewer from "src/components/Vg/Files/VgFilesPdfViewer.vue";
	import VgFilesProgressUploadBar from "src/components/Vg/Files/VgFilesProgressUploadBar.vue";
	import Metadatas from "src/services/Metadatas.js";
	import { PDFDocument } from 'pdf-lib';
	import { mapGetters } from 'vuex';

    export default {
        name: "vg-bon-de-sortie-signature",
		components: {
			VgButton,
			VgModal,
			VgInput,
			VgSignaturePad,
			VgFilesPdfViewer,
			VgFilesProgressUploadBar
		},
    	props: {
			/**
			* bon de sortie
			*/
            value: {
				type: Object,
				required: true
			},
			signatureImage:{
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "signer-bon-de-sortie": "Signer bon de sortie",
			"annuler": "Annuler",
			"signer": "Signer le document",
			"nom-signataire": "Nom signataire",
			"deuxieme-signataire": "Deuxième signataire"
        },
        "en": {
            "signer-bon-de-sortie": "Signer bon de sortie",
			"annuler": "Cancel",
			"signer": "Sign the document",
			"nom-signataire": "Signataire's name",
			"deuxieme-signataire": "Second signatory"
        }
    }
},
        data: function() {
            return {
				pdfLink: null,
				base64File: null,
				pdf: null,
				showSignature: false,
				nomSignataire: null
            };
        },
		created: function(){
			this.initDocument();
		},
		mounted: function(){

		},
		methods: {
			initDocument: function(){
				console.log("DOCUMENT", this.value.document);
				this.VgFilesMixins_getDownloadFileLink(this.value.document).then((link)=>{
					this.pdfLink = link;
					this.VgFilesMixins_convertUrlToBase64String(link).then((base64File)=>{
						this.base64File = base64File;
					});
				});
			},
			onSaveSignature: function(signatureImage){
				console.log("SIGNATURE IMAGE", signatureImage);
				// @TODO coller signature sur document
				PDFDocument.load(this.base64File).then((datas)=>{
					this.pdf = datas;
					let pages = this.pdf.getPages();
					let page = this.pdf.getPage(0);
					this.pdf.embedPng(signatureImage).then((signatureImage)=>{
						let pngDims = signatureImage.scale(0.5);
						page.drawText(this.$t("deuxieme-signataire")+": "+this.nomSignataire, {
							x: 20,
							y: 100 - 10,
							size: 10
						});
						page.drawImage(signatureImage, {
							x: 20,
							y: 100 - pngDims.height,
							width: pngDims.width,
							height: pngDims.height
						});
						this.pdf.saveAsBase64().then((base64)=>{
							this.VgFilesMixins_uploadBase64StringInFirebase(base64, this.value.document.name, this.value.uid, "application/pdf").then((datas)=>{
								this.$emit("save", datas);
							});
						});
					});
				});
				/*const pages = pdfDoc.getPages()
				pages[0].drawText('You can modify PDFs too!')
				const pdfBytes = await pdfDoc.save()*/
				// @TODO save document

				// @TODO close BS signature

			}
		},
        computed: {
			...mapGetters({

            })
		}
    };

</script>
<style lang="scss" scoped>

</style>
