<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getHeaderTitle"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        @action-export="handleActionExport"
        @remove-filters="onRemoveFilters">

        <template #action-button-item>

        </template>

        <template #create-button>
            <vg-button
                type="success"
                @click="onShowCreateBonDeCommande"
                >
                {{ $t("menu-more-create-bon-de-commande") }}
            </vg-button>
        </template>

        <template #search>
            <vg-text-filter v-model="searchQuery" @input="onInputSmartFilter" />
        </template>

        <template #header-bottom-left>
            <vg-tabs v-model="focusedTab"
                :tabs="tabs"
                :color="'gris'"
                @tabClicked="onTabClicked"
            />
        </template>

        <template #primary>
            <vg-bons-de-commande-table
                :filters="agfilters"
                paginate
                style="height:100% !important;"
                :limit="numberOfRowsToDisplay"
                @row-click="onRowBonDeCommandeClick"
                @row-dbl-click="onRowBonDeCommandeDblClick"
                @create-clone="onOpenCreateClone"
                />
        </template>

        <template #secondary-filters>
            <vg-filter-date-intervalle :title="$t('filtre-date-creation')"
                :defaultStartDate="dateIntervalle.startDate"
                :defaultEndDate="dateIntervalle.endDate"
                @change="onChangeFilterDateCreation"/>
            <vg-filter-bons-de-commande-entites v-model="agfilters.bonsDeCommandeEntite_id.value"
                :title="$t('filtre-entite')"/>
            <vg-filter-bons-de-commande-statut
                v-model="agfilters.statut.value" />
            <vg-collapse :title="$t('more-filters')"
                :killContentOnCollapse="false">
                <template #content>
                    <vg-filter-tiers v-model="agfilters.fournisseur_id.value"
                        :title="$t('filtre-fournisseur')"/>
                    <vg-filter-site v-model="agfilters.siteDemandeur_id.value"
                        :title="$t('filtre-site-demandeur')"/>
                    <vg-filter-service v-model="agfilters.serviceDemandeur.value"
                        :title="$t('filtre-service-demandeur')"
                        showAll/>
                    <vg-filter-bons-de-commande-createur v-model="agfilters.createur_id.value"
                        :title="$t('filtre-demandeur')"/>
                    <vg-filter-bons-de-commande-validateur v-model="agfilters.validateur_id.value"
                        :title="$t('filtre-validateur')"/>
                </template>
            </vg-collapse>
            <br>
        </template>

        <template #secondary-widget>
            <vg-dashboard-bons-de-commande-repartition-montant-ht ref="vg-bcs-repartition-montant-ht"
                :inline="false"
                showSelector
                :defaultDateIntervalle="dateIntervalleRepartition"
                @selected="onSelectedBonsDeCommandeMontantHtBar"/>
        </template>

        <vg-bon-de-commande-form v-if="showCreateBonDeCommandeForm"
            :defaultValues="bonDeCommandeClone"
            @close="showCreateBonDeCommandeForm=false;"
            @save="afterCreateBonDeCommande"/>
    </vg-app-layout>
</template>

<script>
import TagGrid from "src/components/Grid/TagGrid.vue";

import VgTabs from "src/components/Vg/VgTabs.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import VgGroup from "src/components/Vg/VgGroup.vue";

import VgBonsDeCommandeTable from "src/components/Vg/BonDeCommande/VgBonsDeCommandeTable.vue";
import VgBonDeCommandeForm from "src/components/Vg/Forms/VgBonDeCommandeForm.vue";

import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
import VgFilterDateIntervalle from "src/components/Vg/Filters/VgFilterDateIntervalle.vue";
import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgFilterService from "src/components/Vg/Filters/VgFilterService.vue";
import VgFilterBonsDeCommandeEntites from "src/components/Vg/Filters/VgFilterBonsDeCommandeEntites.vue";
import VgFilterBonsDeCommandeStatut from "src/components/Vg/Filters/VgFilterBonsDeCommandeStatut";
import VgFilterBonsDeCommandeCreateur from "src/components/Vg/Filters/VgFilterBonsDeCommandeCreateur.vue";
import VgFilterBonsDeCommandeValidateur from "src/components/Vg/Filters/VgFilterBonsDeCommandeValidateur.vue";
import VgDashboardBonsDeCommandeRepartitionMontantHt from "src/components/Vg/Dashboard/VgDashboardBonsDeCommandeRepartitionMontantHt.vue";
import Metadatas from "src/services/Metadatas.js";
import FiltersMixins from 'src/mixins/FiltersMixins';
import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
    name: "bons-de-commande",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Bons de commande",
            "more-filters": "Filtres supplémentaires",
            "Filtres_filtres_title": "Filtres",
            "tab-tous": "Tous",
            "tab-a-payer": "A payer",
            "tab-valides": "Validés",
            "tab-non-valides": "Non validés",
            "tab-delivered": "Commandes livrées",
            "tab-validations-partielles": "Validations partielles",
            "tab-canceled": "Annulés",
            "menu-more-create-bon-de-commande": "Créer BC",
            "filtre-date-creation": "Date création",
            "filtre-fournisseur": "Fournisseur",
            "filtre-entite": "Entité qui passe commande",
            "filtre-statut-bc": "Statut BC",
            "filtre-etat-livraison": "Etat livraison",
            "filtre-etat-paiement": "Etat paiement",
            "filtre-site-demandeur": "Site demandeur",
            "filtre-service-demandeur": "Service demandeur",
            "filtre-date-creation": "Date création",
            "filtre-demandeur": "Demandeur",
            "filtre-validateur": "Validateur",
            "confirm-aucun-pattern-bc-numero": "Aucun modèle numéro bon de commande définit pour votre compte. Veuillez le définir dans les paramètres."
        },
        "en": {
            "page-title": "Purchase orders",
            "more-filters": "More filters",
            "Filtres_filtres_title": "Filters",
            "tab-tous": "All",
            "tab-a-payer": "To pay",
            "tab-valides": "Validated",
            "tab-non-valides": "Not validated",
            "tab-delivered": "Delivered orders",
            "tab-validations-partielles": "Partial validations",
            "tab-canceled": "Canceled",
            "menu-more-create-bon-de-commande": "Create purchase order",
            "filtre-date-creation": "Creation date",
            "filtre-fournisseur": "Supplier",
            "filtre-statut-bc": "Purchase order status",
            "filtre-etat-livraison": "Delivery state",
            "filtre-etat-paiement": "Payment status",
            "filtre-site-demandeur": "Requesting site",
            "filtre-service-demandeur": "Requesting service",
            "filtre-date-creation": "Creation date",
            "filtre-demandeur": "Requester",
            "filtre-validateur": "Validator",
            "confirm-aucun-pattern-bc-numero": "No PO number template defined for your account. Please set it in settings."
        }
    }
},
    components: {
        TagGrid,
        VgTabs,
        VgTextFilter,
        VgButton,
        VgSelect,
        VgGroup,
        VgInput,
        VgCollapse,
        VgBonsDeCommandeTable,
        VgBonDeCommandeForm,
        VgFilterDateIntervalle,
        VgFilterTiers,
        VgFilterSite,
        VgFilterService,
        VgFilterBonsDeCommandeEntites,
        VgFilterBonsDeCommandeStatut,
        VgFilterBonsDeCommandeCreateur,
        VgFilterBonsDeCommandeValidateur,
        VgDashboardBonsDeCommandeRepartitionMontantHt
    },
    mixins: [FiltersMixins,BonsDeCommandeMixins],
    data: function () {
        return {
            searchQuery: "",
            focusedTab: "all",
            showCreateBonDeCommandeForm: false,
            agfilters:{
                validateur_id: {attr: "v.validateur_id", value: null, action: "equals"},
                createur_id: {attr: "bc.createur_id", value: null, action: "equals"},
                fournisseur_id: {attr: "bc.fournisseur_id", value: null, action: "equals"},
                statut: {attr: "bc.statut", value: null, action: "equals"},
                statutPaiement: {attr: "bc.statutPaiement", value: null, action: "equals"},
                statutLivraison: {attr: "bc.statutLivraison", value: null, action: "equals"},
                siteDemandeur_id: {attr: "bc.siteDemandeur_id", value: null, action: "equals"},
                serviceDemandeur: {attr: "bc.serviceDemandeur", value: null, action: "equals"},
                bonsDeCommandeEntite_id: {attr: "bce.bonsDeCommandeEntite_id", value: null, action: "equals"},
                page_fournisseur_name:{attr:"t.name",colId: "t.name", value: null, action:"contains","openParenthesis":true},
                page_statut:{attr:"bc.statut",colId: "bc.statut", value: null, action:"contains","logicalOperator":"OR"},
                page_entite:{attr:"bce.name",colId: "bce.name", value: null, action:"contains","logicalOperator":"OR"},
                page_service_demandeur:{attr:"bc.serviceDemandeur",colId: "bce.serviceDemandeur", value: null, action:"contains","logicalOperator":"OR"},
                page_path:{attr:"l.path",colId: "l.path", value: null, action:"contains","logicalOperator":"OR"},
                page_commentaire:{attr:"bc.commentaire", value: null, action:"contains","logicalOperator":"OR"},
                page_numero:{attr:"bc.numero",colId: "bc.numero", value: null, action:"contains","logicalOperator":"OR","closeParenthesis":true},
                field_dateCreation: {attr: "bc.dateCreation", value: null, action: "between"}
            },
            bonDeCommandeClone: null,
            dateIntervalle: {
                startDate: null, 
                endDate: null
            },
            dateIntervalleRepartition:{
                startDate: moment().format("YYYY-01-01"),
                endDate: moment().format("YYYY-12-31")
            }
        };
    },
    computed: {
        ...mapGetters({
            counters: "BonsDeCommandeStore/getCounters"
        }),
        activeFilters: function(){
            let filtersNames = ["validateur_id", "createur_id", "fournisseur_id", "siteDemandeur_id", "serviceDemandeur", "bonsDeCommandeEntite_id", "field_dateCreation"];
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            return activeFilters.length;
        },
        tabs: function () {
            return [
                /*{
                    label: this.$t("tab-a-payer"),
                    name: "a-payer",
                    counter: this.counters?this.counters["a-payer"]:0,
                    filters: {
                        statut: {attr:"bc.statutPaiement", value:"a-payer", action:"equals"}
                    }
                },*/
                {
                    label: this.$t("tab-tous"),
                    name: "all",
                    counter: this.getTabCounter("all"),
                    filters: {
                        statut: {attr:"bc.statut", value:null, action:"equals"}
                    }
                },
                {
                    label: this.$t("tab-valides"),
                    name: "valides",
                    counter: this.getTabCounter("valides"),
                    filters: {
                        statut: {attr:"bc.statut", value:"validation-complete", action:"equals"}
                    }
                },
                {
                    label: this.$t("tab-validations-partielles"),
                    name: "validations-partielles",
                    counter: this.getTabCounter("validations-partielles"),
                    filters: {
                        statut: {attr:"bc.statut", value:"validation-partielle", action:"equals"}
                    }
                },
                {
                    label: this.$t("tab-non-valides"),
                    name: "non-valides",
                    counter: this.getTabCounter("non-valides"),
                    filters: {
                        statut: {attr:"bc.statut", value:["demande-validation", "validation-partielle"], action:"equals"}
                    }
                },
                {
                    label: this.$t("tab-delivered"),
                    name: "delivered",
                    counter: this.getTabCounter("delivered"),
                    filters: {
                        statut: {attr:"bc.statut", value:"delivered", action:"equals"}
                    }
                },
                {
                    label: this.$t("tab-canceled"),
                    name: "canceled",
                    counter: this.getTabCounter("canceled"),
                    filters: {
                        statut: {attr:"bc.statut", value:"canceled", action:"equals"}
                    }
                }
            ];
        }
    },
    watch: {
        searchQuery: {
            handler: function (query) {
                this.FiltersMixins_saveSearchQuery(query);
                this.onInputSmartFilter(query)
            }
        },
        agfilters: {
            handler: function (nfilters) {
                this.FiltersMixins_saveFilters(nfilters);
                
            },
            deep: true
        }
    },
    created: function () {
        this.FiltersMixins_page_name = "bonsDeCommandes";
        let filters = this.FiltersMixins_getStoredFilters();
        this.searchQuery = this.FiltersMixins_getStoredSearchQuery();
        this.agfilters = Object.assign(this.agfilters, filters);
        if(this.FiltersMixins_getDefaultFocusedTab()) this.focusedTab = this.FiltersMixins_getDefaultFocusedTab();
    },
    mounted: function () {},
    methods: {
        onChangeFilterDateCreation: function(intervalle){
            if(intervalle) this.agfilters.field_dateCreation.value = [intervalle.startDate, intervalle.endDate];
            else this.agfilters.field_dateCreation.value = null;
            this.dateIntervalle.startDate = intervalle?intervalle.startDate:null;
            this.dateIntervalle.endDate = intervalle?intervalle.endDate:null;
            this.dateIntervalleRepartition.startDate = intervalle?intervalle.startDate:null;
            this.dateIntervalleRepartition.endDate = intervalle?intervalle.endDate:null;
        },
        onSelectedBonsDeCommandeMontantHtBar: function(data){
            let repartitionFilters = data.filters;
            repartitionFilters.field_dateCreation = {attr: "bc.dateCreation", value: [data.dateIntervalle.startDate, data.dateIntervalle.endDate], action: "between"};
            this.agfilters = Object.assign({}, {
                validateur_id: {attr: "v.validateur_id", value: null, action: "equals"},
                createur_id: {attr: "bc.createur_id", value: null, action: "equals"},
                fournisseur_id: {attr: "bc.fournisseur_id", value: null, action: "equals"},
                statut: {attr: "bc.statut", value: null, action: "equals"},
                statutPaiement: {attr: "bc.statutPaiement", value: null, action: "equals"},
                statutLivraison: {attr: "bc.statutLivraison", value: null, action: "equals"},
                siteDemandeur_id: {attr: "bc.siteDemandeur_id", value: null, action: "equals"},
                serviceDemandeur: {attr: "bc.serviceDemandeur", value: null, action: "equals"},
                bonsDeCommandeEntite_id: {attr: "bce.bonsDeCommandeEntite_id", value: null, action: "equals"},
                page_fournisseur_name:{attr:"t.name",colId: "t.name", value: null, action:"contains","openParenthesis":true},
                page_statut:{attr:"bc.statut",colId: "bc.statut", value: null, action:"contains","logicalOperator":"OR"},
                page_entite:{attr:"bce.name",colId: "bce.name", value: null, action:"contains","logicalOperator":"OR"},
                page_service_demandeur:{attr:"bc.serviceDemandeur",colId: "bce.serviceDemandeur", value: null, action:"contains","logicalOperator":"OR"},
                page_path:{attr:"l.path",colId: "l.path", value: null, action:"contains","logicalOperator":"OR"},
                page_numero:{attr:"bc.numero",colId: "bc.numero", value: null, action:"contains","logicalOperator":"OR","closeParenthesis":true},
                field_dateCreation: {attr: "bc.dateCreation", value: null, action: "between"}
            }, repartitionFilters);
            this.dateIntervalle.startDate = data.dateIntervalle.startDate;
            this.dateIntervalle.endDate = data.dateIntervalle.endDate;
        },
        onShowCreateBonDeCommande: function(){
            if(!this.$app.patternBonDeCommandeNumero){
                if (window.confirm(this.$t("confirm-aucun-pattern-bc-numero"))) {
                    this.$router.push({ name: "_parametres" });
                }
            }else{
                this.showCreateBonDeCommandeForm = true;
            }
        },
        onOpenCreateClone: function(bonDeCommandeClone){
            //console.log("OPEN CREATE CLONE", bonDeCommandeClone);
            this.bonDeCommandeClone = Object.assign({}, {}, bonDeCommandeClone);
            this.showCreateBonDeCommandeForm = true;
        },
        onTabClicked: function(tab){
            //console.log("onTabClicked", tab);
            this.agfilters = Object.assign({}, this.agfilters, tab.filters);
            this.FiltersMixins_saveDefaultTab(tab.name);
        },
        onRemoveFilters: function(){
            this.dateIntervalle = {startDate: null, endDate: null};
            this.agfilters = {
                validateur_id: {attr: "v.validateur_id", value: null, action: "equals"},
                createur_id: {attr: "bc.createur_id", value: null, action: "equals"},
                fournisseur_id: {attr: "bc.fournisseur_id", value: null, action: "equals"},
                statut: {attr: "bc.statut", value: null, action: "equals"},
                statutPaiement: {attr: "bc.statutPaiement", value: null, action: "equals"},
                statutLivraison: {attr: "bc.statutLivraison", value: null, action: "equals"},
                siteDemandeur_id: {attr: "bc.siteDemandeur_id", value: null, action: "equals"},
                serviceDemandeur: {attr: "bc.serviceDemandeur", value: null, action: "equals"},
                bonsDeCommandeEntite_id: {attr: "bce.bonsDeCommandeEntite_id", value: null, action: "equals"},
                page_fournisseur_name:{attr:"t.name",colId: "t.name", value: null, action:"contains","openParenthesis":true},
                page_statut:{attr:"bc.statut",colId: "bc.statut", value: null, action:"contains","logicalOperator":"OR"},
                page_entite:{attr:"bce.name",colId: "bce.name", value: null, action:"contains","logicalOperator":"OR"},
                page_service_demandeur:{attr:"bc.serviceDemandeur",colId: "bce.serviceDemandeur", value: null, action:"contains","logicalOperator":"OR"},
                page_path:{attr:"l.path",colId: "l.path", value: null, action:"contains","logicalOperator":"OR"},
                page_numero:{attr:"bc.numero",colId: "bc.numero", value: null, action:"contains","logicalOperator":"OR","closeParenthesis":true},
                field_dateCreation: {attr: "bc.dateCreation", value: null, action: "between"}
            };
        },
        handleActionExport:function(e){
            let metadatas = new Metadatas();
            metadatas.setFilters(this.agfilters);
            switch (e.name) {
                case "askForXLS":
                    this.BonsDeCommandeMixins_export(metadatas,"excel").then((datas)=>{});
                    break;
                case "askForCSV":
                    this.BonsDeCommandeMixins_export(metadatas,"csv").then((datas)=>{});
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":"bons-de-commande-tableau"}
                    }));
                    break;
                default:
                    break;
            }

        },
        afterCreateBonDeCommande: function(bonDeCommande){
            this.showCreateBonDeCommandeForm = false;
            this.goToBonDeCommandeZoom(bonDeCommande);
        },
        onRowBonDeCommandeClick: function(row){
            //console.log("onRowBonDeCommandeClick", row);
        },
        onRowBonDeCommandeDblClick: function(row){
            //console.log("onRowBonDeCommandeDblClick", row);
            this.goToBonDeCommandeZoom(row.data);
        },
        goToBonDeCommandeZoom: function(bonDeCommande){
            this.$store.dispatch("BonsDeCommandeStore/setSelectedItem", bonDeCommande);
            this.$router.push({ name: "_bon_de_commande", params: { id: bonDeCommande.id } });
        },
        onInputSmartFilter: function (input) {
            if(input && input.length){
                this.agfilters.page_fournisseur_name.value = input;
                this.agfilters.page_statut.value = input;
                this.agfilters.page_entite.value = input;
                this.agfilters.page_numero.value = input;
                this.agfilters.page_path.value = input;
                this.agfilters.page_service_demandeur.value = input;
                this.agfilters.page_commentaire.value = input;
            }else{
                this.agfilters.page_fournisseur_name.value = null;
                this.agfilters.page_statut.value = null;
                this.agfilters.page_entite.value = null;
                this.agfilters.page_numero.value = null;
                this.agfilters.page_service_demandeur.value = null;
                this.agfilters.page_path.value = null;
                this.agfilters.page_commentaire.value = null;
            }
            
        },
        getTabCounter: function(filter){
            if(this.agfilters.statut.value==filter) return this.counters.filtered+" / "+this.counters[filter];
            else if(this.agfilters.statut.value==null && filter=="all") return this.counters.filtered+" / "+this.counters[filter];
            else return this.counters[filter];
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
