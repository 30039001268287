<template>
<div class="vg-contrats-table">
	<tag-grid v-if="!showCards"
		:idTableau="'vg-contrats-table'"

		v-model="gridOptions"
		:columnDefs="columnDefs"
		:rowData="getContrats"
		:showLoadingOverlay="showLoadingOverlay"
		:overlayLoadingText="$t('overlay-loading-text')"
		:overlayNoRowsText="$t('overlay-no-rows-text')"

        @ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
		@ag-dbl-click="handleDoubleClickRow"
		@ag-click="handleClickRow" >
	</tag-grid>
	<div v-else class="cards-container">
		<contrat-cell-render v-for="(contrat,index) in getContrats" :key="index"
			:contrat="contrat"
			:class="['card-contrat',{'card-contrat-selected': selectedContrat && contrat.id==selectedContrat.id}]"
			@clicked="handleClickRow"/>
	</div>
	<vg-modal v-if="showInterventionsModal"
        :title="$t('modal-detail-interventions-previsionnelles')"
        :width="'900px'"
        @close="onCloseInterventionsModal">
        <template #body>
            <div style="display: flex; justify-content: space-between;">
                <!-- search field -->
                <vg-text-filter
                    v-model="interventionsSearchQuery">
                </vg-text-filter>
                <!-- menu more -->
                <action-menu-aggrid
                    :idTableau="'vg-interventions-table'"
					disabledReport
					placement="bottom-right"/>
            </div>
            <div style="height:300px;">
                <vg-interventions-table
                    :searchQuery="interventionsSearchQuery"
                    :agFilters="interventionsFilters"
                    :hiddenColumns="['contrat.name','tiers.name','cout']"
                    :notShowableColumns="['cout']"
					isPeriodiques>
                </vg-interventions-table>
            </div>
        </template>
        <template #footer>
            <vg-button
                @click="onCloseInterventionsModal">
                {{$t("close")}}
            </vg-button>
        </template>
    </vg-modal>
	<vg-reserves-modal v-if="showReservesModal"
        :width="'900px'"
		:contrat="focusedContrat"
		:filters="reservesDefaultFilters"
        @close="onCloseReservesModal">
		<template #title>
			<span>{{$t('modal-detail-reserves')}} <b>{{focusedContrat.name}}</b></span>
		</template>
    </vg-reserves-modal>
</div>
</template>
<script>
	import TagGrid from "src/components/Grid/TagGrid.vue";

	import VgButton from 'src/components/Vg/VgButton.vue';


	import VgInterventionsTable from 'src/components/Vg/Interventions/VgInterventionsTable.vue';
	import VgReservesModal from 'src/components/Vg/Reserves/VgReservesModal.vue';

	import ButtonCellRender from "src/components/Vg/TagGrid/ButtonCellRender.vue";
	import TagCellRender from "src/components/Vg/TagGrid/TagCellRender.vue";
	import RestrictionSiteCellRender from "src/components/Vg/TagGrid/RestrictionSiteCellRender.vue";
	import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
	import OuiNonCellRender from "src/components/Vg/TagGrid/OuiNonCellRender.vue";
	import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
	import ProchaineInterventionCellRender from "src/components/Vg/TagGrid/ProchaineInterventionCellRender.vue";
	import NbReservesNonLeveesCellRender from "src/components/Vg/TagGrid/NbReservesNonLeveesCellRender.vue";
	import PeriodiciteCellRender from "src/components/Vg/TagGrid/PeriodiciteCellRender.vue";

	import TagGridMixins from "src/mixins/TagGridMixins.js";
	import ContratsMixins from "src/mixins/ContratsMixins.js";
	import IconNameCellRender from "src/components/Vg/TagGrid/IconNameCellRender.vue";
	import ContratCellRender from "src/components/Vg/TagGrid/ContratCellRender.vue";
	import ContratCategorieCellRender from "src/components/Vg/TagGrid/ContratCategorieCellRender.vue";

	import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
    import VgTextFilter from "src/components/Vg/VgTextFilter.vue";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
    export default {
        name: 'vg-contrats-table',
    	props: {
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			showCards:{
				type: Boolean,
				default: false
			}
        },
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
			filters: {
				handler: function(newfilters){
                    //this.metadatasContrats.setFilters(newfilters);
					this.fetch();
				},
				deep: true
			},
			localFilters: {
				handler: function(newfilters){
					//console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
					if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
				},
				deep: true
			},
			getContrats: {
				handler: function(values){
					////console.log("WATCH GET INTERVENTIONS", interventions, this.localFilters);
					//this.TagGridMixins_setFilters(this.gridOptions, this.localFilters);
					//console.log("WATCH CONTRATS", values);
				},
				immediate: false,
				deep: true
			}
		},
		mixins: [
			TagGridMixins,
			ContratsMixins
		],
		components: {
			TagGrid,
			VgButton,

			VgInterventionsTable,
			IconNameCellRender,
			NbReservesNonLeveesCellRender,
			VgReservesModal,
            ActionMenuAggrid,
            VgTextFilter,
			ContratCellRender,
			ContratCategorieCellRender
		},
		i18n:    { "locale":navigator.language,
    "messages": {
"fr": {
		"overlay-loading-text": "Chargement des contrats...",
		"overlay-no-rows-text": "Aucun contrat correspondant au filtrage",
        "header-aggrid-id": "ID",
        "header-aggrid-intitule": "INTITULÉ CONTRAT",
        "header-aggrid-prestataire": "PRESTATAIRE",
        "header-aggrid-lieux": "LIEUX",
        "header-aggrid-type": "Type",
        "header-aggrid-date-debut": "DATE DÉBUT",
        "header-aggrid-statut": "STATUT D’EXPIRATION",
        "header-aggrid-date-fin": "DATE FIN",
        "header-aggrid-tacite": "Tacite reconduction",
        "header-aggrid-annualCost": "Coût annuel HT en {devise}",
        "header-aggrid-renewalPeriodicity": "Période (jours)",
        "header-aggrid-noticePeriod": "Avertissement avant  fin (jours)",
        "header-aggrid-astreinte": "Astreinte",
        "header-aggrid-periodiciteIntervention": "Périodicité intervention",
        "header-aggrid-tags": "Etiquette",
        "interventions-previsionnelles": "Synthèse interventions",
        "header-nb-reserves-non-levees": "Nb réserves non levées",
        "modal-detail-interventions-previsionnelles": "Détails interventions prévisionnelles",
        "modal-detail-reserves": "Réserves du contrat",
        "header-aggrid-categorie": "Catégorie",
        "prochaine-date-previsionnelle": "Prochaine date prévisionnelle",
        "details": "Voir détails",
        "close": "Fermer"
    },
    "en": {
		"overlay-loading-text": "Loading contracts...",
		"overlay-no-rows-text": "No contract matching filtering",
        "header-aggrid-id": "ID",
        "header-aggrid-intitule": "Contract title",
        "header-aggrid-prestataire": "Service provider",
        "header-aggrid-lieux": "LOCATION",
        "header-aggrid-type": "Type",
        "header-aggrid-date-debut": "Start date",
        "header-aggrid-statut": "EXPIRY STATUS",
        "header-aggrid-date-fin": "Ending date",
        "header-aggrid-tacite": "Tacit agreement",
        "header-aggrid-annualCost": "Annual cost excl. tax in {devise}",
        "header-aggrid-renewalPeriodicity": "Period (days)",
        "header-aggrid-noticePeriod": "Alert before end (days)",
        "header-aggrid-astreinte": "On-call-duty",
        "header-aggrid-periodiciteIntervention": "Intervention periodicity",
        "header-aggrid-tags": "Label",
        "interventions-previsionnelles": "Intervention synthesis",
        "modal-detail-interventions-previsionnelles": "Forecast intervention details",
        "header-aggrid-categorie": "category",
        "prochaine-date-previsionnelle": "Next provisional date",
        "details": "See details",
        "close": "Close",
        "header-nb-reserves-non-levees": "Reservation not taken into account",
        "modal-detail-reserves": "Contract reservation"
    },
    "th": {
        "header-aggrid-intitule": "หัวเรื่องสัญญา",
        "header-aggrid-prestataire": "ผู้ให้บริการ",
        "header-aggrid-id": "ID",
        "header-aggrid-lieux": "ที่ตั้ง",
        "header-aggrid-type": "ประเภท",
        "header-aggrid-date-debut": "วันที่เริ่มต้น",
        "header-aggrid-statut": "สถานะหมดอายุ",
        "header-aggrid-date-fin": "วันที่สิ้นสุด",
        "header-aggrid-tacite": "ข้อตกลงปริยาย",
        "header-aggrid-renewalPeriodicity": "ช่วงเวลา (วัน)",
        "header-aggrid-noticePeriod": "แจ้งเตือนก่อนสิ้นสุด (วัน)",
        "header-aggrid-astreinte": "ตามหน้าที่",
        "header-aggrid-periodiciteIntervention": "ระยะเวลาการแทรกแซง",
        "header-aggrid-tags": "ฉลาก",
        "interventions-previsionnelles": "การสังเคราะห์การแทรกแซง",
        "modal-detail-interventions-previsionnelles": "รายละเอียดการแทรกแซงที่คาดการณ์",
        "header-aggrid-categorie": "หมวดหมู่",
        "prochaine-date-previsionnelle": "วันที่ชั่วคราวถัดไป",
        "details": "ดูรายละเอียด",
        "close": "ปิด"
        }
    }
},
        data: function(){
            return {
				showLoadingOverlay: false,
				gridOptions: {},
				columnDefs: [
					{
						headerName: this.$t("header-aggrid-id"),
						field: "id",
						colId: "id",
						width: 100,
						cellClass: ["vg-cell-ballam-theme"],
	                    hide:true
					},
					{
						headerName: this.$t("header-aggrid-intitule"),
						field: "name",
						colId: "name",
						width: 460,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new IconNameCellRender({propsData: {dataColumn: params.data.name, icon: "static/assets/icone/circle/contrat-cercle.png", route: { router: this.$router, name: '_contrat_id', params: {id: params.data.id } }}}).$mount().$el

					},
					{
						headerName: this.$t("header-aggrid-prestataire"),
						field: "tiers.name",
						colId: "tiers.name",
	                    filter: "agTextColumnFilter",
						width: 260,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => {
                            if(!params.data.tiers || !params.data.tiers.name) return "-";
                            return new IconNameCellRender(
                                {
                                    propsData: {
                                        dataColumn: params.data.tiers.name,
                                        icon: "static/assets/icone/circle/tiers-cercle.png"
                                    }
                                }
                            ).$mount().$el
                        }
					},
	                {
						headerName: this.$t("header-aggrid-lieux"),
						colId: "path", // path n'est pas OK
						width: 180,
	                    filter: "agTextColumnFilter",
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer: (params) => new RestrictionSiteCellRender({propsData:{lieux: params.data.lieux,attributePath:"contratslieux_path"}}).$mount().$el
					},
	                {
						headerName: this.$t("header-aggrid-type"),
						field: "type",
	                    colId: "type",
						width: 180,
	                    filter: "agTextColumnFilter",
						cellClass: ["vg-cell-ballam-theme"],
	                    hide:true
					},

					{
						headerName: this.$t("header-aggrid-date-debut"),
						field: "startDate",
						colId: "startDate",
						width: 200,
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new DatetimeCellRender({propsData:{datetime: params.data.startDate}}).$mount().$el
					},
					{
						headerName: this.$t("prochaine-date-previsionnelle"),
	                    field: "prochaineIntervention",
						colId: "prochaineIntervention",
	                    filter: "agTextColumnFilter",
						cellClass: ["vg-cell-ballam-theme"],
						width: 180,
						cellRenderer: (params) => new ProchaineInterventionCellRender({propsData:{contrat:params.data, intervention: params.data.prochaineIntervention}}).$mount().$el
					},
					{
						headerName: this.$t("header-aggrid-date-fin"),
						field: "endDate",
						colId: "endDate",
						width: 180,
	                    hide: true,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new DatetimeCellRender({propsData:{datetime: params.data.endDate}}).$mount().$el
					},
					{
						headerName: this.$t("header-aggrid-tacite"),
						field: "isTacit",
						colId: "isTacit",
						width: 180,
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new OuiNonCellRender({propsData:{isValid: params.data.isTacit}}).$mount().$el
					},
					{
						headerName: this.$t("header-aggrid-annualCost", {devise: this.$app.devise}),
						field: "annualCost",
						colId: "annualCost",
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.annualCost}}).$mount().$el
					},
					{
						headerName: this.$t("header-aggrid-renewalPeriodicity"),
						field: "renewalPeriodicity",
						colId: "renewalPeriodicity",
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t("header-aggrid-noticePeriod"),
						field: "noticePeriod",
						colId: "noticePeriod",
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t("header-aggrid-astreinte"),
						field: "astreinte",
						colId: "astreinte",
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t("header-aggrid-periodiciteIntervention"),
						field: "periodiciteIntervention",
						colId: "periodiciteIntervention",
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer:(params)=>new PeriodiciteCellRender({propsData: {params: params}}).$mount().$el
					},
					{
						headerName: this.$t("header-aggrid-tags"),
						field: "tags",
						colId: "tags",
	                    hide:true,
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer:(params)=>new TagCellRender({propsData: {tags: params.data.tags}}).$mount().$el
					},
					{
						headerName: this.$t("header-aggrid-categorie"),
						field: "categorie.libelleCatgorie",
						colId: "categorie.libelleCatgorie",
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer:(params)=>new ContratCategorieCellRender({propsData: {params: params}}).$mount().$el
					},
					{
						headerName: this.$t("interventions-previsionnelles"),
						field: "status",
						colId: "status",
						cellClass: ["vg-cell-ballam-theme"],
	                    cellRenderer:(params)=>new ButtonCellRender({propsData: { params: params,label:this.$t("details")}})
							.$on("click", this.onClickDetailsInterventionsPrevisionnelle)
							.$mount().$el
					},
					{
						headerName: this.$t('header-nb-reserves-non-levees'),
						field: 'nbReservesNonLevees',
						colId: 'nbReservesNonLevees',
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new NbReservesNonLeveesCellRender({propsData: {params: params}})
							.$on("show-reserves", this.onClickShowReserves)
							.$mount().$el,
						width: 160,
						hide: this.columnIsHidden("nbReservesNonLevees"),
						isVisible: this.columnIsNotShowable("nbReservesNonLevees")
					}
				],
                metadatasContrats: new Metadatas(),
	            showInterventionsModal: false,
	            showReservesModal: false,
				focusedContrat: null,
				interventionsFilters: {
	                contrat_id: { attr:"i.contrat_id", colId: "contrat_id", value: null, action:"equals" }
	            },
	            interventionsSearchQuery: ""
            };
        },
		created: function(){
            //this.metadatasContrats.setFilters(this.filters);
			this.fetch();
		},
		mounted: function(){
			window.addEventListener("askGenerateReport", this.generateReport);
		},
		methods: {
			/**
			* column is hidden
			* @param String colId
			* @return Boolean
			*/
			columnIsHidden: function(colId){
				return this.hiddenColumns.indexOf(colId)!=-1;
			},
			/**
			* column is pinned to left
			* @param String colId
			* @return Boolean
			*/
			columnIsPinnedToLeft: function(colId){
				return this.pinnedColumns.indexOf(colId)!=-1;
			},
			/**
			* column is not showable
			* @param String colId
			* @return Boolean
			*/
			columnIsNotShowable: function(colId){
				return !(this.notShowableColumns.indexOf(colId)!=-1);
			},
			fetch: function(){
				this.showLoadingOverlay = true;
				let filters = Object.assign({}, {}, this.filters);
				if(this.$app.role=="ROLE_SOUS_TRAITANT") filters["field_tiers_id"] = {"attr":"c.tiers_id", "colId": "tiers_id", "value": this.$app.tiers_id, "action":"equals"};
				this.metadatasContrats.setFilters(filters);
				this.ContratsMixins_getContrats(this.metadatasContrats).then((datas)=>{
					this.showLoadingOverlay = false;
					this.$emit("fetch-success", datas);
				});
			},
			handleClickRow: function(e){
				this.$store.dispatch("ContratsStore/setSelectedItem", e.data);
				this.$emit("row-click", e.data);
			},
			handleDoubleClickRow: function(e){
                //this.$emit("row-dbl-click", e.data);
				this.$router.push('/contrat/'+e.data.id);
			},
			onClickDetailsInterventionsPrevisionnelle:function(row){
	            this.interventionsFilters.contrat_id.value = row.data.id;
	            this.showInterventionsModal = true;
	        },
			onClickShowReserves: function(contrat){
				//console.log("onClickShowReserves", contrat);
				this.focusedContrat = contrat;
				this.showReservesModal = true;
			},
	        onCloseInterventionsModal: function(){
	            this.interventionsFilters.contrat_id.value = null;
	            this.showInterventionsModal = false;
	        },
	        onCloseReservesModal: function(){
	            this.focusedContrat = null;
	            this.showReservesModal = false;
	        }
		},
		beforeDestroy:function(){

	    },
        computed:{
			...mapGetters({
				getContrats: 'ContratsStore/getCollection',
				selectedContrat: 'ContratsStore/getSelectedItem'
            }),
			/**
			* Récupère les filtres pour le tableau des réserves qui s'ouvre à partir de "voir réserves".
			* pour l'instant uniquement filtre "path", à voir par la suite si besoin autre filtre
			*/
			reservesDefaultFilters: function(){
				let pathFilter = this.filters.find((filter)=>{
					return filter.attr=="path";
				});
				pathFilter.attr = "l.path";
				return {
					"path": pathFilter
				};
			},
        }
    };
</script>
<style lang="scss" scoped>
.vg-contrats-table{
	height: 100%;
}
.cards-container{
	width: 100%;
	height: 90%;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	>div{
		width: 100%;
		border: 1px solid whitesmoke;
		padding: 5px;
		cursor: pointer;
	}
	.card-contrat:hover{
		border-left: 4px solid whitesmoke;
	}
	.card-contrat-selected{
		border: 1px solid #059cff;
	}
	.card-contrat-selected:hover{
		border-left: 4px solid #059cff;
	}
}

</style>
