<template>
    <vg-modal @save="handleSave" @close="$emit('cancel')" :isSaveDisabled="false" >
        <template #header>
            <h3 v-if="target" class="FSavC-head"><span class="head-p1">{{ $t("fm.mobile.zoom-modale-cloturer-header") }} : </span> <img width="15px;" style="vertical-align:top;" src="/static/assets/icone/wrench-red-light.png"> n° {{target.id}}</h3>
            <h3 v-else class="FSavC-head"> <span class="head-p1"> {{ $t("fm.mobile.zoom-modale-cloturer-header") }} </span> </h3>
        </template>
        <template #body>
			<div v-if="target" class="description">
                <vg-fichesav-description
                    :fm="target"
                    :showIcon="false" />
				<span class="FSavC-piece"> {{$t("dans")}} {{$vgutils.getPathEnd(getPiece)}}</span>
                <br>
                <div v-if="target && target.affectation" style="display:flex;justify-content:flex-start;align-items:center;gap:5px;">
                    <span>{{$t("affectation")}}: </span>
                    <vg-affecte-name v-for="affecte in target.affectation.affectes" v-if="!affecte.tiers_id" :affecte="affecte" :key="affecte.id" />
                    <vg-affecte-name v-for="affecte in target.affectation.affectes" v-if="affecte.tiers_id" :affecte="affecte" :key="affecte.id" />
                    <vg-button v-if="isAnAffectationExterne && !hasAnIntervention" 
                        type="info" 
                        size="sm" 
                        style="margin-left: 10px;" 
                        @click="showValiderIntervention=true;">
                        {{ $t("valider-intervention") }}
                    </vg-button>
                    <span v-else-if="isAnAffectationExterne && hasAnIntervention"
                        style="margin-left:10px;color:#00973a;">
                        <img src="static/assets/icone/verif-conforme.png" style="width:20px;height:20px;margin-right:2px;"/> {{ $t("intervention-validee") }}
                    </span>
                </div>
				<hr>
			</div>
			<div>
                <vg-working-time-selector v-if="getSelectedMaintenance && showWorkingTime"
                    :label="$t('field-workingTime')"
                    v-model="workingTime" />
                <br>
                <vg-button type="info" @click="showConsommations=true;">
                    {{$t("show-consommations")}}
                </vg-button>
                <small v-if="showConsommationsDone">{{$t("consommations-saisies")}} <i class="fas fa-check" style="color:green;"></i></small>
                <vg-consommations-form v-if="showConsommations"
                    @close="showConsommations=false;"
                    @save="showConsommations=false;showConsommationsDone=true;" />
                <hr>
                <vg-input :title="$t('field-rapport')"
					:inputType="'textarea'"
					v-model="rapportCloture"
                    :isRequiredValue="isRapportClotureCuratifRequired">
				</vg-input>
			</div>
            <vg-intervention-form v-if="showValiderIntervention"
                :idMaintenance="getSelectedMaintenance.id"
                :isPonctuelle="true"
                :idTiers="getTiersId"
                :defaultDateEffectiveDebut="getSelectedMaintenance.affectation.start"
                :defaultDateEffectiveFin="getSelectedMaintenance.affectation.end"
                @close="showValiderIntervention=false;"
                @save="onSaveIntervention" />
		</template>
        <template #footer>
            <vg-button @click="$emit('cancel')">{{$t("cancel")}}</vg-button>
            <vg-button :type="buttontypeComputed" type="danger" @click="handleClickCloturer" :disabled="isSaveButtonDisabled">{{$t("cloturer")}}</vg-button>
        </template>
    </vg-modal>

</template>

<script>

	import VgInput from 'src/components/Vg/VgInput.vue';
	import VgWorkingTimeSelector from 'src/components/Vg/FicheSAV/VgWorkingTimeSelector.vue';
	import VgConsommationsForm from 'src/components/Vg/Forms/VgConsommationsForm.vue';

	import ConsommablesMixins from "src/mixins/ConsommablesMixins.js";
	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
    import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
    import VgAffecteName from "src/components/Vg/Affectations/VgAffecteName.vue";
    import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";

    import VgButton from "src/components/Vg/VgButton.vue";
   	import { mapGetters } from 'vuex';

    export default {
		name:"fichesav-close",
        props:{
			target: Object,
			showConsommables: {
				type: Boolean,
				default: true
			},
            showWorkingTime: {
				type: Boolean,
				default: true
			},
			value: String
        },
		mixins: [ ConsommablesMixins, MaintenanceMixins ],
        components:{
			VgInput,
            VgConsommationsForm,
            VgWorkingTimeSelector,
            VgFichesavDescription,
            VgButton,
            VgAffecteName,
            VgInterventionForm
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "field-rapport": "Rapport de clôture",
            "field-workingTime": "Estimation durée intervention interne (en quart d'heure)",
            "dans": "dans",
            "cancel": "Annuler",
            "cloturer": "Clôturer",
            "show-consommations": "Saisir consommations",
            "consommations-saisies": "Consommations saisies",
            "affectation": "Affectation",
            "valider-intervention": "Enregistrer intervention tiers",
            "intervention-validee": "Intervention enregistrée",
            "fm": {
                "mobile": {
                    "zoom-modale-cloturer-header": "Clôture"
                }
            }
        },
        "en": {
            "field-rapport": "Closing report",
            "affectation": "Assignment",
            "valider-intervention": "Save third-party intervention",
            "intervention-validee": "Intervention saved",
            "fm": {
                "mobile": {
                    "zoom-modale-cloturer-header": "Close"
                }
            },
            "field-workingTime": "Estimating internal working Time (to the quarter of an hour)"
        },
        "th": {
            "fm": {
                "mobile": {
                    "zoom-modale-cloturer-header": "ปิด"
                }
            },
            "field-rapport": "กำลงปิดรายงาน",
            "field-workingTime": "เวลาทำงาน (ถึงสิบห้านาทีของหนึ่งชั่วโมง)"
        }
    }
},
		data: function(){
			return {
				rapportCloture: this.value,
                workingTime: this.$app.defaultWorkingTime,
				consommables: null,
                consommations: null,
                showConsommations: false,
                showValiderIntervention: false
			}
		},
		watch: {
			rapportCloture: function(value){
				this.$emit('input', value);
			},
		},
        created:function(){
            if(this.getSelectedMaintenance && this.getSelectedMaintenance.workingTime){
                this.workingTime = this.getSelectedMaintenance.workingTime;
            }
        },
		methods: {
            onSaveIntervention: function(){
                this.showValiderIntervention = false;
                this.MaintenanceMixins_getMaintenance(this.getSelectedMaintenance.id).then(()=>this.$forceUpdate());
            },
            handleSave: function(){
                if(this.showWorkingTime && this.consommations) this.$emit('confirm', {workingTime: this.workingTime, consommations: this.consommations});
                else if(this.showWorkingTime && !this.consommations) this.$emit('confirm', {workingTime: this.workingTime});
                else if(!this.showWorkingTime && this.consommations) this.$emit('confirm', {consommations: this.consommations});
                else this.$emit('confirm');
            },
			handleClickCloturer: function(){
                this.handleSave();
				/*var preConfirm = new Promise((resolve, reject) => {
						this.ConsommablesMixins_createConsommations(this.consommables, this.target.id).then((consommations)=>{
							// //console.log("AFTER CREATE CONSOMMATIONS", consommations);
                            this.consommations = consommations;
							this.ConsommablesMixins_createOperationsConsommations(consommations, this.target.id).then((operations)=>{
								// //console.log("AFTER CREATE OPERATIONS", operations);
								//resolve();
							});
                            if(this.target.isGEP!="1"){ // fm associee à un équipement
        						let consommablesEquipements = [];
        						this.consommables.forEach((conso)=>{
        							if(conso.isConsommableEquipement) consommablesEquipements.push({
        								consommable_id: conso.id,
        								equipement_id: this.target.idEq
        							});
        						});
        						this.ConsommablesMixins_createConsommablesEquipements(consommablesEquipements).then();
        					}
							var stocks = [];
                            this.consommables.forEach((consommable)=>{
                                stocks.push({
                                    id: consommable.stock.id,
                                    userId: this.$app.appID,
                                    quantite: Number(consommable.stock.quantite)-Number(consommable.consommation)
                                });
                            });
							this.ConsommablesMixins_updateStocks(stocks).then((datas) =>{}); // //console.log("AFTER UPDATE STOCK", datas));
						});
					resolve();
				});
				preConfirm.then(()=>this.handleSave());*/
			},

		},
        computed:{
            ...mapGetters({
                  getSelectedMaintenance: 'MaintenancesStore/getSelectedMaintenance'
            }),
            isSaveButtonDisabled: function(){
                return this.isRapportClotureCuratifRequired && (!this.rapportCloture || this.rapportCloture.length<2);
            },
            isRapportClotureCuratifRequired: function(){
                return this.$app.isRapportClotureCuratifRequired?this.$app.isRapportClotureCuratifRequired:false;
            },
            getTiersId: function(){
                if(this.getSelectedMaintenance.affectation && this.getSelectedMaintenance.affectation.affectes && this.getSelectedMaintenance.affectation.affectes.length!=0){
                    let tiers = this.getSelectedMaintenance.affectation.affectes.find((aff)=>aff.hasOwnProperty("tiers_id"));
                    return tiers?tiers.tiers_id:null;
                }else return null;
            },
            isGep:function(){
                return (this.target && this.target.isGEP != 1) || this.target.equipement.isGroupEqp != 1
            },
            equipementId: function(){
                return this.target.idEq != -1? this.target.idEq : this.target.equipement.id;
            },
            getPiece:function(){
                return this.target.path ? this.target.path : this.target.equipement.path;
            },
            hasAnIntervention: function(){
                let operations = this.getSelectedMaintenance.operations;
                let operationIntervention = operations.find((operation)=>operation.flag=="tiersinterventionponctuelle");
                if(operationIntervention!=null) return true;
                else return false;
            },
            isAnAffectationExterne: function(){
                if(this.getSelectedMaintenance && this.getSelectedMaintenance.affectation){
                    let affectation = Object.assign({}, {}, this.getSelectedMaintenance.affectation);
                    return affectation.affectes.findIndex((aff)=>aff.hasOwnProperty("tiers_id") && aff.tiers_id!=null)!=-1;
                }else{
                    return false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.FSavC-head{
    font-size: 17px;
    color:#787878;
    .head-p1{
        font-weight: bold;
        color:black;
    }
}
.FSavC-description{
    font-weight: bold;
    font-size: 16px;
    color:#787878;
}
.FSavC-piece{
    font-size:13px;
}
.FSavC-btn-valid{
    background-color: #FF6927;
}
.description{
    .vg-fichesav-description{
        white-space: initial;
    }
}
</style>
