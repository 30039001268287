function StatutFilter() {
}

StatutFilter.prototype.init = function (params) {
    //console.log("INIT", params);
    this.params = params;
    this.valueGetter = params.valueGetter;
    this.filterText = null;
    this.setupGui(params);
};

// not called by ag-Grid, just for us to help setup
StatutFilter.prototype.setupGui = function (params) {
    //console.log("setupGui", params);
	this.gui = document.createElement("div");
	var html = '<div style="padding: 4px;">'
		+'<select id="statutSelector" style="min-width:100px;">'
		+'<option value="" disabled selected>Choose</option>';
    html += '<option value="En_cours">En cours</option>';
    html += '<option value="en_attente">En attente</option>';
    html += '<option value="prise_en_compte">Prise en compte</option>';
    html += '<option value="Resolue">Résolue</option>';
	html += '</select>'
		+'<i class="fas fa-eraser" style="margin-left: 4px;cursor: pointer;" id="removeStatutFilter"></i>'
		+'</div>';

	this.gui.innerHTML = html;

	this.eStatutSelector = this.gui.querySelector("#statutSelector");
	this.eStatutSelector.addEventListener("change", (event) => {
		//// //console.log("CHANGE", this.filterText);
		this.filterText = event.target.value;

        params.filterChangedCallback();
	});
	this.eRemoveFilter = this.gui.querySelector("#removeStatutFilter");
	this.eRemoveFilter.addEventListener("click", (event) => {
		this.gui.querySelector("#statutSelector").value = null;
		//// //console.log("REMOVE", this.filterText);
		this.filterText = null;
        params.filterChangedCallback();
	});
};

StatutFilter.prototype.getGui = function () {
    return this.gui;
};

StatutFilter.prototype.doesFilterPass = function (params) {
    // make sure each word passes separately
    //console.log("DOES FILTER PASS", params, this.filterText);
	var valueGetter = this.valueGetter;
	var value = valueGetter(params);
    switch (typeof(this.filterText)) {
        case "string":
            return value==this.filterText;
            break;
        case "object":
            return this.filterText.indexOf(value)!=-1
            break;
        case "array":
            return this.filterText.indexOf(value)!=-1
            break;
        default:
            return value==this.filterText;
            break;
    }
};
/*StatutFilter.prototype.setFilter = function (filter) {
    // //console.log("SET FILTER", filter, this.params.api.getModel());
    if(filter){
        let nodesFiltered = [];
        this.params.api.forEachNode((node)=>{
            // //console.log("NODE", node.data.statut, filter);
            if(filter.indexOf(node.data.statut)!=-1)nodesFiltered.push(node);
        });
        return nodesFiltered;
    }
    return false;
};*/

StatutFilter.prototype.isFilterActive = function () {
    return this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
};

StatutFilter.prototype.getModel = function() {
    var model = {value: this.filterText};
    return model;
};

StatutFilter.prototype.setModel = function(model) {
    //console.log("SET MODEL", model, this.filterText);
    this.filterText = model.filter;
};

export default StatutFilter;
