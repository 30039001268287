const axios = require('axios');
import { mapGetters } from 'vuex';
export default {
	data: function () {
		return {
			VgFilesMixins:{
				uid: this.VgFilesMixins_getUid(),
				files: null,
				tags: null,
				defaultTagId: null,
				type: this.VgFilesMixins_getType(),
				uploadState: null, // peut etre aussi progressState à voir...
				thumbnailLink: null
			}
		}
	},
	created: function () {

	},
	methods: {
		/**
		* Initialize firebase app.
		* https://firebase.google.com/docs/reference/js/v8
		* @param Object __config
		* @return
		*/
		VgFilesMixins_initializeFirebase: function(){
			firebase.initializeApp(JSON.parse(this.$app.__fconf));
		},
		/**
		* Logout firebase app.
		* @param Object __config
		* @return
		*/
		VgFilesMixins_logoutFirebaseApp: function(){
			firebase.app().delete().then(()=>{
				console.log("App deleted successfully");
			})
			.catch((error)=>{
				console.log("Error deleting app:", error);
			});
		},
		/**
		* Get session storage content firebase:authUser:<apiKey>:[DEFAULT].
		* @param
		* @return
		*/
		VgFilesMixins_getFirebaseUserInSessionStorage: function(){
			const apiKey = JSON.parse(this.$app.__fconf).apiKey;
			return JSON.parse(sessionStorage.getItem("firebase:authUser:"+apiKey+":[DEFAULT]"));
		},
		/**
		* Delete session storage content firebase:authUser:<apiKey>:[DEFAULT].
		* @param
		* @return
		*/
		VgFilesMixins_deleteFirebaseUserInSessionStorage: function(){
			const apiKey = JSON.parse(this.$app.__fconf).apiKey;
			window.sessionStorage.removeItem("firebase:authUser:"+apiKey+":[DEFAULT]");
		},
		/**
		* get firebase storage in sessionStorage
		* @param
		* @return
		*/
		VgFilesMixins_getFirebaseStorage: function(){
			return sessionStorage.getItem("__fstorageref");
		},
		/**
		* authenticated user with custom token to firebase
		* @return
		*/
		VgFilesMixins_connectToFirebase: function(){
			return new Promise((resolve, reject) => {
				firebase.auth().signInWithCustomToken(this.$app.__ft).catch((error)=>{
					// Handle Errors here.
					var errorCode = error.code;
					var errorMessage = error.message;
					//console.log("******FIREBASE******* SIGN IN WITH CUSTOM TOKEN ERROR", errorCode, errorMessage);

				});
				firebase.auth().onAuthStateChanged((user)=>{
					// //console.log("******FIREBASE******* ON AUTH STATE CHANGED", user);
					//// //console.log("******FIREBASE******* STORAGE", firebase.storage());
					//window.sessionStorage.setItem("__fstorageref", firebase.storage().ref());
					if (user) resolve();
				});
				firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION).then(()=>{
					// Existing and future Auth states are now persisted in the current
					// session only. Closing the window would clear any existing state even
					// if a user forgets to sign out.
					// ...
					// New sign-in will be persisted with session persistence.
					//return firebase.auth().signInWithEmailAndPassword(email, password);
					//console.log("******FIREBASE******* SET PERSISTENCE");
				})
				.catch((error)=>{
					// Handle Errors here.
					var errorCode = error.code;
					var errorMessage = error.message;
					//console.log("******FIREBASE******* SET PERSISTENCE ERROR", errorCode, errorMessage);
					reject(error);
				});
            });
		},
		/**
		* sign out
		* @return
		*/
		VgFilesMixins_disconnectToFirebase: function(){
			firebase.auth().signOut();
		},
		/**
		* set uid of targeted element
		* @param String uid
		* @return
		*/
		VgFilesMixins_setUid: function(uid){
			this.VgFilesMixins.uid = uid;
			window.sessionStorage.setItem('_targeted_uid', uid);
		},
		/**
		* get uid in session storage
		* @return String uid
		*/
		VgFilesMixins_getUid: function(){
			return window.sessionStorage.getItem('_targeted_uid');
		},
		/**
		* set type of targeted element
		* @param String type
		* @return
		*/
		VgFilesMixins_setType: function(type){
			this.VgFilesMixins.type = type;
			window.sessionStorage.setItem('_targeted_type', type);
		},
		/**
		* get type in session storage
		* @return String type
		*/
		VgFilesMixins_getType: function(){
			return window.sessionStorage.getItem('_targeted_type');
		},
		/**
		* set default tag ie id tag de la photo
		* @return
		*/
		VgFilesMixins_setDefaultTagId: function(){
			let defaultTag = this.VgFilesMixins.tags.find((tag)=>tag.label=="Photo");
			if(defaultTag) this.VgFilesMixins.defaultTagId = defaultTag.id;
			window.sessionStorage.setItem('_targeted_default_tag_id', defaultTag.id);
		},
		/**
		* get default tag id
		* @return String id
		*/
		VgFilesMixins_getDefaultTagId: function(){
			return window.sessionStorage.getItem('_targeted_default_tag_id');
		},
		/**
		* initialize
		* @param String uid
		* @param String type (equipement, lieu, ...)
		* @return
		*/
		VgFilesMixins_init: function(uid, type){
			// //console.log("VGFILESMIXINS ------------ init", uid, type);
			this.VgFilesMixins_setUid(null);
			this.VgFilesMixins_setType(null);

			this.VgFilesMixins_setUid(uid);
			this.VgFilesMixins_setType(type);

			this.VgFilesMixins.tags = null;
			this.VgFilesMixins.files = null;

			if(type) this.VgFilesMixins_fetchTagsByType(type);
			if(uid) this.VgFilesMixins_fetchFilesByUid();
		},
		/**
		* fetch files array of targeted element by element uid
		* @return
		*/
		VgFilesMixins_fetchFilesByUid: function(uid=null){
			let entityUid = !uid ? this.VgFilesMixins.uid : uid;
			////console.log("VG FILES MIXINS FETCH FILES BY UID", entityUid);
			return new Promise((resolve, reject) => {
				this.$rc.get('/api/'+this.$app.appID+'/documents/'+entityUid, null, (files) => {
					// //console.log("GET FILES", files);
					if(!uid && files){
						this.VgFilesMixins.files = files;
						resolve(files);
					}else if(uid && files){
						resolve(files);
					}else reject();
				});
            });
		},
		/**
		* fetch tags by type
		* (documents/fichemaintenance documents/equipement documents/categorie documents/lieu documents/tiers documents/contrat documents/intervention)
		* @return
		*/
		VgFilesMixins_fetchTagsByType: function(type){
			return new Promise((resolve, reject) => {
				this.$rc.get('/api/'+this.$app.appID+'/tags/'+type, null, (tags) => {
					this.$store.dispatch("DocumentsStore/setTagsCollection", tags);
					this.VgFilesMixins.tags = tags;
					this.VgFilesMixins_setDefaultTagId();
					resolve();
				});
            });
		},
		/**
		* get file name
		* @param ObjectFile htmlfile
		* @param Boolean unnamed
		* @return
		*/
		VgFilesMixins_getName: function(htmlfile, unnamed){
			let extension = htmlfile.name.split(".")[htmlfile.name.split(".").length-1];
			if(!unnamed) return htmlfile.name;
			else return "IMG_"+moment().unix().toString()+"."+extension; // enregistre le name en format timestamp
		},
		/**
		* upload file ie upload to firebase and save reference to DB
		* @param Object htmlfile representation of file through an input type file html element
		* @param Integer idTag
		* @param Boolean unnamed default false
		* @return
		*/
		VgFilesMixins_uploadFile: function(htmlfile, idTag, unnamed=false){
			return new Promise((resolve, reject) => {
				const fileName = this.VgFilesMixins_getName(htmlfile, unnamed);
				this.VgFilesMixins_uploadFileInFirebase(htmlfile, fileName).then( (response) => {
					this.VgFilesMixins_saveFileInDB({
						name: fileName,
						size: htmlfile.size,
						extension: htmlfile.type,
						uploadedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
						uploadedTo: this.VgFilesMixins_getUid(),
						owner_id: this.$app.idUser,
						idTag: idTag,
						type: "documents/"+this.VgFilesMixins_getType()
					}).then( (document) => {
						this.VgFilesMixins.uploadState = null;
						resolve(document);
					}).catch((e)=>reject(e));
				}).catch((e)=>{
					reject(e);
				});
			});
		},
		/**
		* upload file ie upload to firebase and save reference to DB
		* @param Object htmlfile representation of file through an input type file html element
		* @param Integer idTag
		* @param Boolean unnamed default false
		* @return
		*/
		VgFilesMixins_uploadPDFBlob: function(blob, uid, idTag, typeTag, fileName){
			return new Promise((resolve, reject) => {
				this.VgFilesMixins_uploadFileInFirebase(blob, fileName, uid).then( (response) => {
					this.VgFilesMixins_saveFileInDB({
						name: fileName,
						size: blob.size,
						extension: blob.type,
						uploadedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
						uploadedTo: uid,
						owner_id: this.$app.idUser,
						idTag: idTag,
						type: "documents/"+typeTag
					}, uid).then( (document) => {
						this.VgFilesMixins.uploadState = null;
						resolve(document);
					}).catch((e)=>reject(e));
				}).catch((e)=>{
					reject(e);
				});
			});
		},
		/**
		* upload blob image ie upload to firebase and save reference to DB
		* @param string image representation of image jpg
		* @param string name default timestamp
		* @return
		*/
		VgFilesMixins_uploadImageBlob: function(imageBlob, name=moment().format("x")){
			////console.log("UPLOAD IMAGE", imageBlob, name);
			let extension = imageBlob.type?imageBlob.type.split("/")[1]:"jpg";
			let imageName = name+"."+extension;
			return new Promise((resolve, reject) => {
				this.VgFilesMixins_uploadFileInFirebase(imageBlob, imageName).then( (response) => {
					////console.log("SUCCESS UPLOAD", response);
					this.VgFilesMixins_saveFileInDB({
						name: imageName,
						size: imageBlob.size,
						extension: imageBlob.type,
						uploadedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
						uploadedTo: this.VgFilesMixins_getUid(),
						owner_id: this.$app.idUser,
						idTag: this.VgFilesMixins_getDefaultTagId(),
						type: "documents/"+this.VgFilesMixins_getType()
					}).then( (document) => {
						this.VgFilesMixins.uploadState = null;
						resolve(document);
					}).catch((e)=>reject(e));
				}).catch((e)=>reject(e));
			});
		},
		/**
		* save file reference in DB
		* @param Object file file object entity Documents in DB
		* @return
		*/
		VgFilesMixins_saveFileInDB: function(file, _uid=null){
			// //console.log("saveFileInDB", file);
			return new Promise((resolve, reject) => {
				let uid = _uid?_uid:this.VgFilesMixins_getUid();
				console.log("VgFilesMixins_saveFileInDB UID", uid);
	            this.$rc.post('/api/'+this.$app.appID+'/documents/'+uid, file, (document) => {
	                resolve(document);
	            });
            });
		},
		/**
		* update file in DB
		* @param Object file file object entity Documents in DB
		* @return
		*/
		VgFilesMixins_updateFileInDB: function(file){
			console.log("VgFilesMixins_updateFileInDB", file);
			return new Promise((resolve, reject) => {
	            this.$rc.put('/api/document/'+file.id, file, (document) => {
	                resolve(document);
	            });
            });
		},
		/**
		* upload file to firebase
		* @param Object file representation of file through an input type file html element
		* @param String name file name
		* @return Promise
		*/
		VgFilesMixins_uploadFileInFirebase: function(file, name, _uid=null){
			////console.log("UPLOAD TO FIREBASE", this.VgFilesMixins.uid);

			var storageRef = firebase.storage().ref();

			return new Promise((resolve, reject) => {
				////console.log("FILE", file);
				var metadata = {
					contentType: file.type?file.type:"image/jpeg",
					cacheControl: 'public,max-age=31536000'
				};
				let uid = _uid?_uid:this.VgFilesMixins_getUid();
				console.log("UID", uid);
				var uploadTask = storageRef.child('documents/'+this.$app.__fLocalId+'/'+uid+'/'+name).put(file, metadata);
				uploadTask.on('state_changed', (snapshot) => {
					//this.VgFilesMixins.uploadState = snapshot;
					console.log("UPLOAD STATE FILE - ", snapshot.bytesTransferred, " / ", snapshot.totalBytes);
					console.log("REF FILE ",snapshot.ref.name, snapshot.ref.fullPath);
					this.VgFilesMixins.uploadState = {
						bytesTransferred: snapshot.bytesTransferred,
						totalBytes: snapshot.totalBytes,
						name: snapshot.ref.name,
						fullPath: snapshot.ref.fullPath
					};
				}, (error) => {
					console.log("IMPOSSIBLE D'ENVOYER LE DOCUMENT", error);
					alert("IMPOSSIBLE D'ENVOYER LE DOCUMENT.");
					// Handle unsuccessful uploads
					////console.log("ERROR", error);
					this.VgFilesMixins.uploadState = null;
					reject(error);
				}, () => {
					console.log("UPLOAD FILE DONE");
					// Handle successful uploads on complete
					// For instance, get the download URL: https://firebasestorage.googleapis.com/...
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						console.log('File available at', downloadURL);
						resolve(downloadURL);
					});
				});
            });
		},
		/**
		* upload a base64 string to firebase
		* @param Object file representation of file through an input type file html element
		* @param String name file name
		* @return Promise
		*/
		VgFilesMixins_uploadBase64StringInFirebase: function(base64String, name, _uid=null, _type="image/jpeg"){
			////console.log("UPLOAD TO FIREBASE", this.VgFilesMixins.uid);

			var storageRef = firebase.storage().ref();

			return new Promise((resolve, reject) => {
				////console.log("FILE", file);
				var metadata = {
					contentType: _type?_type:"image/jpeg",
					cacheControl: 'public,max-age=31536000'
				};
				let uid = _uid?_uid:this.VgFilesMixins_getUid();
				console.log("UID", uid);
				var uploadTask = storageRef.child('documents/'+this.$app.__fLocalId+'/'+uid+'/'+name).putString(base64String, "base64", metadata);
				uploadTask.on('state_changed', (snapshot) => {
					//this.VgFilesMixins.uploadState = snapshot;
					console.log("UPLOAD STATE FILE - ", snapshot.bytesTransferred, " / ", snapshot.totalBytes);
					console.log("REF FILE ",snapshot.ref.name, snapshot.ref.fullPath);
					this.VgFilesMixins.uploadState = {
						bytesTransferred: snapshot.bytesTransferred,
						totalBytes: snapshot.totalBytes,
						name: snapshot.ref.name,
						fullPath: snapshot.ref.fullPath
					};
				}, (error) => {
					console.log("IMPOSSIBLE D'ENVOYER LE DOCUMENT", error);
					alert("IMPOSSIBLE D'ENVOYER LE DOCUMENT.");
					// Handle unsuccessful uploads
					////console.log("ERROR", error);
					this.VgFilesMixins.uploadState = null;
					reject(error);
				}, () => {
					console.log("UPLOAD FILE DONE");
					// Handle successful uploads on complete
					// For instance, get the download URL: https://firebasestorage.googleapis.com/...
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						console.log('File available at', downloadURL);
						resolve(downloadURL);
					});
				});
            });
		},
		/**
		* get uid of current file
		* @param Object file representation of file in DB
		* @return String uid
		*/
		VgFilesMixins_getFileUid: function(file){
			let uid = file.tagType=="documents/operation"?"null":file.uploadedTo;
			return uid;
		},
		/**
		* get download file link
		* @param Object file representation of file in DB
		* @return String download link
		*/
		VgFilesMixins_getDownloadFileLink: function(file){
			return new Promise((resolve, reject) => {
				let uid = this.VgFilesMixins_getFileUid(file);
				const storageRef = firebase.storage().ref();
				let pathToFile = file.isDemo?"demo":this.$app.__fLocalId+"/"+uid;
				storageRef.child('documents/'+pathToFile+'/'+file.name).getDownloadURL().then(function(link) {
					// //console.log("******FIREBASE******* FILE DOWNLOAD LINK", link);
					resolve(link);
				}).catch((error)=>{
					reject(error);
				});
			});
		},
		/**
		* get thumbnail link
		* @param Object file representation of file in DB
		* @return String thumbnail link
		*/
		VgFilesMixins_getThumbnailLink: function(file, isPromise=false){
			let uid = this.VgFilesMixins_getFileUid(file);
			this.VgFilesMixins.thumbnailLink = null;
			if(!isPromise){
				if(file.name){
					const storageRef = firebase.storage().ref();
					let pathToFile = file.isDemo?"demo":this.$app.__fLocalId+"/"+uid;
					storageRef.child('documents/'+pathToFile+'/thumb_'+file.name).getDownloadURL().then((link) => {
						// //console.log("******FIREBASE******* THUMBNAIL LINK", link);
						this.VgFilesMixins.thumbnailLink = link;
					}).catch((error) => {
						// //console.log("******FIREBASE******* THUMBNAIL LINK ERROR", error);
						reject();
						//this.VgFilesMixins_getDownloadFileLink(file).then((link)=>this.VgFilesMixins.thumbnailLink = link);
					});
				}
			}else{
				return new Promise((resolve, reject) => {
					if(!file.name) resolve(null);
					const storageRef = firebase.storage().ref();
					let pathToFile = file.isDemo?"demo":this.$app.__fLocalId+"/"+uid;
					storageRef.child('documents/'+pathToFile+'/thumb_'+file.name).getDownloadURL().then((link) => {
						// //console.log("******FIREBASE******* THUMBNAIL LINK", link);
						resolve(link);
					}).catch((error) => {
						// //console.log("******FIREBASE******* THUMBNAIL LINK ERROR", error);
						reject();
					});
				});
			}
		},
		/**
		* download file by url
		* @param Object file
		* @param String url file url
		* @return
		*/
		VgFilesMixins_downloadFile: function(file, url){
			axios({
				url: url,
				method: 'GET',
				responseType: 'blob'
			}).then((response) => {
				// //console.log("DOWNLOAD FILE", response);
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name); //or any other extension
				document.body.appendChild(link);
				link.click();
			});
		},
		/**
		* delete file
		* @param Object file
		* @param Boolean hasThumbnail default true
		* @return
		*/
		VgFilesMixins_deleteFile: function(file, hasThumbnail=true){
			return new Promise((resolve, reject) => {
				// delete file in DB
				this.$rc.delete('/api/document/'+file.id+'?userId='+this.$app.appID, null, (document) => {
					// delete file in firebase
					if(!file.isDemo){
						let storageRef = firebase.storage().ref();
						let uid = this.VgFilesMixins.uid? this.VgFilesMixins.uid: 'null';
						storageRef.child('documents/'+this.$app.__fLocalId+'/'+uid+'/'+file.name).delete().then((d)=>{
							if(hasThumbnail) storageRef.child('documents/'+this.$app.__fLocalId+'/'+uid+'/thumb_'+file.name).delete();
							resolve(document);
						});
					}
	            });
			});
		},
		/**
		* j'ai du refaire une version du computed VgFilesMixins_filesListSection
		* lorsqu'on est sur un zoom tiers & qu'on zoom sur une intervention il ne faut pas perdre le pointer documents du zoom tiers
		* files=[ {
                    "id": "122171",
                    "uploadedTo": "77388871-e11b-11ea-a342-0aaf3ef5f2f8",
                    "description": null,
                    "uid": "7969b80c-e11b-11ea-a342-0aaf3ef5f2f8",
                    "name": "117264276_695150494367501_5649267983094690849_n.jpg",
                    "userId": "7uGOJgrDnp",
                    "uploadedAt": "2020-08-18 10:24:30",
                    "size": "78046",
                    "created_at": "2020-08-18 06:24:30",
                    "updated_at": null,
                    "extension": "image/jpeg",
                    "email": "sabrina.dohring.test@gmail.com",
                    "nom": "Nomtest",
                    "prenom": "Prenomtest",
                    "tags_id": "781",
                    "label": "Bon intervention",
                    "type": "documents/intervention",
                    "color": null
                }, ...]
		* tags = [{
            "id": "1011",
            "label": "sanitaire",
            "type": "composant/type",
            "color": null,
            "userId": null,
            "created_at": "2020-01-20 07:24:09",
            "updated_at": "2020-01-20 07:24:09"
        }, ...]
		*/
		VgFilesMixins_normalizeFilesToListSection: function(files, tags){
			if(!files || !tags) return null;
			var result = tags.map( (tag) => {
				return {tag: tag, files: []};
			});
			files.forEach( (file) => {
				if(file.tags_id && result.findIndex( (item) => item.tag.id == file.tags_id)!=-1)
					result[result.findIndex( (item) => item.tag.id == file.tags_id)].files.push(file);
				else if(
					file.isInherited
					&& result.findIndex( (item) => item.tag && item.tag.label && file.label && item.tag.label.toLowerCase() == file.label.toLowerCase())!=-1
				) result[result.findIndex( (item) => item.tag && item.tag.label && file.label && item.tag.label.toLowerCase() == file.label.toLowerCase())].files.push(file);
			});
			return result;
		},
        /**
		* Récupère les fichiers du plan interactif.
		* IE: fond de plan & geoJsons associés (pieces, pieces_draft, equipements, equipements_draft)
		*
        * @return Promise
        */
        VgFilesMixins_getPlanInteractifFiles: function(){
            return new Promise((resolve, reject)=> {
                this.VgFilesMixins_fetchFilesByUid().then((files)=>{
					console.log("GET PI FILES", files)
					if(files && files.length!=0){
						let fondDePlan = this.VgFilesMixins_getPlanInteractifFile(files, "fond-de-plan");
						let geoJsonPieces = this.VgFilesMixins_getPlanInteractifFile(files, "geojson-pieces");
						let geoJsonPiecesDraft = this.VgFilesMixins_getPlanInteractifFile(files, "geojson-pieces-draft");
						let geoJsonEquipements = this.VgFilesMixins_getPlanInteractifFile(files, "geojson-equipements");
						let geoJsonEquipementsDraft = this.VgFilesMixins_getPlanInteractifFile(files, "geojson-equipements-draft");
						Promise.all([fondDePlan, geoJsonPieces, geoJsonPiecesDraft, geoJsonEquipements, geoJsonEquipementsDraft]).then((values)=>{
							resolve({
								"fond-de-plan": values[0],
								"geojson-pieces": values[1],
								"geojson-pieces-draft": values[2],
								"geojson-equipements": values[3],
								"geojson-equipements-draft": values[4]
							});
						}).catch((e)=>reject(e));
					}else{
						reject("no files found");
					}
                });
            });
        },
		/**
		*
		*/
		VgFilesMixins_getPlanInteractifFile: function(files, tagLabel){
			return new Promise((resolve, reject)=>{
				let tag = this.VgFilesMixins.tags.find((tag)=>tag.label==tagLabel);
				let file = files.find((f)=>f.tagType=="documents/"+this.VgFilesMixins.type && f.tagId==tag.id);
				if(!file) resolve(null);
				else{
					this.VgFilesMixins_getDownloadFileLink(file).then((link)=>{
						resolve({file: file, link: link});
					}).catch((e)=>{
						console.log("ERROR", e);
						reject(e);
					});
				}
			});
		},
		/**
		* create blob à partir d'un string ou array ou object
		* @param string | array | object content
		* @return File
		*/
		VgFilesMixins_newBlob: function(content, _options={type:"text/plain"}){
			return new Blob([typeof(content)!="string"?JSON.stringify(content):content], _options);
		},
		/**
		* Upload geoJson draft content on firebase.
		*
		* @param object lieu - lieu auquel attacher le geoJson
		* @param string | array | object content
		* @param string type default pieces pieces | equipements
		* @return promise
		*/
		VgFilesMixins_saveGeoJsonDraft: function(lieu, content, fileInStorage, type="pieces"){
			return new Promise((resolve, reject) => {
				let fileName = "geoJson_"+type+"_draft.geojson";
				let geoJsonFile = this.VgFilesMixins_newBlob(content);
				console.log("DOCUMENT TAGS", this.tags);
				let tag = this.tags.find((tag)=>tag.label=="geojson-"+type+"-draft");
				this.VgFilesMixins_uploadFileInFirebase(geoJsonFile, fileName).then( (response) => {
					console.log("VgFilesMixins_uploadFileInFirebase", response);
					if(fileInStorage && fileInStorage.file){ // update le fichier
						let updatedFile = Object.assign({}, fileInStorage.file, {
							size: geoJsonFile.size,
							uploadedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
							owner_id: this.$app.idUser,
							userId: this.$app.userId
						});
						this.VgFilesMixins_updateFileInDB(updatedFile).then((document)=>{
							this.VgFilesMixins.uploadState = null;
							resolve(document);
						}).catch((e)=>reject(e));
					}else{
						this.VgFilesMixins_saveFileInDB({
							name: fileName,
							size: geoJsonFile.size,
							extension: geoJsonFile.type,
							uploadedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
							uploadedTo: lieu.uid,
							owner_id: this.$app.idUser,
							idTag: tag.id,
							type: "documents/plan-interactif"
						}).then( (document) => {
							this.VgFilesMixins.uploadState = null;
							resolve(document);
						}).catch((e)=>reject(e));
					}
				}).catch((e)=>reject(e));
			});
		},
		/**
		* Upload geoJson content on firebase.
		*
		* @param object lieu - lieu auquel attacher le geoJson
		* @param string | array | object content
		* @param Object original {file: {}, link:...}
		* @param Object draft {file: {}, link:...}
		* @param string type default pieces pieces|equipements
		* @return promise
		*/
		VgFilesMixins_saveGeoJson: function(lieu, content, original, draft, type="pieces"){
			return new Promise((resolve, reject) => {
				let fileName = "geoJson_"+type+".geojson";
				let geoJsonFile = this.VgFilesMixins_newBlob(content);
				let tag = this.tags.find((tag)=>tag.label=="geojson-"+type);
				this.VgFilesMixins_uploadFileInFirebase(geoJsonFile, fileName).then( (response) => {
					console.log("VgFilesMixins_uploadFileInFirebase", response);
					if(original && original.file){	// fichier geoJson déjà référencé dans la DB
						// ne recréé pas la référence du fichier dans la DB
						this.VgFilesMixins.uploadState = null;
						// delete geoJson_draft associé
						if(draft && draft.file){
							this.VgFilesMixins_deleteFile(draft.file, false).then((data)=>{
								resolve(document);
							}).catch((e)=>reject(e));
						}else{
							resolve(document);
						}
					}else{	// nouveau fichier geoJson
						this.VgFilesMixins_saveFileInDB({
							name: fileName,
							size: geoJsonFile.size,
							extension: geoJsonFile.type,
							uploadedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
							uploadedTo: lieu.uid,
							owner_id: this.$app.idUser,
							idTag: tag.id,
							type: "documents/plan-interactif"
						}).then( (document) => {
							this.VgFilesMixins.uploadState = null;
							// delete geoJson_draft associé
							if(draft && draft.file){
								this.VgFilesMixins_deleteFile(draft.file, false).then((data)=>{
									resolve(document);
								}).catch((e)=>reject(e));
							}else{
								resolve(document);
							}
						}).catch((e)=>reject(e));
					}
				}).catch((e)=>reject(e));
            });
		},
		/**
		* Get geoJson content on firebase.
		*
		* @param object lieu
		* @return promise
		*/
		VgFilesMixins_getGeoJson: function(lieu){
			return new Promise((resolve, reject) => {
				const storageRef = firebase.storage().ref();
				storageRef.child('documents/'+this.$app.__fLocalId+'/'+lieu.uid+'/geoJson/geoJson_pieces_v1.geojson').getDownloadURL().then((link) => {
					this.VgFilesMixins_getFileContentFromUrl(link, true).then((content)=>resolve(content));
				}).catch((error) => {
					reject(error);
				});
            });
		},
		/**
		*
		*/
		VgFilesMixins_getFileContentFromUrl: function(link, parseToJson=false){
			return new Promise((resolve, reject) => {
				// read text from URL location
				let request = new XMLHttpRequest();
				request.open('GET', link, true);
				request.send(null);
				request.onreadystatechange = function () {
					if (request.readyState === 4 && request.status === 200) {
						var type = request.getResponseHeader('Content-Type');
						resolve(parseToJson?JSON.parse(request.responseText):request.responseText);	// json parse only json
					}
				};
            });

		},
		/**
		* Convert le fichier en base64.
		*
		* @param Object file
		* @return Promise
		*/
		VgFilesMixins_convertFileToBase64String: function(file){
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},
		/**
		* Récupère le fichier depuis l'url passée en paramètre et retourne le fichier en base64.
		* @param String url url du fichier
		* @return Promise
		*/
		VgFilesMixins_convertUrlToBase64String: function (url) {
			return new Promise((resolve, reject) => {
				let xhr = new XMLHttpRequest();
				xhr.onload = ()=>{
					let reader = new FileReader();
					reader.onloadend = () => {
						resolve(reader.result);
					};
					reader.readAsDataURL(xhr.response);
				};
				xhr.open('GET', url);
				xhr.responseType = 'blob';
				xhr.send();
			});
		},
		/**
		* Récupère la signature en base64.
		* @return logoFileData
		*/
		VgFilesMixins_getBase64UserSignature: function(){
			return new Promise((resolve, reject)=>{
				this.$rc.get('/api/'+this.$app.appID+'/documents/'+this.$app.user_uid, null, (files) => {
					let signatureFile = files.find((file)=>file.tagId==5101 && file.tagType=="documents/user-signature" && file.tagLabel=="signature");
					if(signatureFile){
						this.VgFilesMixins_getDownloadFileLink(signatureFile).then((link)=>{
							this.VgFilesMixins_convertUrlToBase64String(link).then((base64Signature)=>{
								resolve(base64Signature);
							});
						});
					}else{
						resolve(null);
					}
				});
			});
		},
		/**
		* Récupère l'url de la signature.
		* @return string url
		*/
		VgFilesMixins_getUrlUserSignature: function(){
			return new Promise((resolve, reject)=>{
				this.$rc.get('/api/'+this.$app.appID+'/documents/'+this.$app.user_uid, null, (files) => {
					let signatureFile = files.find((file)=>file.tagId==5101 && file.tagType=="documents/user-signature" && file.tagLabel=="signature");
					if(signatureFile){
						this.VgFilesMixins_getDownloadFileLink(signatureFile).then((link)=>{
							resolve(link);
						});
					}else{
						resolve(null);
					}
				});
			});
		},
		/**
		* Récupère le logo en base64.
		* @return logoFileData
		*/
		VgFilesMixins_getBase64Logo: function(){
			return new Promise((resolve, reject) => {
				this.$rc.get('/api/'+this.$app.appID+'/documents/'+this.$app.account_uid, null, (files) => {
					let logoFile = files.find((file)=>file.tagId==2711 && file.tagType=="account/logo" && file.tagLabel=="logo");
					if(logoFile){
						this.VgFilesMixins_getDownloadFileLink(logoFile).then((link)=>{
							this.VgFilesMixins_convertUrlToBase64String(link).then((base64Logo)=>{
								window.sessionStorage.setItem("account_logo", base64Logo);
								resolve(base64Logo);
							});
						});
					}
				});
            });
		},
		/**
		* Récupère le document en base64.
		* @param Object document
		* @return logoFileData
		*/
		VgFilesMixins_getBase64Document: function(document){
			return new Promise((resolve, reject) => {
				this.$rc.get('/api/'+this.$app.appID+'/documents/'+document.uploadedTo, null, (files) => {
					if(files && files.length!=0){
						let logoFile = files[0];
						this.VgFilesMixins_getDownloadFileLink(logoFile).then((link)=>{
							this.VgFilesMixins_convertUrlToBase64String(link).then((base64Logo)=>{
								resolve(base64Logo);
							});
						});
					}
				});
            });
		},
		/**
		* Récupère le document en base64.
		* @param Object document
		* @return logoFileData
		*/
		VgFilesMixins_getUrlFirstDocument: function(uploadedTo){
			return new Promise((resolve, reject) => {
				this.$rc.get('/api/'+this.$app.appID+'/documents/'+uploadedTo, null, (files) => {
					if(files && files.length!=0){
						this.VgFilesMixins_getDownloadFileLink(files[0]).then((link)=>{
							resolve(link);
						});
					}
				});
            });
		},
	},
	computed: {
		...mapGetters({
            tags: 'DocumentsStore/getTagsCollection'
        }),
		VgFilesMixins_filesListSection: function(){
			var files = this.VgFilesMixins.files;
			var tags = this.VgFilesMixins.tags;
			if(!files || !tags) return null;
			var result = tags.map( (tag) => {
				return {tag: tag, files: []};
			});
			files.forEach( (file) => {
				if(file.tagId && result.findIndex( (item) => item.tag.id == file.tagId)!=-1)
					result[result.findIndex( (item) => item.tag.id == file.tagId)].files.push(file);
				else if(
					file.isInherited
					&& result.findIndex( (item) => item.tag && item.tag.label && file.tagLabel && item.tag.label.toLowerCase() == file.tagLabel.toLowerCase())!=-1
				) result[result.findIndex( (item) => item.tag && item.tag.label && file.tagLabel && item.tag.label.toLowerCase() == file.tagLabel.toLowerCase())].files.push(file);
			});
			return result;
		},
		VgFilesMixins_filesOnlyPhoto: function(){
			const files = this.VgFilesMixins.files;
			if(!files) return [];
			let photos = [];
			files.forEach((file)=>{
				if(file.tagLabel && file.tagLabel.toLowerCase().search("photo")!=-1 && file.extension.search("image/")!=-1) photos.push(file);
			});
			return photos;
		},
		VgFilesMixins_filesOnlyPanoramas: function(){
			const files = this.VgFilesMixins.files;
			if(!files) return null;
			return files.filter((file)=>file.tagLabel=="Panorama");
		},
		VgFilesMixins_filesOnlyPhotoOperation: function(){
			const files = this.VgFilesMixins.files;
			if(!files) return [];
			let photos = [];
			files.forEach((file)=>{
				if(file.tagLabel && file.tagLabel.toLowerCase().search("photo")!=-1 && file.extension.search("image/")!=-1 && (file.tagType.search("operation")!=-1 || file.tagType.search("fichemaintenance")!=-1)) photos.push(file);
			});
			return photos;
		},
		VgFilesMixins_plansTags: function(){
			const tags = this.VgFilesMixins.tags;
			if(!tags) return [];
			let plansTags = [];
			tags.forEach((tag)=>{
				if(tag.label.toLowerCase().search("plan")!=-1) plansTags.push(tag);
			});
			return plansTags;
		},
		VgFilesMixins_plansListSection: function(){
			var files = this.VgFilesMixins.files;
			var tags = this.VgFilesMixins_plansTags;
			if(!files || !tags) return null;
			var result = tags.map( (tag) => {
				return {tag: tag, files: []};
			});
			files.forEach( (file) => {
				if(file.tagId && result.findIndex( (item) => item.tag.id == file.tagId)!=-1) result[result.findIndex( (item) => item.tag.id == file.tagId)].files.push(file);
			});
			return result;
		},
		VgFilesMixins_getRapportIntervention: function(){
			let files = this.VgFilesMixins.files;
			let tags = this.VgFilesMixins.tags;
			if(!files || !tags) return null;
			let rapportInterventionTag = tags.find((tag)=>tag.label=="rapport-intervention" || tag.label=="Rapport intervention");
			return files.find((file)=>file.tagId==rapportInterventionTag.id);
		},
		VgFilesMixins_getTagFondDePlan: function(){
			if(this.$store.getters["DocumentsStore/getTagsCollection"]) return this.$store.getters["DocumentsStore/getTagsCollection"].find((tag)=>tag.label=="fond-de-plan");
			else return null;
		},
		VgFilesMixins_getTagGeoJsonPieces: function(){
			return this.$store.getters["DocumentsStore/getTagsCollection"].find((tag)=>tag.label=="geojson-pieces");
		},
		VgFilesMixins_getTagGeoJsonEquipements: function(){
			return this.$store.getters["DocumentsStore/getTagsCollection"].find((tag)=>tag.label=="geojson-equipements");
		},
		VgFilesMixins_getTagGeoJsonPiecesDraft: function(){
			return this.$store.getters["DocumentsStore/getTagsCollection"].find((tag)=>tag.label=="geojson-pieces-draft");
		},
		VgFilesMixins_getGeoJsonPiecesDraft: function(){
			let file = this.VgFilesMixins.files.find((f)=>f.tagType=="documents/"+this.VgFilesMixins.type && f.tagId==this.VgFilesMixins_getTagGeoJsonPiecesDraft.id);
			if(!file) return null;
			else return file;
		},
		VgFilesMixins_getGeoJsonPieces: function(){
			let file = this.VgFilesMixins.files.find((f)=>f.tagType=="documents/"+this.VgFilesMixins.type && f.tagId==this.VgFilesMixins_getTagGeoJsonPieces.id);
			if(!file) return null;
			else return file;
		}
	}
};
