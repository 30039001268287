import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);



import notifications from './modules/notifications';
import appState from './modules/app';
import filters from './modules/filters';
import fichesav from './modules/fichesav';
import personalParameters from './modules/personalParameters';
import VgTreeStore from './modules/VgTreeStore';
import VgTreeCategoriesStore from './modules/VgTreeCategoriesStore';
import MaintenancesStore from './modules/MaintenancesStore';
import InterventionsStore from './modules/InterventionsStore';
import TiersStore from './modules/TiersStore';
import UsersStore from './modules/UsersStore';
import ContratsStore from './modules/ContratsStore';
import ContactsStore from './modules/ContactsStore';
import TagsStore from './modules/TagsStore';
import TachesStore from './modules/TachesStore';
import EquipementsStore from './modules/EquipementsStore';
import LieuxStore from './modules/LieuxStore';
import CategoriesStore from './modules/CategoriesStore';
import InventairesStore from './modules/InventairesStore';
import OperationsInventairesStore from './modules/OperationsInventairesStore';
import ConsommableMouvementsStore from './modules/ConsommableMouvementsStore';
import OperationsStore from './modules/OperationsStore';
import CheckpointsStore from './modules/CheckpointsStore';
import DocumentsStore from './modules/DocumentsStore';
import PlanInteractifStore from './modules/PlanInteractifStore';
import ComposantsStore from './modules/ComposantsStore';
import BonsDeCommandeStore from './modules/BonsDeCommandeStore';
import BonsDeCommandeEntitesStore from './modules/BonsDeCommandeEntitesStore';
import BonDeCommandeItemsStore from './modules/BonDeCommandeItemsStore';
import BonsDeCommandeHistoriquesStore from './modules/BonsDeCommandeHistoriquesStore';
import ConsommablesStore from './modules/ConsommablesStore';
import GroupeValidateursStore from './modules/GroupeValidateursStore';
import TvasStore from './modules/TvasStore';
import CorpsDetatsStore from './modules/CorpsDetatsStore';
import LibellesProblemStore from './modules/LibellesProblemStore';
import ValidationsStore from './modules/ValidationsStore';
import VerificationsStore from './modules/VerificationsStore';
import ReponsesStore from './modules/ReponsesStore';
import DashboardStore from './modules/DashboardStore';
import LibelServicesStore from './modules/LibelServicesStore';
import BonsDeSortieStore from './modules/BonsDeSortieStore';
import BonsDentreeStore from './modules/BonsDentreeStore';
import FicheDemandeConsommablesStore from './modules/FicheDemandeConsommablesStore';
import StocksStore from './modules/StocksStore';
import VgTreeConsommablesStore from './modules/VgTreeConsommablesStore';
import LieuxIFCStore from './modules/LieuxIFCStore';
import SearchDatasStore from './modules/SearchDatasStore';
import TypologiesMaintenanceStore from './modules/TypologiesMaintenanceStore';
//import createLogger from '../../../src/plugins/logger';
import MouvementsEquipementsStore from './modules/MouvementsEquipementsStore';

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    notifications: notifications,
    filters: filters,
	fichesav: fichesav,
    appstate : appState,
    personalparameters : personalParameters,
    VgTreeStore: VgTreeStore,
    VgTreeCategoriesStore: VgTreeCategoriesStore,
    MaintenancesStore: MaintenancesStore,
    InterventionsStore: InterventionsStore,
    TiersStore: TiersStore,
    UsersStore: UsersStore,
    TachesStore: TachesStore,
    ContratsStore: ContratsStore,
    TagsStore: TagsStore,
    EquipementsStore: EquipementsStore,
    LieuxStore: LieuxStore,
    CategoriesStore: CategoriesStore,
    InventairesStore: InventairesStore,
    OperationsInventairesStore: OperationsInventairesStore,
    ConsommableMouvementsStore: ConsommableMouvementsStore,
    OperationsStore: OperationsStore,
    ContactsStore: ContactsStore,
    CheckpointsStore: CheckpointsStore,
    DocumentsStore: DocumentsStore,
    PlanInteractifStore: PlanInteractifStore,
    ComposantsStore: ComposantsStore,
    BonsDeCommandeStore: BonsDeCommandeStore,
    BonsDeCommandeEntitesStore: BonsDeCommandeEntitesStore,
    BonDeCommandeItemsStore: BonDeCommandeItemsStore,
    ConsommablesStore: ConsommablesStore,
    GroupeValidateursStore: GroupeValidateursStore,
    TvasStore: TvasStore,
    CorpsDetatsStore: CorpsDetatsStore,
    LibellesProblemStore: LibellesProblemStore,
    ValidationsStore: ValidationsStore,
    BonsDeCommandeHistoriquesStore: BonsDeCommandeHistoriquesStore,
    DashboardStore: DashboardStore,
    VerificationsStore: VerificationsStore,
    ReponsesStore: ReponsesStore,
    LibelServicesStore: LibelServicesStore,
    BonsDeSortieStore: BonsDeSortieStore,
    BonsDentreeStore: BonsDentreeStore,
    FicheDemandeConsommablesStore: FicheDemandeConsommablesStore,
    StocksStore: StocksStore,
    VgTreeConsommablesStore: VgTreeConsommablesStore,
    LieuxIFCStore: LieuxIFCStore,
    SearchDatasStore: SearchDatasStore,
    TypologiesMaintenanceStore: TypologiesMaintenanceStore,
    MouvementsEquipementsStore: MouvementsEquipementsStore,
  },
  strict: false,
  //plugins: debug ? [createLogger()] : []
})
