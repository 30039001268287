<template>
    <vg-app-layout
        :isLoadingState="isLoadingState"
        :title="$t('title')"
        :isZoomLayout="$vgutils.isMobile() && etage"
        :colorheader="getHeaderColor"
        :footerHeight="etage && isModeEdition?'lg':'sm'"
        :showSecondary="!etage"
        hideKeyFiguresPanel
        @backpage="backpage()">

        <template #action-button-display-items>
            <li @click="copyPageLink"><i class="fa fa-link"></i> {{$t("copy-url")}}</li>
            <li v-if="etage && isModeEdition" @click="exportPiecesACreer">{{$t("export-pieces-a-creer")}}</li>
            <li v-if="etage && isModeEdition" @click="showDeleteFondDePlan=true;">{{$t("delete-fond-de-plan")}}</li>
            <li v-if="etage" @click="changeMarkerEquipementVersion">{{$t("changer-affichage-marker-equipement")}}</li>
            <li v-if="etage" @click="saveZoomAndMapCenter">{{$t("save-zoom-map-center")}}</li>
        </template>

        <template #header-top-left-complement>
            <span v-if="etage" style="color:#ffb44c;font-size:14px;font-weight:bold;">
                {{isModeEdition?$t("edition"):$t("consultation")}}
            </span>
            <vg-lieu-path-viewer v-if="etage"
                style="font-size:20px;"
                v-model="etage.path"
                :showTooltip="false">
            </vg-lieu-path-viewer>
        </template>

        <template #header-bottom-left>
            <vg-button v-if="etage"
                :type="'grey'"
                :size="'sm'"
                @click="showLeftPanel=!showLeftPanel;">
                <i :class="['fas', {'fa-chevron-left':showLeftPanel,'fa-chevron-right':!showLeftPanel}]" style="margin-right: 5px;"></i>
                {{showLeftPanel?$t("hide-left-panel"):$t("show-left-panel")}}
            </vg-button>
        </template>

        <template #primary>
            <div v-if="!etage" class="select-etage">
                <span>{{$t("select-etage")}}</span>
            </div>
            <vg-tree-lieux v-if="!etage && $vgutils.isMobile()"
                :readOnly="true"
                :showPlanInteractifDatas="true"
                @select-node="onSelectNodeLieu"/>
            <vg-button v-if="etage && $vgutils.isMobile()"
                :type="'grey'"
                :size="'sm'"
                style="margin-bottom:10px;"
                @click="showLeftPanel=!showLeftPanel;">
                <i :class="['fas', {'fa-chevron-left':showLeftPanel,'fa-chevron-right':!showLeftPanel}]" style="margin-right: 5px;"></i>
                {{showLeftPanel?$t("hide-left-panel"):$t("show-left-panel")}}
            </vg-button>
            <vg-plan-interactif v-if="etage"
                :etage="etage"
                :isModeEdition="isModeEdition"
                :showLeftPanel="showLeftPanel"
                @map-initialized="onMapInitialized"
                @map-position="onMapPositionChange"
                @open-equipement-form="showPieceCreateForm=false;"
                @close-equipement-form="showPieceCreateForm=true;"/>
        </template>

        <template #secondary>
            <vg-tree-lieux
                :readOnly="true"
                :showPlanInteractifDatas="true"
                @select-node="onSelectNodeLieu"/>
        </template>

        <template #footer v-if="!$vgutils.isMobile() && can('PLAN_INTERACTIF.UPDATE')">
            <!-- FORMULAIRE CREATION PIECE MARKER -->
            <div v-if="etage && userCanInteract" class="footer-mode-edition">
                <vg-piece-marker-create v-if="isModeEdition && showPieceCreateForm"
                    />
                <vg-button v-if="!isModeEdition"
                    type="grey"
                    size="sm"
                    style="width:100%;"
                    @click="handleChangeMode()">
                    <i class="fas fa-edit"></i> {{$t('mode-edition')}}
                </vg-button>
                <div v-else-if="etage" class="formulaire-creation-pieces-buttons">
                    <span v-if="hasPiecesDraftChanged || hasEquipementsDraftChanged" style="width:100%;text-align:center;font-size:11px;color:#02a7f0;"><i class="fas fa-exclamation-triangle" style="margin-right:5px;"></i> {{$t("sauvegarde-a-faire")}}</span>
                    <vg-button class="actionButton" v-if="isModeEdition"
                        type="success"
                        size="sm"
                        @click="showConfirmSave=true;" >
                        <i class="fas fa-save" style="margin-right:10px;"></i> {{$t('sauvegarder')}}
                    </vg-button>
                    <vg-button
                        type="danger"
                        size="sm"
                        @click="onClickExitModeEdition">
                        <i class="fas fa-times" style="margin-right:10px;"></i> {{$t('exit-mode-edition')}}
                    </vg-button>
                </div>
            </div>
        </template>

        <vg-modal v-if="showConfirmExit"
            :title="$t('confirm-exit-title')"
            @close="showConfirmExit=false;">
            <template v-slot:body>
                {{$t("confirm-exit-verbose")}}
                <br>
                <br>
                {{$t("save-sans-creation-piece-verbose")}}
            </template>
            <template v-slot:footer>
                <vg-button
                    :type="'default'"
                    @click="showConfirmExit=false;">
                    {{$t("cancel")}}
                </vg-button>
                <vg-button
                    :type="'danger'"
                    @click="onConfirmExit">
                    {{$t("dont-save-modifications")}}
                </vg-button>
                <vg-button
                    :type="'success'"
                    @click="showConfirmSave=true;">
                    {{$t("sauvegarder")}}
                </vg-button>
            </template>
        </vg-modal>

        <vg-modal v-if="showConfirmSave"
            :title="$t('confirm-save-title')"
            :width="'800px'"
            @close="showConfirmSave=false;">
            <template #body>
                <div>
                    <vg-switch v-model="savePiecesPourConsultation" />
                    <span>{{$t("sauvegarder-pieces-en-consultation")}}</span>
                </div>
                <div>
                    <vg-switch v-model="saveEquipementsPourConsultation" />
                    <span>{{$t("sauvegarder-equipements-en-consultation")}}</span>
                </div>
            </template>
            <template #footer>
                <vg-button
                    :type="'default'"
                    @click="showConfirmSave=false;">
                    {{$t("cancel")}}
                </vg-button>
                <vg-button
                    :type="'success'"
                    @click="onSavePlanInteractif">
                    {{$t("sauvegarder")}}
                </vg-button>
            </template>
        </vg-modal>

        <dialog-secured-action v-if="showDeleteFondDePlan"
            :validResponse="$t('delete-plan')"
            @close="showDeleteFondDePlan=false;"
            @save="onDeleteFondDePlan">
            <template #header-title>
                {{$t("title-delete-plan-confirm")}}
            </template>
        </dialog-secured-action>

    </vg-app-layout>
</template>
<script>
import LieuMixins from "src/mixins/LieuMixins.js";
import PlanInteractifMixins from "src/mixins/PlanInteractifMixins.js";

import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgIcon from "src/components/Vg/VgIcon.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgModal from "src/components/Vg/VgModal.vue";
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgCheckbox from 'src/components/Vg/VgCheckbox.vue';
import VgGroup from "src/components/Vg/VgGroup.vue";
import VgTag from 'src/components/Vg/VgTag.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgLieuPathViewer from 'src/components/Vg/Lieu/VgLieuPathViewer.vue';
import VgServiceSelector from 'src/components/Vg/Selectors/VgServiceSelector.vue';
import VgSwitch from 'src/components/Vg/VgSwitch.vue';

import VgPlanInteractif from 'src/components/Vg/PlanInteractif/VgPlanInteractif.vue';
import VgPieceMarkerCreate from 'src/components/Vg/PlanInteractif/Forms/VgPieceMarkerCreate.vue';

import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';

import { mapGetters } from 'vuex';

export default {
    name: "plan-interactif",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Plan Interactif",
            "mode-edition": "Passer en mode édition",
            "exit-mode-edition": "Quitter le mode édition",
            "confirm-exit-title": "Voulez-vous quitter le mode édition?",
            "cancel": "Annuler",
            "dont-save-modifications": "Quitter sans enregistrer",
            "confirm-exit-verbose": "Enregistrer les modifications sinon ces modifications seront perdues.",
            "sauvegarder-pieces-en-consultation": "Sauvegarder les pièces en consultation (création de pièces et sauvegarde des marqeurs)",
            "sauvegarder-equipements-en-consultation": "Sauvegarder les équipements en consultation",
            "sauvegarder": "Sauvegarder",
            "sauvegarder-uniquement-positions-equipements": "Sauvegarder les positions équipements",
            "sauvegarder-tout-et-creer-pieces": "Sauvegarder tout et créer pièces",
            "save-uniquement-positions-equipements-verbose": "Sauvegarder les positions équipements: les équipements sont visibles en mode consultation.",
            "confirm-save-title": "Voulez-vous sauvegarder vos modifications?",
            "save-sans-creation-piece-verbose": "Sauvegarder le brouillon: les pièces orange et les équipements récemment ajoutés ne sont pas visibles en mode consultation.",
            "hide-left-panel": "Fermer panneau de gauche",
            "show-left-panel": "Ouvrir panneau de gauche",
            "copy-url": "Copier lien de la page",
            "edition": "Edition",
            "consultation": "Consultation",
            "select-etage": "Sélectionner un étage / L4",
            "export-pieces-a-creer": "Export liste pièces à créer",
            "export-pieces-a-creer-file-name": "Verifgood_pieces_a_creer_",
            "sauvegarde-a-faire": "Sauvegarde à faire",
            "changer-affichage-marker-equipement": "Changer affichage des marqueurs équipements",
            "delete-plan": "Supprimer plan",
            "delete-fond-de-plan": "Supprimer fond de plan",
            "title-delete-plan-confirm": "Voulez-vous supprimer le fond de plan?",
            "save-zoom-map-center": "Sauvegarder le zoom et le centre"
        },
        "en": {
            "title": "Interactive floor level plan",
            "mode-edition": "Switch to edit mode",
            "exit-mode-edition": "Exit edit mode",
            "hide-left-panel": "Hide left panel",
            "show-left-panel": "Show left panel",
            "copy-url": "Copy page link",
            "edition": "Editing",
            "consultation": "Consultation",
            "export-pieces-a-creer": "Export list of rooms to create",
            "export-pieces-a-creer-file-name": "Verifgood_rooms_to_create_",
            "changer-affichage-marker-equipement": "Change equipments markers version",
            "delete-plan": "Delete floor plan",
            "delete-fond-de-plan": "Delete floor plan",
            "title-delete-plan-confirm": "Delete floor plan?",
            "save-zoom-map-center": "Save zoom and center"
        }
    }
},
    mixins:[PlanInteractifMixins, LieuMixins],
    components:{
        VgCategorieSelector,
        VgTreeLieux,
        VgButton,
        VgIcon,
        VgModal,
        VgCollapse,
        VgCheckbox,
        VgTextFilter,
        DialogSecuredAction,
        VgLieuPathViewer,
        VgTag,
        VgGroup,
        VgInput,
        VgPlanInteractif,
        VgServiceSelector,
        VgPieceMarkerCreate,
        VgSwitch
    },
    props:{
    },
    data: function() {
        return {
            isModeEdition: false,
            showConfirmExit: false,
            showConfirmSave: false,
            showLeftPanel: true,
            userCanInteract: false,
            isLoadingState: false,
            savePiecesPourConsultation: false,
            saveEquipementsPourConsultation: false,
            showPieceCreateForm: true,
            showDeleteFondDePlan: false,
            mapPosition: null
        };
    },
    watch:{

    },
    computed: {
        ...mapGetters({
            etage: "PlanInteractifStore/getEtage",
            geoJsonPieces: "PlanInteractifStore/getGeoJsonPieces",
            geoJsonPiecesDraft: "PlanInteractifStore/getGeoJsonPiecesDraft",
            geoJsonEquipements: "PlanInteractifStore/getGeoJsonEquipements",
            geoJsonEquipementsDraft: "PlanInteractifStore/getGeoJsonEquipementsDraft",
            files: "PlanInteractifStore/getFiles",
            hasPiecesDraftChanged: "PlanInteractifStore/getHasPiecesDraftChanged",
            hasEquipementsDraftChanged: "PlanInteractifStore/getHasEquipementsDraftChanged",
            markerEquipementVersion: "PlanInteractifStore/getMarkerEquipementVersion"
        }),
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#6c3a9e' : 'white';
        }
    },
    methods: {
        onMapPositionChange: function(datas){
            this.mapPosition = Object.assign({}, {}, datas);
        },
        saveZoomAndMapCenter: function(){
            let positions = this.$storage.get("pi-positions");
            if(!positions) positions = {};
            positions[this.mapPosition.path] = this.mapPosition;
            this.$storage.set("pi-positions", positions);
        },
        onDeleteFondDePlan: function(){
            this.VgFilesMixins_deleteFile(this.files["fond-de-plan"].file).then(()=>{
                document.location.reload();
            });
        },
        changeMarkerEquipementVersion: function(){
            let newVersion = null;
            if(this.markerEquipementVersion=="version-1") newVersion = "version-2";
            else if(this.markerEquipementVersion=="version-2") newVersion = "version-1";
            this.$storage.set("pi-marker-equipement-version", newVersion);
            this.$store.dispatch("PlanInteractifStore/setMarkerEquipementVersion", newVersion);
        },
        exportPiecesACreer: function(){
            this.PlanInteractif_exportPiecesACreerExcel(this.geoJsonPiecesDraft, this.etage, this.$t("export-pieces-a-creer-file-name"));
        },
        copyPageLink: function(){
            navigator.clipboard.writeText(window.location.href).then(()=>{
                /* success */
                console.log("CLIPBOARD saved", window.location.href);
            }, ()=>{
                /* failure */
                console.log("CLIPBOARD failed", window.location.href);
            });
        },
        onClickExitModeEdition: function(){
            if(!this.hasPiecesDraftChanged && !this.hasEquipementsDraftChanged) this.isModeEdition = false;
            else this.showConfirmExit = true;
        },
        backpage: function(){
            this.$store.dispatch("PlanInteractifStore/clearFiles");
            this.$router.push({ name: "_plan_interactif"});
        },
        onMapInitialized: function(){
            this.userCanInteract = true;
        },
        onConfirmExit: function(){
            document.location.reload();
        },
        handleChangeMode: function(){
            this.isModeEdition = true;
        },
        onSelectNodeLieu: function(lieu){
            //console.log("onSelectNodeLieu", lieu.type_lieu, lieu.id);
            if(lieu.type_lieu=="Etage"){
                if(this.isModeEdition){
                    this.showConfirmExit = true;
                }else{
                    this.$store.dispatch("PlanInteractifStore/clearFiles");
                    this.$store.dispatch("PlanInteractifStore/setEtage", lieu);
                    this.$router.push({ name: "_plan_interactif_etage", params: { etage: lieu.id } });
                }
            }
        },
        fetchEtage: function(idEtage){
            return new Promise((resolve, reject)=>{
                this.LieuMixins_getLieu(idEtage).then((etage)=>{
                    console.log("GET ETAGE", etage);
                    this.$store.dispatch("PlanInteractifStore/setEtage", etage);
                    resolve(etage);
                });
            });
        },
        saveGeoJsonPieces: function(){
            return new Promise((resolve, reject)=>{
                if(this.hasPiecesDraftChanged){
                    let piecesACreer = this.PlanInteractif_getPiecesACreer(this.geoJsonPiecesDraft, this.etage);
                    console.log("PIECES A CREER", piecesACreer);
                    this.LieuMixins_create(piecesACreer).then((datas)=>{
                        console.log("after LieuMixins_create", datas);
                        let geoJsonDraft = this.PlanInteractif_reaffectePiecesAuxMarkers(datas, this.geoJsonPiecesDraft);
                        this.VgFilesMixins_saveGeoJson(this.etage, geoJsonDraft, this.files["geojson-pieces"], this.files["geojson-pieces-draft"], "pieces").then((data)=>{
                            resolve(data);
                        });
                    });
                }else{
                    resolve();
                }
            });
        },
        saveGeoJsonPiecesDraft: function(){
            return new Promise((resolve, reject)=>{
                if(this.hasPiecesDraftChanged){
                    this.VgFilesMixins_saveGeoJsonDraft(this.etage, this.geoJsonPiecesDraft, this.files["geojson-pieces-draft"], "pieces").then((data)=>{
                        resolve(data);
                    });
                }else{
                    resolve();
                }
            });
        },
        saveGeoJsonEquipements: function(){
            return new Promise((resolve, reject)=>{
                if(this.hasEquipementsDraftChanged){
                    this.VgFilesMixins_saveGeoJson(this.etage, this.geoJsonEquipementsDraft, this.files["geojson-equipements"], this.files["geojson-equipements-draft"], "equipements").then((data)=>{
                        resolve(data);
                    });
                }else{
                    resolve();
                }
            });
        },
        saveGeoJsonEquipementsDraft: function(){
            return new Promise((resolve, reject)=>{
                if(this.hasEquipementsDraftChanged){
                    this.VgFilesMixins_saveGeoJsonDraft(this.etage, this.geoJsonEquipementsDraft, this.files["geojson-equipements-draft"], "equipements").then((data)=>{
                        resolve(data);
                    });
                }else{
                    resolve();
                }
            });
        },
        /**
        * Sauvegarde le plan interactif
        * IE: sauvegarde les pièces réaffectation des markers du geojson aux pieces créées et sauvegarde du geoJson
        * note: doit supprimer le geoJson draft associé à ce niveau
        */
        onSavePlanInteractif: function(){
            this.isLoadingState = true;
            Promise.all([
                this.savePiecesPourConsultation?this.saveGeoJsonPieces():this.saveGeoJsonPiecesDraft(),
                this.saveEquipementsPourConsultation?this.saveGeoJsonEquipements():this.saveGeoJsonEquipementsDraft()
            ]).then((values)=>{
                this.showConfirmSave = false;
                this.isModeEdition = false;
                this.isLoadingState = false;
                document.location.reload();
            }).catch((e)=>{
                console.log("IMPOSSIBLE DE SAUVEGARDER LES DONNEES", e);
            });
        },
        initVersions: function(){
            if(!this.$storage.get("pi-marker-equipement-version")) this.$storage.set("pi-marker-equipement-version", "version-1");
            this.$store.dispatch("PlanInteractifStore/setMarkerEquipementVersion", this.$storage.get("pi-marker-equipement-version"));
        }
    },
    created:function(){
        console.log("$ROUTER", this.$route, this.$route.query);
        this.initVersions();
        if(this.$route.name=="_plan_interactif_etage" && this.$route.params.etage){
            this.fetchEtage(this.$route.params.etage);
        }
    },
    mounted: function(){

    },
    destroyed: function(){
        this.$store.dispatch("PlanInteractifStore/clearFiles");
        this.$store.dispatch("LieuxStore/clear");
        this.$store.dispatch("EquipementsStore/clear");
    }
};
</script>

<style lang="scss" scoped>
.footer-mode-edition{
    width:100%;
    height:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 20px;
    gap:20px;
    .formulaire-creation-pieces-buttons{
        flex-grow:1;
        display:flex;
        flex-direction:column;
        justify-content:stretch;
        align-items:stretch;
        gap:10px;
    }
}
.select-etage{
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 24px;
    color: #02a7f0;
}
@media screen and (max-width: 1200px) {
    .select-etage{
        height:50px;
        font-size: 18px;
    }
}

</style>
