function OrigineFilter() {
}

OrigineFilter.prototype.init = function (params) {
    this.valueGetter = params.valueGetter;
    this.filterText = null;
    this.setupGui(params);
};

// not called by ag-Grid, just for us to help setup
OrigineFilter.prototype.setupGui = function (params) {
	
	var demandeurs = params.column.colDef.filterParams.values;
	// //console.log("OrigineFilter DATAS", demandeurs);
	
	this.gui = document.createElement("div");
	var html = '<div style="padding: 4px;">'
		+'<select id="origineSelector" style="min-width:100px;">'
		+'<option value="" disabled selected>Choose</option>';
	for(var i=0; i<demandeurs.length; i++){
		html += '<option value="'+demandeurs[i]+'">'+demandeurs[i]+'</option>';
	}
	html += '</select>'
		+'<i class="fas fa-eraser" style="margin-left: 4px;cursor: pointer;" id="removeOrigineFilter"></i>'
		+'</div>';
		
	this.gui.innerHTML = html;
	
	this.eOrigineSelector = this.gui.querySelector("#origineSelector");
	this.eOrigineSelector.addEventListener("change", (event) => {
		//// //console.log("CHANGE", this.filterText);
		this.filterText = event.target.value;
        params.filterChangedCallback();
	});
	this.eRemoveFilter = this.gui.querySelector("#removeOrigineFilter");
	this.eRemoveFilter.addEventListener("click", (event) => {
		this.gui.querySelector("#origineSelector").value = null;
		//// //console.log("REMOVE", this.filterText);
		this.filterText = null;
        params.filterChangedCallback();
	});
};

OrigineFilter.prototype.getGui = function () {
    return this.gui;
};

OrigineFilter.prototype.doesFilterPass = function (params) {
    // make sure each word passes separately, ie search for firstname, lastname
	var valueGetter = this.valueGetter;
	var value = valueGetter(params);
	// //console.log("ORIGINE FILTER", params.data.contact, params.data, this.filterText);
	return params.data.origin=="DI" ? params.data.contact == this.filterText : params.data.nom+" "+params.data.prenom == this.filterText;
};

OrigineFilter.prototype.isFilterActive = function () {
    return this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
};

OrigineFilter.prototype.getModel = function() {
    var model = {value: this.filterText.value};
    return model;
};

OrigineFilter.prototype.setModel = function(model) {
    this.eFilterText.value = model.value;
};

export default OrigineFilter;
