<template>
    <vg-modal :width="'100vw'" style="z-index: 400;" @close="close">
        <template #header>
            <span>{{ $t('title') }}</span>
            <small v-if="counters.plans==counters.filtered" style="margin-left:30px;">{{$t("plans-partages", {nTotal: counters.plans})}}</small>
            <small v-else style="margin-left:30px;">{{$t("plans-affiches-plans-partages", {nFiltered: counters.filtered, nTotal: counters.plans})}}</small>
        </template>
        <template #body>
            <div class="container">
                <!-- VERSION DESKTOP -->
                <vg-collapse v-if="!$vgutils.isMobile()"
                    :collapseByDefault="collapseListeDesPlans"
                    :title="$t('collapse-title')"
                    :killContentOnCollapse="false">
                    <template #content>
                        <div class="content">
                            <div class="content-table">
                                <tag-grid
                                    :idTableau="getIdTableau"
                                    v-model="gridOptions"
                                    :defaultHeight="'90%'"
                                    :columnDefs="getColumnDefs"
                                    :rowData="plans"
                        			:showLoadingOverlay="showLoadingOverlay"
                        			:overlayLoadingText="$t('overlay-loading-text')"
                        			:overlayNoRowsText="$t('overlay-no-rows-text')"/>
                                <vg-pagination
                                    :totalItems="counters.filtered"
                                    :offset="pagination.offset"
                                    :limit="pagination.numberOfRowsToDisplay"
                                    @pagination-change="handlePaginationChange" />
                            </div>
                            <div class="filters">
                                <vg-text-filter slot="search" v-model="searchQuery"></vg-text-filter>
                                <vg-filter-site v-model="querySite" v-if="!etage"
                                    @input="onChangeFilterSite"/>
                                <!--vg-filter-tags v-model="queryTagDocument"
                                    :type="['documents/lieu']"
                                    @input="onChangeFilterTagDocument" /-->
                            </div>
                        </div>
                    </template>
                </vg-collapse>
                <div v-else>
                    <vg-text-filter slot="search" v-model="searchQuery"></vg-text-filter>
                    <vg-filter-site v-model="querySite" v-if="!etage"
                        @input="onChangeFilterSite" />
                    <tag-grid
                        :idTableau="getIdTableau"
                        v-model="gridOptions"
                        :columnDefs="getColumnDefs"
                        :rowData="plans">
                    </tag-grid>
                </div>
            </div>
            <div class="viewer-plan" v-if="!$vgutils.isMobile() && pdfLink">
                <vg-files-pdf-viewer
                    :pdf="pdfLink">
                </vg-files-pdf-viewer>
            </div>
        </template>
        <template #footer>
            <vg-button v-if="etage"
                :type="'success'"
                @click="showAddPlan=true;">
                {{$t("add-plan")}}
            </vg-button>
            <vg-files-plans-upload v-if="showAddPlan"
    			:lieu="etage"
    			@close="onCloseAddPlan">
    		</vg-files-plans-upload>
            <!-- <button type="button" @click="createRapport">{{ $t('btn-rapport') }}</button> -->
            <vg-button :type="'grey'"
                @click="close">
                {{ $t('btn-retour') }}
            </vg-button>
        </template>
    </vg-modal>
</template>

<script>
import VgFilesPdfViewer from "src/components/Vg/Files/VgFilesPdfViewer.vue";

import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";
import TagGrid from "src/components/Grid/TagGrid.vue";
import FileLinkCellRender from "src/components/Vg/TagGrid/FileLinkCellRender.vue";
import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
import FilesPlanCellRenderer from "src/components/Vg/TagGrid/FilesPlanCellRenderer.vue";
import ButtonCellRender from "src/components/Vg/TagGrid/ButtonCellRender.vue";
import DocumentsMixins from "src/mixins/DocumentsMixins.js";
import LieuMixins from "src/mixins/LieuMixins.js";
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import VgFilesPlansUpload from "src/components/Vg/Files/VgFilesPlansUpload.vue";
import VgFilterTags from "src/components/Vg/Filters/VgFilterTags.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgPagination from "src/components/Vg/VgPagination.vue";

import Metadatas from "src/services/Metadatas";

export default {
    name:"vg-files-plans",
    components:{
        VgInput,
        VgSelect,
        VgButton,
        VgCollapse,
        VgTextFilter,
        FileLinkCellRender,
        DatetimeCellRender,
        PathCellRender,
        FilesPlanCellRenderer,
        ButtonCellRender,
        TagGrid,
        VgFilesPdfViewer,
        VgFilesPlansUpload,
        VgFilterTags,
        VgFilterSite,
        VgPagination
    },
    mixins:[
        DocumentsMixins,
        TagGridMixins,
        LieuMixins
    ],
    i18n:    { "locale":navigator.language,
    "messages": {
"fr": {
        "overlay-loading-text": "Chargement des plans...",
		"overlay-no-rows-text": "Aucun plan correspondant au filtrage",
        "title": "Tiroir des plans partagés",
        "filter-site": "Site",
        "filter-batiment": "Batiment",
        "filter-etage": "Etage",
        "filter-tag-document": "Etiquette document",
        "btn-retour": "Retour",
        "btn-rapport": "Rapport",
        "grid-date": "Date",
        "grid-path": "Lieu",
        "grid-tag-document": "Etiquette document",
        "grid-name": "Document",
        "grid-description": "Commentaire",
        "grid-actions": "Actions",
        "add-plan": "Ajouter un plan au niveau",
        "collapse-title": "Liste des plans PDF",
        "delete": "Supprimer",
        "plans-partages": "{nTotal} plans partagés",
        "plans-affiches-plans-partages": "{nFiltered} plans affichés sur {nTotal} plans partagés"
    },
    "en": {
        "overlay-loading-text": "Loading floor plans...",
		"overlay-no-rows-text": "No floor plan matching filtering",
        "title": "Shared floor plans",
        "filter-site": "Site",
        "filter-batiment": "Building",
        "filter-etage": "Floor",
        "filter-tag-document": "Document tag",
        "btn-retour": "Close",
        "btn-rapport": "Report",
        "grid-date": "Date",
        "grid-path": "Location",
        "grid-tag-document": "Document tag",
        "grid-name": "Document",
        "grid-description": "Comment",
        "grid-actions": "Actions",
        "add-plan": "Add floor level",
        "collapse-title": "Floor level list PDF",
        "delete": "Delete"
    },
    "th": {
        "title": "แผนงานพื้นที่แบ่งปัน",
        "filter-site": "หน้างาน",
        "filter-batiment": "สิ่งปลูกสร้าง",
        "filter-etage": "พื้น",
        "filter-tag-document": "แท็กเอกสาร",
        "btn-retour": "ข้อเสนอแนะ",
        "btn-rapport": "รายงาน",
        "grid-date": "วันที่",
        "grid-path": "ที่ตั้ง",
        "grid-tag-document": "แท็กเอกสาร",
        "grid-name": "เอกสาร",
        "grid-description": "ความคิดเห็น"
        }
    }
},
    props: {
        /**
        * valeur par défaut de querySite, queryBatiment, queryEtage
        * {Site: "Site A", ..., lieu: {...}}
        */
        defaultFilters: {
            type: Object,
            default: null
        },
        etage: {
            type: Object,
            default: null
        }
    },
    data:function(){
        return {
            showLoadingOverlay: false,
            gridOptions:{},
            columnDefs:[
                {
                    headerName: this.$t("grid-date"),
                    width: 80,
                    field: 'uploadedAt',
                    colId: 'uploadedAt',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.uploadedAt}}).$mount().$el
                },
                {
                    headerName: this.$t("grid-path"),
                    width: 80,
                    field: 'path',
                    colId: 'path',
                    filter: "agTextColumnFilter",
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path}}).$mount().$el
                },
                {
                    headerName: this.$t("grid-tag-document"),
                    width: 80,
                    filter: "agTextColumnFilter",
                    field: 'label',
                    colId: 'label',
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("grid-name"),
                    width: 80,
                    field: 'name',
                    colId: 'name',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new FileLinkCellRender({propsData: {params: params, isAutomaticOpenLink: false}})
                        .$mount()
                        .$on("click-link", this.onClickLink).$el
                },
                {
                    headerName: this.$t("grid-description"),
                    width: 80,
                    field: 'description',
                    colId: 'description',
                    cellClass: ["vg-cell-ballam-theme"]
                },
                {
                    headerName: this.$t("grid-actions"),
                    width: 80,
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new ButtonCellRender({propsData: {params: params, label: this.$t("delete"), type: "danger"}})
                        .$mount()
                        .$on("click", this.onDeletePlan).$el,
                    hide: this.$app.role!="ROLE_ADMIN",
                    isVisible: this.$app.role=="ROLE_ADMIN"
                }
            ],
            plans:[],
            searchQuery:'',
            querySite: this.defaultFilters && this.defaultFilters.Site || "",
            queryBatiment: this.defaultFilters && this.defaultFilters.Batiment || "",
            queryEtage: this.defaultFilters && this.defaultFilters.Etage || "",
            queryTagDocument:'',
            pdfLink: null,
            showAddPlan: false,
            collapseListeDesPlans: false,
            pagination: {
                offset: 0,
                numberOfRowsToDisplay: 10
            },
            counters: {}
        };
    },
    watch:{
        searchQuery: {
            handler: function(query){
                //this.TagGridMixins_setQuickFilter(this.gridOptions, query);
                this.fetchPlans();
            }
        },
        defaultFilters:{
            handler:function(filters){
                console.log("watch", filters);
                this.fetchPlans();
            },
            deep:true
        }
    },
    computed:{
        getIdTableau:function(){
            if (this.$vgutils.isMobile()) {
                this.idTableau = "tiroir-plan-tableau-mobile";
            }
            else{
                this.idTableau = "tiroir-plan-tableau";
            }
            return this.idTableau;
        },
        getColumnDefs:function(){
            if(this.$vgutils.isMobile()){
                // hide all columns
                this.columnDefs = this.columnDefs.map(function(x) {
                    x.hide = true;
                    return x;
                });
                // hide tables header
                this.gridOptions.headerHeight = 0;
                this.gridOptions.rowHeight = 110;
                // add cell render
                // TODO add cell render
                this.columnDefs.unshift(
                    {
                        headerName: "id",
                        field: "id",
                        cellRenderer: (params) =>  new FilesPlanCellRenderer({propsData: {params: params}}).$mount().$el
                    }
                );
            }
            return this.columnDefs;
        }
    },
    methods:{
        onDeletePlan: function(params){
            this.VgFilesMixins_deleteFile(params.data).then((data)=>{
                this.fetchPlans();
            });
        },
        onCloseAddPlan: function(){
            this.showAddPlan = false;
            this.$emit("after-add-plan");
            this.fetchPlans();
        },
        onChangeFilterSite: function(site){
            this.fetchPlans();
        },
        onChangeFilterTagDocument: function(tag){
            this.filterAgGrid("label", tag);
        },
        /**
        * ouvre le pdf dans le viewer pdf
        * @param string link
        */
        onClickLink: function(link){
            this.pdfLink = link;
            this.collapseListeDesPlans = true;
        },
        /**
        * ouvre le 1er plan pdf dans le viewer pdf
        */
        openFirstPlan: function(){
            let plan = null;
            if(this.etage){   // si lieu focus le plan à ouvrir est le 1er qui est associé à ce lieu
                plan = this.plans.find((p)=>p.uploadedTo==this.etage.uid);
                if(plan){
                    this.VgFilesMixins_getDownloadFileLink(plan).then((link)=>{
                        this.pdfLink = link
                        this.$emit("first-plan-opened",link)
                    });
                }else{
                    this.$emit("first-plan-opened",null)
                }
            }else if(this.plans && this.plans.length>0){
                plan = this.plans[0];
                this.VgFilesMixins_getDownloadFileLink(plan).then((link)=>{
                    this.pdfLink = link;
                    this.$emit("first-plan-opened",link)
                });
            }
        },
        /**
         * close modal
         *
         * @event close
         */
        close:function(){
            this.$emit("close");
        },
        /**
         * Filtre uitilisant methods ag-grid pour filtre sur une colonne visé
         * @param string nameColumn
         * @param string dataFilter valeur reçu du select
         */
        filterAgGrid: function(nameColumn, dataFilter){
            var filters = [{ colId: nameColumn, value: dataFilter }];
            this.agGridSetFilters(filters);
        },
        /**
        * @param array filters
        */
        agGridSetFilters: function(filters){
            //console.log("FILTERS", filters);
            this.TagGridMixins_setFilters(this.gridOptions, filters);
        },
        /**
         * récupère le path du lieu auquel le plan est associé
         * @param string uid
         * @return string lieu path
         */
        getPlanLieuPath:function(uid){
            let lieu = this.lieux.find((lieu)=>lieu.uid==uid);
            return lieu?lieu.path:null;
        },
        /**
         * fecth plan et des liens vers les plans.
         */
        fetchPlans:function(){
            this.showLoadingOverlay = true;
            let metadatas = new Metadatas();
            let filtersDocuments = {};
            if(this.etage && this.etage.uid) filtersDocuments.uid = {attr: "l.uid", value: this.etage.uid, action: "equals"};
            if(this.querySite && this.querySite.length!=0) filtersDocuments.site = {attr: "l.path", value: this.querySite, action: "start_with"};
            if(this.searchQuery && this.searchQuery.length!=0){
                filtersDocuments.name = {attr: "d.name", value: this.searchQuery, action: "contains", openParenthesis: true};
                filtersDocuments.libel_lieu = {attr: "l.libel_lieu", value: this.searchQuery, action: "contains", logicalOperator: "OR"};
                filtersDocuments.label = {attr: "t.label", value: this.searchQuery, action: "contains", closeParenthesis: true, logicalOperator: "OR"};
            }
            metadatas.setFilters(filtersDocuments);
            metadatas.setLimit(this.pagination.offset, this.pagination.numberOfRowsToDisplay);
            this.DocumentsMixins_getPlans(metadatas).then((datas)=>{
                this.plans = datas.plans;
                this.counters = datas.counters;
                this.showLoadingOverlay = false;
                this.openFirstPlan();
            });
        },
        handlePaginationChange: function(pagination){
            this.pagination.offset = pagination.offset;
            this.pagination.numberOfRowsToDisplay = pagination.numberOfRowsToDisplay;
            this.fetchPlans();
        }
    },
    created: function() {
        this.fetchPlans();
    }
}
</script>
<style lang="css">
    #tiroir-plan-tableau{
        /*height: 30vh !important;*/
    }
</style>
<style lang="scss" scoped>
.container{
    width: 100%;
    .content{
        display: flex;
        justify-content: space-between;
    }
    .content-table{
        width: 100%;
        height: 100%;
    }
    .filters{
        width: 30%;
        margin-left: 10px;
    }
}
.viewer-plan{
    width: 100%;
    margin-top: 10px;
    height: 70vh;
}
</style>
<docs>
    Composant
    ## Usage du composant
    ```html static
          <vg-files-plans v-if="openTiroir" @close="openTiroir = false"></vg-files-plans>
    ```
    ## Possede un emit pour cloturer la modale
    ```javascript
    @close="openTiroir = false"
    ```

    ## Ne contient pas de props

    ## mixin utiliser :
    Link:
    ```javascript
    import VgFilesMixins from "src/components/Vg/Files/VgFilesMixins.js";
          VgFilesMixins_getDownloadFileLink

    ```
    Document:
    ```javascript
    import DocumentsMixins from "src/mixins/DocumentsMixins.js";
          DocumentsMixins_get

    ```
    Lieu:
    import LieuMixins from "src/mixins/LieuMixins.js";
          LieuMixins_getLieux

    ```

</docs>
