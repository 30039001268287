<template>
	<span><!--:class="{'oui': isValid, 'non': !isValid}"
		data-toggle="tooltip"
		:title="tooltipTitle"-->
		{{title}}
	</span>
</template>
<script>

	export default {
        name: 'vg-operation-flag',
		i18n:
        {
            "locale":navigator.language,
            "messages": {
                "fr": {
                    "fermeture": "Fermeture",
                    "img": "Photo",
                    "relance": "Relance",
                    "reouverture": "Ré-ouverture",
                    "email": "E-mail",
                    "email-demande-devis": "E-mail",
                    "urgence": "Urgence",
                    "differe": "Différé",
                    "bi": "Bon d'intervention",
                    "photo": "Photo",
                    "tiersinterventionponctuelle": "Intervention",
                    "affectation/interne": "Affectation interne",
                    "affectation/externe": "Affectation externe",
                    "affectation/externe/email": "Envoi email entreprise affectée",
                    "validation": "Validation fermeture",
                    "consommation": "Consommation",
                    "relance/iot": "Relance IOT",
                    "en_attente": "Mise en attente",
                    "prise_en_compte": "Prise en compte",
                    "retourAFaire": "Retour à faire",
                    "retourFait": "Retour fait",
                    "manuelle": "Commentaire textuel",
                    "changement_equipement": "Réaffectation équipement",
                    "intervention-reserve": "Intervention",
                    "tache": "Tache",
                    "a_prevoir": "A prévoir",
					"remplacement-equipement": "Remplacement équipement"
                },
                "en": {
                    "fermeture": "Closure",
                    "img": "Photo",
                    "relance": "Reminder",
                    "reouverture": "Reopening",
                    "email": "E-mail",
                    "urgence": "Urgent",
                    "differe": "Postponed",
                    "bi": "Intervention order",
                    "photo": "Photo",
                    "tiersinterventionponctuelle": "Intervention",
                    "affectation/interne": "Internal assignment",
                    "affectation/externe": "External assignment",
                    "validation": "Closure validation",
                    "consommation": "Consumption",
                    "relance/iot": "IOT Reminder",
                    "en_attente": "Put on hold",
                    "prise_en_compte": "taken into account",
                    "retourAFaire": "Feedback to give",
                    "retourFait": "Feedback given",
                    "changement_equipement": "Equipment reassignement",
                    "manuelle": "Text commentary",
                    "intervention-reserve": "Intervention",
                    "tache": "Task",
                    "a_prevoir": "Schedule task",
					"remplacement-equipement": "Equipment replacement"
                },
                "th": {
                    "fermeture": "การปิด",
                    "img": "รูปภาพ",
                    "relance": "การเตือนความจำ",
                    "reouverture": "กำลังเปิดอีกครั้ง",
                    "email": "อีเมล",
                    "urgence": "ด่วน",
                    "differe": "ถูกเลื่อนออกไป",
                    "bi": "คำสั่งการแทรกแซง",
                    "photo": "รูปภาพ",
                    "tiersinterventionponctuelle": "การแทรกแซง",
                    "affectation/interne": "การมอบหมายภายใน",
                    "affectation/externe": "การมอบหมายภายนอก",
                    "validation": "การตรวจสอบการปิด",
                    "consommation": "การบริโภค",
                    "relance/iot": "การเตือนความจำ IOT",
                    "en_attente": "พักไว้",
                    "prise_en_compte": "รวม",
                    "retourAFaire": "ข้อเสนอแนะที่ต้องให้",
                    "retourFait": "ให้ข้อเสนอแนะแล้ว",
                    "changement_equipement": "มอบหมายอุปกรณ์อีกครั้ง"
                }
            }
        },
		components:{

		},
    	props: {
			value: {
				type: Object,
				required: true
			}
        },
        data: function() {
            return {
				operation: this.value
			};
        },
		computed: {
			title: function(){
				if(this.operation.flag && this.operation.flag!="manuelle") return this.$t(this.operation.flag);
				return this.$t("manuelle");
			}
        }
    };

</script>
<style scoped lang="scss">

</style>
