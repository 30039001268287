<template>
    <vg-app-layout
         :title="pageTitle"
         :icon="'fiche-de-maintenance.png'"
         :colorheader="getHeaderColor"
         :isZoomLayout="true"
         :isActionButtonDisplay="false"
         @action-export="handleActionExport"
         @backpage="handleBackPage">
            <template v-slot:header-top-left-complement>
                <vg-fichesav-description v-if="getSelectedMaintenance"
                    :fm="getSelectedMaintenance"
                    :showIcon="false" 
                    inline />
            </template>

         <template v-slot:search>
             <vg-text-filter
                 v-model="searchQuery">
             </vg-text-filter>
         </template>
         <template #create-button>
            <vg-button
                type="default-danger"
                @click="isUpdatingFm = true">
                {{$t('update')}}
            </vg-button>
             <vg-button type="info" 
                @click="isModalFicheManagementOpened=true;">
                {{$t("actions")}}
            </vg-button>
            <!--vg-button :type="'default'" @click="handleClickPrintMaintenance"> {{$t("print-maintenance")}} </vg-button-->
        </template>
         <template v-slot:panel-menu-more>
             <vg-button
                 size="md"
                 type="default-round"
                 @click="isUpdatingFm = true">
                 {{$t('update-fiche')}}
             </vg-button>
         </template>
         <template v-slot:body v-if="$vgutils.isMobile() && getSelectedMaintenance.id">

             <!-- MOBILE -->

                    <!-- Details -->
                    <div class="section-details">
                        <vg-fichesav-description :fm="getSelectedMaintenance" />
                        <div>
                            <img v-if="getSelectedMaintenance.isBloquant && (getSelectedMaintenance.isBloquant=='1' || getSelectedMaintenance.isBloquant==1)"
                                src="/static/assets/icone/maintenance-bloquante-red.svg" 
                                style="width:30px;height:30px;"/>
                            <vg-fichesav-statut-viewer :fm="getSelectedMaintenance" />
                        </div>
                    </div>
                    <br>
                    <div class="vg-flex">
                        <vg-box-info :label="$t('dateOuvertureSAV')">
                            <vg-datetime-viewer v-model="getSelectedMaintenance.dateOuvertureSAV"></vg-datetime-viewer>
                        </vg-box-info>
                        <vg-box-info :label="$t('localisation')">
                            <vg-lieu-path-viewer v-model="getSelectedMaintenance.equipement.path"
                                onlyPath>
                            </vg-lieu-path-viewer>
                            <vg-lieu-path-viewer v-model="getSelectedMaintenance.equipement.path"
                                onlyPiece>
                            </vg-lieu-path-viewer>
                        </vg-box-info>
                    </div>
                    <div class="vg-flex">
                        <vg-box-info :label="$t('origin')">
                            <span class="value">
                                <vg-fichesav-origin :fm="getSelectedMaintenance"></vg-fichesav-origin> {{getSelectedMaintenance.contact}}
                            </span>
                        </vg-box-info>
                        <vg-box-info :label="$t('composant-equipement')" @click="handleClickZoomEquipement">
                            <img v-if="getSelectedMaintenance.isGEP=='1' && getSelectedMaintenance.libelComposant && getSelectedMaintenance.iconComposant"
                    			width="20" height="20"
                    			:src="getSelectedMaintenance.iconComposant"
                    			onerror="this.style.visibility='hidden'"/>
                    		<img v-else-if="getSelectedMaintenance.equipement && getSelectedMaintenance.equipement.isGroupEqp=='1' && getSelectedMaintenance.composant && getSelectedMaintenance.composant.libelComposant && getSelectedMaintenance.composant.icon"
                    			width="20" height="20"
                    			:src="getSelectedMaintenance.composant.icon"
                    			onerror="this.style.visibility='hidden'"/>
                    		<img v-else-if="getSelectedMaintenance.equipement && getSelectedMaintenance.equipement.categorie_icon"
                    			width="20" height="20"
                    			:src="getSelectedMaintenance.equipement.categorie_icon"
                    			onerror="this.style.visibility='hidden'"/>
                            <span class="value" v-if="getSelectedMaintenance.equipement.isGroupEqp != 1" >
                                {{getSelectedMaintenance.equipement.libel_equipement}}
                            </span>
                            <span class="value" v-else-if="getSelectedMaintenance.composant">{{getSelectedMaintenance.composant.libelComposant}}</span>
                        </vg-box-info>
                    </div>
                    <div class="vg-flex">
                        <vg-box-info :label="$t('affecte-a')">
                            <div class="value" v-if="getSelectedMaintenance.affectation">
                                <div v-for="affecte in getSelectedMaintenance.affectation.affectes">
                                    <span v-if="affecte.name">{{affecte.name}} </span>
                                    <span v-else> {{affecte.user_name}}</span>
                                </div>
                            </div>
                        </vg-box-info>
                        <vg-box-info :label="$t('qrcode')">
                            <span class="value">{{ getSelectedMaintenance.equipement.qrCode }}</span>
                        </vg-box-info>
                    </div>
                    <br>
                    <vg-collapse :title="$t('historique')"
                        :collapseByDefault="false"
                        :type="'section'">
                        <template #content>
                            <vg-operations-viewer :class="['tableau-operation', getTableauOperationClass]" v-if="getSelectedMaintenance.operations"
                                :idTableau="'show-maintenance-operations-viewer'"
                                :fm="getSelectedMaintenance"
                                :oneColumnView="true"
                                @update="handleOpenUpdateOperation"
                                @update-commentaire="handleOpenUpdateCommentaireOperation"
                                @delete="handleOpenDeleteOperation"
                                @consommable-show="handleConsommableShow"
                                @intervention-show="handleInterventionShow"
                                @operation-delete="onDeleteOperation"
                                @after-update-operation="onAfterUpdateOperation" />
                        </template>
                    </vg-collapse>
                    

                    <vg-files-viewer-photo
                        :files="VgFilesMixins_filesOnlyPhoto">
                    </vg-files-viewer-photo>




         </template>
         <template v-slot:primary>
             <div v-if="getSelectedMaintenance.id">
                 <vg-collapse :title="$t('details')"
                     :collapseByDefault="false"
                     :type="'section'"
                     >
                     <template v-slot:pretitle>
                         
                     </template>
                     <template v-slot:content>

                        <vg-libelle-editable :label="$t('localisation')">
                            <template v-slot:read>
                                <vg-lieu-path-viewer v-model="getSelectedMaintenance.equipement.path"></vg-lieu-path-viewer>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('dateOuvertureSAV')">
                            <template v-slot:read>
                                <vg-datetime-viewer v-model="getSelectedMaintenance.dateOuvertureSAV" ></vg-datetime-viewer>
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="getSelectedMaintenance.equipement.isGroupEqp != 1?$t('libel-equipement'):$t('libel-composant')">
                            <template v-slot:read>
                                <a v-if="getSelectedMaintenance.equipement.isGroupEqp != 1"
                                    @click="handleClickZoomEquipement"
                                    style="cursor: pointer;color:#059CFF;">
                                    <vg-equipement-viewer :equipement="getSelectedMaintenance.equipement" />
                                </a>
                                <vg-composant-viewer v-else
                                    v-model="getSelectedMaintenance.composant">
                                </vg-composant-viewer>
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="$t('corps-detat')">
                            <template v-slot:read>
                                <span></span>
                                <span>
                                    <vg-tag
                                            color="red"
                                            v-if="getSelectedMaintenance.corpsDetat"
                                        >
                                        <template>
                                            {{getSelectedMaintenance.corpsDetat.name}}
                                        </template>
                                    </vg-tag>
                                    <div v-else>-</div>
                                </span>
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="$t('commentaire-client')">
                            <template v-slot:read>
                                <span>{{getSelectedMaintenance.commentaireClient}}</span>
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="$t('urgence')">
                            <template v-slot:read>
                                <span v-if="getSelectedMaintenance.urgence && getSelectedMaintenance.urgence=='1'">{{getSelectedMaintenance.urgence}}</span>
                                <span v-else>{{$t("non")}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('bloquant')" v-if="$app.handleFmBloquante">
                            <template #read>
                                <img v-if="getSelectedMaintenance.isBloquant && (getSelectedMaintenance.isBloquant=='1' || getSelectedMaintenance.isBloquant==1)"
                                    src="/static/assets/icone/maintenance-bloquante-red.svg" 
                                    style="width:30px;height:30px;"/>
                                <span v-if="getSelectedMaintenance.isBloquant && (getSelectedMaintenance.isBloquant=='1' || getSelectedMaintenance.isBloquant==1)">
                                    {{$t('oui')}}
                                </span>
                                <span v-else>{{$t("non")}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable v-if="getSelectedMaintenance.typologie" :label="$t('typologie')">
                            <template v-slot:read>
                                <span> {{ getSelectedMaintenance.typologie }}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable>
                            <template v-slot:read>
                                <vg-button
                                    size="md"
                                    type="info"
                                    @click="exportPdf">
                                    {{$t('exporter-pdf')}}
                                </vg-button>
                            </template>
                        </vg-libelle-editable>

                     </template>
                 </vg-collapse>

                 <vg-collapse :title="$t('gestion-calendaire-statut')"
                     :collapseByDefault="false"
                     :type="'section'"
                     >
                     <template v-slot:pretitle>
                         <vg-button
                             size="md"
                             type="default-round"
                         >
                             <i class="far fa-calendar"></i>
                         </vg-button>
                     </template>
                     <template v-slot:content>
                         <vg-libelle-editable :label="$t('demandeur')">
                             <template v-slot:read><span > {{getSelectedMaintenance.contact}} </span></template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('dateOuvertureSAV')">
                             <template v-slot:read>
                                 <b> <vg-datetime-viewer v-model="getSelectedMaintenance.dateOuvertureSAV"></vg-datetime-viewer></b>
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('dateFermetureSAV')">
                             <template v-slot:read>
                                 <b> <vg-datetime-viewer
                                     v-model="getSelectedMaintenance.dateFermetureSAV">
                                 </vg-datetime-viewer></b>
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('duree-traitement')" v-if="getSelectedMaintenance.dureeTraitement">
                             <template #read>
                                <vg-duree-viewer v-model="getSelectedMaintenance.dureeTraitement"/>
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('statut')">
                             <template v-slot:read>
                                 <span >
                                     <vg-fichesav-statut-viewer :fm="getSelectedMaintenance"></vg-fichesav-statut-viewer>
                                 </span>
                             </template>
                         </vg-libelle-editable>
                         <!--vg-button
                              type="default"
                              v-if="getSelectedMaintenance.statut != 'Resolue'"
                              @click="isClosingFicheSav=true">
                               {{$t("cloturer")}}
                         </vg-button>
                         <vg-button
                              v-if="getSelectedMaintenance.statut == 'Resolue'"
                              type="default-danger"
                              @click="reopen">
                               {{$t("reouvrir")}}
                         </vg-button-->
                     </template>

                 </vg-collapse>

                 <vg-collapse :title="$t('affectation')"
                     :collapseByDefault="false"
                     :type="'section'"
                     >
                     <!--template v-slot:pretitle>
                         <vg-button
                             size="md"
                             type="default-round"
                             @click="showAffectationModal=true">
                             <i class="fas fa-pencil-alt"></i>
                         </vg-button>
                     </template-->
                     <template v-slot:content>
                         <div style="display:flex;flex-direction:column;">
                             <vg-libelle-editable :label="$t('affectation-mode')">
                                 <template v-slot:read>
                                     <span > {{isAffectationModeIsInterne()?$t("affectation-interne"):$t("affectation-externe")}} </span>
                                 </template>
                             </vg-libelle-editable>
                             <vg-libelle-editable :label="$t('affectation-debut')">
                                 <template v-slot:read>
                                     <vg-datetime-viewer v-if="getSelectedMaintenance.affectation"
                                         v-model="getSelectedMaintenance.affectation.start">
                                     </vg-datetime-viewer>
                                 </template>
                             </vg-libelle-editable>
                             <vg-libelle-editable :label="$t('affectation-fin')">
                                 <template v-slot:read>
                                     <vg-datetime-viewer v-if="getSelectedMaintenance.affectation"
                                         v-model="getSelectedMaintenance.affectation.end">
                                     </vg-datetime-viewer>
                                 </template>
                             </vg-libelle-editable>
                             <vg-libelle-editable :label="$t('affectation-interne')">
                                 <template v-slot:read>
                                     <div v-if="getSelectedMaintenance.affectation">
                                         <vg-affecte-name v-for="affecte in getSelectedMaintenance.affectation.affectes" v-if="!affecte.tiers_id" :affecte="affecte" :key="affecte.id"></vg-affecte-name>
                                     </div>
                                 </template>
                             </vg-libelle-editable>
                             <vg-libelle-editable :label="$t('affectation-externe')">
                                 <template v-slot:read>
                                     <div v-if="getSelectedMaintenance.affectation">
                                         <vg-affecte-name v-for="affecte in getSelectedMaintenance.affectation.affectes" v-if="affecte.tiers_id" :affecte="affecte" :key="affecte.id"></vg-affecte-name>
                                     </div>
                                 </template>
                             </vg-libelle-editable>

                         </div>
                     </template>
                 </vg-collapse>

                 <vg-collapse v-show="can('MAINTENANCE.GESTION_FINANCIERE')" :title="$t('gestion-financiere')"
                     :collapseByDefault="false"
                     :type="'section'">
                     <template v-slot:pretitle>
                         <vg-button
                             size="md"
                             type="default-round"
                         >
                             <i class="fas fa-euro-sign"></i>
                         </vg-button>
                     </template>

                     <template v-slot:content>
                         <vg-libelle-editable :label="$t('duree-intervention')">
                             <template v-slot:read>
                                 <vg-fichesav-workingtime-viewer v-model="getSelectedMaintenance.workingTime"/>
                                 <vg-button v-if="(getSelectedMaintenance.statut=='Resolue' || getSelectedMaintenance.statut=='Supervisor') && $app.role=='ROLE_ADMIN'"
                                    :type="'info'"
                                    :size="'sm'"
                                    style="margin-left:10px;"
                                    @click="onClickUpdateWorkingTime">
                                    <i class="fas fa-pen"></i>
                                 </vg-button>
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('cout-interne')">
                             <template v-slot:read v-if="getSelectedMaintenance.workingTime">
                                 <vg-input-cost v-model="coutInterne" />
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('cout-consommation')" v-if="hasConsommations">
                             <template v-slot:read>
                                 <vg-input-cost v-model="coutConsommations" />
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('cout-externe')">
                             <template v-slot:read>
                                 <vg-input-cost v-model="coutExterne" />
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('cout-total')">
                             <template v-slot:read>
                                 <vg-input-cost v-model="coutTotal" />
                             </template>
                         </vg-libelle-editable>

                     </template>
                 </vg-collapse>

                 <vg-collapse :title="$t('documents-attaches')"
                     :collapseByDefault="false"
                     :type="'section'"
                     >
                     <template v-slot:pretitle>
                         <vg-button
                             size="md"
                             type="default-round"
                             @click="isAddDocuments=!isAddDocuments;">
                             <i class="fas fa-plus"></i>
                         </vg-button>
                     </template>
                     <template v-slot:content>
                         <vg-files-uploader v-if="isAddDocuments" ref="maintenance-uploader-desktop"
                             :tagsList="VgFilesMixins.tags"
                             @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;isAddDocuments=false;">
                         </vg-files-uploader>
                         <vg-files-viewer-list-section
                             :files="VgFilesMixins_filesListSection"
                             @delete="VgFilesMixins_fetchFilesByUid()">
                         </vg-files-viewer-list-section>
                     </template>
                 </vg-collapse>
             </div>
         </template>
         <template v-slot:secondary>
            <div style="margin-bottom:20px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;gap:20px;">
                <span style="color: #1286ff;font-size: 19px;">{{$t("timeline")}}</span>
                <vg-fichesav-timeline />
            </div>
             <div class="operation-contener" >
                 <div class="operation-action">
                     <!--vg-button
                         :size="'xs'"
                         :type=" 'info' "
                         @click="isModalFicheManagementOpened=true"
                     >
                         <i class="fas fa-plus"></i>
                     </vg-button-->
                     {{$t("historique")}}
                     <vg-button :type="'info'"
                        :size="'sm'"
                        style="margin-left:30px;"
                        @click="isModalFicheManagementOpened=true;">
                         {{$t("ajouter-action")}}
                     </vg-button>
                 </div>

                 <vg-tabs v-model="focusedTab"
                     :tabs="tabs"
                     :color="'jaune'"
                     @tabClicked="onTabClicked">
                 </vg-tabs>
                 <vg-operations-viewer class="tableau-operation" v-if="getSelectedMaintenance.operations"
                     v-model="getSelectedMaintenance.operations"
                     :idTableau="'show-maintenance-operations-viewer'"
                     :fm="getSelectedMaintenance"
                     :localFilters="operationsLocalFilters"
                     @update="handleOpenUpdateOperation"
                     @update-commentaire="handleOpenUpdateCommentaireOperation"
                     @delete="handleOpenDeleteOperation"
                     @consommable-show="handleConsommableShow"
                     @intervention-show="handleInterventionShow"
                     @operation-delete="onDeleteOperation"
                     @after-update-operation="onAfterUpdateOperation" />
             </div>
             <vg-files-viewer-photo
                 :files="VgFilesMixins_filesOnlyPhoto">
             </vg-files-viewer-photo>
         </template>
         <template v-slot:footer v-if="$vgutils.isMobile()">


                 <vg-button
                    type="default-link"
                    size="sm"
                    v-if="getSelectedMaintenance.statut != 'Resolue'"
                    @click="isClosingFicheSav=true">
                     <i class="far fa-times-circle"></i> {{$t("cloturer")}}
                 </vg-button>
                 <vg-button
                    v-else
                    size="sm"
                    type="default-link"
                    @click="reopen">
                     <i class="fas fa-power-off"></i> {{$t("reouvrir")}}
                 </vg-button>
                 <vg-button
                    type="default-link"
                    size="sm"
                    @click="showAffectationModal=true">
                     <i class="fas fa-user-plus"></i> {{$t("affectation")}}
                 </vg-button>

                 <vg-files-uploader ref="maintenance-uploader-mobile"
                    style="margin-right:10px;"
                     :isOnlyPhoto="true"
                     @upload-done="VgFilesMixins_fetchFilesByUid()">
                 </vg-files-uploader>

                 <vg-button
                    type="default-link"
                    size="sm"
                    @click="isModalFicheManagementOpened=true">
                    <i class="fas fa-plus-circle"></i> {{$t("operation")}}
                 </vg-button>


         </template>
         <template v-slot:tools>
             <vg-fichesav-affectation v-if="showAffectationModal"
                @close="showAffectationModal=false;"
                @save="showAffectationModal=false;" />

             <vg-fichesav-management v-if="getSelectedMaintenance && isModalFicheManagementOpened"
                @close="handleCloseModalFicheManagement"
                @back-page="handleBackPage()"
                @operation-submited="handleOperationEmailSubmited" />

             <vg-operation-update v-if="isUpdatingOperation"
                 v-model="focusedOperation"
                 :flag="focusedOperation.flag"
                 @close="isUpdatingOperation=false"
                 @save="isUpdatingOperation=false;focusedOperation=null;"
             >
             </vg-operation-update>
             <vg-operation-commentaire-update v-if="isUpdatingCommentaireOperation"
                 v-model="focusedOperation"
                 @close="isUpdatingCommentaireOperation=false"
                 @save="isUpdatingCommentaireOperation=false"
             >
         </vg-operation-commentaire-update>
             <!-- Ci dessous pour le mobile bizarre que le mobile n'utilise pas le vg maintenance forme contenant deja ce btn cloture -->
             <fichesav-close v-if="isClosingFicheSav"
                 :target="getSelectedMaintenance"
                 :showWorkingTime="!($app.isSupervisor && getSelectedMaintenance.statut=='Resolue')"
                 @close="isClosingFicheSav = false"
                 @cancel="isClosingFicheSav = false"
                 @confirm="cloturer"
                 @open-new-consommable="handleOpenNewConsommable('close')"
                 v-model="rapportCloture">
             </fichesav-close>
             <vg-maintenance-form
                 v-if="isUpdatingFm"
                 v-model="getSelectedMaintenance"
                 :displayedFields="displayedFields"
                 @update-maintenance="handleUpdateMaintenance"
                 @close="onCloseMaintenanceForm"
             >
             </vg-maintenance-form>
             <vg-equipement-select-modal v-if="showModalChangeEquipement"
                 v-model="getSelectedMaintenance.materiel_id"
                 @close="showModalChangeEquipement=false"
                 @save="handleSaveUpdateEquipement">
             </vg-equipement-select-modal>
         </template>
    </vg-app-layout>
</template>

<script>

    import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
    import VgOperationsViewer from "src/components/Vg/Operation/VgOperationsViewer.vue";
	import VgOperationUpdate from "src/components/Vg/Operation/VgOperationUpdate.vue";
	import VgOperationCommentaireUpdate from "src/components/Vg/Operation/VgOperationCommentaireUpdate.vue";
	import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
    import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
    import VgAffecteName from "src/components/Vg/Affectations/VgAffecteName.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgTabs from "src/components/Vg/VgTabs.vue";
    import VgTag from "src/components/Vg/VgTag.vue";
    import VgDureeViewer from "src/components/Vg/VgDureeViewer.vue";
    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgInputCost from "src/components/Vg/VgInputCost.vue";
    import VgMaintenanceForm from "src/components/Vg/Forms/VgMaintenanceForm.vue";
    import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
    import VgFichesavOrigin from 'src/components/Vg/FicheSAV/VgFichesavOrigin.vue';
    import VgFichesavManagement from 'src/components/Vg/FicheSAV/VgFichesavManagement.vue';
    import VgFichesavStatutViewer from "src/components/Vg/FicheSAV/VgFichesavStatutViewer.vue";
    import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
    import FichesavClose from 'src/components/Vg/FicheSAV/FichesavClose.vue';
    import VgEquipementSelectModal from 'src/components/Vg/Equipements/VgEquipementSelectModal.vue';
    import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
    import VgTooltip from 'src/components/Vg/VgTooltip.vue';
    import VgBoxInfo from "src/components/Vg/Forms/VgBoxInfo.vue";
    import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
    import VgComposantViewer from "src/components/Vg/Composant/VgComposantViewer.vue";
    import VgFichesavWorkingtimeViewer from "src/components/Vg/FicheSAV/VgFichesavWorkingtimeViewer.vue";
    import VgFichesavAffectation from "src/components/Vg/FicheSAV/VgFichesavAffectation.vue";
    import VgFichesavTimeline from "src/components/Vg/FicheSAV/VgFichesavTimeline.vue";
    import VgEquipementViewer from "src/components/Vg/Equipements/VgEquipementViewer.vue";

    import { mapGetters } from 'vuex';
    export default {
        name: 'maintenance',
        components: {
            VgFichesavDescription,
            VgOperationsViewer,
            VgOperationUpdate,
            VgOperationCommentaireUpdate,
            VgFilesViewerPhoto,
            VgFilesUploader,
            VgButton,
            VgTabs,
            VgCollapse,
            VgTooltip,
            VgAffecteName,
            VgDatetimeViewer,
            VgInputCost,
            VgMaintenanceForm,
            VgFilesViewerListSection,
            VgFichesavOrigin,
            VgFichesavManagement,
            VgFichesavStatutViewer,
            VgInputCost,
            VgLibelleEditable,
            VgEquipementSelectModal,
            FichesavClose,
            VgBoxInfo,
            VgLieuPathViewer,
            VgComposantViewer,
            VgFichesavWorkingtimeViewer,
            VgFichesavAffectation,
            VgTag,
            VgDureeViewer,
            VgFichesavTimeline,
            VgEquipementViewer
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Détails de la fiche curative n°",
            "page-title-typologie": "Détails {typologie} n°",
            "page-title-mobile": "Détails fiche n°",
            "oui": "Oui",
            "non": "Non",
            "details": "Détails",
            "corps-detat": "Corps d'état",
            "affectation": "Affectation",
            "libel-equipement": "Libellé équipement",
            "libel-composant": "Libellé composant",
            "commentaire-client": "Description",
            "localisation": "Localisation",
            "composant-equipement": "Composant/équipement",
            "origin": "Origine",
            "qrcode": "Qrcode",
            "affecte-a": "Affectée à",
            "urgence": "Urgent",
            "bloquant": "Bloquant",
            "gestion-financiere": "Gestion financière",
            "documents-attaches": "Documents attachés",
            "affectation-mode": "Mode affectation",
            "affectation-interne": "Interne",
            "affectation-externe": "Externe",
            "affectation-debut": "Date début",
            "affectation-fin": "Date fin",
            "affectation-affectes": "Affectation",
            "duree-intervention": "Durée intervention",
            "cout-interne": "Coût interne",
            "cout-externe": "Coût externe",
            "cout-consommation": "Coût pièces détachées/consos",
            "cout-total": "Coût total",
            "gestion-calendaire-statut": "Gestion calendaire et statut",
            "update-equipement": "Modifier l'équipement",
            "update-fiche": "Modifier la fiche",
            "update": "Modifier",
            "cloturer": "Clôturer",
            "reouvrir": "Ré-ouvrir",
            "operation": "Operations",
            "historique": "Historique",
            "ajouter-action": "Ajouter action",
            "actions": "Actions",
            "dateOuvertureSAV": "Date ouverture",
            "dateFermetureSAV": "Date fermeture",
            "demandeur": "Demandeur",
            "statut": "Statut",
            "print-maintenance": "Imprimer",
            "toutes": "Toutes",
            "commentaires": "Commentaires textuels",
            "consommations": "Consommations",
            "retours": "Retours",
            "taches": "Taches",
            "a-prevoir": "A prévoir",
            "changements-statut": "Changements statut",
            "communications-tiers": "Communications tiers",
            "typologie": "Typologie",
            "timeline": "Timeline",
            "exporter-pdf": "éditer un rapport",
            "export-pdf": "Export PDF",
            "jours": "jours",
            "duree-traitement": "Intervalle ouverture fermeture fiche"
        },
        "en": {
            "page-title": "Curative sheet n°",
            "page-title-mobile": "Sheet n° details",
            "oui": "Yes",
            "non": "No",
            "details": "Details",
            "corps-detat": "Technical field",
            "affectation": "Assignements",
            "libel-equipement": "Equipment's name",
            "commentaire-client": "Description",
            "urgence": "Emergency",
            "urgence": "Blocking",
            "gestion-financiere": "Financial datas",
            "documents-attaches": "Attached documents",
            "affectation-mode": "Assignements mode",
            "affectation-interne": "Internal",
            "affectation-externe": "External",
            "affectation-debut": "Start date",
            "affectation-fin": "end date",
            "affectation-affectes": "Assignement",
            "duree-intervention": "Intervention duration",
            "cout-interne": "Cost",
            "cout-externe": "External cost",
            "cout-consommation": "Spare parts cost",
            "cout-total": "Total cost",
            "gestion-calendaire-statut": "Schedule and status",
            "update-equipement": "Update equipment",
            "cloturer": "Resolve",
            "reouvrir": "Reopen",
            "operation": "Operations",
            "dateOuvertureSAV": "Start date",
            "dateFermetureSAV": "End date",
            "demandeur": "Requester",
            "statut": "Status",
            "print-maintenance": "Print",
            "libel-composant": "Component label",
            "localisation": "Location",
            "composant-equipement": "Component/equipment",
            "origin": "Source",
            "qrcode": "Qrcode",
            "affecte-a": "Assigned to",
            "update-fiche": "Update sheet",
            "update": "Update",
            "toutes": "All",
            "commentaires": "Text comment",
            "consommations": "Consumption",
            "retours": "Feedback",
            "taches": "Tasks",
            "a-prevoir": "To provide",
            "changements-statut": "Status update",
            "communications-tiers": "Suppliers",
            "typologie": "Sheet type",
            "timeline": "Timeline",
            "exporter-pdf": "Get a report",
            "export-pdf": "Export PDF",
            "jours": "days"
        }
    }
},
		mixins:[
            MaintenanceMixins
        ],
        props:{
            id:{
                type: Number | String,
                default: null
            },
            openActions:{
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                showAffectationModal: false,
                isUpdatingOperation:false,
                isUpdatingCommentaireOperation:false,
                //isUpdatingAffectations: false,
                isUpdatingFm: false,
                isUpdatingGestionCalendaireEtStatut: false,
                isAddDocuments: false,
                isModalFicheManagementOpened: this.openActions || false,
                isClosingFicheSav:false,
                focusedOperation:null,
                rapportCloture: null,
                showModalChangeEquipement:false,
                operationsLocalFilters: null,
                displayedFields: null
            }
        },
        methods:{
            onCloseMaintenanceForm: function(maintenance){
                this.isUpdatingFm = false;
                this.displayedFields = null;
            },
            onClickUpdateWorkingTime: function(){
                this.displayedFields = ["workingTime"];
                this.isUpdatingFm = true;
            },
            onAfterUpdateOperation: function(e){
                this.fetch();
            },
            onTabClicked: function(e){
                console.log("ON TAB CLICKED", e);
                this.operationsLocalFilters = e.filtres;
            },
            /**
            * affectation interne || externe
            */
            isAffectationModeIsInterne: function(){
                if(this.getSelectedMaintenance.affectation && this.getSelectedMaintenance.affectation.affectes)
                    return this.getSelectedMaintenance.affectation.affectes.findIndex((affecte)=>affecte.roles)!=-1;
                return true;
            },
            /**
            * @param Object operation
            */
            handleOpenUpdateOperation: function(operation){
                this.focusedOperation = operation;
                this.isUpdatingOperation = true;
			},
            /**
            * @param Object operation
            */
            handleOpenUpdateCommentaireOperation: function(operation){
                this.focusedOperation = operation;
                this.isUpdatingCommentaireOperation = true;
            },
            onDeleteOperation: function(operation){
                this.deleteOperation(operation);
            },
            deleteOperation: function(operation){
                this.getSelectedMaintenance.operations.forEach((op, i) => {
                    if(op["id"] == operation.id){
                        this.getSelectedMaintenance.operations.splice(i, 1);
                    }
                });
            },
            /**
            * @param Object operation
            */
			handleOpenDeleteOperation: function(operation){
				this.focusedOperation = operation;
				//this.showModal.deleteOperation = true;
                this.MaintenanceMixins_deleteOperation(operation.id, operation).then((operation)=>{
                    this.deleteOperation(operation);
                });
			},
            /**
            * @param integer idConsommable
            */
            handleConsommableShow: function(idConsommable){
                this.$router.push({ name: '_consommable_id', params: {id: idConsommable} });
			},
            /**
            * @param integer idIntervention
            */
			handleInterventionShow: function(idIntervention){
                this.$router.push({ name: '_intervention', params: { id: idIntervention }});
			},
            /**
            * @param array
            */
            handleSaveAffectations: function(datas){
                /*let fm = this.getSelectedMaintenance;
				fm.affectations.users = datas.users?datas.users: null;
				fm.affectations.tiers = datas.tiers?datas.tiers: null;
				this.$eventbus.$emit('tag-grid-updaterows', fm);*/
                this.MaintenanceMixins_getMaintenance(this.getSelectedMaintenance.id).then((datas)=>{
                    this.$store.dispatch("MaintenancesStore/updateMaintenance",datas);
                    this.isUpdatingAffectations = false;
                });

			},
            cloturer: function(data) {
                //console.log("execute cloture...",data);
                this.isClosingFicheSav = false;
                let workingTime = data.workingTime ? data.workingTime : null;
                var query = {
                    id: this.getSelectedMaintenance.id,
                    dateFermetureSAV: moment().format("YYYY-MM-DD HH:mm:ss"),
                    statut: this.$app.underSupervisor ? "Supervisor" : "Resolue",
                    operation: this.rapportCloture,
                    userId: this.$app.appID,
                    idUser: this.$app.idUser,
                    workingTime: workingTime
                };
                //if(data && data.workingTime) query.workingTime = data.workingTime;
                this.MaintenanceMixins_resolveMaintenance(query).then(()=>{
                    this.getSelectedMaintenance.statut = "Resolue";
                    this.$store.dispatch("MaintenancesStore/updateMaintenance", query);
                    this.$router.push({ name: '_maintenances'});
                });
            },
            reopen:function(){
                let maintenanceId = this.getSelectedMaintenance.id;
                this.getSelectedMaintenance.statut = "En_cours";
                this.MaintenanceMixins_reopenMaintenances(maintenanceId).then(()=>{
                    this.$store.dispatch("MaintenancesStore/updateMaintenance", {"id":maintenanceId,"statut":"En_cours"});
                });
            },
            handleCloseModalFicheManagement: function(){
                this.isModalFicheManagementOpened = false;
                this.MaintenanceMixins_getMaintenance(this.getSelectedMaintenance.id).then(()=>this.$forceUpdate());
			},
            handleBackPage:function(){
                this.$router.go(-1);
                this.$store.dispatch("MaintenancesStore/setMaintenance",[]);
                this.$store.dispatch("OperationsStore/clear");
            },
            handleOperationEmailSubmited: function(){
                this.MaintenanceMixins_getMaintenance(this.$route.params.id).then((maintenance)=>{
                    this.VgFilesMixins_init(maintenance.uid, "fichemaintenance");
                });
            },
            handleUpdateMaintenance:function(maintenance){
                document.location.reload();
                this.isUpdatingFm = false;
                this.displayedFields = null;
            },
            handleSaveUpdateEquipement: function(equipementId){
                console.log("handleSaveUpdateEquipement", equipementId);
            },
            handleClickZoomEquipement:function(){
                this.$router.push({ name: '_equipement_id', params: { id: this.getSelectedMaintenance.equipement.id }})
            },
            fetch: function(){
                this.MaintenanceMixins_getMaintenance(this.$route.params.id).then((maintenance)=>{
                    this.VgFilesMixins_init(maintenance.uid, "fichemaintenance");
                });
            },
            exportPdf(){
                this.MaintenanceMixins_getPdfFile(this.$route.params.id, "Fiche_maintenance").then((pdf)=>{

                });
            }
        },
		created: function(){
            this.fetch();
        },
		mounted: function(){},
        computed:{
            ...mapGetters({
                  getSelectedMaintenance: 'MaintenancesStore/getSelectedMaintenance'
            }),
            tabs:function(){
                return [
                    {label: this.$t("toutes"), name:"toutes", filtres:[
                        {attr: "flag", colId: "flag", value: null, action: "equals"}
                    ]},
                    {label: this.$t("commentaires"), name:"commentaires", filtres:[
                        {attr: "flag", colId: "flag", value: ["manuelle", "", "validation"], action: "equals"}
                    ]},
                    {label: this.$t("consommations"), name:"consommations", filtres:[
                        {attr: "flag", colId: "flag", value: "consommation", action: "equals"}
                    ]},
                    {label: this.$t("retours"), name:"retours", filtres:[
                        {attr: "flag", colId: "flag", value: ["retourFait", "retourAFaire", "relance"], action: "equals"}
                    ]},
                    {label: this.$t("taches"), name:"taches", filtres:[
                        {attr: "flag", colId: "flag", value: "tache", action: "equals"}
                    ]},
                    {label: this.$t("a-prevoir"), name:"a-prevoir", filtres:[
                        {attr: "flag", colId: "flag", value: "a_prevoir", action: "equals"}
                    ]},
                    {label: this.$t("communications-tiers"), name:"communications-tiers", filtres:[
                        {attr: "flag", colId: "flag", value: ["intervention-reserve", "tiersinterventionponctuelle", "email", "bi", "affectation/externe"], action: "equals"}
                    ]},
                    {label: this.$t("changements-statut"), name:"changements-statut", filtres:[
                        {attr: "flag", colId: "flag", value: ["fermeture", "en_attente", "prise_en_compte", "reouverture", "validation", "changement_equipement", "urgence", "differe", "relance/iot", "affectation/affectation/interne", "affectation/interne", "affectation/externe"], action: "equals"}
                    ]}
                ];
            },
            /**
            * @return string
            */
            pageTitle: function(){
                var debut = this.$t("page-title");
                let maintenance = this.getSelectedMaintenance;
                if(maintenance.typologie && maintenance.typologie.length){
                    debut = this.$t("page-title-typologie", {typologie:maintenance.typologie});
                }
                if(this.$vgutils.isMobile()){
                    return this.$t("page-title-mobile") +" : "+ maintenance.id;
                }
                if(maintenance){
                    var complement = maintenance.id + " : ";
                    // if (maintenance.equipement && maintenance.equipement.isGroupEqp == "1") {
                    //     complement = maintenance.id + " : "+ maintenance.composant.libelComposant + " " +maintenance.commentaireClient;
                    // } else if(maintenance.equipement) {
                    //     complement = maintenance.id + " : "+ maintenance.equipement.libel_equipement + " " +maintenance.commentaireClient;
                    // }
                }
                return debut + complement;
            },
            /**
            * @return Object
            */
            schedule: function(){
                let fm = this.getSelectedMaintenance;
                let schedule = {
                    dateAffectation: moment().format("YYYY-MM-DD HH:mm:ss"),
                    start: moment().format("YYYY-MM-DD HH:mm:ss"),
                    end: moment().add("30","m").format("YYYY-MM-DD HH:mm:ss"),
                    item: fm,
                    uidAffected: []
                };
                if(fm.affectation && fm.affectation.affectes && fm.affectation.affectes.length!=0){
                    schedule = Object.assign(schedule,{
                        id: fm.affectation.id,
                        start: fm.affectation.start,
                        end: fm.affectation.end,
                        uid: fm.affectation.uid
                    });
                }
                return schedule;
            },
            hasConsommations: function(){
                return this.getSelectedMaintenance.operations.findIndex((operation)=>operation.hasOwnProperty("consommableMouvement") && operation.consommableMouvement)!=-1;
            },
            /**
            * @return integer
            */
            coutConsommations:function(){
                let operations = this.getSelectedMaintenance.operations;
                let coutConsommation = 0;
                operations.forEach((operation, i) => {
                    if(operation.hasOwnProperty("consommableMouvement") && operation.consommableMouvement) coutConsommation += Number(operation.consommableMouvement.cout);
                });
                return coutConsommation;
            },
            coutInterne: function(){
                return this.MaintenanceMixins_coutInterne(this.getSelectedMaintenance.workingTime);
            },
            /**
            * @return integer
            */
            coutExterne:function(){
                if(this.getSelectedMaintenance && this.getSelectedMaintenance.operations
                    && Array.isArray(this.getSelectedMaintenance.operations)
                    && this.getSelectedMaintenance.operations.length!=0){
                    let cout = 0;
                    this.getSelectedMaintenance.operations.forEach((operation)=>{
                        if(operation.tiers.intervention && operation.tiers.intervention.cout) cout += Number(operation.tiers.intervention.cout);
                    });
                    return cout;
                }else return 0.00;
            },
            /**
            * @return integer
            */
            coutTotal:function(){
                let coutExterne = this.coutExterne ? this.coutExterne : 0;
                let coutInterne = this.getSelectedMaintenance.workingTime ? this.MaintenanceMixins_coutInterne(this.getSelectedMaintenance.workingTime) : 0;
                let coutConsommations = this.coutConsommations ? this.coutConsommations : 0;
                return Number(coutExterne)+Number(coutInterne)+Number(coutConsommations);
            },
            /**
            * @return string
            */
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
            },
            getTableauOperationClass: function(){
                if(this.getSelectedMaintenance.operations && this.getSelectedMaintenance.operations.length!=0 && this.getSelectedMaintenance.operations.length<=5) return 'tableau-operation-height-'+this.getSelectedMaintenance.operations.length;
                else if(this.getSelectedMaintenance.operations && this.getSelectedMaintenance.operations.length!=0) return 'tableau-operation-height-5';
                else return 'tableau-operation-empty';
            }
        },
        destroyed: function(){
            this.$store.dispatch("MaintenancesStore/setMaintenance",[]);
            this.$store.dispatch("OperationsStore/clear");
        }
    };
</script>
<style lang="scss">
	/* src/views/Maintenance/Maintenance.vue */

.complement-title{
    background-color: rgba(253, 192, 44, 0.1);
    border: 1px solid #FDC02C;
    color: #FDC02C;
    padding: 1px 8px;
    margin: 0 8px;
    font-size: 23px;
    max-width: 30vw;
    width: fit-content;
}

.operation-contener{
    .tableau-operation{
        height: 400px !important;
        margin-bottom: 5%;
        .tag-grid{
            height: 100% !important;
        }
    }
}
.operation-action{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    color: #1286ff;
    font-size: 19px;
    align-items: center;
    height: 40px;
}
@media only screen and (max-width: 768px){
    .header-top-left{
        span {
            font-size:15px;
        }
    }
    #wrapper-footer{
        .uploader-container{

    		.upload-photo{
    			height: 50px;
    			margin: 0;
                border: none;
    			align-items: center;
    		}
    	}
    }

    .tableau-operation{
        border-bottom: 1px solid #dbdbdb;
    }
    .tableau-operation-empty{
        height: 150px !important;
    }
    @mixin heightOperations{
        @for $i from 1 to 20 {
            .tableau-operation-height-#{$i} {
                height: calc($i * 50px) !important;
            }
        }
    }
    @include heightOperations;
}

.vg-flex{
    display: flex;
    .vg-fichesav-origin{
        margin-right: 7px;
    }
}
.section-details{
    display: flex;
    justify-content: space-between;
}

</style>
