<template>
    <div>
        <button @click="$router.go(-1)" class="hidden-print"><i class="fas fa-arrow-left" ></i></button>

        <vg-reporting-prints
            class="vg-print-show"
            :idTableau="'vg-fiche-sav-rapport'"
            :data="getMaintenances"
            :columnDefs="columnDefs"
            :pathSplitKey="'equipement.path'"
            :search="searchQuery"
            :allTitle="$t('report-title')">
        </vg-reporting-prints>
    </div>

</template>
<script>
import StatutFmCellRender from "src/components/Vg/TagGrid/StatutFmCellRender.vue";
import AffectationFmCellRender from "src/components/Vg/TagGrid/AffectationFmCellRender.vue";
import UrgenceFmCellRender from "src/components/Vg/TagGrid/UrgenceFmCellRender.vue";
import OrigineFmCellRender from "src/components/Vg/TagGrid/OrigineFmCellRender.vue";
import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
import EquipementFmCellRender from "src/components/Vg/TagGrid/EquipementFmCellRender.vue";
import FromDateCellRender from "src/components/Vg/TagGrid/FromDateCellRender.vue";
import OperationTextuelleCellRender from "src/components/Vg/TagGrid/OperationTextuelleCellRender.vue";
import ThumbnailCellRender from "src/components/Vg/TagGrid/ThumbnailCellRender.vue";
import DescriptionFmCellRender from "src/components/Vg/TagGrid/DescriptionFmCellRender.vue";
import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
import VgCostCellRender from "src/components/Vg/TagGrid/VgCostCellRender.vue";
import StatutFilter from "src/components/Grid/StatutFilter.js";
import AffectationFilter from "src/components/Grid/AffectationFilter.js";
import OrigineFilter from "src/components/Grid/OrigineFilter.js";
import VgReportingPrints from "src/components/Vg/TagGrid/VgReportingPrints.vue";

import { mapGetters } from 'vuex';
export default {
    name:'rapport-maintenance',
    props:{},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "AgGrid-Id": "N° fiche",
            "AgGrid-Description": "Description",
            "AgGrid-Localisation": "Localisation",
            "AgGrid-Piece": "Piece",
            "AgGrid-Affectation": "Affectations",
            "AgGrid-Urgence": "Urg.",
            "AgGrid-Origine": "Origine",
            "AgGrid-workingTime": "Durée intervention",
            "AgGrid-DateOuverture": "Ouverture",
            "AgGrid-DateFermeture": "Fermeture",
            "AgGrid-dateAffectation": "Date affectation",
            "AgGrid-Traitement": "Traitement",
            "AgGrid-DateDiferee": "Diferee",
            "AgGrid-Equipement": "Composant/Equip",
            "AgGrid-Affecte": "Affecte a",
            "AgGrid-Status": "Statut",
            "AgGrid-Relance": "Relance",
            "AgGrid-RetourAFaire": "Retour à faire",
            "AgGrid-RetourFait": "Retour Fait",
            "AgGrid-operation-last-manuelle": "Dernière opération",
            "AgGrid-operation-validation": "Validation",
            "AgGrid-Age": "Age",
            "AgGrid-Categorie": "Categorie",
            "cout-total": "Coût total",
            "cout-externe": "Coûts externe",
            "cout-interne": "Coûts interne",
            "cout-consommations": "Coût consommations",
            "AgGrid-Photo-1": "Photo 1",
            "AgGrid-Photo-2": "Photo 2",
            "AgGrid-Photo-3": "Photo 3",
            "AgGrid-coutInterne": "Coût interne",
            "AgGrid-coutExterne": "Coût externe",
            "AgGrid-coutConsommations": "Coût consommations",
            "AgGrid-coutTotal": "Coût total",
            "aucune-affectation": "Aucune affectation",
            "report-title": "Rapport d'activité de la maintenance curatives"
        },
        "en": {
            "AgGrid-Fiche": "Sheet no.",
            "AgGrid-Description": "Description",
            "AgGrid-Localisation": "Location",
            "AgGrid-Piece": "Room",
            "AgGrid-Affectation": "Assignments",
            "AgGrid-Urgence": "Emergency ",
            "AgGrid-Origine": "Origin",
            "AgGrid-DateOuverture": "Opening date",
            "AgGrid-DateFermeture": "Closing date",
            "AgGrid-dateAffectation": "Date of assignment",
            "AgGrid-Traitement": "Treatment",
            "AgGrid-DateDiferee": "Date postponed",
            "AgGrid-Equipement": "Equipment",
            "AgGrid-Affecte": "Assigned to",
            "AgGrid-Status": "Status",
            "AgGrid-Relance": "Reminder",
            "AgGrid-Age": "Age ms",
            "AgGrid-Categorie": "Category",
            "cout-total": "Total cost",
            "AgGrid-workingTime": "Duration of work",
            "AgGrid-coutInterne": "Internal cost",
            "AgGrid-coutExterne": "External cost",
            "AgGrid-coutConsommations": "Consumption cost",
            "AgGrid-coutTotal": "Total Cost",
            "AgGrid-Id": "Sheet N°",
            "AgGrid-RetourAFaire": "Require a feedback",
            "AgGrid-RetourFait": "Feedback done",
            "AgGrid-operation-last-manuelle": "Last operation",
            "AgGrid-operation-validation": "Validation",
            "AgGrid-Photo-1": "Photo 1",
            "AgGrid-Photo-2": "Photo 2",
            "AgGrid-Photo-3": "Photo 3",
            "aucune-affectation": "No assignments",
            "report-title": "Maintenance report"
        },
        "th": {
            "AgGrid-Fiche": "แผ่นงานหมายเลข",
            "AgGrid-Description": "คำอธิบาย",
            "AgGrid-Localisation": "ที่ตั้ง",
            "AgGrid-Piece": "ห้อง",
            "AgGrid-Affectation": "งานที่มอบหมาย",
            "AgGrid-Urgence": "ความเร่งด่วน ",
            "AgGrid-Origine": "ต้นกำเนิด",
            "AgGrid-DateOuverture": "วันที่เปิด",
            "AgGrid-DateFermeture": "วันที่ปิด",
            "AgGrid-dateAffectation": "วันที่ของงานที่มอบหมาย",
            "AgGrid-Traitement": "การปฏิบัติ",
            "AgGrid-DateDiferee": "วันที่ถูกเลื่อนออก",
            "AgGrid-Equipement": "อุปกรณ์",
            "AgGrid-Affecte": "มอบหมายไปยัง",
            "AgGrid-Status": "สถานะ",
            "AgGrid-Relance": "การแจ้งเตือน",
            "AgGrid-Age": "ms อายุ",
            "AgGrid-Categorie": "หมวดหมู่",
            "AgGrid-workingTime": "ระยะเวลาของงาน",
            "AgGrid-coutInterne": "ต้นทุนภายใน",
            "AgGrid-coutExterne": "ต้นทุนภายนอก",
            "AgGrid-coutConsommations": "ต้นทุนสินค้าสิ้นเปลือง",
            "AgGrid-coutTotal": "ต้นทุนทั้งหมด",
            "AgGrid-Id": "แผ่นงานหมายเลข",
            "AgGrid-RetourAFaire": "ต้องการข้อเสนอแนะ",
            "AgGrid-RetourFait": "ให้ข้อเสนอแนะแล้ว",
            "AgGrid-operation-last-manuelle": "ปฏิบัติการล่าสุด",
            "AgGrid-operation-validation": "การตรวจสอบ",
            "AgGrid-Photo-1": "รูปภาพ 1",
            "AgGrid-Photo-2": "รูปภาพ 2",
            "AgGrid-Photo-3": "รูปภาพ 3",
            "aucune-affectation": "ไม่มีงานที่มอบหมาย",
            "report-title": "รายงานเธรดการบำรุงรักษา"
        }
    }
},
    components:{
        VgReportingPrints
    },
    data:function(){
        return {
            searchQuery: "",
            columnDefs: [
                {
                    headerName: this.$t('AgGrid-Id'),
                    field: 'id',
                    colId: 'id',
                    cellClass: ["vg-cell-ballam-theme"],
                    width: 100,
                    //pinned: 'left',
                    hide: true,
                },
                {
                    headerName: this.$t('AgGrid-Description'),
                    field: 'commentaireClient',
                    colId: 'commentaireClient',
                    getQuickFilterText: (params) => {
                        var result = "";
                        if(params.data.composant && params.data.composant.libelComposant) result += params.data.composant.libelComposant+" ";
                        else result += params.data.equipement.libel_equipement+" ";
                        result += params.data.commentaireClient;
                        return result;
                    },
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new DescriptionFmCellRender({propsData: {params: params}}).$mount().$el,
                    width: 400,
                    //pinned: 'left',
                    hide: false,
                },
                {
                    headerName: this.$t('AgGrid-Localisation'),
                    field: 'path',
                    colId: 'path',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.equipement.path}}).$mount().$el,
                    width: 230,
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-Piece'),
                    field: 'path',
                    colId: 'piece',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => params.data.equipement.path.split("/").pop(),
                    width: 130,
                    //pinned: 'left',
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-Status'),
                    field: 'statut',
                    colId: 'statut',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new StatutFmCellRender({propsData: {params: params}}).$mount().$el,
                    width: 100,
                    filter: StatutFilter,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-Affectation'),
                    name: 'affectation',
                    field: 'affectation',
                    colId: 'affectation',
                    filter: AffectationFilter,
                    filterParams: {
                        values: []
                    },
                    getQuickFilterText: function(params) {
                        var result = '';
                        if(params.data.affectation){
                            params.data.affectation.affectes.forEach((affecte)=>affecte.user_id?result+=' '+affecte.user_name:result+=' '+affecte.name);
                        }
                        return result;
                    },
                    tooltip: (params)=>{
                        if(params.data.affectation && params.data.affectation.affectes){
                            return ;
                        }else{
                            return this.$t("aucune-affectation");
                        }
                    },
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new AffectationFmCellRender({propsData: {params: params}}).$mount().$el,
                    width: 130,
                    hide: true
                },
                {
                    headerName: 'isMeAffectation',
                    colId: 'isMeAffectation',
                    getQuickFilterText: function(params) {
                        return params.data.affectation && params.data.affectation.affectes.forEach((affecte)=>affecte.user_id==this.$app.idUser);
                    },
                    cellClass: ["vg-cell-ballam-theme"],
                    filter: "agTextColumnFilter",
                    hide: true,
                    isVisible:false
                },
                {
                    headerName: this.$t('AgGrid-workingTime'),
                    colId: 'workingTime',
                    field: 'workingTime',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => params.data.workingTime?params.data.workingTime+' <small>'+this.$t("minutes")+'</small>':'',
                    width: 120,
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-Urgence'),
                    field: 'urgence',
                    colId: 'urgence',
                    filter: 'agTextColumnFilter',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new UrgenceFmCellRender({propsData: {params: params}}).$mount().$el,
                    width: 100,
                    filter: "agTextColumnFilter",
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-Origine'),
                    colId: 'origin',
                    field: 'origin',
                    filter: OrigineFilter,
                    filterParams: {
                        values: []
                    },
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new OrigineFmCellRender({propsData: {params: params}}).$mount().$el,
                    width: 160,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-DateOuverture'),
                    name: 'dateOuvertureSAV',
                    field: 'dateOuvertureSAV',
                    colId: 'dateOuvertureSAV',
                    getQuickFilterText: (params) => this.$vgutils.getDate(params.data.dateOuvertureSAV),
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateOuvertureSAV}}).$mount().$el,
                    width: 170,
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-DateFermeture'),
                    field: 'dateFermetureSAV',
                    colId: 'dateFermetureSAV',
                    name: 'dateFermetureSAV',
                    getQuickFilterText: (params) => this.$vgutils.getDate(params.data.dateFermetureSAV),
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateFermetureSAV}}).$mount().$el,
                    width: 170,
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-dateAffectation'),
                    field: 'affectation.start',
                    colId: 'dateAffectation',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.affectation?params.data.affectation.start:null}}).$mount().$el,
                    width: 170,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-Equipement'),
                    colId: 'libelEquipement',
                    cellClass: ["vg-cell-ballam-theme"],
                    getQuickFilterText: (params) => params.data.composant && params.data.composant.libelComposant?params.data.composant.libelComposant:params.data.equipement.libel_equipement,
                    cellRenderer: (params) => new EquipementFmCellRender({propsData: {params: params}}).$mount().$el,
                    width: 180,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-Relance'),
                    field: 'operations.isRelance',
                    colId: 'isRelance',
                    cellClass: ["vg-cell-ballam-theme"],
                    width: 160,
                    filter: "agTextColumnFilter",
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-RetourAFaire'),
                    field: 'operations.isRetourAFaire',
                    colId: 'isRetourAFaire',
                    cellClass: ["vg-cell-ballam-theme"],
                    width: 160,
                    filter: "agTextColumnFilter",
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-RetourFait'),
                    field: 'operations.isRetourFait',
                    colId: 'isRetourFait',
                    cellClass: ["vg-cell-ballam-theme"],
                    width: 160,
                    filter: "agTextColumnFilter",
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-operation-last-manuelle'),
                    field: 'operations.operation_last_manuelle',
                    colId: 'operation_last_manuelle',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new OperationTextuelleCellRender(
                        {propsData: {params: params}}
                    )
                        .$on("open-operation-create", this.openOperationCreate)
                        .$mount().$el,
                    width: 160,
                    filter: "agTextColumnFilter",
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-operation-validation'),
                    field: 'operations.operation_validation',
                    colId: 'operation_validation',
                    cellClass: ["vg-cell-ballam-theme"],
                    width: 160,
                    filter: "agTextColumnFilter",
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-Age'),
                    colId: 'age',
                    filter: "number",
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new FromDateCellRender({propsData: {value: params.data.dateOuvertureSAV}}).$mount().$el,
                    width: 104,
                    hide: true
                },
                {
                    headerName: this.$t('AgGrid-Categorie'),
                    width: 200,
                    field: 'equipement.libelleCatgorie',
                    colId: 'libelleCatgorie',
                    cellClass: ["vg-cell-ballam-theme"],
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-coutInterne'),
                    width: 100,
                    field: 'coutInterne',
                    colId: 'coutInterne',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.coutInterne}}).$mount().$el,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-coutExterne'),
                    width: 100,
                    field: 'coutExterne',
                    colId: 'coutExterne',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.coutExterne}}).$mount().$el,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-coutConsommations'),
                    width: 100,
                    field: 'coutConsommations',
                    colId: 'coutConsommations',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.coutConsommations}}).$mount().$el,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-coutTotal'),
                    width: 100,
                    field: 'coutTotal',
                    colId: 'coutTotal',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new VgCostCellRender({propsData: {value: params.data.coutTotal}}).$mount().$el,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-Photo-1'),
                    width: 100,
                    field: 'photos',
                    colId: 'photos',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new ThumbnailCellRender({
                        propsData: {
                            params: {
                                data: params.data.photos.length>0?params.data.photos[0]:{}
                            },
                            attributeName: "name",
                            attributeUid: "uploadedTo"
                        }
                    }).$mount().$el,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-Photo-2'),
                    width: 100,
                    field: 'photos',
                    colId: 'photos2',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new ThumbnailCellRender({
                        propsData: {
                            params: {
                                data: params.data.photos.length>1?params.data.photos[1]:{}
                            },
                            attributeName: "name",
                            attributeUid: "uploadedTo"
                        }
                    }).$mount().$el,
                    hide: false
                },
                {
                    headerName: this.$t('AgGrid-Photo-3'),
                    width: 100,
                    field: 'photos',
                    colId: 'photos3',
                    cellClass: ["vg-cell-ballam-theme"],
                    cellRenderer: (params) => new ThumbnailCellRender({
                        propsData: {
                            params: {
                                data: params.data.photos.length>2?params.data.photos[2]:{}
                            },
                            attributeName: "name",
                            attributeUid: "uploadedTo"
                        }
                    }).$mount().$el,
                    hide: false
                }
            ],
        }
    },
    computed:{
        ...mapGetters({
              getMaintenances: 'MaintenancesStore/getMaintenances'
        }),
    },
    methods: {},
    created: function(){},
    mounted: function(){}
}
</script>
<style lang="scss" scoped></style>
