<template>
    <div class="vg-calendar">
        <div v-if="showLeftPanel" class="calendar-left-panel no-print">
            <vg-calendar-picker
                @focused-date="onFocusedDate" />
            <hr>
            <vg-calendar-filters 
                v-model="calendars" />
            <hr>
            <vg-filter-tiers @input="onChangeFiltreTiers" />
            <hr>
            <vg-filter-site
                v-model="filtreSites"
                :multiple="true"
                :display="'modal'"
                :isAllSitesSelected="true"
                @input="onChangeFiltreSites" />
        </div>
        <div :class="['calendar-main-panel',{'width-100-percent':!showLeftPanel}]">
            <div class="actions">
                <div class="date-select no-print">
                    <vg-button
                    class="no-print"
                        :type="'grey'"
                        @click="setToday">
                        {{$t("today")}}
                    </vg-button>
                    <vg-button v-if="view!='planning'"
                        class="no-print"
                        :type="'grey'"
                        @click="setPrevious">
                        <i class="fas fa-chevron-left"></i>
                    </vg-button>
                    <vg-button v-if="view!='planning'"
                        class="no-print"
                        :type="'grey'"
                        @click="setNext">
                        <i class="fas fa-chevron-right"></i>
                    </vg-button>
                    <vg-button v-if="view!='planning'"
                        class="no-print"
                        :type="'grey'"
                        @click="printCalendar">
                        {{ $t("print") }}
                    </vg-button>
                </div>
                <div v-if="view=='month'">{{readableDate}}</div>
                <vg-button-group v-if="showViewSelector" class="calendar-view-selector-buttons">
                    <vg-button
                        class="no-print"
                        :type="view=='day'?'info':'grey'"
                        @click="setView('day')">
                        {{$t("view-day")}}
                    </vg-button>
                    <vg-button
                        class="no-print"
                        :type="view=='week'?'info':'grey'"
                        @click="setView('week')">
                        {{$t("view-week")}}
                    </vg-button>
                    <vg-button v-if="showViewMonthSelector"
                        class="no-print"
                        :type="view=='month'?'info':'grey'"
                        @click="setView('month')">
                        {{$t("view-month")}}
                    </vg-button>
                </vg-button-group>
                <vg-select v-if="showViewSelector" class="no-print calendar-view-selector" 
                    v-model="view"
                    :clearable="false"
                    :options="getViews"
                    :attributeLabel="'label'"
                    :attributeValue="'value'" />
            </div>
            <calendar v-if="view!='planning'"
                ref="calendar"
                :calendars="calendarsDisplayed"
                :schedules="calendarEventsDisplayed"
                :view="view"
                :taskView="false"
                :theme="customTheme"
                :scheduleView="scheduleView"
                :week="week"
                :template="template"
                :useCreationPopup="false"
                :useDetailPopup="false"
                :isReadOnly="isReadOnly"
                :disableClick="true"
                :disableDblClick="true"
                @clickSchedule="onClickSchedule"
                @beforeCreateSchedule="onBeforeCreateSchedule"
                @beforeUpdateSchedule="onBeforeUpdateSchedule" />
            <vg-calendar-planning v-else 
                :calendars="calendarsDisplayed"
                :schedules="calendarEventsDisplayed"
                :focusedDay="focusedDay"
                :isReadOnly="isReadOnly"
                @click-schedule="onClickSchedule"
                @remove-focused-day="focusedDay=null;"/>
            <!-- popup detail pour un evenement -->
            <popup-detail v-if="focusedSchedule && focusedCalendar"
                v-model="focusedSchedule"
                :calendar="focusedCalendar"
                :event="mouseEvent"
                @open-schedule="onOpenSchedule"
                @close-popup="onClosePopup"
                @after-update-schedule="onAfterUpdateSchedule"
                @open-update="onOpenUpdate"
                @open-verifications="onOpenVerifications" />
            <!-- popup plusieurs evenements regroupés -->
            <popup-more v-if="focusedSchedule && focusedSchedule.raw.nEvents"
                v-model="focusedSchedule"
                :event="mouseEvent"
                :calendars="calendars"
                @open-schedule="onOpenPopupSchedule"
                @close-popup="onClosePopup" />
            <vg-verifications-modal v-if="showVerificationsModal"
                :filters="verificationsFilters"
                @close="onCloseVerificationsModal">
                <template #title>
                    {{ $t("modal-verifications-title") }}: {{ getFocusedScheduleDateStart }} - {{ getFocusedScheduleDateEnd }}
                    <small v-if="isCurrentSession" style="margin-left: 20px;">
                        {{ focusedSchedule.raw.tache.nbEquipementChecked }} / {{ focusedSchedule.raw.tache.nbEquipementToCheck }} {{ $t("equipements-verifies") }}
                    </small>
                    <small v-else-if="!isCurrentSession && focusedSchedule.raw.progression" style="margin-left: 20px;">
                        {{ focusedSchedule.raw.progression.verified }} / {{ focusedSchedule.raw.progression.total }} {{ $t("equipements-verifies") }}
                    </small>
                </template>
            </vg-verifications-modal>
        </div>
    </div>
</template>

<script>

import 'tui-calendar/dist/tui-calendar.css';
import { Calendar } from '@toast-ui/vue-calendar';

import VgCalendarPicker from "src/components/Vg/VgCalendarPicker.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgButtonGroup from "src/components/Vg/VgButtonGroup.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgButtonDropdown from "src/components/Vg/VgButtonDropdown.vue";

import ScheduleTemplate from "src/components/Vg/Calendar/ScheduleTemplate.vue";
import ScheduleMonthTemplate from "src/components/Vg/Calendar/ScheduleMonthTemplate.vue";
import PopupDetail from "src/components/Vg/Calendar/PopupDetail.vue";
import PopupMore from "src/components/Vg/Calendar/PopupMore.vue";
import VgCalendarFilters from "src/components/Vg/Calendar/VgCalendarFilters.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgFilterTiers from "src/components/Vg/Filters/VgFilterTiers.vue";
import VgCalendarPlanning from "src/components/Vg/Calendar/VgCalendarPlanning.vue";
import VgVerificationsModal from "src/components/Vg/Verification/VgVerificationsModal.vue";
import moment from 'moment';

var CUSTOM_THEME = {
    // header
    'week.dayname.textAlign': 'center',
    'month.dayname.textAlign': 'center',
    // week timegrid 'timegrid'
    'week.timegridLeft.width': '42px',
    'week.timegridLeft.backgroundColor': 'inherit',
    'week.timegridLeft.fontSize': '11px',
    'week.timegridLeftTimezoneLabel.height': '40px',
    'week.timegridLeftAdditionalTimezone.backgroundColor': 'white',
    'week.timegridOneHour.height': '42px',
    'week.timegridHalfHour.height': '21px',
    'week.timegridHalfHour.borderBottom': 'none',
    'week.timegridHorizontalLine.borderBottom': '1px solid #dadce0',
    'week.timegrid.paddingRight': '8px',
    'week.timegridSchedule.borderRadius': '2px',
    'week.timegridSchedule.paddingLeft': '2px',
    // current time line
    'week.currentTime.color': '#f9b934',
    'week.currentTime.fontSize': '11px',
    'week.currentTime.fontWeight': '400',
    'week.currentTimeLinePast.border': 'none',
    'week.currentTimeLineBullet.backgroundColor': '#f9b934',
    'week.currentTimeLineToday.border': '2px solid #f9b934',
    'week.currentTimeLineFuture.border': 'none',
    'week.today.backgroundColor': 'rgba(249, 185, 52, 0.05)',
    'week.daygridLeft.width': '40px'
    /*
    // header
    'week.dayname.height': '30px',
    'week.dayname.backgroundColor': '#e4e4e4',
    'week.dayname.textAlign': 'center',
    'week.pastDay.color': '#bbb',
    'month.dayname.height': '30px',
    'month.dayname.backgroundColor': '#e4e4e4',
    'month.dayname.textAlign': 'center',
    'common.backgroundColor': 'white',
    'common.today.color': 'white',
    'common.holiday.color': 'black',
    // week timegrid 'timegrid'
    'week.timegridLeft.width': '42px',
    'week.timegridLeft.backgroundColor': 'inherit',
    'week.timegridLeft.fontSize': '11px',
    'week.timegridLeftTimezoneLabel.height': '40px',
    'week.timegridLeftAdditionalTimezone.backgroundColor': 'white',
    'week.timegridOneHour.height': '42px',
    'week.timegridHalfHour.height': '21px',
    'week.timegridHalfHour.borderBottom': 'none',
    'week.timegridHorizontalLine.borderBottom': '1px solid #dadce0',
    'week.timegrid.paddingRight': '8px',
    'week.timegridSchedule.borderRadius': '2px',
    'week.timegridSchedule.paddingLeft': '2px',
    // current time line
    'week.currentTime.color': '#f9b934',
    'week.currentTime.fontSize': '11px',
    'week.currentTime.fontWeight': '400',
    'week.currentTimeLinePast.border': 'none',
    'week.currentTimeLineBullet.backgroundColor': '#f9b934',
    'week.currentTimeLineToday.border': '1px solid #f9b934',
    'week.currentTimeLineFuture.border': 'none',
    'week.today.backgroundColor': 'rgba(249, 185, 52, 0.05)',
    'week.daygridLeft.width': '40px'*/
};
var CUSTOM_CALENDAR = {
    defaultView: "month",
    scheduleView: true,
    week: (context)=>{
        return {
            daynames: [context.$t("dimanche"), context.$t("lundi"), context.$t("mardi"), context.$t("mercredi"), context.$t("jeudi"), context.$t("vendredi"), context.$t("samedi")],
            narrowWeekend: false,
            startDayOfWeek: 1
        };
    },
    month: (context)=>{ // https://nhn.github.io/tui.calendar/latest/MonthOptions
        return {
            daynames: [context.$t("dimanche"), context.$t("lundi"), context.$t("mardi"), context.$t("mercredi"), context.$t("jeudi"), context.$t("vendredi"), context.$t("samedi")],
            narrowWeekend: false,
            startDayOfWeek: 1,
            visibleWeeksCount: true,
            visibleScheduleCount: 2
        };
    },
    template: (context)=>{
        return {
            weekDayname: (title)=>{// {color: "#70757a",date: 17,day: 0,dayName: "DIM.",isToday: false,left: 85.71428571428572,renderDate: "2020-05-17",width: 14.285714285714286}
                return title.dayName+" "+moment(title.renderDate).format("D/M");
            },
            monthDayname: (title)=>{// {color: "#70757a",day: 6,label: "Sat",left: 85.71428571428572,width: 14.285714285714286}
                return context.$t("month-"+title.label);
            },
            /**
            * customization de l'evenement sur une plage horaire
            */
            time: (schedule)=>{
                let calendar = context.calendars.find((cal)=>cal.id==schedule.calendarId);
                if(context.view=="day" || context.view=="week") return new ScheduleTemplate({propsData: {value: schedule, calendar: calendar, view: context.view}}).$mount().$el.outerHTML;
                else return new ScheduleMonthTemplate({propsData: {value: schedule, calendar: calendar}}).$mount().$el.outerHTML;
            },
            /**
            * customization de l'evenement à la journée
            */
            allday: (schedule)=>{
                let calendar = context.calendars.find((cal)=>cal.id==schedule.calendarId);
                if(context.view=="day" || context.view=="week") return new ScheduleTemplate({propsData: {value: schedule, calendar: calendar, view: context.view}}).$mount().$el.outerHTML;
                else return new ScheduleMonthTemplate({propsData: {value: schedule, calendar: calendar}}).$mount().$el.outerHTML;
            },
            timegridDisplayPrimaryTime: (time)=>{// {color: "#70757a",fontWeight: "normal",hidden: false,hour: 23,minutes: 0}
                return moment(time.hour+":"+time.minutes, "h:m").format("HH:mm");
            },
            monthMoreTitleDate: (title)=>{
                let daynames = [context.$t("dimanche"), context.$t("lundi"), context.$t("mardi"), context.$t("mercredi"), context.$t("jeudi"), context.$t("vendredi"), context.$t("samedi")];
                return daynames[moment(title).format("d")]+" "+moment(title).format("D");
            },
            /**
            * customizer vue mois -> close bouton dans popup
            */
            monthMoreClose: (close)=>{
                return "x";
            },
            /**
            * customizer vue mois -> +1 à côté de la date
            */
            monthGridHeaderExceed: (hiddenSchedules)=>{
                return '<span class="day-more">+ '+hiddenSchedules+' <span class="day-more-verbose">'+context.$t("autres")+'</span></span>';
            },
            alldayTitle: (test)=>{
                return "";
            }
        };
    }
};

export default {
    name: "vg-calendar",
    components:{
        Calendar,
        VgCalendarPicker,
        VgButton,
        VgButtonGroup,
        VgSelect,
        VgButtonDropdown,
        ScheduleTemplate,
        ScheduleMonthTemplate,
        PopupDetail,
        PopupMore,
        VgCalendarFilters,
        VgFilterSite,
        VgFilterTiers,
        VgCalendarPlanning,
        VgVerificationsModal
    },
    props: {
        /**
        * dateRange {start:..., end:...}
        */
        value: {
            type: Object,
            default: function(){
                return {};
            }
        },
        isReadOnly: {
            type: Boolean,
            default: true
        },
        calendars: {
            type: Array,
            default: []
        },
        calendarEvents: {
            type: Array,
            default: []
        },
        defaultView:{
            type: String,
            default: null
        },
        showLeftPanel:{
            type: Boolean,
            default: true
        },
        showViewSelector: {
            type: Boolean,
            default: true
        },
        showViewMonthSelector: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "dimanche": "D",
            "lundi": "L",
            "mardi": "M",
            "mercredi": "M",
            "jeudi": "J",
            "vendredi": "V",
            "samedi": "S",
            "today": "Aujourd'hui",
            "view-day": "Jour",
            "view-week": "Semaine",
            "view-month": "Mois",
            "view-planning": "Planning",
            "month-Sun": "D",
            "month-Mon": "L",
            "month-Tue": "M",
            "month-Wed": "M",
            "month-Thu": "J",
            "month-Fri": "V",
            "month-Sat": "S",
            "month-01": "Janvier",
            "month-02": "Février",
            "month-03": "Mars",
            "month-04": "Avril",
            "month-05": "Mai",
            "month-06": "Juin",
            "month-07": "Juillet",
            "month-08": "Août",
            "month-09": "Septembre",
            "month-10": "Octobre",
            "month-11": "Novembre",
            "month-12": "Décembre",
            "popup-update": "Modifier",
            "popup-delete": "Supprimer",
            "tous": "Tous",
            "modal-verifications-title": "Vérifications sur la période",
            "equipements-verifies": "équipements vérifiés",
            "print": "Imprimer"
        },
        "en": {
            "dimanche": "SUN.",
            "lundi": "MON.",
            "mardi": "TUE.",
            "mercredi": "WED.",
            "jeudi": "THU",
            "vendredi": "FRI.",
            "samedi": "SAT.",
            "today": "Saturday",
            "view-day": "Day",
            "view-week": "Week",
            "view-month": "Month",
            "view-planning": "Planning",
            "month-Sun": "Sunday",
            "month-Mon": "Monday",
            "month-Tue": "Tuesday",
            "month-Wed": "Wednesday",
            "month-Thu": "Thursday",
            "month-Fri": "Friday",
            "month-Sat": "Saturday",
            "month-01": "January",
            "month-02": "February",
            "month-03": "March",
            "month-04": "April",
            "month-05": "May",
            "month-06": "June",
            "month-07": "July",
            "month-08": "August",
            "month-09": "September",
            "month-10": "October",
            "month-11": "November",
            "month-12": "Décember",
            "popup-update": "Update",
            "popup-delete": "Delete",
            "tous": "All",
            "autres": "autres",
            "print": "Print"
        },
        "th": {
            "dimanche": "อา.",
            "lundi": "จ.",
            "mardi": "อ.",
            "mercredi": "พ.",
            "jeudi": "พฤ.",
            "vendredi": "ศ.",
            "samedi": "ส.",
            "today": "วันเสาร์",
            "view-day": "วัน",
            "view-week": "สัปดาห์",
            "view-month": "เดือน",
            "month-Sun": "วันอาทิตย์",
            "month-Mon": "วันจันทร์",
            "month-Tue": "วันอังคาร",
            "month-Wed": "วันพุธ",
            "month-Thu": "วันพฤหัสบดี",
            "month-Fri": "วันศุกร์",
            "month-Sat": "วันเสาร์",
            "month-01": "มกราคม",
            "month-02": "กุมภาพันธ์",
            "month-03": "มีนาคม",
            "month-04": "เมษายน",
            "month-05": "พฤษภาคม",
            "month-06": "มิถุนายน",
            "month-07": "กรกฏาคม",
            "month-08": "สิงหาคม",
            "month-09": "กันยายน",
            "month-10": "ตุลาคม",
            "month-11": "พฤศจิกายน",
            "month-12": "ธันวาคม",
            "popup-update": "อัปเดต",
            "popup-delete": "ลบ",
            "tous": "ทั้งหมด",
            "autres": "more",
            "print": "พิมพ์"
        }
    }
},
    data: function() {
        return {
            calendarsList: this.calendars,

            customTheme: CUSTOM_THEME,
            view: this.defaultView || this.$vgutils.isMobile()?"planning":CUSTOM_CALENDAR.defaultView,
            scheduleView: CUSTOM_CALENDAR.scheduleView,
            week: CUSTOM_CALENDAR.week(this),
            month: CUSTOM_CALENDAR.month(this),
            template: CUSTOM_CALENDAR.template(this),

            dateRange: this.value,
            colorRange: [
                "#ad1457","#f45112","#e4c441","#008043","#3f51b5","#8e24aa",
                "#d81b60","#ef6c00","#c0ca33","#009688","#8b96d2","#795548",
                "#d50000","#f09300","#7cb342","#039bc5","#b36ddb","#616161",
                "#e67c73","#f6bf26","#33b679","#4285f4","#9e69af","#a79b8e"
            ],

            focusedSchedule: null,
            focusedCalendar: null,
            focusedDay: null,
            mouseEvent: null,
            filtreSites: null,
            filtreTiers: null,
            showVerificationsModal: false,
            verificationsFilters: {}
        };
    },
    watch: {
        calendars: {
            handler: function(values){
                this.calendarsList = values;
            }
        },
        value: {
            handler: function(range){
                this.dateRange = range;
                this.$refs.calendar.invoke("setDate", range.start);
            }
        }
    },
    mounted: function(){
        this.initDefaultCalendarFocus();
        this.$refs.calendar.invoke("setDate", this.dateRange.start);
    },
    computed:{
        getViews: function(){
            return [
                {label: this.$t("view-planning"), value: "planning"}, 
                {label: this.$t("view-day"), value: "day"}, 
                {label: this.$t("view-week"), value: "week"}, 
                {label: this.$t("view-month"), value: "month"}
            ];
        },
        readableDate: function(){
            if(this.view=="month")
                return this.$t("month-"+moment(this.dateRange.start).format("MM"))+" "+moment(this.dateRange.start).format("YYYY");
            else if(this.view=="week" && moment(this.dateRange.start).format("MM")==moment(this.dateRange.end).format("MM"))
                return moment(this.dateRange.start).format("D")+" - "+moment(this.dateRange.end).format("D")+" "+this.$t("month-"+moment(this.dateRange.start).format("MM"));
            else if(this.view=="week" && moment(this.dateRange.start).format("MM")!=moment(this.dateRange.end).format("MM"))
                return moment(this.dateRange.start).format("D")+" "+this.$t("month-"+moment(this.dateRange.start).format("MM"))+" - "+moment(this.dateRange.end).format("D")+" "+this.$t("month-"+moment(this.dateRange.end).format("MM"));
            else if(this.view=="day")
                return moment(this.dateRange.start).format("D")+" "+this.$t("month-"+moment(this.dateRange.start).format("MM"));
            else return "-";
        },
        calendarsDisplayed: function(){
            return this.calendarsList.filter((calendar)=>calendar.checked);
        },
        calendarEventsDisplayed: function(){
            let events = this.calendarEvents.filter(
                (event)=>this.calendarsDisplayed.findIndex((calendar)=>event.calendarId==calendar.id)!=-1
            );
            if(events.length==0) return [];
            else return events;
        },
        isJournaliere: function(){
            return this.focusedSchedule.raw.tache && this.focusedSchedule.raw.tache.periodicite==1;
        },
        getDateStart: function(){
            if(this.isJournaliere) return moment(this.focusedSchedule.raw.start).format("YYYY-MM-DD 00:00:00");
            else if(this.focusedSchedule.raw.progression) return this.focusedSchedule.raw.progression.dateDebut;
            else return this.focusedSchedule.raw.start;
        },
        getDateEnd: function(){
            if(this.isJournaliere) return moment(this.focusedSchedule.raw.end).format("YYYY-MM-DD 23:59:59");
            else if(this.focusedSchedule.raw.progression) return this.focusedSchedule.raw.progression.dateFin;
            else return this.focusedSchedule.raw.end;
        },
        getDateStartDDMMYYYY: function(){
            return moment(this.getDateStart).format("DD/MM/YYYY");
        },
        getDateEndDDMMYYYY: function(){
            return moment(this.getDateEnd).format("DD/MM/YYYY");
        },
        isCurrentSession: function(){
            if(this.focusedSchedule.raw && this.focusedSchedule.raw.tache){
                let debutDateProgressionCourante = moment(this.focusedSchedule.raw.tache.dateprochaineVerif).subtract(parseInt(this.focusedSchedule.raw.tache.periodicite), "days").format("YYYY-MM-DD");
                let finDateProgressionCourante = moment(this.focusedSchedule.raw.tache.dateprochaineVerif).format("YYYY-MM-DD");
                let dateSession = moment(this.focusedSchedule.start._date).format("YYYY-MM-DD");
                if(debutDateProgressionCourante<=dateSession && dateSession<finDateProgressionCourante) return true;
                else if(dateSession<debutDateProgressionCourante) return false; // c'est le passé
                else return null; // c'est le futur
            }else{
                return null;
            }
        },
    },
    methods:{
        onCloseVerificationsModal: function(){
            this.showVerificationsModal = false;
            this.$store.dispatch("VerificationsStore/clear");
            this.focusedSchedule = null;
            this.verificationsFilters = {};
        },
        onOpenVerifications: function(schedule){
            this.focusedSchedule = schedule;
            this.verificationsFilters = {
                "tache_id": {attr: "et.tache_id", value: schedule.raw && schedule.raw.tache?schedule.raw.tache.id:null, action: "equals"},
                "sites": {attr: "l.path", value: null, action: "start_with"},
                "dateVerification": {attr: "et.dateVerif", value: [this.getDateStart, this.getDateEnd], action: "between"}
            };
            this.showVerificationsModal = true;
            this.focusedCalendar = null;
            this.mouseEvent = null;
        },
        initDefaultCalendarFocus: function(){
            if(localStorage.getItem("calendar-focus")){
                let calendarFocus = JSON.parse(localStorage.getItem("calendar-focus"), true);
                this.view = calendarFocus.view;
                this.dateRange = calendarFocus.dateRange;
            }
        },
        setInLocalStorageCalendarFocus: function(){
            localStorage.setItem("calendar-focus", JSON.stringify({
                view: this.view,
                dateRange: this.dateRange,
                focusedDay: this.focusedDay
            }));
        },
        onAfterUpdateSchedule: function(schedule){
            this.onClosePopup();
            this.$emit("after-update-schedule", schedule);
        },
        onBeforeCreateSchedule: function(event){
            this.$emit("before-create-schedule", event);
        },
        onBeforeUpdateSchedule: function(event){
            this.$emit("before-update-schedule", event);
        },
        onChangeFiltreSites: function(sites){
            this.$emit("change-filter-sites", sites);
        },
        onChangeFiltreTiers: function(tiers){
            this.$emit("change-filter-tiers", tiers);
        },
        /**
        * @param object data {date: YYYY-MM-DD, start: YYYY-MM-DD+" 00:00", end: YYYY-MM-DD+" 23:59"}
        */
        onFocusedDate: function(data){
            //console.log("ON FOCUSED DATE", data);
            if(this.view=="day"){
                this.dateRange = {
                    start: moment(data.date).format("YYYY-MM-DD"),
                    end: moment(data.date).format("YYYY-MM-DD")
                };
            }else if(this.view=="week"){
                this.dateRange = {
                    start: moment(data.date).startOf('isoWeek').format("YYYY-MM-DD"),
                    end: moment(data.date).endOf('isoWeek').format("YYYY-MM-DD")
                };
            }else if(this.view=="month"){
                this.dateRange = {
                    start: moment(data.date).startOf('month').format("YYYY-MM-DD"),
                    end: moment(data.date).endOf('month').format("YYYY-MM-DD")
                };
            }
            this.setInLocalStorageCalendarFocus();
            this.$emit("change-date", this.dateRange);
        },
        onOpenSchedule: function(data){
            this.$emit("open-schedule", data);
        },
        onOpenUpdate: function(data){
            this.onClosePopup();
            this.$emit("open-update", data);
        },
        onOpenPopupSchedule: function(data){
            let mouseEvent = this.mouseEvent;
            this.onClosePopup();
            this.onClickSchedule({schedule: data.schedule, event: mouseEvent});
        },
        onClickSchedule: function(event){
            let schedule = event.schedule;
            let calendar = this.calendarsList.find((cal)=>cal.id==schedule.calendarId);
            this.focusedSchedule = schedule;
            this.focusedCalendar = calendar;
            this.mouseEvent = event.event;
        },
        onClosePopup: function(event){
            this.focusedSchedule = null;
            this.focusedCalendar = null;
            this.mouseEvent = null;
        },
        printCalendar: function(){
            window.print();
        },
        setView: function(view){
            this.view = view;
            this.setInLocalStorageCalendarFocus();
        },
        setToday: function(){
            if(this.view!='planning') this.changeDateRange('today');
            else this.focusedDay = moment().format("YYYY-MM-DD");
            this.setInLocalStorageCalendarFocus();
        },
        setPrevious: function(){
            this.changeDateRange('prev');
        },
        setNext: function(){
            this.changeDateRange('next');
        },
        changeDateRange: function(action){
            this.$refs.calendar.invoke(action);
            if (this.view == "month") {
                this.dateRange = {
                    start: moment(this.$refs.calendar.invoke("getDateRangeEnd")._date).subtract(1,'months').startOf('month').format("YYYY-MM-DD"),
                    end: moment(this.$refs.calendar.invoke("getDateRangeEnd")._date).format("YYYY-MM-DD")
                };
            }else{
                this.dateRange = {
                    start: moment(this.$refs.calendar.invoke("getDateRangeStart")._date).format("YYYY-MM-DD"),
                    end: moment(this.$refs.calendar.invoke("getDateRangeEnd")._date).format("YYYY-MM-DD")
                };
            }
            this.setInLocalStorageCalendarFocus();
            //console.log(moment(this.dateRange.start).format("DD-MM"));
            //console.log(moment(this.dateRange.end).format("DD-MM"));
            // //console.log(this.$refs.calendar.invoke('next'));
            this.$emit("change-date", this.dateRange); // $emit {start: ..., end: ...}

        }
    }
};
</script>
<style>
.tui-full-calendar-timegrid-hour{
    font-size: 10px;
    letter-spacing: .1rem;
}
.tui-full-calendar-dayname-date-area{
    font-size: 10px;
    letter-spacing: .1rem;
}
.tui-full-calendar-month-week-item .tui-full-calendar-weekday-schedule{
    margin: 0 !important;
    margin-right: 5px !important;
}
.tui-full-calendar-month-more-list{
    padding: 0 0px 0 5px !important;
}
.tui-full-calendar-time-schedule-content{
    /*padding: 2px !important;*/
    padding: 0px !important;
    border: none !important;
}
.tui-full-calendar-time-schedule-content.tui-full-calendar-time-schedule-content-time{
    height: 100% !important;
}
.tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time {
    /*background-color: #f2fbff !important;*/
}
.tui-full-calendar-weekday-schedule{
    /*background-color: #f2fbff !important;*/
}
.tui-full-calendar-month-more-schedule.tui-full-calendar-month-more-allday.tui-full-calendar-weekday-schedule-title{
    /*background-color: #f2fbff !important;*/
}
.tui-full-calendar-time-schedule {
    /*background-color: #f2fbff !important;*/
}
.day-more{
    padding: 0px 5px;
    font-weight: bold;
    font-size: 12px;
}
@media only screen and (max-width:800px){
    .tui-full-calendar-weekday-grid-header{
        width: max-content;
    }
    .tui-full-calendar-weekday-schedule.tui-full-calendar-weekday-schedule-time .tui-full-calendar-weekday-schedule-title {
        padding-left: 1px;
    }
    .tui-full-calendar-floating-layer{
        z-index: 200 !important;
    }
    .day-more{
        letter-spacing: -1px;
        padding: 0px 1px;
    }
    .day-more-verbose{
        display: none;
    }
}
</style>
<style lang="scss" scoped>
.vg-calendar{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .calendar-view-selector{
        display: none;
    }
    .calendar-view-selector-buttons{
        display: block;
    }
    .calendar-left-panel{
        padding-right: 20px;
        width: 20%;
    }
    .calendar-main-panel{
        width: 80%;
        >.actions{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            >.date-select{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                >span{
                    font-size: 22px;
                    font-family: Verdana,sans-serif;
                    line-height: 28px;
                }
            }
            margin-bottom: 10px;
        }
    }
    .width-100-percent{
        width: 100%;
    }
}
@media only screen and (max-width:1200px){
    .vg-calendar{
        .calendar-view-selector{
            display: block;
        }
        .calendar-view-selector-buttons{
            display: none;
        }
    }
}
@media print {
  .no-print {
    display: none;
  }
  body, html, .container {
    overflow: hidden;
  }
}
</style>