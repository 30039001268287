<template>
    <span  class="libel">
		<img style=""  :src="params.data.icon" onerror="this.style.visibility='hidden'" />
		<span v-if="params.data.libelEquipement">{{params.data.libelEquipement}}</span>
		<span v-else-if="params.data.libel_equipement">{{params.data.libel_equipement}}</span>
	</span>
    <!-- <span  class="libel">{{ params.data.libelEquipement }}</span> -->
</template>

<script>
    import Vue from "vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
		name: "libel-equipement-cell-renderer",
        mixins:[LangMixins],
        props: {
			params: Object
		},
		created: function(){
			//// //console.log("CREATED CELL RENDER LIBEL EQ", this.$lang); // OK :)
		}
    });
</script>

<style lang="scss" scoped>
    .libel {
		font-weight: bold;
        height: 100%;
        display: flex;
        align-items: center;
        img{
            height: 20px;
            width: 20px;
            margin-right:10px;
            vertical-align:middle;
        }
        span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>
