<template>
    <vg-select
        v-model="selectedSites"
        :options="sites"
        :attributeLabel="'libel_lieu'"
        :attributeValue="attributeValue"
        :multiple="multiple"
        @input="handleInputChange" />
</template>
<script>
import VgSelect from "src/components/Vg/VgSelect.vue";
import LieuMixins from "src/mixins/LieuMixins.js";
import Metadatas from "src/services/Metadatas";
export default {
    name: 'vg-sites-selector',
    mixins:[LieuMixins],
	components: {
		VgSelect
	},
    props: {
		/**
        * @model
        */
        value: {
            type: Array,
            default: ()=>{
                return null;
            }
        },
		/**
        * Filters used for query {"attr":"type_lieu","value":"Site","action":"equals"}.
        */
        filters: {
            type: Object,
            default: ()=>{
                return {};
            }
        },
        attributeValue: {
            type:String,
            default:"id"
        },
        multiple:{
            type:Boolean,
            default:false
        },
        disabled:{
            type:Boolean,
            default:false
        },
        defaultValue:{
            type: Array,
            default: function(){
                return null;
            }
        }
    },
    data: function() {
        return {
            selectedSites: this.multiple?[]:null,
            sites: []
        };
    },
    watch:{
        value: {
            handler: function(val){
                /*if(val && typeof(val)=="object" && val.length!=0 && this.attributeValue && val[0].hasOwnProperty(this.attributeValue))
                    this.selectedSites = val;
                else if(val && typeof(val)=="object" && val.length!=0 && this.attributeValue && !val[0].hasOwnProperty(this.attributeValue))
                    this.selectedSites = this.sites.filter((site)=>val.includes(site[this.attributeValue]));
                else this.selectedSites = val;*/
            }
        }
    },
	created: function(){
        this.getHasManySitesValue();
        this.fetch();
	},
    methods:{
        getHasManySitesValue: function(){
            this.hasManySites = !this.$storage.get("has-only-one-site");
        },
        /**
        * Emit change with array of lieu_s id .
        * @event change
        * @param Array lieux_value
        */
        handleInputChange:function(lieux_value){
            if (!this.multiple) {
                this.$emit("input",lieux_value);
            }
            this.$emit("change",lieux_value);
            if(!Array.isArray(lieux_value)) this.$emit("selected", this.sites.find((site)=>site.id==lieux_value));
        },
        /**
        * fetch sites
        */
        fetch: function(){
            let metadatas = new Metadatas();
            this.LieuMixins_getSites(metadatas).then((datas)=>{
                this.$store.dispatch("LieuxStore/setSites", datas.lieux);
                this.sites = [...datas.lieux];
                this.$storage.set("has-only-one-site", this.sites.length && this.sites.length==1);
                this.getHasManySitesValue();
                this.selectedSites = this.getDefaultSelectedSites;
                this.$emit("after-feeded",this.sites);
            });
        },
    },
    computed:{
        getDefaultSelectedSites: function(){
            if(this.defaultValue && this.defaultValue.length!=0) return this.defaultValue.map((site)=>this.attributeValue? site[this.attributeValue]: site);
            else if(this.value && this.multiple) return this.sites.filter((site)=>this.value.indexOf(site[this.attributeValue] || site.path)!=-1);
            else if(this.value && !this.multiple) return this.sites.filter((site)=>this.value.indexOf(site[this.attributeValue] || site.path)!=-1)[0];
            else return this.multiple?[]: null;
        }
    }
};

</script>
<style lang="scss" scoped>

</style>
