<template>
    <div>
        <small><vg-datetime-viewer v-model="params.data.dateCreation"/> <span>{{params.data.createur.nom}} {{params.data.createur.prenom}}</span></small>
        <br>
        {{$t("bon-de-sortie")}}
        <b class="link">{{params.data.numero}}</b>
        <br>
        <div style="display:flex;justify-content:space-between;gap:5px;align-items:center;">
            <small>{{$t("signataire")}}: {{params.data.signataire}}</small>
            <div>
                <vg-button size="xs" type="info" @click="openFile">
                    {{$t("voir-bs")}}
                </vg-button>
                <vg-button size="xs" type="success" @click="$emit('open-signer', params.data)">
                    {{$t("signer")}}
                </vg-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "bon-de-sortie-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			},
            isAutomaticOpenLink:{
                type: Boolean,
                default: true
            }
		},
        components:{
            VgOperationFlag,
            VgDatetimeViewer,
            VgButton
        },
        data: function() {
            return {
                showSignature: false
			};
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "bon-de-sortie": "Bon de sortie",
            "voir-bs": "Voir bon de sortie",
            "signataire": "Signataire",
            "signer": "Signer"
        },
        "en": {
            "bon-de-sortie": "Exit voucher",
            "voir-bs": "Show exit voucher",
            "signataire": "Signatory",
            "signer": "Signature"
        }
    }
},
        methods:{
            onClickMaintenance: function(){
                this.$emit("go-to-maintenance",this.params.data.operation.ficheSav_id);
            },
            openFile: function(){
				if(this.params.data.document) this.VgFilesMixins_getDownloadFileLink(this.params.data.document).then((link)=>
                    this.isAutomaticOpenLink ? window.open(link, '_blank') : this.$emit("click-link", link)
                );
			},
            openFDC: function(){
                this.$emit("go-to-fdc",this.params.data.ficheDemandeConsommable_id);
            }
        }
    });
</script>

<style lang="scss" scoped>
.link{
    font-weight: bold;
    color:#3999ff;
}
.link:hover{
    cursor: pointer;
    text-decoration: underline dotted;
}
</style>
