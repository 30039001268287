<template>
    <vg-app-layout
        :title="pageTitle"
        :icon="'fiche-de-maintenance.png'"
        :colorheader="getHeaderColor"
        :isZoomLayout="true"
        :isActionButtonDisplay="false"
        @action-export="handleActionExport"
        @backpage="handleBackPage">
        <template #header-top-left-complement>
            <vg-fichesav-description v-if="getSelectedMaintenance"
                :fm="getSelectedMaintenance"
                :showIcon="false" 
                inline />
        </template>
        <template #search>
            <vg-text-filter v-model="searchQuery" />
        </template>
        <template #create-button>
            <vg-button
                type="default-danger"
                @click="isUpdatingFm = true">
                {{ $t("modifier") }}
            </vg-button>
             <vg-button type="info" 
                @click="isModalFicheManagementOpened=true;">
                {{$t("actions")}}
            </vg-button>
        </template>
        <template #panel-menu-more>
            <vg-button
                size="md"
                type="default-round"
                @click="isUpdatingFm = true">
                {{$t('modifier')}}
            </vg-button>
        </template>
        <template #body v-if="$vgutils.isMobile() && getSelectedMaintenance">
            
        </template>
        <template #primary>
            <div v-if="getSelectedMaintenance && getSelectedMaintenance.id">
                <vg-collapse :title="$t('details')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #pretitle>
                        
                    </template>
                    <template #content>
                        <vg-libelle-editable :label="$t('localisation')">
                            <template #read>
                                <vg-lieu-path-viewer v-model="getSelectedMaintenance.equipement.path" />
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="$t('dateOuvertureSAV')">
                            <template #read>
                                <vg-datetime-viewer v-model="getSelectedMaintenance.dateOuvertureSAV" />
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="getSelectedMaintenance.equipement.isGroupEqp != 1?$t('libel-equipement'):$t('libel-composant')">
                            <template #read>
                                <a v-if="getSelectedMaintenance.equipement.isGroupEqp != 1"
                                    @click="handleClickZoomEquipement"
                                    style="cursor: pointer;color:#059CFF;">
                                    <vg-equipement-viewer :equipement="getSelectedMaintenance.equipement" />
                                </a>
                                <vg-composant-viewer v-else v-model="getSelectedMaintenance.composant" />
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="$t('corps-detat')">
                            <template #read>
                                <span></span>
                                <span>
                                    <vg-tag v-if="getSelectedMaintenance.corpsDetat"
                                        color="red">
                                        <template>
                                            {{getSelectedMaintenance.corpsDetat.name}}
                                        </template>
                                    </vg-tag>
                                    <div v-else>-</div>
                                </span>
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="$t('commentaire-client')">
                            <template v-slot:read>
                                <span>{{getSelectedMaintenance.commentaireClient}}</span>
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable :label="$t('urgence')">
                            <template v-slot:read>
                                <span v-if="getSelectedMaintenance.urgence && getSelectedMaintenance.urgence=='1'">{{getSelectedMaintenance.urgence}}</span>
                                <span v-else>{{$t("non")}}</span>
                            </template>
                        </vg-libelle-editable>
                        
                        <vg-libelle-editable v-if="getSelectedMaintenance.typologie" :label="$t('typologie')">
                            <template v-slot:read>
                                <span> {{ getSelectedMaintenance.typologie }}</span>
                            </template>
                        </vg-libelle-editable>

                        <vg-libelle-editable>
                            <template v-slot:read>
                                <vg-button
                                    size="md"
                                    type="info"
                                    @click="exportPdf">
                                    {{$t('exporter-pdf')}}
                                </vg-button>
                            </template>
                        </vg-libelle-editable>
                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('gestion-calendaire-statut')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template #pretitle>
                        <vg-button
                            size="md"
                            type="default-round">
                            <i class="far fa-calendar"></i>
                        </vg-button>
                    </template>
                    <template #content>
                        <vg-libelle-editable :label="$t('demandeur')">
                            <template v-slot:read><span > {{getSelectedMaintenance.contact}} </span></template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('dateOuvertureSAV')">
                            <template v-slot:read>
                                <b> <vg-datetime-viewer v-model="getSelectedMaintenance.dateOuvertureSAV"></vg-datetime-viewer></b>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('dateFermetureSAV')">
                            <template v-slot:read>
                                <b> <vg-datetime-viewer
                                    v-model="getSelectedMaintenance.dateFermetureSAV">
                                </vg-datetime-viewer></b>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('statut')">
                            <template v-slot:read>
                                <span >
                                    <vg-fichesav-statut-viewer :fm="getSelectedMaintenance"></vg-fichesav-statut-viewer>
                                </span>
                            </template>
                        </vg-libelle-editable>
                        <vg-button
                            type="default"
                            v-if="getSelectedMaintenance.statut != 'Resolue'"
                            @click="isClosingFicheSav=true">
                            {{$t("cloturer")}}
                        </vg-button>
                        <vg-button
                            v-if="getSelectedMaintenance.statut == 'Resolue'"
                            type="default-danger"
                            @click="reopen">
                            {{$t("reouvrir")}}
                        </vg-button>
                    </template>

                </vg-collapse>

                <vg-collapse :title="$t('affectation')"
                    :collapseByDefault="false"
                    :type="'section'" >
                    <template #content>
                        <div style="display:flex;flex-direction:column;">
                            <vg-libelle-editable :label="$t('affectation-mode')">
                                <template v-slot:read>
                                    <span > {{isAffectationModeIsInterne()?$t("affectation-interne"):$t("affectation-externe")}} </span>
                                </template>
                            </vg-libelle-editable>
                            <vg-libelle-editable :label="$t('affectation-debut')">
                                <template v-slot:read>
                                    <vg-datetime-viewer v-if="getSelectedMaintenance.affectation"
                                        v-model="getSelectedMaintenance.affectation.start">
                                    </vg-datetime-viewer>
                                </template>
                            </vg-libelle-editable>
                            <vg-libelle-editable :label="$t('affectation-fin')">
                                <template v-slot:read>
                                    <vg-datetime-viewer v-if="getSelectedMaintenance.affectation"
                                        v-model="getSelectedMaintenance.affectation.end">
                                    </vg-datetime-viewer>
                                </template>
                            </vg-libelle-editable>
                            <vg-libelle-editable :label="$t('affectation-interne')">
                                <template v-slot:read>
                                    <div v-if="getSelectedMaintenance.affectation">
                                        <vg-affecte-name v-for="affecte in getSelectedMaintenance.affectation.affectes" v-if="!affecte.tiers_id" :affecte="affecte" :key="affecte.id"></vg-affecte-name>
                                    </div>
                                </template>
                            </vg-libelle-editable>
                            <vg-libelle-editable :label="$t('affectation-externe')">
                                <template v-slot:read>
                                    <div v-if="getSelectedMaintenance.affectation">
                                        <vg-affecte-name v-for="affecte in getSelectedMaintenance.affectation.affectes" v-if="affecte.tiers_id" :affecte="affecte" :key="affecte.id"></vg-affecte-name>

                                    </div>
                                </template>
                            </vg-libelle-editable>

                        </div>
                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('gestion-financiere')"
                    :collapseByDefault="false"
                    :type="'section'">
                    <template v-slot:pretitle>
                        <vg-button
                            size="md"
                            type="default-round"
                        >
                            <i class="fas fa-euro-sign"></i>
                        </vg-button>
                    </template>
                    <template v-slot:content>
                        <vg-libelle-editable :label="$t('duree-intervention')">
                            <template v-slot:read>
                                <span>{{getSelectedMaintenance.workingTime}}</span>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('cout-interne')">
                            <template v-slot:read v-if="getSelectedMaintenance.workingTime">
                                <vg-input-cost v-model="coutInterne" />
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('cout-consommation')" v-if="hasConsommations">
                            <template v-slot:read>
                                <vg-input-cost v-model="coutConsommations"/>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('cout-externe')">
                            <template v-slot:read>
                                <vg-input-cost v-model="coutExterne"/>
                            </template>
                        </vg-libelle-editable>
                        <vg-libelle-editable :label="$t('cout-total')">
                            <template v-slot:read>
                                <vg-input-cost v-model="coutTotal"/>
                            </template>
                        </vg-libelle-editable>
                    </template>
                </vg-collapse>

                <vg-collapse :title="$t('documents-attaches')"
                    :collapseByDefault="false"
                    :type="'section'"
                    >
                    <template v-slot:pretitle>
                        <vg-button
                            size="md"
                            type="default-round"
                            @click="isAddDocuments=!isAddDocuments;">
                            <i class="fas fa-plus"></i>
                        </vg-button>
                    </template>
                    <template v-slot:content>
                        <vg-files-uploader v-if="isAddDocuments"
                            :tagsList="VgFilesMixins.tags"
                            @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;isAddDocuments=false;">
                        </vg-files-uploader>
                        <vg-files-viewer-list-section
                            :files="VgFilesMixins_filesListSection"
                            @delete="VgFilesMixins_fetchFilesByUid()">
                        </vg-files-viewer-list-section>
                    </template>
                </vg-collapse>
            </div>
        </template>
        <template #secondary>
            <div style="margin-bottom:20px;display:flex;flex-direction:column;justify-content:flex-start;align-items:flex-start;gap:20px;">
                <span style="color: #1286ff;font-size: 19px;">{{$t("timeline")}}</span>
                <vg-fichesav-timeline v-if="getSelectedMaintenance"/>
            </div>
            <div class="operation-contener" >
                <div class="operation-action">
                    {{$t("historique")}}
                    <vg-button :type="'info'"
                        :size="'sm'"
                        style="margin-left:30px;"
                        @click="isModalFicheManagementOpened=true;">
                        {{$t("ajouter-action")}}
                    </vg-button>
                </div>
                <vg-tabs v-model="focusedTab"
                    :tabs="tabs"
                    :color="'jaune'"
                    @tabClicked="onTabClicked"/>
                <vg-operations-viewer class="tableau-operation" v-if="getSelectedMaintenance && getSelectedMaintenance.operations"
                    v-model="getSelectedMaintenance.operations"
                    :idTableau="'show-maintenance-operations-viewer'"
                    :fm="getSelectedMaintenance"
                    :localFilters="operationsLocalFilters"
                    @update="handleOpenUpdateOperation"
                    @update-commentaire="handleOpenUpdateCommentaireOperation"
                    @delete="handleOpenDeleteOperation"
                    @consommable-show="handleConsommableShow"
                    @intervention-show="handleInterventionShow"
                    @operation-delete="onDeleteOperation"
                    @after-update-operation="onAfterUpdateOperation"/>
            </div>
            <vg-files-viewer-photo
                :files="VgFilesMixins_filesOnlyPhoto" />
        </template>
        <template #tools>
            <vg-maintenance-form v-if="isUpdatingFm"
                v-model="getSelectedMaintenance"
                @update-maintenance="handleUpdateMaintenance"
                @close="isUpdatingFm=false" />

            <vg-fichesav-management v-if="getSelectedMaintenance && getSelectedMaintenance.id && isModalFicheManagementOpened"
                @close="handleCloseModalFicheManagement"
                @back-page="handleBackPage()"
                @operation-submited="handleOperationEmailSubmited" />

            <vg-operation-update v-if="isUpdatingOperation"
                v-model="focusedOperation"
                :flag="focusedOperation.flag"
                @close="isUpdatingOperation=false"
                @save="isUpdatingOperation=false;focusedOperation=null;" />
            <!-- Ci dessous pour le mobile bizarre que le mobile n'utilise pas le vg maintenance forme contenant deja ce btn cloture -->
            <fichesav-close v-if="isClosingFicheSav"
                :target="getSelectedMaintenance"
                :showWorkingTime="!($app.isSupervisor && getSelectedMaintenance.statut=='Resolue')"
                @close="isClosingFicheSav = false"
                @cancel="isClosingFicheSav = false"
                @confirm="cloturer"
                @open-new-consommable="handleOpenNewConsommable('close')"
                v-model="rapportCloture" />
        </template>
        <template #footer v-if="$vgutils.isMobile()">
            <vg-button v-if="getSelectedMaintenance.statut != 'Resolue'"
                type="default-link"
                size="sm"
                @click="isClosingFicheSav=true">
                <i class="far fa-times-circle"></i> {{$t("cloturer")}}
            </vg-button>
            <vg-button v-else
                size="sm"
                type="default-link"
                @click="reopen">
                <i class="fas fa-power-off"></i> {{$t("reouvrir")}}
            </vg-button>
            <vg-button
                type="default-link"
                size="sm"
                @click="isUpdatingAffectations=true">
                <i class="fas fa-user-plus"></i> {{$t("affectation")}}
            </vg-button>

            <vg-files-uploader
                style="margin-right:10px;"
                    :isOnlyPhoto="true"
                    @upload-done="VgFilesMixins_fetchFilesByUid()" />

            <vg-button
                type="default-link"
                size="sm"
                @click="isModalFicheManagementOpened=true">
                <i class="fas fa-plus-circle"></i> {{$t("operation")}}
            </vg-button>
        </template>
    </vg-app-layout>
</template>

<script>

    import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
    import VgOperationsViewer from "src/components/Vg/Operation/VgOperationsViewer.vue";
	import VgOperationUpdate from "src/components/Vg/Operation/VgOperationUpdate.vue";
	import VgFilesViewerPhoto from "src/components/Vg/Files/VgFilesViewerPhoto.vue";
    import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
    import VgAffecteName from "src/components/Vg/Affectations/VgAffecteName.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgTabs from "src/components/Vg/VgTabs.vue";
    import VgTag from "src/components/Vg/VgTag.vue";
    import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
    import VgInputCost from "src/components/Vg/VgInputCost.vue";
    import VgMaintenanceForm from "src/components/Vg/Forms/VgMaintenanceForm.vue";
    import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
    import VgFichesavDescription from "src/components/Vg/FicheSAV/VgFichesavDescription.vue";
    import VgFichesavManagement from 'src/components/Vg/FicheSAV/VgFichesavManagement.vue';
    import VgFichesavStatutViewer from "src/components/Vg/FicheSAV/VgFichesavStatutViewer.vue";
    import VgFichesavTimeline from "src/components/Vg/FicheSAV/VgFichesavTimeline.vue";
    import FichesavClose from 'src/components/Vg/FicheSAV/FichesavClose.vue';
    import VgEquipementSelectModal from 'src/components/Vg/Equipements/VgEquipementSelectModal.vue';
    import VgEquipementViewer from 'src/components/Vg/Equipements/VgEquipementViewer.vue';
    import VgComposantViewer from "src/components/Vg/Composant/VgComposantViewer.vue";
    import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
    import VgTooltip from 'src/components/Vg/VgTooltip.vue';

    import { mapGetters } from 'vuex';
    export default {
        name: 'reserve',
        components: {
            VgOperationsViewer,
            VgOperationUpdate,
            VgFilesViewerPhoto,
            VgFilesUploader,
            VgButton,
            VgCollapse,
            VgTabs,
            VgTag,
            VgTooltip,
            VgAffecteName,
            VgDatetimeViewer,
            VgInputCost,
            VgMaintenanceForm,
            VgFilesViewerListSection,
            VgFichesavDescription,
            VgFichesavManagement,
            VgFichesavStatutViewer,
            VgFichesavTimeline,
            VgInputCost,
            VgLibelleEditable,
            VgEquipementSelectModal,
            VgEquipementViewer,
            VgComposantViewer,
            FichesavClose
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "modifier": "Modifier",
            "page-title": "Détails de la réserve / observation n°",
            "page-title-mobile": "Détails réserve / observation n°",
            "details": "Détails",
            "affectation": "Affectation",
            "gestion-financiere": "Gestion financière",
            "documents-attaches": "Documents attachés",
            "affectation-mode": "Mode affectation",
            "affectation-interne": "Interne",
            "affectation-externe": "Externe",
            "affectation-debut": "Date début",
            "affectation-fin": "Date fin",
            "affectation-affectes": "Affectation",
            "duree-intervention": "Durée intervention",
            "cout-interne": "Coût interne",
            "cout-externe": "Coût externe",
            "commentaire-client": "Commentaire",
            "libel-equipement": "Libellé équipement",
            "libel-composant": "Libellé composant",
            "cout-consommation": "Coût pièces détachées/consos",
            "cout-total": "Coût total",
            "gestion-calendaire-statut": "Gestion calendaire et statut",
            "update-equipement": "Modifier l'équipement",
            "cloturer": "Clôturer",
            "reouvrir": "Ré-ouvrir",
            "dateOuvertureSAV": "Date ouverture",
            "dateFermetureSAV": "Date fermeture",
            "demandeur": "Demandeur",
            "statut": "Statut",
            "typologie": "Typologie",
            "print-maintenance": "Imprimer",
            "corps-detat": "Corps d'état",
            "reserve": "Réserve_Observation",
            "exporter-pdf": "Exporter PDF",
            "urgence": "Urgence",
            "operation": "Operations",
            "historique": "Historique",
            "ajouter-action": "Ajouter action",
            "actions": "Actions",
            "toutes": "Toutes",
            "commentaires": "Commentaires textuels",
            "consommations": "Consommations",
            "retours": "Retours",
            "taches": "Taches",
            "a-prevoir": "A prévoir",
            "changements-statut": "Changements statut",
            "communications-tiers": "Communications tiers"
        },
        "en": {
            "modifier": "Update",
            "details": "Details",
            "affectation": "Assignements",
            "gestion-financiere": "Financial datas",
            "documents-attaches": "Attached documents",
            "affectation-mode": "Assignements mode",
            "affectation-interne": "Internal",
            "affectation-externe": "External",
            "affectation-debut": "Start date",
            "affectation-fin": "end date",
            "affectation-affectes": "Assignement",
            "duree-intervention": "Intervention duration",
            "cout-interne": "Cost",
            "cout-externe": "External cost",
            "libel-equipement": "Equipment's name",
            "libel-composant": "Component label",
            "commentaire-client": "Description",
            "cout-consommation": "Spare parts cost",
            "cout-total": "Total cost",
            "gestion-calendaire-statut": "Schedule and status",
            "update-equipement": "Update equipment",
            "cloturer": "Resolve",
            "reouvrir": "Reopen",
            "operation": "Operations",
            "dateOuvertureSAV": "Start date",
            "dateFermetureSAV": "End date",
            "demandeur": "Requester",
            "statut": "Status",
            "print-maintenance": "Print",
            "page-title": "Reservation / Observation details n°",
            "typologie": "Maintenance type",
            "page-title-mobile": "Reservation / Observations details n°",
            "corps-detat": "Technical field",
            "reserve": "Reservation_Observation",
            "exporter-pdf": "Export PDF",
            "urgence": "Emergency",
            "toutes": "All",
            "commentaires": "Text comment",
            "consommations": "Consumption",
            "retours": "Feedback",
            "taches": "Tasks",
            "a-prevoir": "To provide",
            "changements-statut": "Status update",
            "communications-tiers": "Suppliers"
        }
    }
},
		mixins:[
            MaintenanceMixins
        ],
        data: function() {
            return {
                isUpdatingOperation:false,
                isUpdatingAffectations: false,
                isUpdatingFm: false,
                isUpdatingGestionCalendaireEtStatut: false,
                isAddDocuments: false,
                isModalFicheManagementOpened:false,
                isClosingFicheSav:false,
                focusedOperation:null,
                rapportCloture: null,
                operationsLocalFilters: null
            }
        },
        methods:{
            onTabClicked: function(e){
                console.log("ON TAB CLICKED", e);
                this.operationsLocalFilters = e.filtres;
            },
            exportPdf(){
                this.MaintenanceMixins_getPdfFile(this.$route.params.id, this.$t("reserve")).then((pdf)=>{

                });
            },
            handleClickZoomEquipement:function(){
                this.$router.push({ name: '_equipement_id', params: { id: this.getSelectedMaintenance.equipement.id }})
            },
            /**
            * affectation interne || externe
            */
            isAffectationModeIsInterne: function(){
                if(this.getSelectedMaintenance.affectation && this.getSelectedMaintenance.affectation.affectes)
                    return this.getSelectedMaintenance.affectation.affectes.findIndex((affecte)=>affecte.roles)!=-1;
                return true;
            },
            /**
            * @param Object operation
            */
            handleOpenUpdateOperation: function(operation){
                this.focusedOperation = operation;
                this.isUpdatingOperation = true;
			},
            /**
            * @param Object operation
            */
			handleOpenDeleteOperation: function(operation){
				this.focusedOperation = operation;
				//this.showModal.deleteOperation = true;
                this.MaintenanceMixins_deleteOperation(operation.id, operation).then((operation)=>{
                    this.getSelectedMaintenance.operations.forEach((op, i) => {
                        if(op["id"] == operation.id){
                            this.getSelectedMaintenance.operations.splice(i, 1);
                        }
                    });
                });
			},
            /**
            * @param integer idConsommable
            */
            handleConsommableShow: function(idConsommable){
                this.$router.push({ name: '_consommable_id', params: {id: idConsommable} });
			},
            /**
            * @param integer idIntervention
            */
			handleInterventionShow: function(idIntervention){
                this.$router.push({ name: '_intervention', params: { id: idIntervention }});
			},
            /**
            * @param array
            */
            handleSaveAffectations: function(datas){
                /*let fm = this.getSelectedMaintenance;
				fm.affectations.users = datas.users?datas.users: null;
				fm.affectations.tiers = datas.tiers?datas.tiers: null;
				this.$eventbus.$emit('tag-grid-updaterows', fm);*/
                this.MaintenanceMixins_getMaintenance(this.getSelectedMaintenance.id).then((datas)=>{
                    this.$store.dispatch("MaintenancesStore/updateMaintenance",datas);
                    this.isUpdatingAffectations = false;
                });

			},
            /*
            * Open page impression.
            *
            */
            /*handleClickPrintMaintenance: function(){
                this.$router.push({ name: '_reserve_print'});
            },*/
            cloturer: function(data) {
                //console.log("execute cloture...",data);
                this.isClosingFicheSav = false;
                let workingTime = data.workingTime ? data.workingTime : null;
                var query = {
                    id: this.getSelectedMaintenance.id,
                    dateFermetureSAV: moment().format("YYYY-MM-DD HH:mm:ss"),
                    statut: this.$vgutils.getStatut(this.$app),
                    operation: this.rapportCloture,
                    userId: this.$app.appID,
                    idUser: this.$app.idUser,
                    workingTime: workingTime
                };
                //if(data && data.workingTime) query.workingTime = data.workingTime;
                this.MaintenanceMixins_resolveMaintenance(query).then(()=>{
                    this.getSelectedMaintenance.statut = "Resolue";
                    this.$store.dispatch("MaintenancesStore/updateMaintenance", query);
                    this.$router.push({ name: '_maintenances'});
                });
            },
            reopen:function(){
                let maintenanceId = this.getSelectedMaintenance.id;
                this.getSelectedMaintenance.statut = "En_cours";
                this.MaintenanceMixins_reopenMaintenances(maintenanceId).then(()=>{
                    this.$store.dispatch("MaintenancesStore/updateMaintenance", {"id":maintenanceId,"statut":"En_cours"});
                });
            },
            handleCloseModalFicheManagement: function(){
                this.isModalFicheManagementOpened = false;
                this.MaintenanceMixins_getMaintenance(this.getSelectedMaintenance.id).then(()=>this.$forceUpdate());
			},
            handleBackPage:function(){
                this.$router.go(-1);
                this.$store.dispatch("MaintenancesStore/setMaintenance",[]);
                this.$store.dispatch("OperationsStore/clear");
            },
            handleOperationEmailSubmited: function(){
                this.MaintenanceMixins_getMaintenance(this.$route.params.id).then((maintenance)=>{
                    this.VgFilesMixins_init(maintenance.uid, "fichemaintenance");
                });
            },
            handleUpdateMaintenance:function(){
                //console.log("handleUpdateMaintenance");
            },
            exportPdf(){
                this.MaintenanceMixins_getPdfFile(this.$route.params.id, "Fiche_maintenance").then((pdf)=>{});
            }
        },
		created: function(){
            this.MaintenanceMixins_getMaintenance(this.$route.params.id).then((maintenance)=>{
                this.VgFilesMixins_init(maintenance.uid, "fichemaintenance");
            });
        },
		mounted: function(){},
        computed:{
            ...mapGetters({
                  getSelectedMaintenance: 'MaintenancesStore/getSelectedMaintenance'
            }),
            /**
            * @return string
            */
            pageTitle: function(){
                var debut = this.$t("page-title");
                let maintenance = this.getSelectedMaintenance;
                if(this.$vgutils.isMobile()){
                    return this.$t("page-title-mobile") +" : "+ maintenance.id;
                }
                if(maintenance){
                    var complement = maintenance.id + " : ";
                    // if (maintenance.equipement && maintenance.equipement.isGroupEqp == "1") {
                    //     complement = maintenance.id + " : "+ maintenance.composant.libelComposant + " " +maintenance.commentaireClient;
                    // } else if(maintenance.equipement) {
                    //     complement = maintenance.id + " : "+ maintenance.equipement.libel_equipement + " " +maintenance.commentaireClient;
                    // }
                }
                return debut + complement;
            },
            /**
            * @return Object
            */
            schedule: function(){
                let fm = this.getSelectedMaintenance;
                let schedule = {
                    dateAffectation: moment().format("YYYY-MM-DD HH:mm:ss"),
                    start: moment().format("YYYY-MM-DD HH:mm:ss"),
                    end: moment().add("30","m").format("YYYY-MM-DD HH:mm:ss"),
                    item: fm,
                    uidAffected: []
                };
                if(fm.affectation && fm.affectation.affectes && fm.affectation.affectes.length!=0){
                    schedule = Object.assign(schedule,{
                        id: fm.affectation.id,
                        start: fm.affectation.start,
                        end: fm.affectation.end,
                        uid: fm.affectation.uid
                    });
                }
                return schedule;
            },
            /**
            * @return integer
            */
            coutConsommations:function(){
                if(!this.getSelectedMaintenance || !this.getSelectedMaintenance.operations) return 0;
                let operations = this.getSelectedMaintenance.operations;
                let coutConsommation = 0;
                operations.forEach((operation, i) => {
                    if(operation.consommableMouvement){
                        coutConsommation += operation.consommableMouvement.cout*1
                    }
                });
                return coutConsommation;
            },
            /**
            * @return integer
            */
            coutInterne:function(){
                return this.MaintenanceMixins_coutInterne(this.getSelectedMaintenance.workingTime);
            },
            /**
            * @return integer
            */
            coutExterne:function(){
                if(this.getSelectedMaintenance && this.getSelectedMaintenance.operations
                    && Array.isArray(this.getSelectedMaintenance.operations)
                    && this.getSelectedMaintenance.operations.length!=0){
                    let cout = 0;
                    this.getSelectedMaintenance.operations.forEach((operation)=>{
                        if(operation.tiers.intervention && operation.tiers.intervention.cout) cout += Number(operation.tiers.intervention.cout);
                    });
                    return cout;
                }else return 0.00;
            },
            /**
            * @return integer
            */
            coutTotal:function(){
                let coutExterne = this.coutExterne ? this.coutExterne : 0;
                let coutInterne = this.getSelectedMaintenance.workingTime ? this.MaintenanceMixins_coutInterne(this.getSelectedMaintenance.workingTime) : 0;
                let coutConsommations = this.coutConsommations ? this.coutConsommations : 0;
                return Number(coutExterne)+Number(coutInterne)+Number(coutConsommations);
            },
            /**
            * @return string
            */
            getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
            },
            hasConsommations: function(){
                if(this.getSelectedMaintenance && this.getSelectedMaintenance.operations) return this.getSelectedMaintenance.operations.findIndex((operation)=>operation.hasOwnProperty("consommableMouvement") && operation.consommableMouvement)!=-1;
                else return false;
            },
            tabs:function(){
                return [
                    {label: this.$t("toutes"), name:"toutes", filtres:[
                        {attr: "flag", colId: "flag", value: null, action: "equals"}
                    ]},
                    {label: this.$t("commentaires"), name:"commentaires", filtres:[
                        {attr: "flag", colId: "flag", value: ["manuelle", "", "validation"], action: "equals"}
                    ]},
                    {label: this.$t("consommations"), name:"consommations", filtres:[
                        {attr: "flag", colId: "flag", value: "consommation", action: "equals"}
                    ]},
                    {label: this.$t("retours"), name:"retours", filtres:[
                        {attr: "flag", colId: "flag", value: ["retourFait", "retourAFaire", "relance"], action: "equals"}
                    ]},
                    {label: this.$t("taches"), name:"taches", filtres:[
                        {attr: "flag", colId: "flag", value: "tache", action: "equals"}
                    ]},
                    {label: this.$t("a-prevoir"), name:"a-prevoir", filtres:[
                        {attr: "flag", colId: "flag", value: "a_prevoir", action: "equals"}
                    ]},
                    {label: this.$t("communications-tiers"), name:"communications-tiers", filtres:[
                        {attr: "flag", colId: "flag", value: ["intervention-reserve", "tiersinterventionponctuelle", "email", "bi", "affectation/externe"], action: "equals"}
                    ]},
                    {label: this.$t("changements-statut"), name:"changements-statut", filtres:[
                        {attr: "flag", colId: "flag", value: ["fermeture", "en_attente", "prise_en_compte", "reouverture", "validation", "changement_equipement", "urgence", "differe", "relance/iot", "affectation/affectation/interne", "affectation/interne", "affectation/externe"], action: "equals"}
                    ]}
                ];
            },
        }


    };
</script>
<style lang="scss">

</style>
