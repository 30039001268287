<template>
	<div style="display:flex; font-weight: 400;">
		<div class="currency">
			{{currency}}
		</div>
		<div>
			<input
				v-if="!readonly"
				v-model="cost"
				:placeholder="placeholder"
				:disabled="disabled"
				:min="min"
				:max="max"
				title="Amount"
				pattern="^\d+(?:\.\d{1,2})?$"
				@blur="onBlurHandler"
			>
			<span v-else>
				{{cost}}
			</span>
		</div>
	</div>


</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue";
    export default {
        name: 'vg-input-cost',
		components: {
			VgInput
		},
        props: {
			value: {
				type: String
			},
			readonly: {
				type: Boolean,
				default: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: ""
			},
			decimalPrecision:{
				type: Number,
				default: 2
			},
			emptyValue: {
				type: String,
				default: null
			},
			outputType: {
				type: String,
				default: "String"
			},
			min: {
				type: String | Number,
				default: null
			},
			max: {
				type: String | Number,
				default: null
			},
			enableNegative: {
				type: Boolean,
				default: null
			}
        },
		watch:{
			cost: function(newcost){
				// quand la valeur passe null ou 0
				// le input disparait et il ny a plus
				// moyen d'y revenir
				//this.$emit("input", newcost);
			},
			value: function(newvalue){
				this.cost = this.formatCost(newvalue, this.decimalPrecision);
			}
		},
        data: function() {
            return {
				cost: this.formatCost(this.value, this.decimalPrecision),
				currency: this.$app.devise,
				separator: ","
            };
        },
		created: function(){
			// //console.log("LANG", this.$langs.current);
		},
		mounted: function(){

		},
		methods: {
		    /**
		     * Handle blur event.
		     * @param {Object} e
		     */
		    onBlurHandler (e) {
				console.log(e);
				this.$emit('input', e);
		    },
		    /**
		     * Handle input event.
		     */
		    onInputHandler (e) {
		      this.$emit('input', e)
		    },
			/**
			* Cast amount to float and fixed x digit after , .
			* @param {integer} amount
			* @param {integer} precision how many digit after ,
			* @return {float}
			*/
			formatCost:function(amount, precision){
				return Number.parseFloat(amount).toFixed(precision);
			}
		},
        computed: {

		}
    };

</script>
<style scoped>
	.currency{
		margin-right: 5px;
		color: dimgrey;
	}
</style>
