
<template lang="html">
	<div :class="['viewer-document-container', {'underline': noDownload}]" @click="handleClickDocument">
		<i class="fa-lg far fa-file"></i>
		<span>{{fileToShow.name}}</span>
	</div>
</template>
<script>
	export default {
        name: 'vg-files-viewer-document',
	    components: {

        },
		filters: {

		},
		mixins:[

        ],
        props: {
			file: {	// @TODO utiliser v-model
				type: Object,
				required: true
			},
			noDownload:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
 				fileToShow: this.file
			};
        },
		watch: {
			file: {
				handler: function(f){
					this.fileToShow = f;
				},
				deep: true
			}
		},
		filters:{

		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			handleClickDocument: function(){
				this.VgFilesMixins_getDownloadFileLink(this.fileToShow).then((link)=>{
					if(!this.noDownload) this.VgFilesMixins_downloadFile(this.fileToShow, link);
					else window.open(link, '_blank');
				});
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>
.viewer-document-container{
	width: 100%;
	cursor: pointer;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	i{
		margin-right: 5px;
	}
}
.underline{
	color: #3999ff;
	text-decoration: underline;
}
</style>
