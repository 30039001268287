<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getPageIcon"
        :colorheader="getPageColor"
		:isLoadingState="isLoadingState">
        <template #primary>
            <br>
            <br>
            
            <div style="width: 100%;display: flex;justify-content: center;">
                <span style="font-size: 26px;">{{ $t("valider-tache") }}</span>
            </div>
            
            <br>
            
            <div style="width: 100%;display: flex;justify-content: center;">
                <span style="font-size: 18px;"><b>{{ $t("etape-1") }} : </b>{{ $t("quel-lieu-ou-equipement") }}</span>
            </div>
            <br>
            
            <!-- champ recherche avec micro && bouton scanner -->
            <div style="width:100%;display: flex;justify-content: space-between;align-items:center;gap: 5px;margin-bottom: 30vh;">
                <vg-search-datas-filter 
                    position="slot"
                    type="lancement"
                    size="lg"
                    afterSaveRedirectTo="_lancement"
                    :placeholder="$t('rechercher-equipement-lieu')" />
                <vg-button type="grey"
                    size="lg"
                    style="height: 50px;"
                    @click="showScanner=true;">
                    <i class="fa fa-2x fa-qrcode" />
                </vg-button>
            </div>

            <br>
            <br>


            <hr>
            <!--section collapse infos complémentaires-->
            <!--liste des sessions de taches-->
            <vg-collapse :title="$t('informations-complementaires')"
                :collapseByDefault="false">
                <template #content>
                    <vg-calendar-planning 
                        :calendars="calendarsDisplayed"
                        :schedules="calendarEventsDisplayed"
                        :isReadOnly="true"
                        :showCalendarDays="false"
                        @click-schedule="openCheck" />
                </template>
            </vg-collapse>

            <vg-modal v-if="showScanner"
               :title="$t('scanner-une-etiquette')"
               @close="showScanner=false;">
               <template #body>
                   <zxing-scanner
                       :isDemo="false"
                       @scanSuccess="onScannSuccess" />
               </template>
               <template #footer>
                   <vg-button @click="showScanner=false;">
                       {{$t('close')}}
                   </vg-button>
               </template>
            </vg-modal>

        </template>
    </vg-app-layout>
</template>

<script>
    const ICON_DESKTOP = "verification.png";
    const ICON_MOBILE = "mobile/verification.png";
    const HEADER_BACKGROUND_COLOR_MOBILE = "#f36f3a";
    
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgCollapse from "src/components/Vg/VgCollapse.vue";
    import VgSearchDatasFilter from "src/components/Vg/SearchDatas/VgSearchDatasFilter.vue";
    import zxingScanner from "src/components/QrCode/Zxing/zxingScanner.vue";
    import VgCalendarPlanning from "src/components/Vg/Calendar/VgCalendarPlanning.vue";

    import equipementsMixins from 'src/mixins/equipementsMixins.js';
    import CalendarMixins from 'src/mixins/CalendarMixins.js';

    import { mapGetters } from 'vuex';
    export default {
        name: 'lancement',
        components: {
            VgInput,
            VgButton,
            VgModal,
            VgCollapse,
            VgSearchDatasFilter,
            zxingScanner,
            VgCalendarPlanning
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Ronde",
            "scanner": "SCANNER",
            "scanner-une-etiquette": "Scanner une étiquette",
            "informations-complementaires": "Informations complémentaires",
            "close": "Fermer",
            "non-trouve": "Equipement/lieu non trouvé",
            "impossible-charger-equipement": "Impossible de charger l'équipement {libel}",
            "impossible-charger-lieu": "Impossible de charger la pièce {libel}",
            "rechercher-equipement-lieu": "Saisissez au moins 3 lettres",
            "etape-1":"Etape 1",
            "quel-lieu-ou-equipement": "Quel lieu ou équipement?",
            "valider-tache": "Valider une tâche"
        },
        "en": {
            
        }
    }
},
		mixins:[equipementsMixins, CalendarMixins],
        props:{
            
        },
        data: function() {
            return {
                searchQuery: "",
    			showScanner: false,
    			isLoadingState: false,
                calendarsDisplayed: [
                    {
                        id: "tache-session",
                        name: this.$t("calendar-tache-session"),
                        checked: true,
                        color: "white",
                        bgColor: "#4F56A6",
                        dragBgColor: "#4F56A6",
                        borderColor: "transparent",
                        section: this.$t("section-preventif")
                    }
                ],
                calendarEvents: []
            }
        },
        watch:{
            
        },
        methods:{
            /**
             * @param array lieuIds
             * @return {promise}
             */
            fetchEquipementByQrCode:function(qrCode){
                return new Promise((resolve,reject)=>{
                    let metadatas = new Metadatas();
                    metadatas.setFilters(
                        {
                            "qrCode":{attr: "e.qrCode", value: qrCode, action: "equals", "openParenthesis":true},
                            "codeUn":{attr: "l.codeUn", value: qrCode, action: "equals", "logicalOperator":"OR", "closeParenthesis":true}
                        }
                    );
                    this.equipementsMixins_getEquipements(metadatas).then((equipements)=>{

                        resolve(equipements);
                    });
                });
            },
            onScannSuccess: function(code){
                this.isLoadingState = true;
                this.fetchEquipementByQrCode(code).then((response)=>{
                    let equipement = response.datas.pop();
                    if(!equipement){
                        this.showScanner = false;
                        alert(this.$t("non-trouve"));
                        return;
                    }
                    let isGroupEqp = parseInt(equipement.isGroupEqp);
                    if(isGroupEqp){ // lieu
                        this.$router.push({name: "_lieu_id", params: {id: equipement.idLieu_id}})
							.catch(failure => {
								alert(this.$t("impossible-charger-lieu", {libel: equipement.libel_lieu}));
							});
                    }else{ // equipement
                        this.$router.push({name: "_equipement_id", params: {id: equipement.id}})
							.catch(failure => {
								alert(this.$t("impossible-charger-equipement", {libel: equipement.libel_equipement}));
							});
                    }
                });
            },
            fetchCalendarEvents: function(){
				this.isLoadingState = true;
				this.calendarEvents = [];
				let dateStart = moment().format("YYYY-MM-DD 00:00:00");
				let dateEnd = moment().format("YYYY-MM-DD 23:59:59");
				this.CalendarMixins_get(dateStart, dateEnd, [], [], [this.$app.idUser]).then((datas)=>{
					this.calendarEvents = datas.events;
					this.isLoadingState = false;
				});
			},
            openCheck: function(datas){
                let schedule = datas.schedule;
                if(this.can("CHECKS.PAGE")){
                    if(schedule.raw.tache.type_tache=="Verification_equipement") {
                        if(schedule.raw.tache.isScanRequired==1) this.$router.push({name: "_process_verification_tache", params: { type: "equipement", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                        else this.$router.push({ name: '_verification_progression_tache', params: { tacheId: schedule.raw.tache.id, afterSaveRedirectTo: "_lancement"} });
                    }else if(schedule.raw.tache.type_tache=="Relever_compteur") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "compteur", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                    }else if(schedule.raw.tache.type_tache=="Relever_Grandeur_physique") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "grandeur-physique", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                    }else if(schedule.raw.tache.type_tache=="Verification_Lieu") {
                        this.$router.push({name: "_process_verification_tache", params: { type: "lieux", tacheId: schedule.raw.tache_id, afterSaveRedirectTo: "_lancement" }});
                    }
                }
            },
        },
		created: function(){
            this.fetchCalendarEvents();
		},
		mounted: function(){

		},
        computed:{
            ...mapGetters({
                
            }),
            getPageIcon: function(){
                return this.$vgutils.isMobile()?ICON_MOBILE:ICON_DESKTOP;
            },
            getPageColor: function(){
                return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : 'white';
            },
            calendarEventsDisplayed: function(){
                return this.calendarEvents.filter((event)=>this.calendarsDisplayed.findIndex((calendar)=>event.calendarId==calendar.id)!=-1);
            },
        }


    };
</script>
<style lang="scss" scoped>

</style>
