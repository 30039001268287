
<template lang="html">
	<div class="vg-files-viewer-list">
		<div v-for="file, index in value" :key="index">
			<vg-files-viewer-document
				:file="file">
			</vg-files-viewer-document>
			<!--vg-button
				:size="'sm'"
				:type="'default-grey'"
				>voir
			</vg-button-->
		</div>
	</div>
</template>
<script>
	import VgFilesViewerDocument from "src/components/Vg/Files/VgFilesViewerDocument.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	export default {
        name: 'vg-files-viewer-list',
	    components: {
			VgFilesViewerDocument,
			VgButton
        },
		filters: {

		},
		mixins:[

        ],
        props: {
			/**
			* @model
			*/
			value: {
				type: Array,
				required: true
			}
        },
        data: function() {
            return {

			};
        },
		watch: {

		},
		filters:{

		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {

		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>
.vg-files-viewer-list{
	width: 100%;
	>div{
		margin-bottom: 7px;
		display: flex;
		justify-content: space-between;
	}
	>div:hover{
		color:#57c1db;
	}
}
</style>
