<template>
	<div class="vg-fichesav-timeline">
		<div class="timeline-part" v-for="(operation, index) in getOperationsTimeline" :key="operation.id">
			<div :class="['timeline-phase', {'timeline-phase-icon':operation.flag=='manuelle'||operation.flag=='email-demande-devis'}]" >
				<div v-if="operation.flag=='manuelle' || operation.flag=='email-demande-devis'" class="phase-icon">
					<div class="phase-icon-inter"></div>
					<span class="icon">
						<i v-if="operation.flag=='manuelle'" class="fas fa-comment-dots fa-2x"></i>
						<i v-else-if="operation.flag=='email-demande-devis'" class="fas fa-envelope fa-2x"></i>
					</span>
					<div class="phase-icon-inter"></div>
				</div>
				<span class="phase" v-else>{{$t(getPhase(operation.flag))}}</span>
				<div class="complement">
					<div v-if="operation.affectation && (operation.flag=='affectation/interne' || operation.flag=='affectation/externe')" class="affectes">
						<div v-for="(affecte, index) in operation.affectation.affectes" :key="index" class="affecte">
							<i :class="['fas',{'fa-user':affecte.user_name?true:false,'fa-industry':affecte.name?true:false}]"></i>
							<span>{{affecte.user_name?affecte.user_name:affecte.name}}</span>
						</div>
					</div>
					<div v-else-if="operation.flag=='manuelle'" class="comment">
						{{operation.operation}}
					</div>
					<div v-else-if="operation.flag=='email-demande-devis'" class="comment">
						{{$t("email-demande-devis")}} <b>{{operation.tiers.name}}</b>
					</div>
					<div v-else-if="operation.flag=='consommation'" class="comment">
						<span style="font-size:14px;">{{operation.consommableMouvement.quantite}} x</span> <b>{{operation.consommable.name}}</b> <small>{{operation.consommable.refExterne}}</small>
					</div>
					<div v-else-if="operation.flag=='tache' || operation.flag=='a_prevoir'" class="comment">
						<div class="tache">
							<i :class="['far fa-2x',{'fa-square':operation.statut=='en_cours','fa-check-square':operation.statut=='resolue'}]"></i>
							<span>{{operation.operation}}</span>
							<vg-operation-tags-viewer v-if="operation.tags && operation.tags.length!=0"
								v-model="operation.tags" />
						</div>
						<span v-if="operation.retourClient">{{$t("commentaire-operation")}}: {{operation.retourClient}}</span>
					</div>
					<div v-if="operation.flag=='tiersinterventionponctuelle'" class="comment">
						<span>{{$t("intervention", {numero: operation.tiers.intervention.numeroIntervention, cout: operation.tiers.intervention.cout})}}</span>
						<br>
						<span style="font-weight:bold;"><i class="fas fa-industry" style="margin-right:5px;"></i> {{operation.tiers.name}}</span>
					</div>
					<span>{{$t("par")}} {{operation.username}}</span>
					<vg-datetime-viewer v-model="operation.dateOperation"/>
					<span v-if="(operation.flag=='affectation/externe' || operation.flag=='affectation/interne') && maintenance.statut!='Resolue'"
						class="link"
						@click="showAffectationModal=true;">
						{{$t("btn-modifier-affectation")}}
					</span>
					<span v-if="operation.flag=='fermeture' && maintenance.statut!='En_cours' && maintenance.statut!='prise_en_compte' && maintenance.statut!='en_attente'"
						class="link"
						@click="reopen">
						{{$t("btn-reouvrir")}}
					</span>
				</div>
			</div>
			<div class="timeline-inter" v-if="index!=getOperationsTimeline.length-1">
				<span class="ecart">{{getEcart(operation, getOperationsTimeline[index+1])}}</span>
				<!--i class="fa fa-arrow-right fa-2x"></i-->
				<div class="inter"></div>
			</div>
		</div>
		<vg-fichesav-affectation v-if="showAffectationModal"
           @close="showAffectationModal=false;"
           @save="showAffectationModal=false;" />
	</div>
</template>
<script>
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
	import VgOperationTagsViewer from "src/components/Vg/Operation/VgOperationTagsViewer.vue";
	import VgFichesavAffectation from 'src/components/Vg/FicheSAV/VgFichesavAffectation.vue';
	import MaintenanceMixins from 'src/mixins/MaintenanceMixins.js'
	import { mapGetters } from 'vuex';
    export default {
        name: "vg-fichesav-timeline",
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "fermeture": "Clôture",
            "ouverture": "Ouverture",
            "reouverture": "Ré-ouverture",
            "affectation-interne": "Affectation interne",
            "affectation-externe": "Affectation externe",
            "en-attente": "En attente",
            "prise-en-compte": "Prise en compte",
            "changement-equipment": "Changement équipement",
            "tiersinterventionponctuelle": "Bon d'intervention",
            "intervention": "Intervention n° {numero} coût: {cout}",
            "email-demande-devis": "Envoie email demande de devis à",
            "consommation": "Consommation",
            "commentaire-operation": "Commentaire",
            "tache": "Tache",
            "a-prevoir": "Matériel à prévoir",
            "par": "Par",
            "diff-days": "+ {d}j",
            "diff-hours": "+ {h}h",
            "diff-minutes": "+ {m}min",
            "diff-seconds": "+ {s}s",
            "btn-modifier-affectation": "Modifier affectation",
            "btn-reouvrir": "Réouvrir"
        },
        "en": {}
    }
},
		components:{
			VgButton,
			VgDatetimeViewer,
			VgOperationTagsViewer,
			VgFichesavAffectation
		},
		mixins:[ MaintenanceMixins ],
    	props: {

        },
		watch: {

		},
        data: function() {
            return {
				showAffectationModal: false
            };
        },
		created: function(){

		},
		mounted: function(){

		},
		methods: {
			reopen:function(){
	            this.MaintenanceMixins_reopenMaintenances(this.maintenance.id).then(()=>{
	                this.$store.dispatch("MaintenancesStore/updateMaintenance", {"id":this.maintenance.id, "statut":"En_cours"});
	            });
	        },
			getPhase: function(flag){
				let phase = null;
				switch(flag){
					case "fermeture":
					case "ouverture":
					case "reouverture":
						phase = flag;
						break;
					case "affectation/interne":
						phase = "affectation-interne";
						break;
					case "affectation/externe":
						phase = "affectation-externe";
						break;
					case "en_attente":
						phase = "en-attente";
						break;
					case "prise_en_compte":
						phase = "prise-en-compte";
						break;
					case "changement_equipment":
						phase = "changement-equipment";
						break;
					case "a_prevoir":
						phase = "a-prevoir";
						break;
					default:
						phase = flag;
						break;
				}
				return phase;
			},
			getEcart: function(operation, operationSuivante){
				let dateCourante = moment(operation.dateOperation);
				let dateSuivante = moment(operationSuivante.dateOperation);
				let diffDays = dateSuivante.diff(dateCourante, "days");
				if(diffDays==0){
					let diffHours = dateSuivante.diff(dateCourante, "hours");
					if(diffHours==0){
						let diffMinutes = dateSuivante.diff(dateCourante, "minutes");
						if(diffMinutes==0){
							let diffSeconds = dateSuivante.diff(dateCourante, "seconds");
							return this.$t("diff-seconds", {s: diffSeconds});
						}else return this.$t("diff-minutes", {m: diffMinutes});
					}else return this.$t("diff-hours", {h: diffHours});
				}else return this.$t("diff-days", {d: diffDays});
			}
		},
        computed: {
			...mapGetters({
                maintenance: "MaintenancesStore/getSelectedMaintenance",
				operations: "OperationsStore/getCollection"
            }),
			getOperationsTimeline: function(){
				//let flags = ["fermeture", "ouverture", "reouverture", "affectation/interne", "affectation/externe", "en_attente", "prise_en_compte", "changement_equipment"];
				let operationsFiltered = [...this.operations]/*.filter((operation)=>flags.includes(operation.flag))*/.sort((op1, op2)=>op1.id-op2.id);
				let operationCreation = {
					dateOperation: this.maintenance.dateOuvertureSAV,
					username: this.maintenance.contact,
					flag: "ouverture"
				};
				operationsFiltered.unshift(operationCreation);
				return operationsFiltered;
			}
		}
    };

</script>
<style scoped lang="scss">
$inter-color: #fdc02c;
$background-color: #fdc02c;
.vg-fichesav-timeline{
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	width: 100%;
	overflow-x: auto;
	.timeline-part{
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		.timeline-phase{
			width:150px;
			margin-top: 1px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			.phase{
				width: 100%;
				text-align: center;
				color: white;
				font-size: 12px;
				font-weight: bold;
				border: 2px solid $inter-color;
				background-color: $background-color;
				padding: 5px;
			}
			.complement{
				margin-top:5px;
				width:100%;
				font-size: 11px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				.affectes{
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					border: 1px solid $background-color;
					padding: 5px;
					width: 100%;
					.affecte{
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						gap:5px;
					}
				}
				.comment{
					width: 100%;
					border: 1px solid $background-color;
					padding: 5px;
				}
				.tache{
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 5px;
				}
				.link{
					text-decoration: underline;
					color: #059CFF;
					cursor:pointer;
				}
			}
		}
		.timeline-phase-icon{
			margin-top: 0px;
			.phase-icon{
				display: flex;
				justify-content: center;
				align-items: center;
				width:100%;
				.phase-icon-inter{
					border-top: 2px solid $inter-color;
					width: 100%;
					margin-top:-6px;
				}
				.icon{
					width: 40px;
					text-align: center;
					color: $background-color;
					font-size: 12px;
					font-weight: bold;
					border: 2px solid $inter-color;
					background-color: white;
					padding: 5px;
					border-radius: 100%;
				}
			}
		}
		.timeline-inter{
			width:80px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			.ecart{
				font-size: 11px;
			}
			.inter{
				border-top: 2px solid $inter-color;
				width: 100%;
			}
		}
	}
}
</style>
<!--style scoped lang="scss">
.vg-fichesav-timeline{
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 20px;
	.timeline-part{
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: 20px;
	}
	.timeline-phase,.timeline-inter{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
	}
	.timeline-phase{
		padding-top: 23px;
	}
	.timeline-inter{
		gap: 5px;
	}
	.phase{
		width: 150px;
		padding-top: 10px;
		background-color:  #7f7f7f;
		padding: 5px 8px;
		color: white;
		font-size: 14px;
		text-align: center;
		border-radius: 3px;
	}
}
</style-->
