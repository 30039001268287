"use strict";
exports.__esModule = true;
exports.IFCManager = void 0;
var web_ifc_api_1 = require("web-ifc/web-ifc-api");
/*interface IFCManager {
    ifcManager: any,
    ifcUrl: string,
    datas: Uint8Array,
    models: Array<number>
}*/
var IFCManager = /** @class */ (function () {
    function IFCManager() {
        this.modelID = 0;
        this.ifcManager = new web_ifc_api_1.IfcAPI();
        this.ifcManager.SetWasmPath("../../static/wasm/");
    }
    /**
     *
     * @param url
     * @returns {Promise}
     */
    IFCManager.prototype.loadIfc = function (url) {
        var _this = this;
        this.ifcUrl = url;
        return new Promise(function (resolve, reject) {
            var oReq = new XMLHttpRequest();
            oReq.responseType = "arraybuffer";
            oReq.addEventListener("load", function () {
                var datas = new Uint8Array(oReq.response);
                _this.rawDatas = datas;
                resolve(new Uint8Array(oReq.response));
            });
            oReq.open("GET", url);
            oReq.send();
        });
    };
    /**
     *
     * @param ifcUrl
     * @returns {Promise}
     */
    IFCManager.prototype.openModel = function (ifcUrl) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.ifcManager.Init().then(function () {
                _this.loadIfc(ifcUrl).then(function (ifcData) {
                    _this.modelID = _this.ifcManager.OpenModel(ifcData);
                    resolve(_this.ifcManager.IsModelOpen(_this.modelID));
                });
            });
        });
    };
    /**
     *
     * @param ifcUrl
     * @returns {Promise}
     */
    IFCManager.prototype.openModelText = function (ifcText) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.ifcManager.Init().then(function () {
                var uint8array = new TextEncoder().encode(ifcText);
                _this.modelID = _this.ifcManager.OpenModel(uint8array);
                resolve(_this.ifcManager.IsModelOpen(_this.modelID));
            });
        });
    };
    IFCManager.prototype.closeModel = function () {
        this.ifcManager.CloseModel(this.modelID);
    };
    IFCManager.prototype.getManager = function () {
        return this.ifcManager;
    };
    IFCManager.prototype.setManager = function (manager) {
        this.ifcManager = manager;
        return this;
    };
    return IFCManager;
}());
exports.IFCManager = IFCManager;
