<template>
    <div class="dashboard-Cell">
        <div class=" card-head" >
            <div class="head-name">
                <span>
                    <img v-if="params.icon && params.icon.length" :src="params.icon" alt="icône tâche vérification" width="24" height="24">
                    <img v-else  src="../../../../static/assets/icone/tache-de-verification.png" alt="icône tâche vérification" width="24" height="24">
                </span>
                <span class="libelle-tache-verification">{{ params.libel_tache }}</span>
            </div>
            <div v-if="params.users && params.users.length" class="affectation">
                <span>{{$t("affecte-a")}} :</span>
                <span v-for="user in params.users">
                    <vg-tag :label="user.nom+' '+user.prenom"></vg-tag>
                </span>
            </div>
            <div class="informations-temporelles">
                <span><span class="txt-date">{{ $t("avantLe") }}</span> {{dateButoire}}</span>
                <span class="temps-restant">{{ $t("reste") }} <b>{{timeToX}}</b></span>
            </div>
        </div>
        <div class=" dashVerif-card-body" v-if="params.total_todo != '0'">
            <div >
                <div class=" dashVerif-card-body-taskDone">
                    <span><b>{{ params.total_done }}</b></span>
                </div>
                <div class=" dashVerif-card-body-totalTask">
                    <span>{{ $t("total") }} <b>{{ params.total_todo }}</b></span>
                </div>
            </div>
            <vg-progress v-model="params.total_done"
                :total="params.total_todo"
                bar
                percentage
                style="width:100%;" />
        </div>
        <div v-else>
            {{$t("no-equipement")}}
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgTag from "src/components/Vg/VgTag.vue";
    import VgProgress from "src/components/Vg/VgProgress.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components:{
            VgTag,
            VgProgress
        },
        data:function(){
            return{
                dateButoire : "",
            }
        },
        mounted: function(){
            //console.log(this.params);
            if (this.params.date_butoire) {
                this.dateButoire = moment(this.params.date_butoire).format("DD/MM/YYYY");
            }
            else{
                this.dateButoire = moment().format("DD/MM/YYYY")
            }
        },
        computed:{
            timeToX:function(){
                moment.updateLocale('en', {
                    relativeTime : {
                        future: "dans %s",
                        past:   "il y a %s",
                        s:  "secondes",
                        m:  "une minute",
                        mm: "%d minutes",
                        h:  "une heure",
                        hh: "%d heures",
                        d:  "un jour",
                        dd: "%d jours",
                        M:  "un mois",
                        MM: "%d mois",
                        y:  "un an",
                        yy: "%d ans"
                    }
                });
                var time = moment(this.params.date_butoire).toNow(true);

                return time;
            }
        },
        i18n:    { "locale":navigator.language,
	    	"messages": {
                "fr":{
                    "header-titre": "Progression des vérifications",
                    "avantLe": "Avant le",
                    "reste": "Il reste",
                    "total": "Total",
                    "zoom-equipRest": "équipement(s) restant(s)",
                    "feedback-a": "Vérifications Ok",
                    "no-equipement": "Aucun équipement(s) à vérifier",
                    "affecte-a":"Affecter à"
                },
                "en":{
                    "header-titre": "Verification progression",
                    "avantLe": "Before",
                    "reste": "time remaining",
                    "total": "Total",
                    "zoom-equipRest": "Remaining equipments",
                    "feedback-a": "Verification done",
                    "no-equipement": "No equipment to check",
                    "affecte-a":"Assign to"
                }
            }
        }
    });
</script>

<style lang="scss" scoped>
.dashboard-Cell{
    padding-right: 15px;
    .card-head{
        margin-bottom: 5px;
        .head-name{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img{
                margin:0;
            }
            span{
                margin-right: 10px;
            }
            .libelle-tache-verification {
                font-size: 15px;
                font-weight: bold;
                position: relative;
                top: 2px;
            }
        }
        .affectation{
            display: flex;
            align-items: center;

            span{
                margin-right: 5px;
            }
        }

        .informations-temporelles {
            margin: 4px 0px ;
            .temps-restant {
                float: right;
            }
        }
    }

    .dashVerif-card-body-taskDone{
        width: 50%;
        float: left;
    }


    .dashVerif-card-body-totalTask{
        text-align: right;
    }
    .txt-date{
        color:#a2a2a2;
    }
}

@media (max-width: 768px){
    .dashboard-Cell{
        font-size: 12px;
        padding:0;
        .libelle-tache-verification{
            font-size: 14px;
        }

    }
}

</style>
