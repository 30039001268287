/**
 *
 *   REST CLIENT -- is a wrapper to communicate with backend symfony (verifgood)
 *
 *
 *@author TxTony
 *@version 1.0
 *@license MIT
 *@class
 */
import RestClientExceptions from 'src/Exceptions/services/RestClientExceptions.js';
import Metadatas from 'src/services/Metadatas.js';
import VGRoles from 'src/mixins/RolesMixins.js';
const axios = require('axios');
const NProgress = require('nprogress');
class RestClient {
    /**
     * @constructor
     */
    constructor() {
        // V2
        this.host = process.env.API_HOST || 'https://symlab.herokuapp.com/public/index.php';

        this.__options = {};
        this.displayLoader = true,
        this.dispatchResponse = true;
        this.metadatas = new Metadatas();
        this.contentType = 'application/json';
    }
    setContentType(contentType){
        this.contentType = contentType;
        return this;
    }
    getContentType(){
        return this.contentType;
    }
    /**
    * Getter.
    * @return Metadatas
    *
    */
    getMetadatas(metadatas){
        return this.metadatas;
    }
    /**
    * Setter.
    * @param Metadatas
    */
    setMetadatas(metadatas){
        this.metadatas = metadatas;
    }
    resetHost() {
        this.host = 'https://symlab.herokuapp.com/web/app.php';
    }
    /**
     * @deprecated
     *
     */
	useHostv2(){
		// console.warn("RestClient.useHostv2()");
		//this.host = this.hostv2;
	}
    setDisplayLoader(bool){
        this.displayLoader = bool;
    }
    setHost(host) {
        this.host = host;
    }
    getHost() {
        return this.host;
    }
    setDispatchResponse(bool){
        this.dispatchResponse  = bool;
    }
    isResponseDispatchable(){
        return this.dispatchResponse;
    }
	setOptions(options){
		this.__options = options;
	}

    /**
     * Transform query object to the euivalent in string ready to use for the Url to send.
     * @example { age : '= 26',name : '= foo'}  =  age=+26&name=+foo
     * @private
     * @param {Object} param a simple object wich contain element for querying
     * @return {String} The query in string
     */
    addQuery(param) {
        var query = "";
        if (param) {
            for (var name in param) {
                ////// // //console.log('name : ' + name + '/' + 'param : ' + param);
                if(typeof(param[name])=="object") query += name + '=' + encodeURIComponent(JSON.stringify(param[name])) /* encodeURIComponent(param[name]) */ + '&';
                else query += name + '=' + encodeURIComponent(param[name]) /* encodeURIComponent(param[name]) */ + '&';
            }
            query = query.substr(0, query.length - 1);
        }
        ////// // //console.log('query = ' + query);
        return query.length ? "?"+query:"";
    }
    /**
     * @private
     */
    addPath(key) {
        if (key) {
            return key;
        } else {
            // throw error
        }
    }
    /**
     *	Dispatch event Starting rest client send
     *
     *   @fires RestClient#RestClientStarting
     *	@param object data to dispatch in the event
     */
    restClientStartingEvent(object) {
        var restClientStartingEvent = new CustomEvent('RestClientStarting', {
            'detail': object
        });
        window.dispatchEvent(restClientStartingEvent);
    }
    /**
     *	Dispatch event success rest client
     *
     *   @fires RestClient#RestClientSuccess
     *	@param object data to dispatch in the event
     */
    restClientSuccessEvent(object) {
        var restClientSuccessEvent = new CustomEvent('RestClientSuccess', {
            'detail': object,
            'bubbles': false,
            'cancelable': false
        });
        ////;
        window.dispatchEvent(restClientSuccessEvent);
    }
    /**
     *	Dispatch event error rest client
     *
     *   @fires RestClient#RestClientError
     *	@param object data to dispatch in the event
     */
    restClientErrorEvent(object) {
        var restClientErrorEvent = new CustomEvent('RestClientError', object);
        window.dispatchEvent(restClientErrorEvent);
    }
    /**
     * Send HTTP request to backend server. and dispatch event when starting the request , success request , or fail request
     * @private
     * @param {Function} cb result callback in JSON.
     * @param {string} method 'GET', 'POST'.
     * @param {String} key is the endpoint location
     * @param {Object} [param] query parameter
     * @param {Object} [object] payload
     */
     // WARNING SHOULD BE REVIEWED
    send(method, key, param={}, object, cb, cbr) {
        NProgress.configure({
          template: '<div class="bar" role="bar" style="z-index:99999;height:3px;background:#faba00;"><div class="peg" style="box-shadow:0 0 10px #faba00,0 0 3px #29d;opacity:1;"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>'
        });
        NProgress.start();
        // on s'assure que les metadatas qui vont au serveur sont formatés correctement
        // en utilisant la class Metadatas
        // attention il existe pas mal de requêtes n'utilisant pas encore la class Metadatas
        if(method=="GET"){
            !param ? param={}:null;
            if(!param.hasOwnProperty("metadatas") && !param["metadatas"]){
                param["metadatas"] = new Metadatas().get()
            }else{
                // todo
            }
        }

        let user = JSON.parse(window.sessionStorage.getItem('user'));
        let appID = user?user.appID:null;
        let apiToken = user?user.__t:null;
        var that = this;
        var base = this.host;
        base += this.addPath(key);
        base += this.addQuery(param);
        const yourDate = new Date();
        let date = yourDate.toISOString().split("T")[0];
        let time = yourDate.getHours()+":"+yourDate.getMinutes();
        ////;
        // Make a request for a user with a given ID
        var config = {
            url:base,
            method: method,
            headers:{
                'X-AUTH-TOKEN': apiToken,
                'X-CLIENT-DATETIME': date+" "+time,
                'Content-Type':this.getContentType(),
                'Cache-Control': 'public',

                //'If-None-Match': '31d5809ea7bfbbb263626f4b2dd4dce5'  le hash correspond à l'Etag
                // prévoir un if-modified si if none match est renseigné
                },
                //crossDomain: true,
                //withCredentials: true,
                // `onUploadProgress` allows handling of progress events for uploads
                onUploadProgress: function (progressEvent) {
                  // Do whatever you want with the native progress event
                  // //console.log("axiosEvTUP---",progressEvent);
                },

                // `onDownloadProgress` allows handling of progress events for downloads
                onDownloadProgress: function (progressEvent) {
                  // Do whatever you want with the native progress event
                  // //console.log("axiosEvTDL---",progressEvent);
                },

        };
        if(appID){
            config["headers"]['X-APP-ID'] = appID;
        }
        if( object ){
            config['data'] = typeof object == "string" ? object : JSON.stringify(object);
        }
        axios(Object.assign(config,this.__options))
        .then(function (response) {
            if(response.data.datas) cb && cb(response.data.datas,response.data.metadatas);
            else cb && cb(response.data);
            if(that.isResponseDispatchable()){
                that.restClientSuccessEvent({
                    'entities': response.data,
                    'method': method
                });
            }
            NProgress.done();
            //that.displayLoader = true;
            that.resetHost();
            // // //console.log(response);
        })
        .catch(function (error) {		// ATTENTION ! il catch les erreurs du cb
            console.log(error);
            if(error.response && error.response.status && error.response.status == 422 && error.response.data == "appId mismatched"){
                window.sessionStorage.removeItem("user");
                that.restClientErrorEvent({
                    'detail': error.responseText,
                    'method': 'GET',
                    'httpStatus': 422,
                });
                window.location.replace("/login");
            }
            if(error.response && error.response.status == 401){
                window.sessionStorage.removeItem("user");
                that.restClientErrorEvent({
                    'detail': "INVALID_CREDENTIALS",
                    'method': 'GET',
                    'httpStatus': 401,
                });
                if(window.location.pathname != "/login"){
                    window.location.replace("/login");
                }
            }
            if(error.response && error.response.status == 403){
                window.sessionStorage.removeItem("user");
                if(error.response.data.reason == "DEMO_IS_OVER"){
                    that.restClientErrorEvent({
                        'detail': "DEMO_IS_OVER",
                        'method': 'GET',
                        'httpStatus': 403,
                    });
                }
                if(window.location.pathname != "/login"){
                    window.location.replace("/login");
                }
            }
            if(error.response && error.response.status == 409){
                window.sessionStorage.removeItem("user");
                if(error.response.data == "Email already exists"){
                    that.restClientErrorEvent({
                        'detail': "EMAIL_ALREADY_EXISTS",
                        'method': 'GET',
                        'httpStatus': 409,
                    });
                }
            }
            if(error.response && error.response.status == 417){
                window.sessionStorage.removeItem("user");
                alert(error.response.data);
            }

            cbr && cbr(error);
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // //console.log(error.response.data);
              // //console.log(error.response.status);
              // //console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              // //console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              // //console.log('Error', error.message);
            }
            NProgress.done();
            // // //console.log(error.config);
          });
        return;
    };
    sendFile(url, file){
        return new Promise((resolve, reject)=>{
            let user = JSON.parse(window.sessionStorage.getItem('user'));
            let apiToken = user?user.__t:null;
            
            let formData = new FormData();
            formData.append("fileDatas", file);
            axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'X-AUTH-TOKEN': apiToken,
                    'Cache-Control': 'public'
                },
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
    /**
    *
    *
    */
    isUserIsAuthorized(_options, defaultRule){
        if(_options && _options.hasOwnProperty("rule") && _options.rule) return VGRoles.methods.can(_options.rule);
        else return VGRoles.methods.can(defaultRule);
    };
    /**
     * Get object.
	 * @public
	 * @example var query = {
	 *		'method':'custom',
	 *		'fields':'id',
	 *	'customParams':'{'userId':'=+'+userId+''}'
	 *	};
	 * @param {String} key is the endpoint location see example
     * @param {Function} cb callback for success
     * @param {Function} cb callback for error
     * @param {string} key Represent the entity to interact with
     */
    get(key, query, cb, cbr=null) {
        // // //console.log(query);
        //if(VGRoles.methods.can("READ")){
            this.send('GET', key, query, {}, cb, cbr);
        //}

    };
    /**
     * Delete object.
     * @public
     * @deprecated
     * @param {String} key is the endpoint location see example
     * @param {Function} cb callback for success
     * @param {string} key Represent the entity to interact with
     */
    remove(key, obj, cb) {
        if(VGRoles.methods.can("DELETE")){
            if (!obj.id) {
                RestClientExceptions.unknowEntityId();
            } else {
                this.send('GET', key + '/ids/' + obj.id + '/remove', {}, {}, cb);
            }
        }
    };
    /**
     * Create object.
	 * @public
	 * @example var query = {
			'libelleCatgorie': 'azertyuiopqsdfghjklmwxcvbn123456789',
			'userId': userId
		};
	 * @param {String} key is the endpoint location see example
     * @param {Function} cb callback for success
     * @param {Object} obj the object to persist
     */
    post(key, obj, cb, cbr) {
        if(VGRoles.methods.can("CREATE")){
            this.send('POST', key, {}, obj, cb, cbr);
        }

    };
    /**
     * Update object.
	 * @public
	 * @example var query = {
			'libelleCatgorie': 'azertyuiopqsdfghjklmwxcvbn123456789',
			'userId': userId
		};
	 * @param {String} key is the endpoint location see example
     * @param {Function} cb callback for success
     * @param {Object} obj the object to persist
     */
    put(key, obj, cb, cbr, _options={}) {
        if(this.isUserIsAuthorized(_options, "UPDATE")){
            /*if (!obj.id) {
                RestClientExceptions.unknowEntityId();
            }else{*/
                this.send('PUT', key, {}, obj, cb, cbr);
            //}
        }

    };
    /**
     * Delete an entity of DB be careful id's entity is required
     * @example var idObject = 1;
     *	restClientObj.remove('/entities/Categorie',idObject,callback);
     * @param {String} key is the endpoint location see example
     * @param {Function} cb callback for success
     * @param {Object} obj the object to persist
     */
    delete(key, obj, cb, cbr) {
        if(VGRoles.methods.can("DELETE")){
            if (obj && (!obj.datas && !obj.id)) {
                RestClientExceptions.unknowEntityId();
            } else {
                this.send('DELETE', key, null, obj, cb, cbr);
            }
        }
    };
    /**
     * Update object.
     * @public
     * @deprecated
     * @example var query = {
     *		'id':categorie[0].id,
     *		'libelleCatgorie': 'azertyuiopqsdfghjklmwxcvbn'
     *	};
     * @param {Function} cb callback for success
     * @param {Object} obj the object to update id must be present in this object
     */
    update(key, obj, cb) {
        /*if (!obj.id) {
            RestClientExceptions.unknowEntityId();
        } else {
            this.send('PUT', key + '/ids/' + obj.id, {}, obj, cb);
        }*/
        if(VGRoles.methods.can("UPDATE"))
        if (!obj.id) {
            RestClientExceptions.unknowEntityId();
        } else {
			// // //console.log("ELSE", key, obj);
            this.send('PUT', key + '/ids/' + obj.id, {}, JSON.stringify(obj), cb);
        }
    };
}
export default  RestClient;
