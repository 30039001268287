<template>

        <vg-app-layout
            :isLoadingState="isLoading"
            :colorheader="getHeaderColor"
            :isZoomLayout="true"
            :isActionButtonDisplay="false"
            @backpage="handleBackPage()" >
            <template #header-title>
                <div>
                    <span v-if="tache">{{tache.libel_tache}}</span>
                    <span v-if="equipement && equipement.lieu && tache.type_tache=='Verification_Lieu'">
                        {{$t("dans")}} 
                        <vg-lieu-path-viewer v-model="equipement.lieu.path" onlyPiece />
                    </span>
                </div>
            </template>
            <template #header-top-right-pre v-if="isEquipementsDisplayed">
                <img src="/static/assets/icone/mobile/scanner.png" width="28" height="28" @click="showScanner=true;"/>
            </template>
            <template #panel-menu-more>
                <vg-button
                    type="info"
                    @click="showTacheDescription = true">
                    {{$t('show-commentaire-tache')}}
                </vg-button>
            </template>
            <template #primary >
                <div class="main-container" v-if="equipement && tache" >
                    <div class="header">
                        <div class="description-equipement">
                            <span v-if="equipement.isGroupEqp!='1'">{{equipement.libel_equipement}}</span>
                            <small v-if="equipement.isGroupEqp!='1'">{{equipement.qrCode}}</small>
                            <vg-lieu-path-viewer v-model="equipement.lieu.path" :onlyPiece="equipement.isGroupEqp=='1'"/>
                        </div>
                        <img :src="equipement.categorie.icon" height="25"/>
                    </div>
                    <div class="search" ><!--v-if="hasTooManyReponses"-->
                        <vg-text-filter v-model="searchValue" />
                    </div>
                    <div class="checkpoints-list" >
                        <div v-if="can('RELEVE_COMPTEUR.CREATE_DATE_DEPASSEE') && tache.type_tache=='Relever_compteur'"
                            style="margin-top:20px;">
                            <vg-input v-model="verification.dateVerif"
                                :title="$t('date-releve')"
                                inline
                                isRequiredValue
                                inputType="datetime-local"
                                @input="onChangeVerificationDateVerif"/>
                        </div>
                        <div v-for="(rubrique, rubriqueName) in groupByRubrique(getReponses)" class="checkpoint" :key="rubrique">
                            <vg-collapse v-if="rubriqueName" :title="rubriqueName"
                                :collapseByDefault="false"
                                :killContentOnCollapse="false"
                                type="section"
                                style="margin-top: 10px;">
                                <template #titlecomplement>
                                    <span :class="{'counter-non-conforme': counterRubriqueNonConformite(rubrique)!=0}">{{counterRubriqueNonConformite(rubrique)}}</span> / {{rubrique.length}}
                                </template>
                                <template #content>
                                    <div v-for="(reponse, index) in rubrique" :key="index">

                                        <reponse-boolean
                                            v-if="reponse.type_reponse=='Oui/Non'"
                                            :tache="tache"
                                            :reponse="reponse"
                                            @select="onSelectReponse"
                                            @change="onChangeReponse(reponse, $event)" />
                                        <reponse-commentaire
                                            v-if="reponse.type_reponse=='Commentaire'"
                                            :tache="tache"
                                            :reponse="reponse"
                                            @select="onSelectReponse"
                                            @change="onChangeReponse(reponse, $event)" />
                                        <reponse-boolean-optionnelle
                                            v-if="reponse.type_reponse=='Oui/Non/NC'"
                                            :tache="tache"
                                            :reponse="reponse"
                                            @select="onSelectReponse"
                                            @change="onChangeReponse(reponse, $event)" />

                                        <reponse-nombre
                                            v-else-if="reponse.type_reponse=='Nombre' || reponse.type_reponse=='relever_usage_seuils'"
                                            :tache="tache"
                                            :reponse="reponse"
                                            @change="onChangeReponse(reponse, $event)"
                                            @select="onSelectReponse" />

                                        <reponse-index
                                            v-else-if="reponse.type_reponse=='Index'"
                                            :tache="tache"
                                            :reponse="reponse"
                                            @change="onChangeReponse(reponse, $event)"
                                            @select="onSelectReponse" />

                                        <reponse-consommation
                                            v-else-if="reponse.type_reponse=='consommation'"
                                            :tache="tache"
                                            :reponse="reponse"
                                            :checkpoint="reponse.checkpoint"
                                            @change="onChangeReponse(reponse, $event)"
                                            @select="onSelectReponse" />
                                    </div>
                                </template>
                            </vg-collapse>
                            <div v-else v-for="(reponse, index) in rubrique" :key="index">

                                <reponse-boolean
                                    v-if="reponse.type_reponse=='Oui/Non'"
                                    :tache="tache"
                                    :reponse="reponse"
                                    @select="onSelectReponse"
                                    @change="onChangeReponse(reponse, $event)" />
                                <reponse-commentaire
                                    v-if="reponse.type_reponse=='Commentaire'"
                                    :tache="tache"
                                    :reponse="reponse"
                                    @select="onSelectReponse"
                                    @change="onChangeReponse(reponse, $event)" />
                                <reponse-boolean-optionnelle
                                    v-if="reponse.type_reponse=='Oui/Non/NC'"
                                    :tache="tache"
                                    :reponse="reponse"
                                    @select="onSelectReponse"
                                    @change="onChangeReponse(reponse, $event)" />

                                <reponse-nombre
                                    v-else-if="reponse.type_reponse=='Nombre' || reponse.type_reponse=='relever_usage_seuils'"
                                    :tache="tache"
                                    :reponse="reponse"
                                    @change="onChangeReponse(reponse, $event)"
                                    @select="onSelectReponse" />

                                <reponse-index
                                    v-else-if="reponse.type_reponse=='Index'"
                                    :tache="tache"
                                    :reponse="reponse"
                                    @change="onChangeReponse(reponse, $event)"
                                    @select="onSelectReponse" />

                                <reponse-consommation
                                    v-else-if="reponse.type_reponse=='consommation'"
                                    :tache="tache"
                                    :reponse="reponse"
                                    :checkpoint="reponse.checkpoint"
                                    @change="onChangeReponse(reponse, $event)"
                                    @select="onSelectReponse" />
                            </div>
                        </div>
                        <vg-collapse v-if="isEquipementsDisplayed"
                            v-show="equipements && equipements.length!=0"
                            :title="$t('equipements')"
                            :collapseByDefault="false"
                            :killContentOnCollapse="false"
                            type="section"
                            style="width:inherit;margin-top: 10px;">
                            <template #titlecomplement>
                                <span :class="{'counter-non-conforme': counterEquipementsNonConformes!=0}">{{counterEquipementsNonConformes}}</span> / {{equipements.length}}
                            </template>
                            <template #content>
                                <vg-equipements-table v-if="tache && tache.type_tache=='Verification_Lieu'"
                                    :filters="equipementsFilters"
                                    :searchQuery="searchValue"
                                    isCheckLieu />
                            </template>
                        </vg-collapse>
                    </div>
                    <div class="last-check" v-if="lastReponse && lastVerificationReponses.length">
                        <div class="reponse">
                            {{$t("derniere-reponse")}} <b>{{lastVerificationReponses[0].reponse}}</b>
                        </div>
                        <div class="date">
                            {{$t("le-date")}}
                            <vg-datetime-viewer v-model="lastVerificationReponses[0].date_reponse" :format="'dddd MM/DD/YYYY HH:mm'"/>
                        </div>
                    </div>
                    <reponse-detail v-if="selectedReponse"
                        :reponse="selectedReponse"
                        @save="onSaveReponseDetail"
                        @close="selectedReponse = null" />
                </div>

                <vg-modal v-if="showTacheDescription"
                    :title="$t('commentaire-tache')"
                    @close="showTacheDescription=false;">
                    <template #body>
                        {{tache.commentaire_tache}}
                        <hr>
                        <h3>{{$t('documents')}}</h3>
                        <vg-files-viewer-list-section
                            :files="VgFilesMixins_filesListSection"
                            @delete="VgFilesMixins_fetchFilesByUid()">
                        </vg-files-viewer-list-section>
                    </template>
                    <template #footer>
                        <vg-button @click="showTacheDescription=false;">{{$t("close")}}</vg-button>
                    </template>
                </vg-modal>
                <vg-modal v-if="showMaintenancesModal"
                    :title="$t('create-new-maintenance')"
                    @close="showMaintenancesModal=false;">
                    <template #body>
                        <span style="color:#35b1ea;font-size:16px;">{{$t("maintenances-en-cours")}} :</span>
                        <fm-cell-render v-for="(maintenance, index) in filteredMaintenances" :key="index" :fm="maintenance"
                            style="border-bottom:1px solid whitesmoke;padding: 10px 0px;"/>
                    </template>
                    <template #footer>
                        <vg-button type="danger" @click="showMaintenancesModal=false;">{{$t("creer-nouvelle-maintenance")}}</vg-button>
                        <vg-button type="success" @click="setLastMaintenance">{{$t("ne-pas-creer-nouvelle-maintenance")}}</vg-button>
                    </template>
                </vg-modal>
                <vg-modal v-if="showModalConfirmVerification"
                    @close="showModalConfirmVerification=false;">
                    <template #header>
                        {{ $t("valider-non-conformites", {nombreNonConformites: verification.nbNonConformites}) }}
                    </template>
                    <template #body>
                        <vg-input :title="$t('spentTime')" class="spent-time">
                            <vg-input-number
                                v-model="verification.spentTime"
                                @input="onChangeNumber"
                            >
                            </vg-input-number>
                        </vg-input>
                        <vg-input
                            :inputType="'textarea'"
                            :title="$t('modal-confirmation--commenter-verification')"
                            v-model="verification.commentaire">
                        </vg-input>
                    </template>
                    <template #footer>
                        <vg-button :type="'default-danger'" style="width:50%" @click="showModalConfirmVerification=false;">{{$t("non")}}</vg-button>
                        <vg-button :type="'success'" style="width:50%;" @click="save">{{$t("oui")}}</vg-button>
                    </template>
                </vg-modal>
                <vg-modal v-if="showModalPrendrePhoto"
                    :title="$t('success')"
                    @close="handleCloseModalPrendrePhoto">
                    <template #body>
                        <h2 style="color: #50b156;">{{$t("verification-success")}}</h2>
                    </template>
                    <template #footer>
                        <vg-files-uploader style="width:50%;margin-right:5px;"
                            :inputLabel="$t('continuer-avec-photo')"
                            :isOnlyPhoto="true"
                            :isUnnamed="true"
                            :uidToUpload="verification.uid"
                            :defaultType="'verification'"
                            @upload-done="handleCloseModalPrendrePhoto">
                        </vg-files-uploader>
                        <vg-button style="width:50%;"
                            @click="handleCloseModalPrendrePhoto">
                            {{$t("continuer-sans-photo")}}
                        </vg-button>
                    </template>
                </vg-modal>
                <vg-modal v-if="showModalFichesCuratives"
                    :title="$t('fiches-curatives-creees')"
                    @close="onCloseModalFichesCuratives">
                    <template #body>
                        <vg-fichesav-table 
                            :filters="fichesCurativesFilters"
                            showCards
                            :showRelance="false"
                            :disabledRowClick="true"
                            affectable
                            canTakePhotos
                            :paginate="false"
                            @fetch-success="isLoading=false;"/>
                    </template>
                    <template #footer>
                        <vg-button type="default-success"
                            @click="onCloseModalFichesCuratives">
                            {{ $t("close") }}
                        </vg-button>
                    </template>
                </vg-modal>
            </template>

            <template #footer v-if="equipement && tache">
                <!--vg-button type="grey" @click="$router.go(-1)">
                    {{ $t("cancel") }}
                </vg-button-->
                <vg-button type="danger" style="width:100%;height: inherit;" 
                    :disabled="!verification.dateVerif"
                    @click="handleShowConfirmation">
                    {{ hasOnlyCheckpointsConsommations ? $t("valider") : $t("valider-non-conformites", {nombreNonConformites: verification.nbNonConformites}) }}
                </vg-button>
            </template>
            <vg-modal v-if="showScanner"
               :title="$t('scanner-une-etiquette')"
               @close="showScanner=false;">
               <template #body>
                   <zxing-scanner
                       :isDemo="false"
                       @scanSuccess="onScannSuccess" />
               </template>
               <template #footer>
                   <vg-button @click="showScanner=false;">
                       {{$t('close')}}
                   </vg-button>
               </template>
            </vg-modal>
            <vg-maintenance-form v-if="showMaintenanceForm"
                :displayedOpenButton="false"
                @close="onCloseMaintenanceForm"
                @created="onCreatedMaintenance" />
        </vg-app-layout>


</template>

<script>
import TachesMixins from "src/mixins/TachesMixins";
import ReponsesMixins from "src/mixins/ReponsesMixins";
import equipementsMixins from "src/mixins/equipementsMixins";
import VerificationMixins from "src/mixins/VerificationMixins";
import Metadatas from "src/services/Metadatas";

import moment from "moment";
import VgButton from 'src/components/Vg/VgButton.vue';
import VgInputNumber from 'src/components/Vg/VgInputNumber.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";

import VgEquipementsTable from "src/components/Vg/Equipements/VgEquipementsTable.vue";
import VgFichesavTable from "src/components/Vg/FicheSAV/VgFichesavTable.vue";
import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";
import VgMaintenanceForm from "src/components/Vg/Forms/VgMaintenanceForm.vue";


import ReponseBoolean from 'src/views/Verification/Check/components/ReponseBoolean.vue';
import ReponseBooleanOptionnelle from 'src/views/Verification/Check/components/ReponseBooleanOptionnelle.vue';
import ReponseNombre from 'src/views/Verification/Check/components/ReponseNombre.vue';
import ReponseCommentaire from 'src/views/Verification/Check/components/ReponseCommentaire.vue';
import ReponseIndex from 'src/views/Verification/Check/components/ReponseIndex.vue';
import ReponseConsommation from 'src/views/Verification/Check/components/ReponseConsommation.vue';

import ReponseDetail from 'src/views/Verification/Check/components/ReponseDetail.vue';
import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";

import FmCellRender from "src/components/Vg/TagGrid/FmCellRender.vue";

import zxingScanner from "src/components/QrCode/Zxing/zxingScanner.vue";

import { mapGetters } from 'vuex';
import Vapi from '@vapi-ai/web';

export default {
    name: "vg-process-verification",
    components: {
        ReponseBoolean,
        ReponseNombre,
        ReponseIndex,
        ReponseDetail,
        ReponseCommentaire,
        ReponseConsommation,
		VgLieuPathViewer,
        VgButton,
        VgFilesViewerListSection,
        VgInputNumber,
        VgInput,
        VgCollapse,
        VgTextFilter,
        VgFilesUploader,
        VgDatetimeViewer,
        ReponseBooleanOptionnelle,
        FmCellRender,
        VgEquipementsTable,
        zxingScanner,
        VgMaintenanceForm,
        VgFichesavTable
    },
    mixins: [TachesMixins, equipementsMixins, ReponsesMixins, VerificationMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "CheckpointsList--points-de-verifications": "point(s) de vérification(s)",
            "CheckpointsList--derniere-verification": "Dernière vérification",
            "CheckpointsList--aucune": "Aucune",
            "valider-non-conformites": "Valider {nombreNonConformites} non conformité(s)",
            "valider": "Valider",
            "derniere-reponse": "Dernière:",
            "show-commentaire-tache": "Voir description tâche",
            "commentaire-tache": "Description de la tâche",
            "cancel": "Annuler",
            "close": "Fermer",
            "documents": "Documents",
            "check": "Check",
            "joindre-photo": "Joindre une photo ?",
            "oui": "Oui",
            "non": "Non",
            "spentTime": "Estimation temps passé ( minutes )",
            "modal-confirmation--commenter-verification": "Ajouter un commentaire ( optionnel )",
            "prendre-photo-title": "Voulez-vous ajouter une photo ?",
            "verification-success": "Vérification validée",
            "success": "Finaliser cette tâche",
            "valeur-hors-seuil": "Valeur hors seuil",
            "typed-value": "Valeur saisie",
            "continuer-sans-photo": "Continuer",
            "continuer-avec-photo": "Photo",
            "le-date": "Le",
            "create-new-maintenance": "Créer nouvelle fiche curative?",
            "maintenances-en-cours": "Fiches curatives en cours",
            "creer-nouvelle-maintenance": "OUI nouvelle fiche curative",
            "ne-pas-creer-nouvelle-maintenance": "NON fiche curative existante",
            "equipements": "Equipements",
            "scanner-une-etiquette": "Scanner une étiquette",
            "dans": "dans",
            "date-releve": "Date du relevé",
            "fiches-curatives-creees": "Compléter les fiches curatives créées",
            "save": "Enregistrer"
        },
        "en": {
            "CheckpointsList--points-de-verifications": "checkpoint(s)",
            "CheckpointsList--derniere-verification": "Last checkpoint",
            "CheckpointsList--aucune": "None",
            "valider-non-conformites": "Validate {nombreNonConformites} non-compliance(s)",
            "valider": "Validate",
            "derniere-reponse": "Last:",
            "show-commentaire-tache": "Show task description",
            "commentaire-tache": "Task description",
            "cancel": "Cancel",
            "close": "Close",
            "documents": "Documents",
            "check": "Check",
            "joindre-photo": "Add a photo ?",
            "oui": "Yes",
            "non": "No",
            "spentTime": "Estimated spent time ( minutes )",
            "prendre-photo-title": "Do you want to add a photo ?",
            "verification-success": "Verification validated",
            "modal-confirmation--commenter-verification": "Write a commentary ( optional )",
            "success": "Terminate this task",
            "valeur-hors-seuil": "Out of range value",
            "typed-value": "Typed value",
            "continuer-sans-photo": "Continue",
            "continuer-avec-photo": "Photo",
            "le-date": "The",
            "create-new-maintenance": "Create new curative sheet?",
            "maintenances-en-cours": "Ongoing curative sheets",
            "creer-nouvelle-maintenance": "YES new sheet",
            "ne-pas-creer-nouvelle-maintenance": "NO existing curative sheet",
            "equipements": "Equipments",
            "scanner-une-etiquette": "Scan a label",
            "dans": "into",
            "date-releve": "Reading date",
            "fiches-curatives-creees": "Complete the created curative sheets",
            "save": "Save"
        }
    }
},
    props:{
        afterSaveRedirectTo:{
			type: String,
			default: null
		},
        backPageRedirectTo:{
			type: String,
			default: null
		}
    },
    data: function() {
        return {
            datenow: moment().format("YYYY-MM-DD HH:mm"),
			verification: {},
			lastVerificationReponses: [],
            maintenances: [],
			showTacheDescription: false,
            selectedReponse:null,
            showModalConfirmVerification:false,
            showModalPrendrePhoto:false,
            isLoading:false,
            tache:null,
            equipement:null,
            showMaintenancesModal: false,
            filteredMaintenances: [],
            focusedReponse: null,
            searchValue: null,
            showScanner: false,
            scannedEquipement: null,
            showMaintenanceForm: false,
            counterEquipementsNonConformes: 0,
            vapi:null,
            novaIsListening:false,
            showModalFichesCuratives: false,
            fichesCurativesIds: []
        }
    },
    watch: {},
    computed: {
        ...mapGetters({
            equipements: "EquipementsStore/getCollection"
        }),
        checkLieuTabs: function(){
            return [
                {label: this.$t("points-de-verification"), name:"checkpoints", counter: this.verification && this.verification.reponses?this.verification.reponses.length:"-", filters:[]},
                {label: this.$t("equipements"), name:"equipements", counter: this.equipements?this.equipements.length:"-", filters:[]}
            ];
        },
        equipementsFilters: function(){
            return {
                idLieu_id: {attr: "e.idLieu_id", value: this.equipement.idLieu_id, action: "equals"},
                isGroupEqp: {attr: "e.isGroupEqp", value: 1, action: "not_equals"}
            };
        },
        hasOnlyCheckpointsConsommations: function(){
            let checkpointsConsommations = this.verification.reponses.filter((reponse)=>reponse.type_reponse=="consommation");
            return checkpointsConsommations.length==this.verification.reponses.length;
        },
        lastReponse : function(){
            //return true;
            return (this.tache.type_tache=="Relever_compteur" || this.tache.type_tache=="Relever_Grandeur_physique") ? true : false;
        },
        /**
        * @return string
        */
        getHeaderColor: function(){
            return this.$vgutils.isMobile() ? '#f36f3a' : 'white';
        },
        getReponses: function(){
            let question = null;
            let composantType = null;
            let composantLabel = null;
            let searchQuery = this.searchValue && this.searchValue.length!=0?this.searchValue.toLowerCase():null;
            return this.verification.reponses.filter((reponse)=>{
                if(this.searchValue && this.searchValue.length!=0){
                    question = reponse.checkpoint.question.toLowerCase();
                    if(reponse.composant && reponse.composant.typeComposant) composantType = reponse.composant.typeComposant.toLowerCase();
                    else composantType = "";
                    if(reponse.composant && reponse.composant.libelComposant) composantLabel = reponse.composant.libelComposant.toLowerCase();
                    else composantLabel = "";
                    return question.search(searchQuery)!=-1 || composantType.search(searchQuery)!=-1 || composantLabel.search(searchQuery)!=-1;
                }else return true;
            });
        },
        hasTooManyReponses: function(){
            return this.verification.reponses.length>=10;
        },
        isEquipementsDisplayed: function(){
            return this.tache && this.tache.type_tache=="Verification_Lieu" && this.tache.isEquipementsDisplayed;
        },
        fichesCurativesFilters: function(){
            return  [
                {attr: "fm.id", value: this.fichesCurativesIds, action: "equals"}
            ];
        }
    },
    methods: {
        startAvA(){
            console.log("start Ava");
            return new Promise((resolve, reject)=>{

                this.vapi = new Vapi('80f26a73-fff3-404c-928c-9eb37fe3637e');
                    this.vapi.on('speech-start', () => {
                        console.log('Speech has started');
                    });
    
                    this.vapi.on('speech-end', () => {
                        console.log('Speech has ended');
                    });
    
                    this.vapi.on('call-start', () => {
                        console.log('Call has started');
                        this.sendVerificationToAvA();
                    });
    
                    this.vapi.on('call-end', (conversation) => {
                        console.log('Call has stopped',{conversation});
                        this.novaIsListening = false;

                    });
    
                    // Function calls and transcripts will be sent via messages
                    this.vapi.on('message', (message) => {
                        if(message.type = "conversation-update"){
                            console.log(message);
                        }
                    });
    
                    this.vapi.on('error', (e) => {
                        console.error(e)
                    });

                    this.vapi.start('daaa3988-2355-492f-bbbc-565b330a0c54');
            });
        },
        onChangeVerificationDateVerif: function(dt){
            let dateVerif = this.verification.dateVerif;
            this.verification.reponses.forEach((reponse, index)=>{
                this.verification.reponses[index].date_reponse = dateVerif;
            });
        },
        counterRubriqueNonConformite: function(rubrique){
            let counter = 0;
            rubrique.forEach((reponse)=>{
                if(this.isNonCompliantReponse(reponse)) counter++;
            });
            return counter;
        },
        onCloseMaintenanceForm: function(){
            this.showMaintenanceForm = false;
            this.scannedEquipement = null;
            this.$store.dispatch("EquipementsStore/deleteSelectedItem");
        },
        onCreatedMaintenance: function(){
            this.counterEquipementsNonConformes++;
            this.$store.dispatch("EquipementsStore/addSelectedItems", [this.scannedEquipement]);
            this.showMaintenanceForm = false;
            this.scannedEquipement = false;
            this.$store.dispatch("EquipementsStore/deleteSelectedItem");
        },
        onScannSuccess: function(code){
            let scannedEquipement = this.equipements.find((equipement)=>equipement.qrCode==code);
            this.$store.dispatch("EquipementsStore/setSelectedItem", scannedEquipement);
            this.scannedEquipement = scannedEquipement;
            this.showMaintenanceForm = true;
            this.showScanner = false;
        },
        /**
         * @param array reponses
         * @return object
         */
        groupByRubrique:function(reponses){
            let result = {};
            if(!reponses)return result;
            reponses.forEach((current)=>{
                if(current.rubrique){
                    let rubrique = current.rubrique.charAt(0).toUpperCase() + current.rubrique.slice(1);
                    if(!result.hasOwnProperty(rubrique)) result[rubrique] = [];
                    result[rubrique].push(current);
                }else{
                    if(!result.hasOwnProperty("")) result[""] = [];
                    result[""].push(current);
                }
            });
            return result;
        },
        onSaveReponseDetail: function(reponse){
            let indexReponseInVerification = this.verification.reponses.findIndex((r)=>r.idChk_id == reponse.idChk_id);
            this.verification.reponses[indexReponseInVerification] = Object.assign({}, this.verification.reponses[indexReponseInVerification], reponse);
            this.selectedReponse = null;
        },
        handleBackPage:function(){
            if(this.backPageRedirectTo) this.$router.push({name: this.backPageRedirectTo});
            else this.$router.go(-1);
        },
        handleShowConfirmation:function(){
            this.verification.reponses = this.checkCompliance(this.verification.reponses);
            this.showModalConfirmVerification = true;
        },
        /**
         * @param object reponse
         * @return boolean
         */
        isNonCompliantReponse:function(reponse){
            let min = null;
            let max = null;
            if(reponse.type_reponse=="Oui/Non") return reponse.reponse=="Non";
            else if (reponse.type_reponse=="Nombre" || reponse.type_reponse=="Index" ){
                min = Number(reponse.checkpoint.minVal);
                max = Number(reponse.checkpoint.maxVal);
                if(min&& max) return !(min <= reponse.reponse && reponse.reponse <= max);
                else if(!min && max) return !(reponse.reponse <= max);
                else if(min && !max) return !(min <= reponse.reponse);
                else return false;
            }else if(reponse.type_reponse=="consommation") return false;
            else return false;
        },

        getLastVerificationReponse: function(){
            let directives = [];
            let tacheId = this.$route.params["tacheId"];
            let equipementId = this.$route.params["equipementId"];
            let taches  = this.equipement.taches.filter(verif => verif.idTache == tacheId );
            if(taches.length && taches[0]["id"]){
                let filters = [
                    {"attr":"t.id", "colId": "t.id", "value": taches[0]["idTache"], "action":"equals"},
                    {"attr":"eq.id", "colId": "eq.id", "value": equipementId, "action":"equals"}
                ]
                this.ReponsesMixins_getReponses(filters, directives).then((reponses)=>{
                    this.lastVerificationReponses = reponses;
                    this.init();
                });
            }else{
                this.init();
            }

        },
        calculNbNonConformites: function(){
            var reponses = this.verification.reponses;
            this.verification.nbNonConformites = reponses.filter(reponse => this.isNonCompliantReponse(reponse)).length;
		},
        /**
         * @param array reponses
         *
         */
        checkCompliance:function(reponses){
            reponses.forEach((reponse, i) => {
                // let the server check compliance if it is a relever_usage_seuils
                if(reponses[i]['type_reponse'] != "relever_usage_seuils"){
                    reponses[i].isCompliant = !this.isNonCompliantReponse(reponse);
                }
            });
            return reponses;
        },
        setMaintenancesLieesReponse: function(reponse){
            this.filteredMaintenances = this.maintenances.filter((maintenance)=>{
                if(this.tache.type_tache=="Verification_Lieu") return maintenance.composant_id==reponse.checkpoint.composant_id;
                else return true;
            });
        },
        setLastMaintenance: function(){
            let checkpointId = this.focusedReponse.checkpoint.id;
            let indexReponse = this.verification.reponses.findIndex((reponse)=>reponse.checkpoint.id==this.focusedReponse.checkpoint.id);
            let lastReponse = this.lastVerificationReponses.find((reponse)=>reponse.idChk_id==this.focusedReponse.checkpoint.id);
            if(indexReponse!=-1 && lastReponse && lastReponse.idFicheSAV) this.verification.reponses[indexReponse].idFicheSAV = lastReponse.idFicheSAV;
            else if(indexReponse!=-1 && (!lastReponse || !lastReponse.idFicheSAV)) this.verification.reponses[indexReponse].idFicheSAV = this.filteredMaintenances[0].id;
            this.showMaintenancesModal = false;
        },
		onChangeReponse: function(reponse, $event){
            console.log("CHANGE REPONSE", reponse, $event);
            this.filteredMaintenances = [];
            this.focusedReponse = reponse;
            if(this.isNonCompliantReponse(reponse)){
                this.setMaintenancesLieesReponse(reponse);
                if(this.filteredMaintenances.length!=0 && this.tache.type_tache=="Verification_Lieu") this.showMaintenancesModal = true;
            }
			this.calculNbNonConformites();
		},
		onSelectReponse: function(e){
            console.log("SELECT REPONSE", e);
			this.calculNbNonConformites();
			this.selectedReponse = e;
		},
		generateVerification: function(){
			this.verification = {	// POST /verification
				equipement_id: this.equipement.id,
				tache_id: this.tache.id,
				spentTime: this.tache.averageTime,
				dateVerif: this.datenow,
				userId: this.$app.appID,
				username: this.$app.prenom+" "+this.$app.nom,
				nbNonConformites: 0,
				commentaire: null,
				reponses: []
			};
		},
        sendVerificationToAvA(){
            console.log("send verif to AvA");
            let checkpointsReponse = this.verification.reponses.map((reponse)=>{
                return {question: reponse.checkpoint.question, reponse: reponse.reponse, type_reponse: reponse.checkpoint.type_reponse, commentaire : reponse.commentaire};
            });
            this.vapi.send({
                    type: "add-message",
                    message: {
                        role: "system",
                        content: checkpointsReponse.toString()
                    },
                });
        },
		generateReponses: function(){
			var checkpoints = this.tache.checkpoints;
			checkpoints.forEach((checkpoint)=>{

				this.verification.reponses.push( this.generateReponse(checkpoint) );
			});
            //this.sendVerificationToAvA();
		},
        getDefaultReponse: function(checkpoint){
            if(this.tache.type_tache=="Verification_Lieu" || this.tache.type_tache=="Verification_equipement"){
                if(checkpoint.type_reponse=="Oui/Non") return "Oui";
                else if(checkpoint.type_reponse=="relever_usage_seuils"){
                    if(this.lastVerificationReponses.length){
                        return this.lastVerificationReponses[0].reponse;
                    }else{
                        return 0;
                    }
                }
                else if(checkpoint.type_reponse=="Nombre") return 0;
                else if(checkpoint.type_reponse=="Index") return 0;
                else return null;
            }else{
                if(this.lastVerificationReponses.length){
                    return this.lastVerificationReponses[0].reponse;
                }
                if(checkpoint.type_reponse=="Oui/Non") return checkpoint.reponse || "Oui";
                else if(checkpoint.type_reponse=="Nombre") return checkpoint.reponse || 0;
                else if(checkpoint.type_reponse=="Index") return checkpoint.reponse || 0;
                else return checkpoint.reponse || null;
            }
        },
        /**
         * @param object checkpoint
         */
        getMessageOnVerifError:function(checkpoint){
            let message = "";
            if(checkpoint.type_reponse == "Nombre"){
                let min = checkpoint.minVal ? "min : " + checkpoint.minVal : "";
                let max = checkpoint.maxVal ? "max : " + checkpoint.maxVal : "";
                message = `${this.$t("valeur-hors-seuil")} ${min} ${max}`;
            }else{
                message = checkpoint.messageOnVerifError
            }
            return message;
        },
		generateReponse: function(checkpoint){
			let reponse = {};
            reponse = Object.assign(reponse, {
                type_reponse: checkpoint.type_reponse,
                rubrique: checkpoint.rubrique,
                defaultMessageOnVerifError: this.getMessageOnVerifError(checkpoint),
				date_reponse: moment().format("YYYY-MM-DD HH:mm"),
				reponse: this.getDefaultReponse(checkpoint), //checkpoint.reponse || "Oui",
				commentaire: null,
				idEquipement: this.equipement.id,
                idFicheSAV: null,
				dateVerifButoire: this.tache.dateprochaineVerif,
				userId: this.$app.appID,
				username: this.$app.prenom+" "+this.$app.nom,
                idChk_id: checkpoint.id,
                composant: {
                    "id": checkpoint.composant_id,
                    "libelComposant": checkpoint.composant.libelComposant,
                    "typeComposant": checkpoint.composant.typeComposant,
                    "icon": checkpoint.composant.icon
                },
                checkpoint: checkpoint,
            });
            return reponse;
		},
        handleCloseModalPrendrePhoto:function(){
            if(this.afterSaveRedirectTo) this.$router.push({name: this.afterSaveRedirectTo});
            else this.handleBackPage();
        },
        openModalFichesCuratives: function(){
            this.showModalFichesCuratives = true;
        },
        init: function(){
            this.generateVerification();
            this.generateReponses();
            this.calculNbNonConformites();
            this.isLoading = false;
		},
        fetch:function(){
            this.isLoading = true;
            let equipementId = this.$route.params["equipementId"];
            let tacheId = this.$route.params["tacheId"];
            this.VerificationMixins_startVerification(equipementId, tacheId).then((payload)=>{
                /*this.$store.dispatch("TachesStore/set", payload["tache"]);
                this.$store.dispatch("EquipementsStore/set", payload["equipement"]);*/
                this.tache = payload["tache"];
                this.equipement = payload["equipement"];
                this.lastVerificationReponses = payload["previousSavedResponse"];
                this.maintenances = payload["maintenances"];
                //this.getLastVerificationReponse();
                this.VgFilesMixins_init(payload["tache"]["uid"], "tache");
                this.init();
            });
            
            /*this.tachesMixins_getTache(tacheId).then(()=>{
                this.equipementsMixins_getEquipement(equipementId).then(()=>{
                    this.getLastVerificationReponse();
                    this.VgFilesMixins_init(this.tache.uid, "tache");
                });
            });*/
        },
        save:function(){
            this.isLoading = true;

            this.showModalConfirmVerification = false;
            let verifications = {
                "datas": [
                    this.verification
                ]
            };
            this.verification["reponses"].forEach((reponse, i) => {
                if(reponse.type_reponse == "Nombre"){
                    reponse.defaultMessageOnVerifError += " - " + this.$t("typed-value") + " "+ reponse.reponse
                }
            });

            this.VerificationMixins_createVerifications(verifications).then((result)=>{
                this.verification.uid = result[0]["uid"];
                this.verification.id = result[0]["id"];
                console.log("AFTER SAVE VERIFICATION", result);
                this.fichesCurativesIds = this.getFichesCurativesIds(result[0]);
                if(this.tache.affectationMaintenancesAfterVerification==true && this.fichesCurativesIds && this.fichesCurativesIds.length!=0){ 
                    this.openModalFichesCuratives();
                }else{
                    if(parseInt(this.tache.prisePhotoAfterVerification)){
                        this.showModalPrendrePhoto = true;
                    }else{
                        this.handleCloseModalPrendrePhoto();
                    }
                    this.isLoading = false;
                }
            });
        },
        onCloseModalFichesCuratives: function(){
            this.showModalFichesCuratives = false;
            if(parseInt(this.tache.prisePhotoAfterVerification)){
                this.showModalPrendrePhoto = true;
            }else{
                this.handleCloseModalPrendrePhoto();
            }
        },
        getFichesCurativesIds: function(verification){
            let fichesCurativesIds = [];
            verification.reponses.forEach((reponse)=>{
                if(reponse.idFicheSAV) fichesCurativesIds.push(reponse.idFicheSAV);
            });
            return fichesCurativesIds;
        },
        initListenerToStartAva(){
            console.log("init listener for AvA");
            document.addEventListener('keydown', (e) => {
                if (!this.novaIsListening && e.ctrlKey) { // 120 is the keycode for F9
                    this.novaIsListening = true;
                    this.startAvA();
                }
            });
        }
    },
    created: function() {
        console.log("created")
        this.initListenerToStartAva();
        this.fetch();
    },
    beforeDestroy() {
        // Remove the event listener before the component is destroyed
        window.removeEventListener('keydown', this.initListenerToStartAva);
    },
}
</script>

<style lang="scss" scoped>
.main-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 10px;
    .header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        width: 100%;
        margin-bottom: 10px;
        .description-equipement{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2px;
            *:first-child{
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
    .search{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .checkpoints-list{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        width: 100%;
        border-top: 1px solid #d7d7d9;
        .checkpoint{
            width: 100%;
        }
    }
    .last-check{
        text-align: center;
        div{
            margin-bottom: 10px;
        }
        .reponse{
            b{
                font-size: 15px;
            }
        }
        .date{
            font-size: 13px;
        }
    }
    .rubrique{
        border-bottom: solid 1px rgb(68, 68, 68);
    }
}
.counter-non-conforme{
    color: red;
}
</style>
