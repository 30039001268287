<template>
    <vg-app-layout
        :title="$t('page-title')"
        :icon="getHeaderTitle"
        :colorheader="getHeaderColor"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters"
        @global-filters-change="onGlobalFiltersChange">

      <template #search>
        <vg-text-filter @input="onInputSmartFilter" />
      </template>

      <template #header-bottom-left v-if="selectedLieux.length">
          <span v-if="selectedLieux && selectedLieux[0].type_lieu=='Piece'">
              {{$t("pieces-selected", {nPieces: selectedLieux.length})}}
          </span>
          <span v-else-if="selectedLieux && selectedLieux[0].type_lieu=='Site'">
              {{$t("sites-selected", {nSites: selectedLieux.length})}}
          </span>
          <vg-button :type="'default'" style="margin-left: 20px;" @click="showUpdateLieuxMultiple=true;">
              {{$t("update")}}
          </vg-button>
      </template>

      <template #body v-if="$vgutils.isMobile()">
        <vg-sites-table
          style="height: 100% !important"
          :displayMap="isMapSiteDisplayed"
          :filters="agfilters" />
      </template>

      <template #panel-filters-attributs>
        <vg-input :title="$t('famille-lieu')" >
            <vg-lieu-famille-selector v-model="agfilters.field_famille.value"
                onlyExisting />
        </vg-input>
      </template>
    </vg-app-layout>
</template>

<script>
import TagGrid from "src/components/Grid/TagGrid.vue";

import VgButton from "src/components/Vg/VgButton.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgCollapse from "src/components/Vg/VgCollapse.vue";

import VgSitesTable from "src/components/Vg/Lieu/VgSitesTable.vue";

import VgLieuFamilleSelector from "src/components/Vg/Selectors/VgLieuFamilleSelector.vue";

import VgTextFilter from "src/components/Vg/VgTextFilter.vue";

import { mapGetters } from "vuex";
export default {
    name: "Lieux",
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "page-title": "Sites",
            "famille-lieu": "Famille"
        },
        "en": {
            "page-title": "Sites",
            "famille-lieu": "Family"
        }
    }
},
    components: {
        TagGrid,
        VgTextFilter,
        VgButton,
        VgSelect,
        VgInput,
        VgCollapse,
        VgSitesTable,
        VgLieuFamilleSelector
    },
    mixins: [],
    props:{
        defaultFilters:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    data: function () {
        return {
            agfilters: {
                libel_lieu: {attr: "l.libel_lieu", value: null, action: "contains", openParenthesis: true},
                famille: {attr: "l.famille",colId: "l.famille",value: null,action: "contains",logicalOperator: "OR", closeParenthesis: true},
                field_famille: {attr: "l.famille",colId: "l.famille",value: null,action: "equals"}
            },
            gridOptions: {
                headerHeight: this.$vgutils.isMobile() ? 0 : 40,
                rowHeight: this.$vgutils.isMobile() ? 84 : 40
            },
            searchQuery: "",
            isMapSiteDisplayed: false
        };
    },
    computed: {
        ...mapGetters({
            counters: 'LieuxStore/getCounters',
            selectedLieux: 'LieuxStore/getSelectedItems'
        }),
        pageFilters: function(){
            return ["field_famille"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getHeaderColor: function () {
            return this.$vgutils.isMobile() ? "rgb(108, 58, 158)" : "white";
        },
        getHeaderTitle: function () {
            return this.$vgutils.isMobile() ? "lieu-blanc.png" : "lieu.png";
        }
    },
    watch: {
        searchQuery: {
            handler: function (query) {
                if (query != null && query.length) {
                    this.agfilters.libel_lieu.value = query;
                    this.agfilters.famille.value = query;
                }else{
                    this.agfilters.libel_lieu.value = null;
                    this.agfilters.famille.value = null;
                }
            }
        },
        agfilters: {
            handler: function (nfilters) {
                
            },
            deep: true
        }
    },
    created: function () {
        this.agFilters = Object.assign({}, this.agFilters, this.defaultFilters);
    },
    mounted: function () {},
    methods: {
        onGlobalFiltersChange: function(filters){
            this.onRemoveFilters();
            this.agFilters = Object.assign({}, this.agFilters, filters);
        },
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        onInputSmartFilter: function (input) {
            this.agfilters.famille.value = input;
            this.agfilters.libel_lieu.value = input;
        }
    },
    destroyed:function(){
        this.$store.dispatch("LieuxStore/deleteSelectedItems");
    }
};
</script>

<style lang="scss" scoped>

</style>
