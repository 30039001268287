<template lang="html">
    <div @click="onFocusNewPiece" class="formulaire-creation-piece">

        <vg-pieces-form
            class="formulaire-creation-piece" style="height:50vh;gap: 4px;"
            v-model="piece"
            :etage="etage"
            :multiple="false"
        >
        </vg-pieces-form>


    </div>
</template>

<script>
import VgInput from "src/components/Vg/VgInput.vue";
import VgCheckbox from "src/components/Vg/VgCheckbox.vue";

import VgCategorieSelector from "src/components/Vg/Selectors/VgCategorieSelector.vue";
import VgServiceSelector from "src/components/Vg/Selectors/VgServiceSelector.vue";
import VgPiecesForm from "components/Vg/Forms/VgPiecesForm.vue";
import { mapGetters } from 'vuex';

export default {
    name: "vg-piece-marker-create",
    components:{
        VgInput,
        VgCheckbox,
        VgCategorieSelector,
        VgServiceSelector,
        VgPiecesForm
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "categorie": "Catégorie",
            "racine-1": "Racine 1",
            "racine-2": "Racine 2",
            "numero-auto": "Numéro auto.",
            "text-libelle": "Texte libellé",
            "click-sur-plan-pour-valider": "Clic sur le plan pour valider",
            "checkbox-valeur-qrcode-egal-libelle": "Valeur QRCODE = libellé pièce?",
            "qrcode": "QRCODE",
            "libel-piece": "Libellé pièce",
            "peut-etre-vide": "(peut être vide)",
            "service": "Service"
        },
        "en": {
            "click-plan-validation": "Click on the map to validate",
            "qrcode": "QRCODE",
            "libel-piece": "Room label",
            "peut-etre-vide": "(can be empty)"
        }
    }
},
    props: {

    },
    data:function(){
        return {
            piece: {
                libel_lieu: null,
                prefixA: "",
                prefixB: "",
                derniersNumeroConnu: null,
                libel: "",
                numAISuffix: null,
                categorie: null,
                codeUn: null,
                service: null
            },
            isQrCodeEqualLibellePiece: true
        }
    },
    watch:{
        piece: {
            handler: function(value){
                console.log("WATCH PIECE CHANGE", this.piece);
                if(this.piece.libel_lieu && this.piece.codeUn && this.piece.categorie){    // IE tous les attributs required sont nourrit
                    if(this.pieces.findIndex((p)=>p.codeUn==this.piece.codeUn || p.libel_lieu==this.piece.libel_lieu)!=-1){   // IE pièce existe déjà
                        alert("Piece existante");
                    }else{
                        this.$store.dispatch("PlanInteractifStore/setNewPieceMarker", {
                            libel_lieu: this.getLibelPiece,
                            idLieuParent_id: this.etage.id,
                            codeUn: this.piece.codeUn,
                            categorie: this.piece.categorie,
                            service: this.piece.service,
                            type_lieu: "Piece",
                            userId: this.$app.appID,
                            coordX: null,
                            coordY: null
                        });
                    }
                }else{
                }

                // @WARNING PS: il ne faut pas oublier de supprimer newPiece si déjà set dans la map (set dans la map = coordX && coordY existe)
            },
            deep: true
        },
        newPieceMarker: {
            handler: function(pieceMarker, oldPieceMarker){
                
                if(pieceMarker.coordX && pieceMarker.coordX!=oldPieceMarker.coordX && pieceMarker.coordY && pieceMarker.coordY!=oldPieceMarker.coordY){ // cas piece a été ajoutée cf VGPI onMapClick
                    // @TODO auto increment derniersNumeroConnu
                    if(this.piece.derniersNumeroConnu && this.piece.derniersNumeroConnu.length!=0){
                        let nbDigitsderniersNumeroConnu = this.piece.derniersNumeroConnu.toString().length;   // 003 -> 3 digits
                        let numeroIncrement = parseInt(this.piece.derniersNumeroConnu)+1;    // 4 -> 1 digits
                        numeroIncrement = numeroIncrement.toString();
                        if(numeroIncrement.length<nbDigitsderniersNumeroConnu)   // si il manque des digits on les ajoute
                            this.piece.derniersNumeroConnu = numeroIncrement.padStart(nbDigitsderniersNumeroConnu, "0");
                        else this.piece.derniersNumeroConnu = numeroIncrement;
                    }
                    if(this.piece.numAISuffix && this.piece.numAISuffix.length!=0){
                        let nbDigitsderniersNumeroConnu = this.piece.numAISuffix.toString().length;   // 003 -> 3 digits
                        let numeroIncrement = parseInt(this.piece.numAISuffix)+1;    // 4 -> 1 digits
                        numeroIncrement = numeroIncrement.toString();
                        if(numeroIncrement.length<nbDigitsderniersNumeroConnu)   // si il manque des digits on les ajoute
                            this.piece.numAISuffix = numeroIncrement.padStart(nbDigitsderniersNumeroConnu, "0");
                        else this.piece.numAISuffix = numeroIncrement;
                    }
                    this.refreshQrCode();
                    console.log("WATCH newPieceMarker", this.piece);
                }
            },
            deep: true
        }
    },
    methods:{
        onChangeCategoriePiece: function(categorie){
            this.piece.categorie = categorie;
            this.piece.libel = categorie.libelleCatgorie;
            //this.refreshQrCode();
        },
        refreshQrCode: function(){
            if(this.isQrCodeEqualLibellePiece){
                this.piece.codeUn = this.getLibelPiece;
            }else{
                this.piece.codeUn = "VLGL"+moment().valueOf();      // timestamp in millisecond
            }
        },
        onFocusNewPiece: function(){
            /*if(this.isPieceSelected) {
                this.isPieceSelected = false;
                this.piece = Object.assign({}, {}, this.tmpPiece);
            }*/
        },
    },
    computed:{
        ...mapGetters({
            newPieceMarker: "PlanInteractifStore/getNewPieceMarker",
            etage: "PlanInteractifStore/getEtage",
            pieces: "LieuxStore/getCollection"
        }),
        getLibelPiece: function(){
            let libel = "";
            if(this.piece.prefixA && this.piece.prefixA.length!=0) libel += this.piece.prefixA;
            if(this.piece.prefixB && this.piece.prefixB.length!=0){
                if(libel.length!=0 && libel[libel.length-1]!="-") libel += "-";
                libel += this.piece.prefixB;
            }
            if(this.piece.derniersNumeroConnu && this.piece.derniersNumeroConnu.length!=0){
                if(libel.length!=0 && (!this.piece.prefixB || this.piece.prefixB.length==0) && libel[libel.length-1]!="-") libel += "-";
                libel += this.piece.derniersNumeroConnu;
            }
            if(this.piece.categorie && this.piece.categorie.libelleCatgorie.length!=0){
                if(libel.length!=0 && libel[libel.length-1]!="-") libel += "-";
                libel += this.piece.libel_lieu;
            }
            if(this.piece.numAISuffix && this.piece.numAISuffix.length!=0){
                //if(libel.length!=0 && libel[libel.length-1]!="-") libel += "-";
                let numAISuffix = parseInt(this.piece.numAISuffix)
                libel += " "+numAISuffix;
            }
            return libel;
        }
    },
    mounted: function(){

    }
}
</script>

<style lang="scss" scoped>
.formulaire-creation-piece{
    flex-grow:4;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    gap:10px;
    padding:10px 0;
}
</style>
