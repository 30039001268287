function AffectationFilter() {
}

AffectationFilter.prototype.init = function (params) {
    this.valueGetter = params.valueGetter;
    this.filterText = null;
    this.setupGui(params);
};

// not called by ag-Grid, just for us to help setup
AffectationFilter.prototype.setupGui = function (params) {

	var affectations = params.column.colDef.filterParams.values;
	//// //console.log("AffectationFilter DATAS", affectations);

	this.gui = document.createElement("div");
	var html = '<div style="padding: 4px;">'
		+'<select id="affectationSelector" style="min-width:100px;">'
		+'<option value="" disabled selected>Choose</option>';
    html += '<option value="Aucune">Aucune</option>';
	for(var i=0; i<affectations.length; i++){
		html += '<option value="'+affectations[i]+'">'+affectations[i]+'</option>';
	}
	html += '</select>'
		+'<i class="fas fa-eraser" style="margin-left: 4px;cursor: pointer;" id="removeAffectationFilter"></i>'
		+'</div>';

	this.gui.innerHTML = html;

	this.eAffectationSelector = this.gui.querySelector("#affectationSelector");
	this.eAffectationSelector.addEventListener("change", (event) => {
		//// //console.log("CHANGE", this.filterText);
		this.filterText = event.target.value;

        params.filterChangedCallback();
	});
	this.eRemoveFilter = this.gui.querySelector("#removeAffectationFilter");
	this.eRemoveFilter.addEventListener("click", (event) => {
		this.gui.querySelector("#affectationSelector").value = null;
		//// //console.log("REMOVE", this.filterText);
		this.filterText = null;
        params.filterChangedCallback();
	});
};

AffectationFilter.prototype.getGui = function () {
    return this.gui;
};

AffectationFilter.prototype.doesFilterPass = function (params) {
    // make sure each word passes separately, ie search for firstname, lastname
	var valueGetter = this.valueGetter;
	var value = valueGetter(params);
	//// //console.log("DOES FILTER PASS", valueGetter, value, this.filterText);
	var passedForUsers = false;
	var passedForTiers = false;
	var passedForAucune = false;
	if(value.users) passedForUsers = value.users.filter( (user) => user.nom+" "+user.prenom==this.filterText ).length!=0;
	if(value.tiers) passedForTiers = value.tiers.filter( (tier) => tier.name==this.filterText ).length!=0;
    if(this.filterText=="Aucune" && !value.users && !value.tiers) passedForAucune = true;
	return passedForUsers || passedForTiers || passedForAucune;
};

AffectationFilter.prototype.isFilterActive = function () {
    return this.filterText !== null && this.filterText !== undefined && this.filterText !== '';
};

AffectationFilter.prototype.getModel = function() {
    var model = {value: this.filterText.value};
    return model;
};

AffectationFilter.prototype.setModel = function(model) {
    this.eFilterText.value = model.value;
};

export default AffectationFilter;
