<template>
    <div v-if="params.data.flag=='manuelle' || !params.data.flag || params.data.flag==''">
        <vg-button @click="handleUpdate" :type="'link'" :size="'sm'"><i class="fas fa-pen"></i></vg-button>
        <vg-button @click="handleDelete" :type="'link'" :size="'sm'"><i class="fas fa-trash-alt"></i></vg-button>
    </div>
    <div v-else-if="params.data.flag=='consommation'">
        <vg-button @click="handleOpenConsommable" :type="'success'" :size="'sm'">{{$t("consommable-voir-consommable")}}</vg-button>
        <vg-button @click="handleDeleteConsommation" :type="'link'" :size="'sm'"><i class="fas fa-trash-alt"></i></vg-button>
    </div>
    <div v-else-if="params.data.flag=='intervention-reserve'">
        <vg-button @click="onOpenReserveIntervention" type="link" :size="'sm'">{{$t("intervention-voir")}}</vg-button>
    </div>
    <div v-else-if="params.data.flag=='tache' || params.data.flag=='a_prevoir'">
        <vg-button @click="onOpenUpdateOperation" :type="'info'" :size="'sm'">{{$t("commenter")}}</vg-button>
        <vg-button @click="handleUpdate" :type="'link'" :size="'sm'"><i class="fas fa-pen"></i></vg-button>
        <vg-button @click="handleDelete" :type="'link'" :size="'sm'"><i class="fas fa-trash-alt"></i></vg-button>
    </div>
    <div v-else-if="params.data.flag=='remplacement-equipement'">
        <vg-button @click="openEquipementRemplacant" type="grey" size="sm">{{$t("voir-equipement-remplacant")}}</vg-button>
    </div>
    <div v-else-if="params.data.flag=='tiersinterventionponctuelle'">
        <!--vg-button type="info" size="sm" @click="handleOpenIntervention">{{$t("intervention-voir")}}</vg-button-->
        <vg-button type="default-danger" size="sm" @click="handleUpdateIntervention"><i class="fas fa-pen"></i></vg-button>
        <vg-button type="link" size="sm" @click="handleShowInterventionDocuments">{{$t("intervention-voir-documents")}}</vg-button>
    </div>
</template>

<script>
    import Vue from "vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";

    export default Vue.extend({
        name: "actions-operation-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components: {
            VgButton
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "consommable-voir-consommable": "Voir consommable",
            "intervention-voir": "Voir intervention",
            "commenter": "Commenter",
            "intervention-voir-documents": "Voir documents",
            "voir-equipement-remplacant": "Voir équipement remplaçant"
        },
        "en": {
            "consommable-voir-consommable": "See consumable",
            "intervention-voir": "See work",
            "commenter": "Feedback",
            "intervention-voir-documents": "Show files",
            "voir-equipement-remplacant": "Show equipment replacement"
        },
        "th": {
            "consommable-voir-consommable": "ดูวัสดุสิ้นเปลือง",
            "intervention-voir": "ดูการทำงาน"
        }
    }
},
        methods:{
            openEquipementRemplacant: function(){
                this.$emit("show-equipement", this.params.data.sortieEquipement.equipementRemplacant);
            },
            onOpenUpdateOperation: function(){
                this.$emit("update-commentaire", this.params.data);
            },
            handleUpdate: function(){
                this.$emit("update", this.params.data);
            },
            handleDelete: function(){
                this.$emit("delete", this.params.data);
            },
            handleOpenConsommable: function(){
                this.$emit("consommable-show", this.params.data);
            },
            handleDeleteConsommation: function(){
                this.$emit("consommation-delete", this.params.data);
            },
            handleOpenIntervention: function(){
                this.$emit("intervention-show", this.params.data);
            },
            handleUpdateIntervention: function(){
                this.$emit("intervention-update", this.params.data);
            },
            handleShowInterventionDocuments: function(){
                this.$emit("intervention-show-documents", this.params.data);
            },
            onOpenReserveIntervention: function(){
                this.$emit("intervention-reserve-show", this.params.data);
            }
        }
    });
</script>

<style scoped>

</style>
