<template >
    <v-select class="vg-v-select"
        v-model="selected"
        :label="attributeLabel"
        :options="optionsList"
        :placeholder="getPlaceholder"
        :disabled="disabled"
        :clearable="clearable"
        :filterBy="filterBy"
        :multiple="multiple"
        append-to-body
        :calculate-position="withPopper"
        @input="handleSelectedChange"
        @close="$emit('close')">
        <template v-slot:option="option">
                    <div :class="['vg-select-option',{'check': isOptionIsSelected(option)}]"  v-if="multiple">
                        <div class="name-option">
                            <!-- @slot Use this slot v-slot:option -->
                            <slot :option="option">
                                <slot name="option" :option="option">
                                    {{ getLabel(option) }}
                                </slot>
                            </slot>
                        </div>
                        <div v-if="isOptionIsSelected(option)">
                            <i class="fas fa-check"></i>
                        </div>
                    </div>
                    <div v-else>
                        <slot :option="option">
                            <slot name="option" :option="option">
                                {{ getLabel(option) }}
                            </slot>
                        </slot>
                    </div>
        </template>
        <template #selected-option-container="{ option, deselect, multiple, disabled }">
            <div v-if="multiple">
                <div v-show="!isFirstTwoOptionSelectedOptions(option)" class="vs__selected multiple">
                    <span class="name-option-container">
                        <slot name="selected-option" :option="option">
                            {{getLabel(option)}}
                        </slot>
                    </span>
                    <i class="fas fa-times" @click="deselect(option)"></i>
                </div>
                <div v-if="isThirdOption(option)" class="vs__selected multiple more-items-number">
                    + {{getMoreItemsNumber}}
                </div>
            </div>
            <div  class="vs__selected " v-else>
                <slot name="selected-option" :option="option">
                    {{getLabel(option)}}
                </slot>
            </div>
        </template>
        <template #list-header>
            <slot name="list-header"></slot>
        </template>
        <template #list-footer>
            <slot name="list-footer"></slot>
        </template>
        <template #no-options="{ search, searching, loading }">
            <template v-if="searching">
                {{$t("no-options-find")}} <em>{{search}}</em>
            </template>
            <template v-else-if="search && search.length!=0">
                {{$t("search")}}
            </template>
        </template>
        <template #spinner="{ loading }">
            <div v-if="loading" style="border-left-color: rgba(88,151,251,0.71)" class="vs__spinner">
                {{$t("loading")}}
            </div>
        </template>
    </v-select>
</template>

<script>
import vSelect from 'vue-select';
import { createPopper } from '@popperjs/core';
export default {
    name: "vg-select",
    components:{vSelect},
    props: {
        /**
        * @model
        */
        value: {
            type: String | Number | Object | Boolean,
            default: ()=>{
                return null;
            }
        },
        /**
        * collections d'items default []
        */
        options: {
            type: Array,
            required: true,
            default: ()=>{
                return [];
            }
        },
        /**
        * si pas de template slot passé : attributeLabel est l'attribut visible par l'user de l'objet options[0] => options[0][attributeLabel]
        * si template slot passé : attributeLabel est utilisé dans le input pour présenter l'option sélectionnée
        */
        attributeLabel: {
            type: String,
            default: "label"
        },
        /**
        * attribut valeur emit au parent de l'objet options[0] => options[0][attributeValue]
        * si attributeValue n'est pas set, renvoie l'objet correspondant à l'option
        */
        attributeValue: {
            type: String,
            default: null
        },
        /**
        * select option multiple
        */
        multiple: {
            type: Boolean,
            default: false
        },
        /**
        * custom placeholder
        */
        placeholder: {
            type: String,
            default: null
        },
        /**
        * disabled the select option or not
        */
        disabled: {
        	type: Boolean,
        	default: false
        },
        /**
        * show the clear button or not
        * default show clear button
        */
        clearable: {
        	type: Boolean,
        	default: true
        },
        /**
        * function de remanipulation des données avant emit input
        */
        reduce: {
            type: Function,
            default: null
        },
        /**
        * To allow input that's not present within the options - maintient le nouvel item en sélection
        */
        taggable: {
            type: Boolean,
            default: false
        },
        /**
        * If you want added tags to be pushed to the options array - le nouvel item est intégrer dans la liste
        */
        pushtags: {
            type: Boolean,
            default: false
        },
        /**
        * Function de customisation du filtrage par défaut filtre sur label
        * @param {Object} option Object correspondant à l'option
        * @param {String} label correspond à l'attributeLabel
        * @param {String} search chaine de caractère saisie par l'user dans l'input
        */
        filterBy: {
        	type: Function,
        	default: (option, label, search)=>{
        		return (label?label:"").toLowerCase().indexOf(search.toLowerCase()) > -1;
        	}
        },
        /**
        * placement du dropdown bottom || top
        */
        placement: {
            type: String,
            default: "bottom"
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "placeholder": "Sélectionner",
            "no-options-find": "Désolé, aucun élément trouvé pour",
            "loading": "Chargement",
            "search": "Rechercher"
        },
        "en": {
            "placeholder": "Select",
            "no-options-find": "No option found for",
            "loading": "Loading",
            "search": "Search"
        },
        "th": {
            "placeholder": "เลือก",
            "no-options-find": "ไม่พบออปชันสำหรับ",
            "loading": "กำลังโหลด",
            "search": "ค้นหา"
        }
    }
},
    data: function() {
        return {
            selected: this.multiple?[]:null,
            optionsList: this.options || [],
        };
    },
    watch:{
        value: {
            handler: function(val){
                ////console.log("WATCH V-MODEL", val);
                this.getDefaultSelected();
            }
        },
        options: {
            handler: function(opts){
                ////console.log("WATCH OPTIONS", opts);
                this.optionsList = opts || [];
                this.getDefaultSelected();
            }
        }
    },
    mounted: function(){
        this.getDefaultSelected();
        //this.setSelectedValue(this.value);
    },
    computed:{
        /**
        * récupère le placeholder
        */
        getPlaceholder: function(){
            return this.placeholder ? this.placeholder : this.$t('placeholder');
        },
        getMoreItemsNumber: function(){
            return this.selected.length - 2;
        }
    },
    methods:{
        withPopper: function(dropdownList, component, { width }) {
          /**
           * We need to explicitly define the dropdown width since
           * it is usually inherited from the parent with CSS.
           */
           //console.log("DROPDOWN LIST STYLE", width, component.$refs.dropdownMenu.children[10]);
           dropdownList.style.width = width;
           dropdownList.style.zIndex = 999;

          /**
           * Here we position the dropdownList relative to the $refs.toggle Element.
           *
           * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
           * the dropdownList overlap by 1 pixel.
           *
           * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
           * wrapper so that we can set some styles for when the dropdown is placed
           * above.
           */
          const popper = createPopper(component.$refs.toggle, dropdownList, {
            placement: this.placement,
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -1],
                },
              },
              {
                name: 'toggleClass',
                enabled: true,
                phase: 'write',
                fn({ state }) {
                  component.$el.classList.toggle(
                    'drop-up',
                    state.placement === 'top-end'
                  )
                },
              },
          ],
      });

          /**
           * To prevent memory leaks Popper needs to be destroyed.
           * If you return function, it will be called just before dropdown is removed from DOM.
           */
          return () => popper.destroy();
        },
        /**
         * récupère le label pour l'affichage de l'option
         *
         * @param {Object} option Object Number String Boolean
         */
        getLabel: function(option){
            if(option && typeof(option)!="object") return option;
            else if(this.attributeLabel && option[this.attributeLabel]) return option[this.attributeLabel];
            else "default option";
        },
        /**
         * récupère l'item selected par default
         */
        getDefaultSelected: function(){
            //console.log("DEFAULT SELECTED", this.attributeValue, this.multiple, this.value, this.selected);
            if(this.value && this.attributeValue && !this.multiple ) {
                if(typeof(this.value) == "string" || typeof(this.value) == "number" ){
                    this.selected = this.optionsList.find((item)=>item[this.attributeValue]==this.value);
                }else{
                    this.selected = this.optionsList.find((item)=>item[this.attributeValue]==this.value[this.attributeValue]);
                }
            }
            else if(this.value && this.attributeValue && this.multiple){
                this.selected = this.optionsList.filter((item)=>this.value.indexOf(item[this.attributeValue])!=-1);
            }
            else {
                this.selected = this.value;
            }
            ////console.log("SELECTED", this.selected);
        },
        isOptionIsSelected: function(option){
            if(!this.selected) return false;
            else if(typeof(this.selected)=="object" && !this.selected.id) return this.selected.findIndex((item)=>typeof(item)=="object"?item.id==option.id:item==option)!=-1;
            else if(typeof(this.selected)=="object" && this.selected.id) return this.selected.id==option.id;
            else return this.selected==option;
        },
        handleSelectedChange: function(value){
            ////console.log("SELECTED CHANGE", value);
            if(value){
                if(this.attributeValue && value[this.attributeValue]) {
                    this.$emit("input", value[this.attributeValue]);
                }
                else if(this.attributeValue && !value[this.attributeValue]) {
                    if(value[this.attributeValue] === null) {
                        this.$emit("input", null);
                    }else{
                        this.$emit("input", value.map((item)=>item[this.attributeValue]));
                    }
                }
                else {
                    this.$emit("input", value);
                }
            }else this.$emit("input", null);
        },
        isThirdOption: function(option){
            if(typeof(this.selected)=="object" && !this.selected.id) return this.selected.findIndex((item)=>item.id==option.id)==2;
            else return false;
        },
        isFirstTwoOptionSelectedOptions: function(option){
            if(typeof(this.selected)=="object" && !this.selected.id) return this.selected.findIndex((item)=>item.id==option.id)>=2;
            else if(typeof(this.selected)=="object" && this.selected.id) return this.selected.id==option.id;
            return true;
        }
    }
};
</script>
<style lang="scss">
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
    .vg-v-select{
        width: 100%;
        ::placeholder{
            color: #bbbcbe;
        }
        .vg-select-option{
            display: flex;
            flex-direction: row;
            margin: 7px 0;
            &.check{
                color: rgb(47, 151, 227);
                font-weight: bold;
                &:hover{
                    color:white;
                }
            }
            .name-option{
                width: 90%;
            }
        }
        .name-option-container{
            margin-right: 10px;
        }
        .vs__dropdown-toggle{
            border: 1px solid #dcdfe6;
            border-radius: 0px;
            height: 30px !important;
        }
        .vs__dropdown-menu{
            display: block;
            z-index: 999 !important;
        }
        .vs__selected-options{
            padding: 0px !important;
            align-items: center;
        }
        .vs__selected{
            /*border: 1px solid #dcdfe6;*/
            /*background-color: #fff;*/
            padding: 0px 10px;
            margin: 0 0 0 5px !important;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &.multiple{
                border: 1px solid #d9ecff;
                border-radius: 4px;
                background-color: #f4f4f5;
                border-color: #e9e9eb;
                color: #909399;
                font-size: 12px;
                .fa-times{
                    cursor: pointer;

                }
            }


        }
        .vs__deselect{
            margin: 0px 5px;
        }
        .vs__search{
            margin:0px !important;
            height:30px !important;
        }
    }
</style>
<docs>
# Exemples
## cas 1 : array of primitives values
- options
```javascript
["label 1", "label 2"]
```
- appel du composant
```javascript
<vg-select
    v-model="selected"
    :options="options"
    >
</vg-select>
```
- valeur de retour sélection du 1er élément
```javascript
"label 1"
```
## cas 2 : affiche par défaut l'attribut label et retourne par défaut l'objet
- options
```javascript
[
{ id: 1, label: "label 1" },
{ id: 2, label: "label 2" }
]
```
- appel du composant
```javascript
<vg-select
    v-model="selected"
    :options="options"
    >
</vg-select>
```
- valeur de retour sélection du 1er élément
```javascript
{ id: 1, label: "label 1" }
```
## cas 3 : affiche l'attribut libelLieu et retourne l'attribut id
- options
```javascript
[
{ id: 1, libelLieu: "Site 1" },
{ id: 2, libelLieu: "Site 2" }
]
```
- appel du composant
```javascript
<vg-select
    v-model="selected"
    :options="options"
    :attributeLabel="'libelLieu'"
    :attributeValue="'id'"
    >
</vg-select>
```
- valeur de retour sélection du 1er élément
```javascript
1
```
## cas 4 : affiche un template personnalisé par défaut s'applique pour les options et retourne l'attribut id
- options
```javascript
[
{ id: 1, libelLieu: "CH101", path: "O1/S1/B1/RDC/CH101" },
{ id: 2, libelLieu: "CH201", path: "O1/S1/B1/R+1/CH201" }
]
```
- appel du composant
```javascript
<vg-select
v-model="piece"
:options="pieces"
:attributeValue="'id'"
:attributeLabel="'libelLieu'">
    <template v-slot="{option}">
        <div>
            <span class="path">{{getPath(option)}}/</span>
            <span class="piece">{{option.libelLieu}}</span>
        </div>
    </template>
</vg-select>
```
- valeur de retour sélection du 1er élément
```javascript
1
```
## cas 5 : affiche un template personnalisé pour les options et un template personnalisé pour l'option selected et retourne l'attribut id
- options
```javascript
[
{ id: 1, libelLieu: "CH101", path: "O1/S1/B1/RDC/CH101" },
{ id: 2, libelLieu: "CH201", path: "O1/S1/B1/R+1/CH201" }
]
```
- appel du composant
```javascript
<vg-select
v-model="piece"
:options="pieces"
:attributeValue="'id'"
:attributeLabel="'libelLieu'">
    <template v-slot:options="{option}">
        <div>
            <span class="path">{{getPath(option)}}/</span>
            <span class="piece">{{option.libelLieu}}</span>
        </div>
    </template>
    <template v-slot:selected-options="{option}">
        <div>
            <span class="path">{{getPath(option)}}/</span>
            <span class="piece">{{option.libelLieu}}</span>
        </div>
    </template>
</vg-select>
```
## Propriété attributeLabel sert à afficher une valeur dans l'input après sélection
```javascript
:attributeLabel="'libelLieu'"
```
## sélection par défaut d'un item dans le select
```javascript
v-model="piece"
```
- si le select retourne un objet
```javascript
piece = { id: 1, libelLieu: "CH101", path: "O1/S1/B1/RDC/CH101" }
```
- si le select retourne un id
```javascript
piece = 1
```
## Propriété placeholder pour overider le placeholder par défaut définit dans VgSelect
```javascript
:placeholder="$t('select-lieu-placeholder')"
```
## Propriété multiple
- valeur de retour pour un array of primitives values (cas 1)
```javascript
["label 1", "label 2"]
```
- valeur de retour pour un array of object sans attributeValue passé (cas 2)
```javascript
[
{ id: 1, label: "label 1" },
{ id: 2, label: "label 2" }
]
```
- valeur de retour pour un array of object avec attributeValue passé (cas 3)
```javascript
[ 1, 2 ]
```
</docs>
