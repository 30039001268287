<template>
    <div id='historique-compteur' class="full-height">
        <vg-app-layout v-show="!$vgutils.isMobile() && !isPieceTargeted"
    		:title="$t('titre')"
            :icon="'Entite/historique-releves-de-compteurs.png'"
            @action-export="actionExport"
            :activeFilters="activeFilters"
            class="vg-print-hide">

            <template v-slot:search>
                <vg-text-filter v-model="searchQuery"></vg-text-filter>
            </template>
            <template v-slot:create-button>
                <vg-button v-if="can('RELEVE_COMPTEUR.CREATE_DATE_DEPASSEE')"
                    type="success"
                    @click="openReleveCompteur">
                    {{ $t("create-releve-compteur") }}
                </vg-button>
            </template>
            <template v-slot:action-button-item >
                <li @click="showModalRapport=true;">{{$t("menu-more-rapport")}}</li>
            </template>
            <template v-slot:header-bottom-left>
                <vg-tabs
                    v-if="releves"
                    :tabs="tabs"
                    :color="'gris'"
                    @tabClicked="onTabClicked">
                </vg-tabs>
            </template>

            <template v-slot:primary>
                <tag-grid
                    :idTableau="'tableau-releves-compteurs'"
                    :filters="getFilters"
                    v-model="gridOptions"
                    :columnDefs="columnDefs"
                    :rowData="releves"
        			:showLoadingOverlay="showLoadingOverlay"
        			:overlayLoadingText="$t('overlay-loading-text')"
        			:overlayNoRowsText="$t('overlay-no-rows-text')"
                    @grid-feeded="setDefaultFilter()"
                    @row-value-changed="onRowValueChanged" />
            </template>

            <template #secondary-filters>
                <vg-filter-date-intervalle :title="$t('filter-by-date')"
                    :defaultStartDate="agfilters.dateVerif.value[0]"
                    :defaultEndDate="agfilters.dateVerif.value[1]"
                    @change="handleChangeDateIntervalle"/>
                <vg-filter-site
                    v-model="agfilters.path.value" />
                <vg-collapse :title="$t('collapse-filter')"
                    :killContentOnCollapse="false">
                    <template v-slot:content>

                            <vg-input :title="$t('filter-tache')">
                                <vg-select
                                    v-model="agfilters.libel_equipement.value"
                                    :options="equipementscompteurs"
                                    :attributeLabel="'libel_equipement'"
                                    :attributeValue="'libel_equipement'"
                                    >
                                </vg-select>
                            </vg-input>

                    </template>

                </vg-collapse>
                <br>
            </template>
            <template #secondary-widget>
                <vg-tree-lieux
                    v-model="agfilters.path.value"/>
            </template>

        </vg-app-layout>
        <fm-dialog-notif
             v-if="selectedReponse && displayDialogNotifier"
            :msg="'confirmation ?'"
            @close="displayDialogNotifier = false"
            @fm-dialog-notifier-success = "handleUpdateReponse"
            >
          <div>
            <!--div>{{ propsData.div }}<div-->
            <div class="vg-margin-bottom-10">
                {{ selectedReponse.libel_equipement }} <br>
                <span v-html="$vgutils.formatPathCell(selectedReponse.path)" ></span>
            </div>
            <table class="table">
                <thead>
                    <th>{{$t("header-min")}}</th>
                    <th>{{$t("header-value")}}</th>
                    <th>{{$t("header-max")}}</th>
                </thead>
                <tbody>
                    <tr>
                        <td><h3><small>{{ selectedReponse.minVal }}</small></h3></td>
                        <td><h3>{{selectedReponse.reponse}}</h3></td>
                        <td><h3><small>{{ selectedReponse.maxVal }}</small></h3> </td>
                    </tr>
                </tbody>

            </table><br>

          </div>
        </fm-dialog-notif>
        <vg-modal
            :width="'100%'"
            :height="'100%'"
            v-if="showModalRapport"
            @close="showModalRapport = false">
            <template v-slot:body>
                <vg-reporting-prints
                    :idTableau="'releve-compteur'"
                    :data="filteredCompteur"
                    :columnDefs="columnDefVisible"
                    :allTitle="$t('title-rapport')">
                </vg-reporting-prints>
            </template>
        </vg-modal>
    </div>
</template>

<script>
    import TagGrid from 'src/components/Grid/TagGrid.vue';
    import TagGridMixins from 'src/mixins/TagGridMixins.js';

    import VgTabs from 'src/components/Vg/VgTabs.vue';
    import fmDialogNotifier from 'src/views/Verification/Historique/fmDialogNotifier.vue';
    import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';

    import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
    import VgFilterTache from "src/components/Vg/Filters/VgFilterTache.vue";
    import VgFilterTacheType from "src/components/Vg/Filters/VgFilterTacheType.vue";


    import VgCollapse from 'src/components/Vg/VgCollapse.vue';
    import VgGroup from "src/components/Vg/VgGroup.vue";
    import VgSelect from "src/components/Vg/VgSelect.vue";
    import VgInput from "src/components/Vg/VgInput.vue";
    import VgButton from "src/components/Vg/VgButton.vue";

    import ReponsesMixins from 'src/mixins/ReponsesMixins.js';
    import VgFilterDateIntervalle from "src/components/Vg/Filters/VgFilterDateIntervalle.vue";
    import VgReportingPrints from 'src/components/Vg/TagGrid/VgReportingPrints.vue';


    import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";

    import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
    import ThumbnailCellRender from 'src/components/Vg/TagGrid/ThumbnailCellRender.vue';
    import DatetimeCellRender from 'src/components/Vg/TagGrid/DatetimeCellRender.vue';

	import moment from "moment";
    export default {
        name: 'Historique-compteur',
        i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
        "overlay-loading-text": "Chargement des relevés compteurs...",
		"overlay-no-rows-text": "Aucun relevé correspondant au filtrage",
        "titre": "Historique relevés compteurs",
        "date": "Date",
        "pointVerif": "Point de verification",
        "valIndex": "Valeur index",
        "moyenneHeure": "Moyenne/h",
        "moyenneJour": "Moyenne/Jour",
        "moyenneMois": "Moyenne/Mois",
        "column-header-photo": "Photo",
        "operateur": "Operateur",
        "path": "Emplacement",
        "equipement": "Equipement",
        "filtre-title": "Filtres",
        "filtre-arborescence": "Arborescence",
        "collapse-filter": "Filtres complémentaires",
        "collapse-filter-lieux": "Filtrage par lieux",
        "filter-tache": "Tache de vérification",
        "header-min": "Minimum",
        "header-value": "Valeur",
        "header-max": "Maximum",
        "site": "Site",
        "batiment": "Batiment",
        "etage": "Etage",
        "piece": "piece",
        "filter-by-date": "Filtre par date relevé",
        "menu-more-rapport": "Créer un rapport",
        "title-rapport": "Rapport relevé compteurs",
        "tab-toutes": "Tous",
        "create-releve-compteur": "Saisir relevé compteur"
    },
    "en": {
        "overlay-loading-text": "Loading meters history...",
		"overlay-no-rows-text": "No meter history matching filtering",
        "titre": "Meters history",
        "date": "Date",
        "pointVerif": "Checkpoint",
        "valIndex": "Index value",
        "moyenneHeure": "Average/h",
        "moyenneJour": "Average/Day",
        "moyenneMois": "Average/Month",
        "site": "Site",
        "batiment": "Block",
        "etage": "Level",
        "piece": "Room",
        "filter-by-date": "Filtering by date",
        "collapse-filter-lieux": "Filtering by location",
        "column-header-photo": "Photo",
        "operateur": "Operator",
        "path": "Path",
        "equipement": "Equipment",
        "filtre-title": "Filters",
        "filtre-arborescence": "Tree view",
        "collapse-filter": "Extra filters",
        "filter-tache": "Verfication tasks",
        "header-min": "Minimum",
        "header-value": "Value",
        "header-max": "Maximum",
        "menu-more-rapport": "Create a report",
        "title-rapport": "Counter reading report",
        "tab-toutes": "All",
        "create-releve-compteur": "Enter meter reading"
    },
    "th": {
        "titre": "ประวัติการตรวจวัด",
        "date": "วันที่",
        "pointVerif": "จุดตรวจสอบ",
        "valIndex": "มูลค่าดัชนี",
        "moyenneHeure": "ค่าเฉลี่ย/ชั่วโมง",
        "moyenneJour": "ค่าเฉลี่ย/วัน",
        "moyenneMois": "เฉลี่ย/เดือน",
        "site": "หน้างาน",
        "batiment": "บล็อก",
        "etage": "ระดับ",
        "piece": "ห้อง",
        "collapse-filter-lieux": "การกรองตามที่ตั้ง",
        "column-header-photo": "รูปภาพ",
        "operateur": "ผู้ปฏิบัติการ",
        "path": "ทางเดิน",
        "equipement": "อุปกรณ์",
        "filtre-title": "ตัวกรอง",
        "filtre-arborescence": "มุมมองต้นไม้",
        "collapse-filter": "ตัวกรองพิเศษ",
        "filter-tache": "ภารกิจการตรวจสอบ",
        "header-min": "ขั้นต่ำ",
        "header-value": "มูลค่า",
        "header-max": "สูงสุด",
        "menu-more-rapport": "สร้างรายงาน",
        "title-rapport": "รายงานการอ่านเคาน์เตอร์",
        "tab-toutes": "ทั้งหมด"
        }
    }
},
        mixins:[TagGridMixins,ReponsesMixins],
        components: {

            VgCollapse,
            VgGroup,
            VgSelect,
            VgInput,
            VgButton,
            TagGrid,
            VgTabs,
            "fm-dialog-notif":fmDialogNotifier,
			VgTextFilter,
            VgFilterSite,
            VgFilterTache,
            VgFilterTacheType,
            VgFilterDateIntervalle,
            VgReportingPrints,
            PathCellRender,

            ThumbnailCellRender,
            DatetimeCellRender,
            VgTreeLieux
        },
        data :function(){
            return {
                showLoadingOverlay: false,
                showCreateReleveCompteur: false,
                headerDatas: {
                    title: this.$t("titre"),
                    imgvisible: false,
                    buttonvisible: false,
                    noBorder: true
                },
                instance: 'index-compteurs-filters',
                reponses:null,
                gridOptions: {
					editType: 'fullRow'
				},
				columnDefs: [
                    {
                        headerName: this.$t('site'),
                        field: 'path',
                        colId:'paths',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[1];
                        }

                    },
                    {
                        headerName: this.$t('batiment'),
                        field: 'path',
                        colId:'pathb',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[2];
                        }

                    },
                    {
                        headerName: this.$t('etage'),
                        field: 'path',
                        colId:'pathe',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[3];
                        }

                    },
                    {
                        headerName: this.$t('piece'),
                        field: 'path',
                        colId:'pathp',
                        width: 200,
                        hide:true,
                        cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.path.split("/")[4];
                        }

                    },
					{
						headerName:this.$t("date"),
						field: "date_reponse",
						colId: "date_reponse",
						getQuickFilterText: (params) => {
							return this.$vgutils.getDate(params.data.date_reponse);
						},
						width: 180,
						cellClass: "vg-cell-ballam-theme",
						cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.date_reponse}}).$mount().$el
					},
					{
						headerName:this.$t("equipement"),
                        field: "libel_equipement",
						colId: "libel_equipement",
                        filter: "agTextColumnFilter",
                        width: 460,
						cellClass: "vg-cell-ballam-theme"
					},
					{
						headerName:this.$t("path"),
                        field: "path",
						colId: "path",
                        filter: "agTextColumnFilter",
                        width: 460,
						cellClass: "vg-cell-ballam-theme",
                        cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path}}).$mount().$el,
					},
					{
						headerName:this.$t("pointVerif"),
						field: "question",
						width: 300,
						cellClass: "vg-cell-ballam-theme",
						cellRenderer: (params) => {
							var container = document.createElement('div');
							container.innerHTML = '' +
							"<div style='height: 100%; width: 230px; text-align: left;'>"+
							"<span style='margin-right: 10px;'><i class='fa fa-check' style='font-size: 20px;'></i></span>"+
							"<span style='position: relative; top: -2px;'>"+ params.data.question +"</span>"+
							"</div>";
							return container;
						}
					},
                    {
						headerName:this.$t("valIndex"),
						field: "reponse",
						width: 180,
                        editable:true,
						cellClass: "vg-cell-ballam-theme vg-important-cell"
					},

					{
						headerName:this.$t("moyenneHeure"),
						field: "consoHeure",
						width: 180,
						cellClass: "vg-cell-ballam-theme"
					},
					{
						headerName:this.$t("moyenneJour"),
						field: "consoJour",
						width: 180,
						cellClass: "vg-cell-ballam-theme"
					},
					{
						headerName:this.$t("moyenneMois"),
						field: "consoMois",
						width: 180,
						cellClass: "vg-cell-ballam-theme"
					},
					{
						field: "username" ,
                        width: 180,
						headerName: this.$t("operateur"),
						filter: "agTextColumnFilter",
						cellClass: "vg-cell-ballam-theme"
					},
					{
						field: "equipement_id" ,
                        colId:"equipement_id",
                        headerName:"equipement_id",
						headerName: this.$t("id"),
						filter: "agTextColumnFilter",
						cellClass: "vg-cell-ballam-theme",
                        hide:true
					},
                    {
    					headerName: this.$t("column-header-photo"),
    					field: "photos",
    					colId: "photos",
    					cellRenderer: (params) => new ThumbnailCellRender({propsData: {params: params, attributeName: "photos", attributeUid: "equipementTache_uid"}}).$mount().$el
    				}
				],
                selectedEquipment : {},
                releves: null,
                equipementscompteurs: [],
                displayDialogNotifier:false,
                selectedReponse:null,
				searchQuery: '',
                agfilters: {
                    "path": {"attr":"path", "colId": "path", "value": null, "action":"contains"},
                    "type_tache":{"attr":"t.type_tache","colId":"type_tache", "value":"Relever_compteur","action":"equals"},
                    "dateVerif": {"attr":"dateVerif", "colId": "dateVerif", "value": [moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().add(1,'day').format("YYYY-MM-DD")], "action":"between"},
                    ///"libel_tache": {"attr":"libel_tache", "colId": "libel_tache", "value": null, "action":"contains"},
                    "libel_equipement": {"attr":"libel_equipement", "colId": "libel_equipement", "value": null, "action":"contains"}
                    //"qrCode": {"attr":"qrCode", "colId": "qrCode", "value": null, "action":"contains"},
                    //"username": {"attr":"username", "colId": "username", "value": null, "action":"contains"}

                },
                showModalRapport:false,
                focusedTab: null
            }
        },
		watch: {
			searchQuery: {
				handler: function(query){
					this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
            agfilters: {
                handler: function(nfilters) {
                    ////console.log("WATCH AG FILTERS", nfilters);
                    const agfilters = Object.values(nfilters);
                    this.TagGridMixins_setFilters(this.gridOptions, agfilters);
                    this.fetch([], this.getFilters);
                },
                deep: true
            },
		},
        methods: {
            openReleveCompteur: function(){
                this.$router.push({name: "_process_verification_equipement_selection", params: {type: "compteur", showBackPageButton: true} });
            },
            actionExport: function(event){
                window.dispatchEvent(new CustomEvent(event.name, {
                    "detail": {"idTableau":"tableau-releves-compteurs"}
                }));
            },
            fetch: function(directives, filters){
                this.showLoadingOverlay = true;
                this.ReponsesMixins_getReponses(filters, directives).then((releves)=>{
                    this.getEquipementsCompteurs(releves);
                	this.releves = this.formatReleves(releves);
                    this.showLoadingOverlay = false;
                    this.setDefaultFilter();
                });
            },
            handleFilterByPath:function(lieu){
                this.agfilters.path.value = lieu.attrs.path;
            },
			setDefaultFilter: function(){
                if(!this.focusedTab) this.focusedTab = this.tabs[0];
                this.TagGridMixins_setFilters(this.gridOptions, this.focusedTab.filters);
			},
			onTabClicked: function(e){
                this.focusedTab = e;
				this.TagGridMixins_setFilters(this.gridOptions, e.filters);
			},
            onRowValueChanged: function(event){
                this.selectedReponse = event.data;
                this.displayDialogNotifier = true;
            },
            filterByPath: function(node){
                //// //console.log("Filter BY PATH",node);
                this.agfilters.path.value = node.attrs.path;
                this.$forceUpdate();
            },
			getEquipementsCompteurs: function(releves){
				var compteurs = [];
				releves.forEach((releve)=>{
					if( compteurs.findIndex((compteur)=>releve.equipement_id==compteur.equipement_id) == -1) compteurs.push(releve);
				});
				this.equipementscompteurs = compteurs;
			},
			formatReleves: function(releves){
				// //console.log("RELEVES", releves);

				var filteredreleves = [];
				var ecart = null;
				var ecartHours = null;

				releves.forEach(function(releve){
					// //console.log("releve", releve);
					if(!releve.consoHeure && !releve.consoJour){
						releve.consoHeure = null;
						releve.consoJour = null;
                        releve.consoMois = null;
						filteredreleves = releves.filter(function(r){
							return r.equipement_id == releve.equipement_id && r.idCheckpoint == releve.idCheckpoint && r.idTache == releve.idTache;
						});
						//console.log("filteredreleves", filteredreleves);
						if(filteredreleves.length>1){
							for(var index=0; index<filteredreleves.length; index++){
								if(index+1!=filteredreleves.length){
									ecartHours = ( moment(filteredreleves[index].date_reponse).diff(moment(filteredreleves[index+1].date_reponse), "hours", true) );

									if(ecartHours==0) ecartHours=1;
									ecart = Number(filteredreleves[index].reponse)-Number(filteredreleves[index+1].reponse);

                                    let consoHeure = ecart/ecartHours;
									filteredreleves[index].consoHeure = consoHeure;
									filteredreleves[index].consoHeure = filteredreleves[index].consoHeure.toFixed(4);
									filteredreleves[index].consoJour = consoHeure*24;
									filteredreleves[index].consoJour = filteredreleves[index].consoJour.toFixed(4);
                                    filteredreleves[index].consoMois = consoHeure*24*moment(filteredreleves[index].date_reponse).daysInMonth().toFixed(4);

								}else{
									filteredreleves[index].consoJour = "-";
									filteredreleves[index].consoHeure = "-";
									filteredreleves[index].consoMois = "-";
								}
							}
						}else{
							releve.consoJour = "-";
							releve.consoHeure = "-";
							releve.consoMois = "-";
						}
					}
				});
                //console.log(releves);
				return releves;
			},
            handleUpdateReponse:function(){
                var reponse = {
                    "datas": {
                        "id":this.selectedReponse.id,
                        "reponse":this.selectedReponse.reponse
                    }
                }
                this.ReponsesMixins_updateReponse(reponse);
            },
            handleChangeDateIntervalle: function(data){
                this.agfilters.dateVerif.value = [data.startDate, data.endDate];
            },
        },
        created:function(){
            var that = this;
            var directives = [{"name":"LIMIT","offset":0,"limit":1000}];
            this.fetch(directives, this.getFilters);
        },
        computed:{
            activeFilters: function(){
                let filtersNames = ["path", "dateVerif", "libel_equipement"];
                let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
                return activeFilters.length;
            },
			counters: function(){
				var counters = {};
				var compteurs = this.releves;
				compteurs.forEach(function(compteur){
					if(!counters[compteur.equipement_id]) counters[compteur.equipement_id] = 0;
					counters[compteur.equipement_id]++;
				});
				return counters;
			},
			tabs: function(){
				var counters = this.counters;
				var tabs = [];
				var compteurs = this.equipementscompteurs;
                tabs.push({
                    label:this.$t("tab-toutes"),
                    name:this.$t("tab-toutes"),
                    counter: this.releves.length,
                    filters:[{colId: "equipement_id", value: null}]
                });
				this.equipementscompteurs.forEach(function(compteur){
					tabs.push({
						label: compteur.libel_equipement,
						name: compteur.libel_equipement,
						counter: counters[compteur.equipement_id],
						filters:[
							{colId: "equipement_id", value: compteur.equipement_id}
						]
					});
				});
				return tabs;
			},
            getFilters: function(){
                return Object.values(this.agfilters);

            },
            filteredCompteur:function(){
                return this.gridOptions.api.getModel().rowsToDisplay.map((row)=>row.data);
            },
            columnDefVisible:function(){
                return this.TagGridMixins_columnVisibleTagGrid(this.gridOptions.api);
            }
        }
    }
</script>

<style lang="scss" scoped>
    // $properties
    // filters panel
    $filtersPanelHeader: 60px;

    #historique-compteur {
        #historique-compteur__tabs-container {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            padding-left: 20px;
            // &::-webkit-scrollbar {
            //     display: none;
            // }
            .tab {
                border: 1px solid gray;
                border-radius: 6px 6px 0px 0px;
                margin-right: 1px;
                min-width: fit-content;
                padding: 10px;
                &:last-child {
                    margin-right: inherit;
                }
                &.active {
                    background-color: #FF6927;
                    border: none;
                    color: white;
                    font-weight: bold;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
        #filters-panel {
            border: 1px solid gray;
            height: calc(100vh - 203px);
            #filters-panel__header {
                background-color: #F2F2F2;
                border-bottom: 1px solid gray;
                color: #009eec;
                height: $filtersPanelHeader;
            }
            #filters-panel__body {
                height: calc(100% - #{$filtersPanelHeader});
                overflow: auto;
            }
        }
        #grid-container {
            height: calc(100vh - 203px);
            padding-left: 20px;
        }
    }
</style>
