<template >
	<vg-app-layout
        :title="$t('title')"
		:icon="$vgutils.isMobile()?'cle_blanc.png':null"
		:colorheader="getHeaderColor"
		:activeFilters="activeFilters"
		hideKeyFiguresPanel
		@remove-filters="onRemoveFilters">
		<template #header-top-left-complement>
			<vg-select
				class="select-tableau-view"
			    v-model="view"
			    :options="typeMaintenance"
				:attributeValue="'value'"
			    :attributeLabel="'label'"
				:clearable="false">
			</vg-select>
		</template>
		<template #create-button>
			<div style="display:flex;flex-direction:column;font-family: Helvetica Neue Light, Open Sans;">
				<span style="font-size:12px;">{{$t("periode-de-visualisation")}}</span>
				<vg-date-picker-shortcuts key="date-picker-shortcuts-main"
					previousDatesPresets
					:defaultStartDate="initialDateIntervalleRange.startDate"
					:defaultEndDate="initialDateIntervalleRange.endDate"
					@select="onChangeDateIntervalle"
					@finish-selection="onFinishDateSelection" />
			</div>
			<div v-if="isCurative"
				style="display:flex;flex-direction:column;font-family: Helvetica Neue Light, Open Sans;">
				<span style="font-size:12px;">{{$t("filtrage-typologie-maintenance")}}</span>
				<vg-typologies-maintenance-selector v-model="agfilters.typologie.value"
					attributeValue="name"
					style="min-width:200px;"
					@input="onChangeTypologieMaintenance"/>
			</div>
			<vg-button style="min-width:150px;"
				type="info"
				@click="showModalFiltreSites=true;">
				<span v-if="!agfilters.sites.value">{{$t("filtrer-par-sites")}}</span>
				<span v-else>{{ agfilters.sites.value.length }} {{$t("sites-selected")}}</span>
			</vg-button>
        </template>
		<template #action-button-display-items>
			<li @click="showRapportsModal=true;">{{$t("action-afficher-cacher-rapports")}}</li>
			<li @click="onPrintDashboard">{{$t("action-print")}}</li>
		</template>
		<template #header-top-right-pre v-if="$vgutils.isMobile()">
			<vg-button style="min-width:150px;"
				type="info"
				@click="showModalFiltreSites=true;">
				<span v-if="!agfilters.sites.value">{{$t("filtrer-par-sites")}}</span>
				<div v-else>
					<span>{{$t("restriction")}}: </span>
					<span v-if="agfilters.sites.value.length>0" class="site-name">{{agfilters.sites.value[0] | site}}</span>
					<span v-if="agfilters.sites.value.length>1" class="site-name">{{agfilters.sites.value[1] | site}}</span>
					<span v-if="agfilters.sites.value.length>2" class="site-name-more">+ {{agfilters.sites.value.length-2}}</span>
				</div>
			</vg-button>
		</template>
		<template #search v-if="$vgutils.isMobile()">
			<div style="display:flex;flex-direction:column;font-family: Helvetica Neue Light, Open Sans;">
				<span style="font-size:12px;">{{$t("periode-de-visualisation")}}</span>
				<vg-date-picker-shortcuts style="min-width:200px;" 
					key="date-picker-shortcuts-main-mobile"
					previousDatesPresets
					:defaultStartDate="initialDateIntervalleRange.startDate"
					:defaultEndDate="initialDateIntervalleRange.endDate"
					@select="onChangeDateIntervalle" />
			</div>
		</template>
		<template #panel-filters-attributs>
			<div v-if="isCurative"
				style="display:flex;flex-direction:column;font-family: Helvetica Neue Light, Open Sans;">
				<span style="font-size:12px;">{{$t("filtrage-typologie-maintenance")}}</span>
				<vg-typologies-maintenance-selector v-model="agfilters.typologie.value"
					attributeValue="name"
					style="min-width:200px;"/>
			</div>
		</template>
		<vg-modal v-if="showModalFiltreSites"
			:title="$t('title-modal-filtre-site')"
			@close="showModalFiltreSites=false;">
			<template #body>
				<vg-filter-site v-model="agfilters.sites.value"
					:display="'modal'"
					multiple
					isAllSitesSelected
					@change-sites-validation="showModalFiltreSites=false;"/>
			</template>
			<template #footer>
				<vg-button
					@click="showModalFiltreSites=false;">
					{{$t("fermer")}}
				</vg-button>
			</template>
		</vg-modal>
		<vg-modal v-if="showRapportsModal"
			:title="$t('title-modal-afficher-cacher-rapports')"
			@close="showRapportsModal=false;">
			<template #body>
				<vg-checkbox v-for="(rapport, index) in getRapportsList" :key="index" :label="$t('checkbox-'+rapport)"
					:defaultChecked="isRapportShown(rapport)"
					@checked="showRapport(rapport)"
					@unchecked="hideRapport(rapport)"/>
			</template>
			<template #footer>
				<vg-button
					@click="showRapportsModal=false;">
					{{$t("fermer")}}
				</vg-button>
			</template>
		</vg-modal>
		<template #header-bottom-right>
			<span style="color:#505967;font-family: Helvetica Neue Light, Open Sans;font-size: 12px;"><i class="fas fa-info-circle"></i> {{$t("info-drag-and-drop")}}</span>
		</template>
		<template #primary>
			<!-- @WARNING LE FAIRE AUTREMENT : UTILISE POUR AFFICHER UN TITRE AU window.print() -->
			<span id="dashboard-print-title" v-if="dateIntervalleRange">
				{{ $t("dashboard-print-title",{startDate:getReadableDate(dateIntervalleRange.startDate), endDate: getReadableDate(dateIntervalleRange.endDate)}) }}
			</span>
			<div class="body-container" v-if="isCurative && dateIntervalleRange">
				<vg-dashboard-curatif-totaux ref="vg-dashboard-curatif-totaux" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-totaux']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-totaux'], {'drag':dragItemName=='vg-dashboard-curatif-totaux'}]"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedTotaux"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-totaux')"
					@drop="drop($event, 'vg-dashboard-curatif-totaux')"/>
				<vg-dashboard-curatif-repartition-age ref="vg-dashboard-curatif-repartition-age" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-age']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-age'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-age'}]"
					:filters="agfilters"
					@selected="onSelectedAge"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-age')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-age')"/>
				<vg-dashboard-curatif-repartition-composants ref="vg-dashboard-curatif-repartition-composants" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-composants']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-composants'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-composants'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedComposantBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-composants')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-composants')"/>
				<vg-dashboard-curatif-repartition-categories-equipements ref="vg-dashboard-curatif-repartition-categories-equipements" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-categories-equipements']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-categories-equipements'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-categories-equipements'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedCategorieEquipementBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-categories-equipements')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-categories-equipements')"/>
				<vg-dashboard-curatif-repartition-pieces ref="vg-dashboard-curatif-repartition-pieces" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-pieces']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-pieces'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-pieces'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedPieceBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-pieces')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-pieces')"/>
				<vg-dashboard-curatif-repartition-corps-detat ref="vg-dashboard-curatif-repartition-corps-detat" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-corps-detat']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-corps-detat'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-corps-detat'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedCorpsDetatBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-corps-detat')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-corps-detat')"/>
				<vg-dashboard-curatif-repartition-equipements ref="vg-dashboard-curatif-repartition-equipements" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-equipements']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-equipements'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-equipements'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedEquipementBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-equipements')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-equipements')"/>
				<vg-dashboard-curatif-repartition-equipements-couts ref="vg-dashboard-curatif-repartition-equipements-couts" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-equipements-couts']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-equipements-couts'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-equipements-couts'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedEquipementBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-equipements-couts')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-equipements-couts')"/>
				<vg-dashboard-curatif-urgences ref="vg-dashboard-curatif-urgences" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-urgences']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-urgences'], {'drag':dragItemName=='vg-dashboard-curatif-urgences'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedPieUrgences"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-urgences')"
					@drop="drop($event, 'vg-dashboard-curatif-urgences')"/>
				<vg-dashboard-curatif-repartition-demandeur ref="vg-dashboard-curatif-repartition-demandeur" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-demandeur']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-demandeur'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-demandeur'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedDemandeur"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-demandeur')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-demandeur')"/>
				<vg-dashboard-curatif-repartition-user-affecte ref="vg-dashboard-curatif-repartition-user-affecte" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-user-affecte']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-user-affecte'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-user-affecte'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedUserAffecte"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-user-affecte')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-user-affecte')"/>
				<vg-dashboard-curatif-repartition-tiers-affecte ref="vg-dashboard-curatif-repartition-tiers-affecte" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-tiers-affecte']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-tiers-affecte'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-tiers-affecte'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedTiersAffecte"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-tiers-affecte')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-tiers-affecte')"/>
				<vg-dashboard-curatif-repartition-duree-traitement ref="vg-dashboard-curatif-repartition-duree-traitement" v-if="dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-duree-traitement']"
					:class="[dashboardCuratifGridClasses['vg-dashboard-curatif-repartition-duree-traitement'], {'drag':dragItemName=='vg-dashboard-curatif-repartition-duree-traitement'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedDureeTraitement"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-curatif-repartition-duree-traitement')"
					@drop="drop($event, 'vg-dashboard-curatif-repartition-duree-traitement')"/>
				<vg-modal v-if="showMaintenancesListModal"
					:width="'80vw'"
					:height="'100vh'"
					@close="onCloseMaintenancesListModal">
					<template #header>
						{{$t('modal-details-title')}} :
						<!--span>{{$t("du")}} {{intervalDate.start | readableDate}} {{$t("au")}} {{intervalDate.end | readableDate}}</span-->
					</template>
					<template #body>
						<div style="width:100%;height:100%;">
							<div style="display:flex;flex-direction:column;" >
								<!--vg-tabs style="width:50%;" class="hidden-print"
									:tabs="modaltabs"
									:color="'jaune'"
									@tabClicked="onModalTabClicked">
								</vg-tabs-->
								<vg-text-filter style="width:50%;" class="hidden-print"
									v-model="searchQuery">
								</vg-text-filter>
							</div>
							<vg-fichesav-table
								:searchQuery="searchQuery"
								:filters="maintenancesFilters"
								:idUserAffecte="idUserAffecte"
								:idTiersAffecte="idTiersAffecte"
								:onlyEncours="false"
								:counterName="'filtered'" />
						</div>
					</template>
					<template #footer>
						<vg-button
							type="default"
							@click="onCloseMaintenancesListModal">
							{{$t("fermer")}}
						</vg-button>
						<vg-button class="not-responsive"
							type="success"
							@click="handleMaintenancesActionExport('askForCSV')">
							{{$t("export-csv")}}
						</vg-button>
						<vg-button class="not-responsive"
							type="success"
							@click="handleMaintenancesActionExport('askForXLS')">
							{{$t("export-excel")}}
						</vg-button>
					</template>
				</vg-modal>
			</div>
			<div class="body-container" v-else-if="isPreventive && dateIntervalleRange">
				<vg-dashboard-preventif-repartition-conformites ref="vg-dashboard-preventif-repartition-conformites"
					v-if="dashboardPreventifGridClasses['vg-dashboard-preventif-repartition-conformites']"
					:class="[dashboardPreventifGridClasses['vg-dashboard-preventif-repartition-conformites'], {'drag':dragItemName=='vg-dashboard-preventif-repartition-conformites'}]"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedConformiteBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-preventif-repartition-conformites')"
					@drop="drop($event, 'vg-dashboard-preventif-repartition-conformites')"/>
				<vg-dashboard-preventif-relever-compteurs ref="vg-dashboard-preventif-relever-compteurs"
					v-if="dashboardPreventifGridClasses['vg-dashboard-preventif-relever-compteurs']"
					:class="[dashboardPreventifGridClasses['vg-dashboard-preventif-relever-compteurs'], {'drag':dragItemName=='vg-dashboard-preventif-relever-compteurs'}]"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedReleverCompteurBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-preventif-relever-compteurs')"
					@drop="drop($event, 'vg-dashboard-preventif-relever-compteurs')"/>
				<vg-dashboard-preventif-prochaines-interventions ref="vg-dashboard-preventif-prochaines-interventions"
					v-if="dashboardPreventifGridClasses['vg-dashboard-preventif-prochaines-interventions']"
					:class="[dashboardPreventifGridClasses['vg-dashboard-preventif-prochaines-interventions'], {'drag':dragItemName=='vg-dashboard-preventif-prochaines-interventions'}]"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-preventif-prochaines-interventions')"
					@drop="drop($event, 'vg-dashboard-preventif-prochaines-interventions')"/>
				<vg-dashboard-preventif-progression-interne ref="vg-dashboard-preventif-progression-interne"
					v-if="dashboardPreventifGridClasses['vg-dashboard-preventif-progression-interne']"
					:class="[dashboardPreventifGridClasses['vg-dashboard-preventif-progression-interne'], {'drag':dragItemName=='vg-dashboard-preventif-progression-interne'}]"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					draggable
					@selected="onSelectProgression"
					@drag-start="dragStart($event, 'vg-dashboard-preventif-progression-interne')"
					@drop="drop($event, 'vg-dashboard-preventif-progression-interne')"/>
				<vg-modal v-if="showVerificationsListModal"
					:width="'80vw'"
					:height="'100vh'"
					@close="onCloseVerificationsListModal">
					<template #header>
						{{$t('modal-details-title-verifications')}}
					</template>
					<template #body>
						<vg-verifications-viewer
							:filters="verificationsFilters" />
					</template>
					<template #footer>
						<vg-button
							type="default"
							@click="onCloseVerificationsListModal">
							{{$t("fermer")}}
						</vg-button>
					</template>
				</vg-modal>
				<vg-modal v-if="showProgressionModal"
					:width="'80vw'"
					:height="'100vh'"
					@close="onCloseProgressionModal">
					<template #header>
						{{$t("modal-details-title-progression")}}: <b style="margin-left:2px;">{{selectedProgression.libel_tache}}</b>
					</template>
					<template #body>
						<span>{{$t("equipements-fait")}}: {{selectedProgression.total_done}} / {{selectedProgression.total_todo}}</span>
						<br>
						<span>{{$t("date-butoire")}}: <vg-datetime-viewer v-model="selectedProgression.date_butoire" hideTime/></span>
						<br>
						<br>
						<tag-grid
	                        :idTableau="'dashboard-progression-tableau'"
	                        v-model="gridOptionsZoom"
	                        :columnDefs="columnDefsZoom"
	                        :rowData="equipmentToCheck"
	            			:showLoadingOverlay="showEquipementsLoadingOverlay"
	            			:overlayLoadingText="$t('equipements-overlay-loading-text')"
	            			:overlayNoRowsText="$t('equipements-overlay-no-rows-text')" />
					</template>
					<template #footer>
						<vg-button
							type="default"
							@click="onCloseProgressionModal">
							{{$t("fermer")}}
						</vg-button>
					</template>
				</vg-modal>
			</div>
			<div class="body-container" v-else-if="isConsommables && dateIntervalleRange && depotDefault">
				<vg-dashboard-consommables-repartition-consommations-maintenances ref="vg-dashboard-consommables-repartition-consommations-maintenances"
					v-if="dashboardConsommablesGridClasses['vg-dashboard-consommables-repartition-consommations-maintenances']"
					:class="[dashboardConsommablesGridClasses['vg-dashboard-consommables-repartition-consommations-maintenances'], {'drag':dragItemName=='vg-dashboard-consommables-repartition-consommations-maintenances'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedConsommationBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-consommables-repartition-consommations-maintenances')"
					@drop="drop($event, 'vg-dashboard-consommables-repartition-consommations-maintenances')"/>
				<vg-dashboard-consommables-repartition-consommations-bons-de-sortie ref="vg-dashboard-consommables-repartition-consommations-bons-de-sortie"
					v-if="dashboardConsommablesGridClasses['vg-dashboard-consommables-repartition-consommations-bons-de-sortie']"
					:class="[dashboardConsommablesGridClasses['vg-dashboard-consommables-repartition-consommations-bons-de-sortie'], {'drag':dragItemName=='vg-dashboard-consommables-repartition-consommations-bons-de-sortie'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedConsommationBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-consommables-repartition-consommations-bons-de-sortie')"
					@drop="drop($event, 'vg-dashboard-consommables-repartition-consommations-bons-de-sortie')"/>
				<vg-dashboard-consommables-repartition-en-stock ref="vg-dashboard-consommables-repartition-en-stock"
					v-if="dashboardConsommablesGridClasses['vg-dashboard-consommables-repartition-en-stock']"
					:class="[dashboardConsommablesGridClasses['vg-dashboard-consommables-repartition-en-stock'], {'drag':dragItemName=='vg-dashboard-consommables-repartition-en-stock'}]"
					:limit="10"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					:depotId="depotDefault.id"
					@selected="onSelectedConsommableBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-consommables-repartition-en-stock')"
					@drop="drop($event, 'vg-dashboard-consommables-repartition-en-stock')"/>
				<vg-modal v-if="showConsommationsListModal"
					:width="'80vw'"
					:height="'100vh'"
					@close="onCloseConsommationsListModal">
					<template #header>
						{{$t('modal-details-title-consommations-'+consommationsFilters.flag.value)}}
					</template>
					<template #body>
						<vg-consommable-mouvements-table defaultHeight="100%"
							:filters="consommationsFilters" />
					</template>
					<template #footer>
						<vg-button
							type="default"
							@click="onCloseConsommationsListModal">
							{{$t("fermer")}}
						</vg-button>
					</template>
				</vg-modal>
			</div>
			<div class="body-container" v-else-if="isBonsDeCommande && dateIntervalleRange">
				<vg-dashboard-bons-de-commande-repartition-montant-ht ref="vg-dashboard-bc-repartition-montant-ht"
					v-if="dashboardBonsDeCommandeGridClasses['vg-dashboard-bc-repartition-montant-ht']"
					:class="[dashboardBonsDeCommandeGridClasses['vg-dashboard-bc-repartition-montant-ht'], {'drag':dragItemName=='vg-dashboard-bc-repartition-montant-ht'}]"
					:filters="agfilters"
					:defaultDateIntervalle="dateIntervalleRange"
					@selected="onSelectedBonsDeCommandeMontantHtBar"
					draggable
					@drag-start="dragStart($event, 'vg-dashboard-bc-repartition-montant-ht')"
					@drop="drop($event, 'vg-dashboard-bc-repartition-montant-ht')"/>
				<vg-modal v-if="showBonsDeCommandeListModal"
					:width="'80vw'"
					:height="'100vh'"
					@close="onCloseBonsDeCommandeListModal">
					<template #header>
						{{$t('modal-details-title-bons-de-commande')}}
					</template>
					<template #body>
						<vg-bons-de-commande-table defaultHeight="100%"
							:filters="bonsDeCommandeFilters" />
					</template>
					<template #footer>
						<vg-button
							type="default"
							@click="onCloseBonsDeCommandeListModal">
							{{$t("fermer")}}
						</vg-button>
						<vg-button class="not-responsive"
							type="success"
							@click="handleBonsDeCommandeActionExport('askForCSV')">
							{{$t("export-csv")}}
						</vg-button>
						<vg-button class="not-responsive"
							type="success"
							@click="handleBonsDeCommandeActionExport('askForXLS')">
							{{$t("export-excel")}}
						</vg-button>
					</template>
				</vg-modal>
					
			</div>
		</template>
	</vg-app-layout>
</template>

<script>

	import VgButton from "src/components/Vg/VgButton.vue";
	import VgCheckbox from "src/components/Vg/VgCheckbox.vue";
	import VgSelect from "src/components/Vg/VgSelect.vue";
	import VgTabs from "src/components/Vg/VgTabs.vue";
	import VgDatetimeViewer from "src/components/Vg/VgDatetimeViewer.vue";
	import TagGrid from "src/components/Grid/TagGrid.vue";

	import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
	import VgFilterSite from 'src/components/Vg/Filters/VgFilterSite.vue';
	import VgTypologiesMaintenanceSelector from 'src/components/Vg/Selectors/VgTypologiesMaintenanceSelector.vue';

	import VgDashboardCuratifTotaux from "src/components/Vg/Dashboard/VgDashboardCuratifTotaux.vue";
	import VgDashboardCuratifUrgences from "src/components/Vg/Dashboard/VgDashboardCuratifUrgences.vue";
	import VgDashboardCuratifRepartitionAge from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionAge.vue";
	import VgDashboardCuratifRepartitionDemandeur from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionDemandeur.vue";
	import VgDashboardCuratifRepartitionUserAffecte from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionUserAffecte.vue";
	import VgDashboardCuratifRepartitionTiersAffecte from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionTiersAffecte.vue";
	import VgDashboardCuratifRepartitionComposants from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionComposants.vue";
	import VgDashboardCuratifRepartitionCategoriesEquipements from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionCategoriesEquipements.vue";
	import VgDashboardCuratifRepartitionCorpsDetat from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionCorpsDetat.vue";
	import VgDashboardCuratifRepartitionEquipements from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionEquipements.vue";
	import VgDashboardCuratifRepartitionEquipementsCouts from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionEquipementsCouts.vue";
	import VgDashboardCuratifRepartitionPieces from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionPieces.vue";
	import VgDashboardCuratifRepartitionDureeTraitement from "src/components/Vg/Dashboard/VgDashboardCuratifRepartitionDureeTraitement.vue";
	import VgDashboardCuratifEquipementsRatios from "src/components/Vg/Dashboard/VgDashboardCuratifEquipementsRatios.vue";

	import VgDashboardPreventifRepartitionConformites from "src/components/Vg/Dashboard/VgDashboardPreventifRepartitionConformites.vue";
	import VgDashboardPreventifReleverCompteurs from "src/components/Vg/Dashboard/VgDashboardPreventifReleverCompteurs.vue";
	import VgDashboardPreventifProchainesInterventions from "src/components/Vg/Dashboard/VgDashboardPreventifProchainesInterventions.vue";
	import VgDashboardPreventifProgressionInterne from "src/components/Vg/Dashboard/VgDashboardPreventifProgressionInterne.vue";

	import VgDashboardConsommablesRepartitionConsommationsMaintenances from "src/components/Vg/Dashboard/VgDashboardConsommablesRepartitionConsommationsMaintenances.vue";
	import VgDashboardConsommablesRepartitionConsommationsBonsDeSortie from "src/components/Vg/Dashboard/VgDashboardConsommablesRepartitionConsommationsBonsDeSortie.vue";
	import VgDashboardConsommablesRepartitionEnStock from "src/components/Vg/Dashboard/VgDashboardConsommablesRepartitionEnStock.vue";

	import VgDashboardBonsDeCommandeRepartitionMontantHt from "src/components/Vg/Dashboard/VgDashboardBonsDeCommandeRepartitionMontantHt.vue";

	import VgConsommableMouvementsTable from "src/components/Vg/Consommables/VgConsommableMouvementsTable.vue";
	import VgBonsDeCommandeTable from "src/components/Vg/BonDeCommande/VgBonsDeCommandeTable.vue";

	import VgDatePicker from "src/components/Vg/VgDatePicker.vue";
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";

	import VgFichesavTable from "src/components/Vg/FicheSAV/VgFichesavTable.vue";
	import VgVerificationsViewer from "src/components/Vg/Verification/VgVerificationsViewer.vue";

	import ZoomDashboardCellRender from "src/components/Vg/TagGrid/ZoomDashboardCellRender.vue";

	import DashboardMixins from "src/mixins/DashboardMixins.js";
	import VerificationMixins from "src/mixins/VerificationMixins.js";
	import StocksMixins from "src/mixins/StocksMixins.js";
	import MaintenanceMixins from "src/mixins/MaintenanceMixins.js";
	import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";

	import Metadatas from "src/services/Metadatas.js";
	import { mapGetters } from "vuex";

    export default {
        name: 'dashboard',
		mixins: [ DashboardMixins, VerificationMixins, StocksMixins, MaintenanceMixins, BonsDeCommandeMixins ],
        components: {
			VgButton,
			VgCheckbox,
			VgSelect,
			VgTabs,
			VgDatetimeViewer,
			TagGrid,
			VgTextFilter,
			VgFilterSite,
			VgTypologiesMaintenanceSelector,
			VgDashboardCuratifTotaux,
			VgDashboardCuratifUrgences,
			VgDashboardCuratifRepartitionAge,
			VgDashboardCuratifRepartitionComposants,
			VgDashboardCuratifRepartitionDemandeur,
			VgDashboardCuratifRepartitionUserAffecte,
			VgDashboardCuratifRepartitionTiersAffecte,
			VgDashboardCuratifRepartitionCategoriesEquipements,
			VgDashboardCuratifRepartitionCorpsDetat,
			VgDashboardCuratifRepartitionEquipements,
			VgDashboardCuratifRepartitionEquipementsCouts,
			VgDashboardCuratifRepartitionPieces,
			VgDashboardCuratifRepartitionDureeTraitement,
			VgDashboardCuratifEquipementsRatios,
			VgDashboardPreventifRepartitionConformites,
			VgDashboardPreventifReleverCompteurs,
			VgDashboardPreventifProchainesInterventions,
			VgDashboardPreventifProgressionInterne,
			VgDashboardConsommablesRepartitionConsommationsMaintenances,
			VgDashboardConsommablesRepartitionConsommationsBonsDeSortie,
			VgDashboardConsommablesRepartitionEnStock,
			VgDashboardBonsDeCommandeRepartitionMontantHt,
			VgDatePicker,
			VgDatePickerShortcuts,
			VgFichesavTable,
			VgVerificationsViewer,
			VgConsommableMouvementsTable,
			VgBonsDeCommandeTable
        },
		i18n:    { "locale":navigator.language,
    "messages": {
		    "fr": {
		        "title": "Tableau de bord",
		        "curative": "Maintenance curative",
		        "preventive": "Maintenance préventive",
		        "consommables": "Consommables",
		        "generer-rapport": "Générer rapport pdf",
		        "export-csv": "Export csv",
		        "modal-details-title": "Fiches curatives",
		        "modal-details-title-verifications": "Liste des verifications",
		        "fermer": "Fermer",
				"filtrer-par-sites": "Filtrer par sites",
				"sites-selected": "Sites sélectionnés",
				"title-modal-filtre-site": "Filtrer par sites",
				"restriction": "Restriction sites",
				"periode-de-visualisation": "Période de visualisation",
				"info-drag-and-drop": "Les rapports peuvent être réagencés par glisser-déposer",
				"action-afficher-cacher-rapports": "Afficher/cacher rapports",
				"action-print": "Imprimer tableau de bord",
				"title-modal-afficher-cacher-rapports": "Afficher / cacher les rapports",
				"checkbox-vg-dashboard-curatif-totaux": "Totaux fiches curatives",
				"checkbox-vg-dashboard-curatif-urgences": "Ouvertures fiches curatives urgentes / non urgentes",
				"checkbox-vg-dashboard-curatif-repartition-age": "Fiches curatives ouverte répartition age",
				"checkbox-vg-dashboard-curatif-repartition-categories-equipements": "REPARTITION des fiches curatives catégorie equipements",
				"checkbox-vg-dashboard-curatif-repartition-composants": "REPARTITION des fiches curatives composants",
				"checkbox-vg-dashboard-curatif-repartition-corps-detat": "REPARTITION des fiches curatives corps d'état",
				"checkbox-vg-dashboard-curatif-repartition-equipements": "REPARTITION des fiches curatives équipements",
				"checkbox-vg-dashboard-curatif-repartition-equipements-couts": "REPARTITION des coûts fiches curatives par équipements",
				"checkbox-vg-dashboard-curatif-repartition-pieces": "REPARTITION des fiches curatives lieux",
				"checkbox-vg-dashboard-curatif-repartition-demandeur": "REPARTITION des fiches curatives demandeur",
				"checkbox-vg-dashboard-curatif-repartition-user-affecte": "REPARTITION des fiches curatives utilisateur affecté",
				"checkbox-vg-dashboard-curatif-repartition-tiers-affecte": "REPARTITION des fiches curatives tiers affecté",
				"checkbox-vg-dashboard-curatif-repartition-duree-traitement": "REPARTITION des fiches curatives durée traitement",
				"checkbox-vg-dashboard-preventif-repartition-conformites": "REPARTITION des fiches préventives conformités",
				"checkbox-vg-dashboard-preventif-relever-compteurs": "REPARTITION des fiches préventives relevé compteurs",
				"checkbox-vg-dashboard-preventif-prochaines-interventions": "REPARTITION des fiches préventives prochaines interventions",
				"checkbox-vg-dashboard-preventif-progression-interne": "REPARTITION des fiches préventives progression interne",
				"checkbox-vg-dashboard-consommables-repartition-consommations-maintenances": "REPARTITION des consommations fiches curatives",
				"checkbox-vg-dashboard-consommables-repartition-consommations-bons-de-sortie": "REPARTITION des consommations bons de sortie",
				"checkbox-vg-dashboard-consommables-repartition-en-stock": "REPARTITION des consommables en stock",
				"checkbox-vg-dashboard-bc-repartition-montant-ht": "REPARTITION des bons de commande montant HT",
				"dashboard-print-file-name": "tableau_bord_verifgood_{startDate}_{endDate}",
				"dashboard-print-title": "Tableau de bord du {startDate} au {endDate}",
				"modal-details-title-progression": "Progression tâche",
				"equipements-fait": "Equipements fait",
				"date-butoire": "Date butoire",
				"equipements-overlay-loading-text": "Chargement des équipements à vérifier...",
				"equipements-overlay-no-rows": "Aucun équipement à verifier",
				"modal-details-title-consommations-consommation-fm": "Consommations des fiches curatives",
				"modal-details-title-consommations-bon-de-sortie": "Consommations des bons de sortie",
				"modal-details-title-bons-de-commande": "Bons de commande",
				"filtrage-typologie-maintenance": "Filtre typologie",
				"export-excel": "Export-Excel",
				"bons-de-commande": "Bons de commande",
				"liste-maintenances": "Liste-fiches-curatives"
		    },
		    "en": {
		        "title": "Dashboard",
		        "curative": "Curative maintenance",
		        "preventive": "Preventive maintenance",
		        "consommables": "Consumables",
		        "generer-rapport": "Create PDF report",
		        "export-csv": "Export-CSV",
				"fermer": "Close",
				"modal-details-title": "Curative sheets",
				"modal-details-title-verifications": "Verifications list",
				"filtrer-par-sites": "Filter by sites",
				"sites-selected": "Selected sites",
				"info-drag-and-drop": "Reports can be reordered by drag & drop",
				"action-afficher-cacher-rapports": "Show/hide reports",
				"action-print": "Print dashboard",
				"title-modal-afficher-cacher-rapports": "Show / hide reports",
				"checkbox-vg-dashboard-curatif-totaux": "Total curative sheets",
				"checkbox-vg-dashboard-curatif-urgences": "Urgent / non-urgent curative sheet openings",
				"checkbox-vg-dashboard-curatif-repartition-age": "Open curative sheet age distribution",
				"checkbox-vg-dashboard-curatif-repartition-categories-equipements": "Curative sheets per equipments categories",
				"checkbox-vg-dashboard-curatif-repartition-composants": "Curative sheets per components",
				"checkbox-vg-dashboard-curatif-repartition-corps-detat": "Curative sheets per job categories",
				"checkbox-vg-dashboard-curatif-repartition-equipements": "Curative sheets per equipments",
				"checkbox-vg-dashboard-curatif-repartition-equipements-couts": "Curative sheets costs per equipments",
				"checkbox-vg-dashboard-curatif-repartition-pieces": "Curative sheets per rooms",
				"checkbox-vg-dashboard-curatif-repartition-demandeur": "Curative sheets per requester",
				"checkbox-vg-dashboard-curatif-repartition-user-affecte": "Curative sheets per affected user",
				"checkbox-vg-dashboard-curatif-repartition-tiers-affecte": "Curative sheets per affected third party",
				"checkbox-vg-dashboard-curatif-repartition-duree-traitement": "Curative sheets per treatment duration",
				"checkbox-vg-dashboard-preventif-repartition-conformites": "Preventive sheets per compliance",
				"checkbox-vg-dashboard-preventif-relever-compteurs": "Preventive sheets per meter readings",
				"checkbox-vg-dashboard-preventif-prochaines-interventions": "Preventive sheets per next interventions",
				"checkbox-vg-dashboard-preventif-progression-interne": "Preventive sheets per internal progression",
				"checkbox-vg-dashboard-consommables-repartition-consommations-maintenances": "Curative sheets consumptions",
				"checkbox-vg-dashboard-consommables-repartition-consommations-bons-de-sortie": "Exit vouchers consumptions",
				"checkbox-vg-dashboard-consommables-repartition-en-stock": "Consumables in stock",
				"checkbox-vg-dashboard-bc-repartition-montant-ht": "Purchase orders amount HT",
				"dashboard-print-file-name": "dashboard_verifgood_{startDate}_{endDate}",
				"dashboard-print-title": "Dashboard from {startDate} to {endDate}",
				"modal-details-title-progression": "Task progress",
				"equipements-fait": "Equipments done",
				"date-butoire": "Deadline",
				"equipements-overlay-loading-text": "Loading equipments to check...",
				"equipements-overlay-no-rows": "No equipment to check",
				"modal-details-title-consommations-consommation-fm": "Curative sheets consumptions",
				"modal-details-title-consommations-bon-de-sortie": "Exit vouchers consumptions",
				"modal-details-title-bons-de-commande": "Purchase orders",
				"filtrage-typologie-maintenance": "Typology filter",
				"export-excel": "Export-Excel",
				"bons-de-commande": "Purchase orders",
            	"liste-maintenances": "Curative-sheets-list"
		    },
		    "th": {
		        "curative": "เชิงแก้ไข",
		        "preventive": "เชิงป้องกัน"
		    }
    }
},

		filters: {
			site: function(path){
				return path.split("/")[1];
			}
		},
        data: function() {
            return {
				view: "curative", // curative || preventive
				showMaintenancesListModal: false,
				showVerificationsListModal: false,
				showProgressionModal: false,
				showConsommationsListModal: false,
				maintenancesFilters: [],
				idUserAffecte: null,
				idTiersAffecte: null,
				agfilters:{
					sites: {attr: "l.path", value: null, action: "start_with"},
					typologie: {attr: "fm.typologie", value: null, action: "equals"}
				},
				consommationsFilters:{},
				searchQuery: "",
				showModalFiltreSites: false,
				dateIntervalleRange: null,
				dragItemName: null,
				dashboardCuratifGrid: {
					// 1ere colonne
					"vg-dashboard-curatif-totaux": {col:1,colSize:1,row:1,rowSize:1},	//"grid-col-1-md-row-1-md",
					"vg-dashboard-curatif-repartition-composants": {col:1,colSize:1,row:2,rowSize:1},
					"vg-dashboard-curatif-repartition-pieces": {col:1,colSize:1,row:3,rowSize:1},
					"vg-dashboard-curatif-repartition-equipements": {col:1,colSize:1,row:4,rowSize:1},
					"vg-dashboard-curatif-urgences": {col:1,colSize:1,row:5,rowSize:1},
					// 2eme colonne
					"vg-dashboard-curatif-repartition-age": {col:2,colSize:1,row:1,rowSize:1},
					"vg-dashboard-curatif-repartition-categories-equipements": {col:2,colSize:1,row:2,rowSize:1},
					"vg-dashboard-curatif-repartition-corps-detat": {col:2,colSize:1,row:3,rowSize:1},
					"vg-dashboard-curatif-repartition-equipements-couts": {col:2,colSize:1,row:4,rowSize:1},
					"vg-dashboard-curatif-repartition-demandeur": {col:2,colSize:1,row:5,rowSize:1},
					"vg-dashboard-curatif-repartition-user-affecte": {col:1,colSize:1,row:6,rowSize:1},
					"vg-dashboard-curatif-repartition-tiers-affecte": {col:2,colSize:1,row:6,rowSize:1},
					"vg-dashboard-curatif-repartition-duree-traitement": {col:1,colSize:1,row:7,rowSize:1},
				},
				dashboardPreventifGrid: {
					"vg-dashboard-preventif-repartition-conformites": {col: 1, colSize: 1, row: 1, rowSize: 1},
					"vg-dashboard-preventif-relever-compteurs": {col: 2, colSize: 1, row: 1, rowSize: 1},
					"vg-dashboard-preventif-prochaines-interventions": {col: 1, colSize: 1, row: 2, rowSize: 1},
					"vg-dashboard-preventif-progression-interne": {col: 2, colSize: 1, row: 2, rowSize: 1}
				},
				dashboardConsommablesGrid: {
					"vg-dashboard-consommables-repartition-consommations-maintenances": {col: 1, colSize: 1, row: 1, rowSize: 1},
					"vg-dashboard-consommables-repartition-consommations-bons-de-sortie": {col: 2, colSize: 1, row: 1, rowSize: 1},
					"vg-dashboard-consommables-repartition-en-stock": {col: 1, colSize: 1, row: 2, rowSize: 1}
				},
				dashboardBonsDeCommandeGrid:{
					"vg-dashboard-bc-repartition-montant-ht": {col: 1, colSize: 2, row: 1, rowSize: 1}
				},
				dashboardCuratifGridClasses: {},
				dashboardPreventifGridClasses: {},
				dashboardConsommablesGridClasses: {},
				dashboardBonsDeCommandeGridClasses: {},
				showRapportsModal: false,
				verificationsFilters: [],
				selectedProgression: null,
				equipmentToCheck: null,
				gridOptionsZoom: {
	                headerHeight: 0,
	                rowHeight: 100,
	            },
				columnDefsZoom: [{
	                    headerName: "Libelle tache",
	                    field: 'libel_equipement',
	                    colId: 'libel_equipement',
	                    suppressSizeToFit: false,
	                    unSortIcon: true,
	                    cellClass: ["vg-cell-mobile-card"],
	                    cellRenderer: (params) => new ZoomDashboardCellRender({
	                        propsData: {
	                            params: params.data,
	                            icon:this.selectedProgression.icon
	                        }
	                    }).$mount().$el,
	                },
	                {
	                    headerName: "id",
	                    width: 180,
	                    field: 'id',
	                    colId: 'id',
	                    hide: true,
	                },
	                {
	                    headerName: "path",
	                    width: 180,
	                    field: 'path',
	                    colId: 'path',
	                    filter: 'agTextColumnFilter',
	                    hide: true,
	                },
	                {
	                    headerName: "qrCode",
	                    width: 180,
	                    field: 'qrCode',
	                    colId: 'qrCode',
	                    hide: true,
	                },
	                {
	                    headerName: "isScanRequired",
	                    width: 180,
	                    field: 'isScanRequired',
	                    colId: 'isScanRequired',
	                    hide: true
	                }
	            ],
				initialDateIntervalleRange: {
					startDate: moment().startOf('year').format("YYYY-MM-DD HH:mm"),
					endDate: moment().endOf('year').format("YYYY-MM-DD HH:mm")
				},
				bonsDeCommandeFilters: {},
				showBonsDeCommandeListModal: false
			};
		},
        computed: {
			...mapGetters({
	            depotDefault: "StocksStore/getDepotDefault",
	            depotFlottant: "StocksStore/getDepotFlottant"
	        }),
            pageFilters: function(){
                return ["sites", "typologie"];
            },
            activeFilters: function(){
                let filtersNames = this.pageFilters;
                let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
                return activeFilters.length;
            },
			typeMaintenance: function(){
				let tabs = [];
				if(this.can("DASHBOARD.CURATIF")) tabs.push({label:this.$t("curative"),value:"curative"});
				if(this.can("DASHBOARD.PREVENTIF")) tabs.push({label:this.$t("preventive"),value:"preventive"});
				if(this.can("DASHBOARD.CONSOMMABLE")) tabs.push({label:this.$t("consommables"),value:"consommables"});
				if(this.can("DASHBOARD.BON_DE_COMMANDE")) tabs.push({label:this.$t("bons-de-commande"),value:"bons-de-commande"});
				return tabs;
			},
			getHeaderColor: function(){
                return this.$vgutils.isMobile() ? '#ffc73d' : 'white';
            },
			getRapportsList: function(){
				return Object.keys(this.getDashboardGridClasses);
			},
			isCurative: function(){
				return this.view=="curative";
			},
			isPreventive: function(){
				return this.view=="preventive";
			},
			isConsommables: function(){
				return this.view=="consommables";
			},
			isBonsDeCommande: function(){
				return this.view=="bons-de-commande";
			},
			getDashboardGrid: function(){
				if(this.isCurative) return this.dashboardCuratifGrid;
				else if(this.isPreventive) return this.dashboardCuratifGrid;
				else if(this.isConsommables) return this.dashboardConsommablesGrid;
				else if(this.isBonsDeCommande) return this.dashboardBonsDeCommandeGrid;
				else return {};
			},
			getDashboardGridClasses: function(){
				if(this.isCurative) return this.dashboardCuratifGridClasses;
				else if(this.isPreventive) return this.dashboardPreventifGridClasses;
				else if(this.isConsommables) return this.dashboardConsommablesGridClasses;
				else if(this.isBonsDeCommande) return this.dashboardBonsDeCommandeGridClasses;
				else return {};
			},
        },
        methods: {
			initDefaultDateIntervalleRange: function(){
				if(localStorage.getItem("dashboard-default-date-picker-value")){
					let dateIntervalle = JSON.parse(localStorage.getItem("dashboard-default-date-picker-value"));
					if(dateIntervalle && dateIntervalle.startDate && dateIntervalle.endDate){ 
						this.initialDateIntervalleRange = dateIntervalle;
						this.dateIntervalleRange = dateIntervalle;
					}else localStorage.setItem("dashboard-default-date-picker-value", null);
				}else{
					this.dateIntervalleRange = Object.assign({}, {}, this.initialDateIntervalleRange);
				}
			},
			onChangeTypologieMaintenance: function(typologie){
				localStorage.setItem("dashboard-curative-typologie", typologie);
			},
            onRemoveFilters: function(){
                this.pageFilters.forEach((filterName)=> this.agfilters[filterName].value = null );
            },
			onSelectProgression: function(datas){
				console.log("onSelectProgression", datas);
				this.selectedProgression = datas.progression;
				this.showProgressionModal = true;
				this.showProgressionLoadingOverlay = true;
				this.VerificationMixins_getTacheState(datas.progression).then((response)=>{
	                this.equipmentToCheck = response;
	                this.showProgressionLoadingOverlay = false;
	            });
			},
            handleMaintenancesActionExport:function(name){
                let metadatas = new Metadatas();
                metadatas.setFilters(this.maintenancesFilters);
                switch (name) {
                    case "askForXLS":
                        this.MaintenanceMixins_getFile(metadatas,this.$t("liste-maintenances"),"xlsx").then((datas)=>{});
                        break;
                    case "askForCSV":
                        this.MaintenanceMixins_getFile(metadatas,this.$t("liste-maintenances"),"csv").then((datas)=>{});
                        break;
                    case "gestionColonnesEventId":
                        window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                            "detail": {"idTableau":"vg-fichesav-table"}
                        }));
                        break;
                    default:
                }
            },
            handleBonsDeCommandeActionExport:function(name){
				let metadatas = new Metadatas();
				metadatas.setFilters(this.bonsDeCommandeFilters);
				switch (name) {
                    case "askForXLS":
                        this.BonsDeCommandeMixins_export(metadatas, "bons-de-commande", "xlsx").then((datas)=>{});
                        break;
                    case "askForCSV":
						this.BonsDeCommandeMixins_export(metadatas, "bons-de-commande", "csv").then((datas)=>{});
                        break;
					case "gestionColonnesEventId":
						window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
							"detail": {"idTableau":"bons-de-commande-tableau"}
						}));
						break;
					default:
						break;
				}
            },
			getReadableDate: function(dt){
				return moment(dt).format("DD/MM/YYYY");
			},
			onPrintDashboard: function(){
				document.title = this.$t("dashboard-print-file-name", {startDate: moment(this.dateIntervalleRange.startDate).format("YYYY-MM-DD"), endDate: moment(this.dateIntervalleRange.endDate).format("YYYY-MM-DD")});
				window.print();
			},
			isRapportShown: function(rapportName){
				return this.getDashboardGridClasses[rapportName]!=null;
			},
			showRapport: function(rapportName){
				let maxRowFirstColumn = 1;
				let maxRowSecondColumn = 1;
				let currentRapportPosition = null;
				let dashboardGrid = this.getDashboardGrid;
				Object.keys(dashboardGrid).forEach((name)=>{
					currentRapportPosition = dashboardGrid[name];
					if(currentRapportPosition && currentRapportPosition.col==1 && currentRapportPosition.row>maxRowFirstColumn){
						maxRowFirstColumn = currentRapportPosition.row;
					}
					if(currentRapportPosition && currentRapportPosition.col==2 && currentRapportPosition.row>maxRowSecondColumn){
						maxRowSecondColumn = currentRapportPosition.row;
					}
				});
				if(maxRowFirstColumn<=maxRowSecondColumn) dashboardGrid[rapportName] = {col:1, colSize:1, row:maxRowFirstColumn+1, rowSize:1};
				else dashboardGrid[rapportName] = {col:2, colSize:1, row:maxRowSecondColumn+1, rowSize:1};
				this.setLocalStorage(dashboardGrid, this.view);
				this.setGridClasses();
			},
			hideRapport: function(rapportName){
				// décalle tous les rapports au dessous en row-1
				let rapportPosition = this.getDashboardGrid[rapportName];
				Object.keys(this.getDashboardGrid).forEach((name)=>{
					if(name!=rapportName && this.getDashboardGrid[name] && this.getDashboardGrid[name].col==rapportPosition.col && this.getDashboardGrid[name].row>rapportPosition.row){
						this.getDashboardGrid[name].row = this.getDashboardGrid[name].row-1;
					}
				});
				this.getDashboardGrid[rapportName] = null;
				this.setLocalStorage(this.getDashboardGrid, this.view);
				this.setGridClasses();
			},
			dragStart: function(ev, refName=null){
				this.dragItemName = refName;
			},
			drop: function(ev, refName=null){
				if(refName && refName!=this.dragItemName){
					ev.preventDefault();
					this.switchItems(this.dragItemName, refName);
					this.dragItemName = null;
					this.setLocalStorage(this.getDashboardGrid, this.view);
					this.setGridClasses();
				}
			},
			/**
			* interverti les items entre eux
			* @param String fromItemName
			* @param String toItemName
			*/
			switchItems: function(fromItemName, toItemName){
				let fromItemPosition = this.getDashboardGrid[fromItemName];
				let toItemPosition = this.getDashboardGrid[toItemName];
				this.getDashboardGrid[fromItemName] = {col:toItemPosition.col, colSize:toItemPosition.colSize, row:toItemPosition.row, rowSize:toItemPosition.rowSize};
				this.getDashboardGrid[toItemName] = {col:fromItemPosition.col, colSize:fromItemPosition.colSize, row:fromItemPosition.row, rowSize:fromItemPosition.rowSize};
			},
			setCuratifGridClasses: function(){
				Object.keys(this.dashboardCuratifGrid).forEach((itemName)=>{
					let refGridPosition = this.dashboardCuratifGrid[itemName];	// {col:1,colSize:1,row:1,rowSize:1};
					if(refGridPosition){
						let colSize = refGridPosition.colSize==1?"md":"lg";
						let rowSize = refGridPosition.rowSize==1?"md":"lg";
						this.dashboardCuratifGridClasses[itemName] = "grid-col-"+refGridPosition.col+"-"+colSize+"-row-"+refGridPosition.row+"-"+rowSize;
					}else{
						this.dashboardCuratifGridClasses[itemName] = null;
					}
				});
				this.$forceUpdate();
			},
			setPreventifGridClasses: function(){
				Object.keys(this.dashboardPreventifGrid).forEach((itemName)=>{
					let refGridPosition = this.dashboardPreventifGrid[itemName];	// {col:1,colSize:1,row:1,rowSize:1};
					if(refGridPosition){
						let colSize = refGridPosition.colSize==1?"md":"lg";
						let rowSize = refGridPosition.rowSize==1?"md":"lg";
						this.dashboardPreventifGridClasses[itemName] = "grid-col-"+refGridPosition.col+"-"+colSize+"-row-"+refGridPosition.row+"-"+rowSize;
					}else{
						this.dashboardPreventifGridClasses[itemName] = null;
					}
				});
				this.$forceUpdate();
			},
			setConsommablesGridClasses: function(){
				Object.keys(this.dashboardConsommablesGrid).forEach((itemName)=>{
					let refGridPosition = this.dashboardConsommablesGrid[itemName];	// {col:1,colSize:1,row:1,rowSize:1};
					if(refGridPosition){
						let colSize = refGridPosition.colSize==1?"md":"lg";
						let rowSize = refGridPosition.rowSize==1?"md":"lg";
						this.dashboardConsommablesGridClasses[itemName] = "grid-col-"+refGridPosition.col+"-"+colSize+"-row-"+refGridPosition.row+"-"+rowSize;
					}else{
						this.dashboardConsommablesGridClasses[itemName] = null;
					}
				});
				this.$forceUpdate();
			},
			setBonsDeCommandeGridClasses: function(){
				Object.keys(this.dashboardBonsDeCommandeGrid).forEach((itemName)=>{
					let refGridPosition = this.dashboardBonsDeCommandeGrid[itemName];	// {col:1,colSize:1,row:1,rowSize:1};
					if(refGridPosition){
						let colSize = refGridPosition.colSize==1?"md":"lg";
						let rowSize = refGridPosition.rowSize==1?"md":"lg";
						this.dashboardBonsDeCommandeGridClasses[itemName] = "grid-col-"+refGridPosition.col+"-"+colSize+"-row-"+refGridPosition.row+"-"+rowSize;
					}else{
						this.dashboardBonsDeCommandeGridClasses[itemName] = null;
					}
				});
				this.$forceUpdate();
			},
			onChangeDateIntervalle: function(data){
				console.log("CHANGE DATE INTERVALLE", data);
				localStorage.setItem("dashboard-default-date-picker-value", JSON.stringify(data));
				this.dateIntervalleRange = data;
			},
			onFinishDateSelection: function(data){
				console.log("FINISH DATE SELECTION", data);
				localStorage.setItem("dashboard-default-date-picker-value", JSON.stringify(data));
				this.dateIntervalleRange = data;
			},
			addSitesFiltersToMaintenancesFilters: function(){
				if(this.agfilters.sites.value) this.maintenancesFilters.push(Object.assign({},{}, this.agfilters.sites));
			},
			addSitesFiltersToVerificationsFilters: function(){
				if(this.agfilters.sites.value) this.verificationsFilters.push(Object.assign({},{}, this.agfilters.sites));
			},
			onSelectedDemandeur: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedUserAffecte: function(datas){
				console.log("SELECTED USER AFFECTE", datas);
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				if(datas.idUserSelected) this.idUserAffecte = datas.idUserSelected;
				else this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedTiersAffecte: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				if(datas.idTiersSelected) this.idTiersAffecte = datas.idTiersSelected;
				else this.idTiersAffecte = null;
				this.idUserAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedDureeTraitement: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedComposantBar: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedCategorieEquipementBar: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedPieceBar: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedCorpsDetatBar: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedEquipementBar: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedAge: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedTotaux: function(datas){
				console.log("SELECTED TOTAUX", datas, this.agfilters.sites.value);
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedPieUrgences: function(datas){
				this.maintenancesFilters = [...datas.filters];
				this.addSitesFiltersToMaintenancesFilters();
				this.maintenancesFilters.push({attr: "fm.dateOuvertureSAV", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"});
				this.idUserAffecte = null;
				this.idTiersAffecte = null;
				this.showMaintenancesListModal = true;
			},
			onSelectedConformiteBar: function(datas){
				let month = datas.bar.month;
				let filters = [{attr: "et.dateVerif", value: [month+"-01", month+"-31"], action: "between"}];
				if(datas.name=="verifications-non-conformes") filters.push({attr: "et.nbNonConformites", value: 1, action: "greater_than"});
				this.verificationsFilters = [...filters];
				this.addSitesFiltersToVerificationsFilters();
				this.showVerificationsListModal = true;
			},
			onSelectedConsommationBar: function(datas){
				let filters = datas.filters;
				filters.dateMouvement = {attr: "cm.dateMouvement", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"};
				this.consommationsFilters = filters;
				this.showConsommationsListModal = true;
			},
			onSelectedConsommableBar: function(datas){
				this.$router.push({ name: '_consommable_id', params: { id: datas.bar.id } });
			},
			onSelectedBonsDeCommandeMontantHtBar: function(datas){
				let filters = datas.filters;
				filters.dateCreation = {attr: "bc.dateCreation", value: [datas.dateIntervalle.startDate, datas.dateIntervalle.endDate], action: "between"};
				this.bonsDeCommandeFilters = filters;
				this.showBonsDeCommandeListModal = true;
			},
			onSelectedBonsDeCommandeSite: function(datas){
				
			},
			onCloseMaintenancesListModal: function(){
				this.showMaintenancesListModal = false;
				this.maintenancesFilters = [];
				this.$store.dispatch("MaintenancesStore/setMaintenances", []);
			},
			onCloseVerificationsListModal: function(){
				this.showVerificationsListModal = false;
				this.verificationsFilters = [];
				this.$store.dispatch("VerificationsStore/set", []);
			},
			onCloseConsommationsListModal: function(){
				this.showConsommationsListModal = false;
				this.consommationsFilters = {};
				this.$store.dispatch("ConsommableMouvementsStore/set", []);
			},
			onCloseBonsDeCommandeListModal: function(){
				this.showBonsDeCommandeListModal = false;
				this.bonsDeCommandeFilters = {};
				this.$store.dispatch("BonsDeCommandeStore/set", []);
			},
			onCloseProgressionModal: function(){
				this.showProgressionModal = false;
				this.equipmentToCheck = [];
				this.selectedProgression = null;
			},
			openRapportMaintenances: function(){
				this.$router.push({ name: '_maintenances_print' });
			},
			exportCSV: function(){
				window.dispatchEvent(new CustomEvent("askForCSV", {
					"detail": {"idTableau": "vg-fichesav-table"}
				}));
			},
			setLocalStorage: function(grid, view="curative"){
				localStorage.setItem("dashboard-"+view+"-grid", JSON.stringify(grid));
			},
			getLocalStorage: function(view="curative"){
				return Object.assign({}, {}, JSON.parse(localStorage.getItem("dashboard-"+view+"-grid")));
			},
			setGridClasses: function(){
				this.setCuratifGridClasses();
				this.setPreventifGridClasses();
				this.setConsommablesGridClasses();
				this.setBonsDeCommandeGridClasses();
			},
			initLocalStorage: function(){
				// curatif
				if(!localStorage.getItem("dashboard-curative-grid")) this.setLocalStorage(this.dashboardCuratifGrid);
				else this.dashboardCuratifGrid = this.getLocalStorage();
				// preventif
				if(!localStorage.getItem("dashboard-preventive-grid")) this.setLocalStorage(this.dashboardPreventifGrid, "preventive");
				else this.dashboardPreventifGrid = this.getLocalStorage("preventive");
				// consommables
				if(!localStorage.getItem("dashboard-consommables-grid")) this.setLocalStorage(this.dashboardConsommablesGrid, "consommables");
				else this.dashboardConsommablesGrid = this.getLocalStorage("consommables");
				// typologie
				if(localStorage.getItem("dashboard-curative-typologie") && localStorage.getItem("dashboard-curative-typologie")!="null") this.agfilters.typologie.value = localStorage.getItem("dashboard-curative-typologie");
				else this.agfilters.typologie.value = null;
			},
			initFocus: function(){
				if(this.$route.params.focus) this.view = this.$route.params.focus;
			},
			fetchDepots: function(){
	            return new Promise((resolve, reject)=>{
	                let metadatasDepots = new Metadatas();
	                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
	                    resolve(datas);
	                });
	            });
	        },
        },
		created: function(){
			this.initDefaultDateIntervalleRange();
			this.initFocus();
			this.initLocalStorage();
			this.setGridClasses();
			this.fetchDepots();
		},
        mounted: function(){
			
        }
    };
</script>
<style lang="scss" scoped>
.select-tableau-view{
	width: auto;
	min-width: 250px;
}
.body-container{
	height: 100%;
	width: 100%;
	display: grid;
	justify-content: stretch;
	align-content: stretch;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: minmax(250px, 30vh) minmax(250px, 30vh) minmax(250px, 30vh);
	grid-auto-rows: minmax(250px, 30vh);
	gap: 15px;
	[draggable="true"] {
		/*
		To prevent user selecting inside the drag source
		*/
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
	.drag{
		border: 2px solid #d3d7dd;
		opacity: 0.8;
	}
}
@media screen and (max-width: 1200px) {
	.body-container{
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 15px 0;
		>div{
			width: 100%;
		}
	}
	.not-responsive{
		display: none;
	}
}
.site-name{
	padding: 0 5px;
	font-weight: bold;
	border: 1px solid white;
}
.site-name-more{
	padding: 0 5px;
	font-weight: bold;
	border: 1px solid white;
}

@mixin gridItem($colStart, $rowStart) {
	.grid-col-#{$colStart}-md-row-#{$rowStart}-md {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 1) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 1) !important;
	}
	.grid-col-#{$colStart}-lg-row-#{$rowStart}-md {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 2) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 1) !important;
	}
	.grid-col-#{$colStart}-md-row-#{$rowStart}-lg {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 1) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 2) !important;
	}
	.grid-col-#{$colStart}-lg-row-#{$rowStart}-lg {
		grid-column-start: #{$colStart} !important;
		grid-column-end: calc($colStart + 2) !important;
		grid-row-start: #{$rowStart} !important;
		grid-row-end: calc($rowStart + 2) !important;
	}
}

@for $col from 1 to 2 {
	@for $row from 1 to 20 {
	    @include gridItem($col, $row);
	}
}

#dashboard-print-title{
	display: none;
}
@media print{
	#dashboard-print-title{
		display: block;
		font-size: 20px;
		font-weight: bold;
		margin: 20px 0;
	}
}
</style>
