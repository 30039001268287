<template lang="html">
	<vg-input :title="title">
		<vg-date-picker-shortcuts
			:defaultStartDate="startDate"
			:defaultEndDate="endDate"
			:appendToBody="true"
			opens="left"
			previousDatesPresets
			@select="handleChangeDatePicker"
			@finish-selection="handleChangeDatePicker" />
	</vg-input>
</template>
<script>
	import VgInput from "src/components/Vg/VgInput.vue"
	import VgDatePickerShortcuts from "src/components/Vg/VgDatePickerShortcuts.vue";
    export default {
        name: 'vg-filter-date-intervalle',
	    components: {
			VgInput,
			VgDatePickerShortcuts
        },
		filters: {

		},
		mixins:[

        ],
        props: {
			title: {
				type: String,
				default: null
			},
			defaultStartDate: {
				type: String,
				default: null
			},
			defaultEndDate: {
				type: String,
				default: null
			}
        },
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Filtrage par date"
        },
        "en": {
            "title": "Filter by date"
        },
        "th": {
            "title": "กรองโดยวันที่"
        }
    }
},
        data: function(){
            return {
				startDate: this.defaultStartDate,
				endDate: this.defaultEndDate
			};
        },
		watch: {
			defaultStartDate: {
				handler: function(dt){
					this.startDate = dt;
				}
			},
			defaultEndDate: {
				handler: function(dt){
					this.endDate = dt;
				}
			}
		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			handleChangeDatePicker: function(e){
				this.$emit("change", {startDate: e.startDate, endDate: e.endDate});
			},
		},
		computed: {
			
		}
	};
</script>

<style lang="scss" scoped>

</style>
