<template>
    <div v-if="piece && existInDatabase"
        :class="['piece-marker',{'piece-marker-created': hasPieceId, 'piece-marker-has-maintenance': piece.hasMaintenance, 'piece-marker-zoom-out': zoomOut}]"
        :draggable="draggable"
        @dragstart="$emit('drag-start', {event: $event, piece: piece})"
        @click="$emit('click', {event: $event, piece: piece})">
        <span>{{piece.libel_lieu}}</span>
    </div>
    <div v-else class="piece-marker piece-marker-to-delete">
        <span>{{$t("none")}} - {{ piece.libel_lieu }}</span>
    </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
    name:"piece-marker",
    components:{},
	props: {
        piece:{
            type: Object,
            required: true
        },
        geoJsonPoint: {
            type: Object
        },
        draggable: {
            type: Boolean,
            default: false
        },
        /** 
         * marker minimaliste pour rendre le marker plus petit 
         * */
        zoomOut:{
            type: Boolean,
            default: false
        },
        pieces: {
            type: Array,
            default: function(){
                return [];
            }
        }
	},
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "none": "A supprimer"
        },
        "en": {
            "none": "To delete"
        }
    }
},
    data: function(){
        return {

        };
    },
    methods:{

    },
    computed: {
        hasPieceId: function(){
            return this.piece && this.piece.hasOwnProperty("id") && this.piece.id;
        },
        existInDatabase: function(){
            return this.piece && this.pieces && this.pieces.findIndex((piece)=>piece.id==this.piece.id)!=-1;
        }
    }
});
</script>
<style lang="scss">
.leaflet-div-icon{
    border: white !important;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leaflet-marker-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style lang="scss" scoped>
$marker-default-background-color: #ff9967;
$marker-piece-background-color: #7b54a0;
$marker-to-delete-background-color: #aaa;
$marker-default-color: white;
.piece-marker{
    cursor:pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background-color: $marker-default-background-color;
    color: $marker-default-color;
    font-size: 10px;
    border-radius: 3px;
    span{
        height: 100%;
        width: 100%;
        padding: 3px 6px;
    }
}
.piece-marker-created{
    background-color: $marker-piece-background-color;
}
.piece-marker-to-delete{
    background-color: $marker-to-delete-background-color;
}
.piece-marker-zoom-out{
    font-size: 10px;
    span{
        padding: 0px 2px;
    }
}
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
.piece-marker-has-maintenance {
    background-color: red;
    -webkit-animation: blink 1s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation: blink 1s;
    -moz-animation-iteration-count: infinite;
    -o-animation: blink 1s;
    -o-animation-iteration-count: infinite;
}
</style>
