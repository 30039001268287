<template>
	<vg-modal
		:title="$t('myAccount.profilUser')"
		@close="$emit('close')">
		<template #body>
			<div class="informationName">
				<i class="fa fa-user-circle" style="margin-right:10px;"></i> {{user.prenom}} {{user.nom}}
			</div>
			<br>
			<div class="informationEmail">
				<i class="fas fa-at" style="margin-right:10px;"></i> {{user.mail}}
			</div>
			<br>
			<div class="informationRole">
				<i class="fas fa-info-circle" style="margin-right:10px;"></i> <vg-user-role-viewer v-model="user.role" />
			</div>
			<br>
			<vg-lang-selector />
		</template>
		<template #footer>
			<vg-button :type="'danger'" @click="handleDisconnectSession" style="width:100%;"
				:disabled="disableDisconnectButton">
				{{ $t('myAccount.deco')}}
			</vg-button>
		</template>
	</vg-modal>
</template>
<script>
import VgLangSelector from "src/components/Vg/Internationalisation/VgLangSelector.vue";
import UserMixins from "src/mixins/UserMixins";
import VgButton from "src/components/Vg/VgButton.vue";
import VgUserRoleViewer from "src/components/Vg/User/VgUserRoleViewer.vue";
export default {
    name: 'vg-my-account',
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "myAccount": {
                "profilUser": "Profil Utilisateur",
                "deco": "Deconnexion"
            }
        },
        "en": {
            "myAccount": {
                "profilUser": "User profile",
                "deco": "Logout"
            }
        },
        "th": {
            "myAccount": {
                "profilUser": "โปรไฟล์ผู้ใช้งาน",
                "deco": "การขาดการเชื่อมต่อ"
            }
        }
    }
} ,
	components: {
		VgLangSelector,
		VgButton,
		VgUserRoleViewer
	},
    mixins:[UserMixins],
    data: function() {
        return {
            user: {
            	nom: this.$app.nom,
            	prenom: this.$app.prenom,
            	fonction: this.$app.fonction,
            	mail: this.$app.email,
            	role: this.$app.role
            },
            lang: this.$langs.current,
			disableDisconnectButton: false
        };
    },
    methods: {
		removeSessionValues: function(){
			this.VgFilesMixins_deleteFirebaseUserInSessionStorage();
			window.sessionStorage.removeItem("user");
		},
		handleDisconnectSession: function(){
			this.disableDisconnectButton = true;
            this.VgFilesMixins_deleteFirebaseUserInSessionStorage();
            this.UserMixins_logout().then(()=>{
                this.VgFilesMixins_logoutFirebaseApp();
                //this.$router.push({ name: '_login' });
                location.reload();
            })
		},
    }
};
</script>
<style lang="scss">
#enteteMyAccount{
	height: 50px;
	background: #57C1DB;
	vertical-align: middle;
	padding: 12px;
	color:white;
	.fa-user-circle{
		float: left;
		margin-right: 10px;
	}
	div{
		padding: 4px;
	}
}
.informationUtilisateur{
	padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 10px;
    padding-left: 25px;
	.informationName{
		font-size: 17px;
	    margin-bottom: 13px;
	    font-weight: 400;
	}
	.informationEmail{
		margin-bottom: 13px;
		color:#c3c3c3;
		font-size: 13px;
	}
	.informationRole{
		font-size: 13px;
		margin-bottom: 15px;

	}

}
.btnDeconnexion{
	border-top: 1px solid #d1d1d1;
	padding: 15px 25px;
	color:#77CDE2;
	font-size: 13px;
	cursor:pointer;
	&:hover{
		background-color: rgb(250, 250, 250);
	}
}
</style>
