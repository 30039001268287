import { render, staticRenderFns } from "./VgAffectationTacheForm.vue?vue&type=template&id=f7ef427c&scoped=true&lang=html&"
import script from "./VgAffectationTacheForm.vue?vue&type=script&lang=js&"
export * from "./VgAffectationTacheForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7ef427c",
  null
  
)

export default component.exports