<template lang="html">
    <div :class="{'vg-tabs': type=='default', 'vg-tabs-pane': type=='pane'}">
        <div v-for="(tab, index) in getTabs"
            :class="['tab-item', color, {'active': tab.name==focused, 'notDisplay': (tab.isNotMobile == true && $vgutils.isMobile())}]"
            @click="handleTabs(tab, index)">
            <img v-if="tab.img" :src="tab.img"/>
            <span>{{tab.label}}</span>
            <div v-if="tab.hasOwnProperty('counter')" class="badge-counter">
                {{tab.counter}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'vg-tabs',
    props:{
        color: {
            type: String,
            default:"jaune"
        },
        tabs: {
            type: Array,
            default: function(){
                return [
                    /*{label: "Courant", name:"courantes", counter: 5,
                        filters:[
                            {colId: "isRelance", value:null},
                            {colId: "statut", value: null},
                            {colId: "urgence", value:null},
                            {colId: "traitement", value:null},
                            {colId: "isMeAffectation", value: null}
                        ]},
                    {label: "Affectation", name:"mesaffectes", counter: 5,
                        filters:[
                            {colId: "isRelance", value:null},
                            {colId: "statut", value: null},
                            {colId: "urgence", value: null},
                            {colId: "traitement", value:null},
                            {colId: "isMeAffectation", value: "1"}
                        ]},
                    {label: "Relance", name:"relances", counter: 5,
                        filters:[
                            {colId: "isRelance", value:"1"},
                            {colId: "statut", value: null},
                            {colId: "urgence", value:null},
                            {colId: "traitement", value:null},
                            {colId: "isMeAffectation", value: null}
                        ]}*/
                ];
            }
        },
		value: String,
        /**
        * default affiche tabs standards
        * pane affiche tabs à la hubspot ex zoom site
        * @values default | pane
        */
        type: {
            type: String,
            default: "default"
        }
    },
    data: function(){
        return{
            colorTabs: this.color,
            tabsDatas: this.tabs,
            focused: this.value ? this.value : this.tabs[0].name
        };
    },
    watch: {
        tabs: {
            handler: function(values){
                this.tabsDatas = values;
            },
            deep: true
        }
    },
    computed:{
        getTabs:function(){
            return this.tabsDatas;
        }
    },
    methods:{
        handleTabs: function(tab, index){
            this.focused = tab.name;
            this.$emit("input", tab.name);
			this.$emit('tabClicked', tab);
        }
    }
}
</script>

<style lang="scss" scoped>
$bgc-violet:#7b54a0;
$bgc-orange:#f06f3a;
$bgc-blue:#6699ff;
$bgc-jaune: #fdc02c;
$bgc-vert: #76E476;
$bgc-gris: #d8d9da;
$bgc-dark: #999999;
$text-tab-color: #43454b;
@mixin theTabsColor($bgc){
    border:1px solid rgba($bgc, 0.5);
    border-left:1px solid rgba($bgc, 0.5);
    background-color: rgba($bgc, 0.1);
    &.active{
        background-color: $bgc;
        border-bottom:1px solid #5d6269;
        @if $bgc == $bgc-gris {
            color: $text-tab-color;
        } @else {
            color: white;
        }
    }
}
@mixin borderBottomColor($bgc){
    border-bottom: 3px solid $bgc;
}
.vg-tabs{
    width: inherit;
    height: 34px;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .tab-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        height: 100%;
        padding: 0 22px;
        border: 1px solid grey;
        white-space: nowrap;
        img{
            height: 20px;
            width: 20px;
        }
        .badge-counter{
            font-size: 10px;
            color: #353535;
            font-weight: bold;
        }
    }
    .tab-item:hover{
        cursor: pointer;
        border-bottom: 1px solid #5d6269;
    }
    .orange{
        @include theTabsColor($bgc-orange);
        // background-color: $bgc-orange;
    }
    .violet{
        @include theTabsColor($bgc-violet);
        // background-color: $bgc-violet;
    }
    .bleu{
        @include theTabsColor($bgc-blue);
        // background-color: $bgc-blue;
    }
    .jaune{
        @include theTabsColor($bgc-jaune);
        // background-color: $bgc-jaune;
    }
    .vert{
        @include theTabsColor($bgc-vert);
    }
    .gris{
        @include theTabsColor($bgc-gris);
    }
    .dark{
        @include theTabsColor($bgc-dark);
    }
}
.vg-tabs:hover{
    overflow: auto;
}

.vg-tabs-pane{
    width: inherit;
    height: 34px;
    overflow-x: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #d3d3d3;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .tab-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        height: 100%;
        padding: 0 22px;
        white-space: nowrap;
        img{
            height: 20px;
            width: 20px;
        }
        .badge-counter{
            font-size: 10px;
            color: #353535;
            font-weight: bold;
        }
    }
    .tab-item:hover{
        cursor: pointer;
    }
    .orange{
        &.active{
            @include borderBottomColor($bgc-orange);
        }
    }
    .violet{
        &.active{
            @include borderBottomColor($bgc-violet);
        }
    }
    .bleu{
        &.active{
            @include borderBottomColor($bgc-blue);
        }
    }
    .jaune{
        &.active{
            @include borderBottomColor($bgc-jaune);
        }
    }
    .vert{
        &.active{
            @include borderBottomColor($bgc-vert);
        }
    }
    .gris{
        &.active{
            @include borderBottomColor($bgc-gris);
        }
    }
    .dark{
        &.active{
            @include borderBottomColor($bgc-dark);
        }
    }
}
</style>
