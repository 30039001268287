import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Login from './views/Login.vue';
import Signup from './views/Signup/Signup.vue';
import Progression from './views/Verification/Mobile/Progression.vue';
import GenereQrCode from './views/GenereQrCode/GenereQrCode.vue';
// import ActivitesMaintenance from './views/Maintenance/Activites/Activites-Maintenance.vue';
import ActivitesMaintenance from './views/Maintenance/Activites/ActivitesMaintenance.vue';
// import MaintenanceCalendrier from './views/Maintenance/maintenanceCalendrier.vue';
import Verification from './views/Verification/Verification.vue';
import VerificationPrint from './views/Verification/VerificationPrint.vue';
import Bons from './views/Bons/Bons.vue';
import Consommables from './views/Consommables/Consommables.vue';
import Consommable from './views/Consommables/components/Consommable.vue';
import FicheDemandeConsommables from './views/FicheDemandeConsommables/FicheDemandeConsommables.vue';
import FicheDemandeConsommable from './views/FicheDemandeConsommables/FicheDemandeConsommable.vue';
import DemandeConsommables from './views/DemandeConsommables/DemandeConsommables.vue';

import ProcessVerification from './views/Verification/ProcessVerification.vue';
import ProcessVerificationTacheSelection from './views/Verification/ProcessVerificationTacheSelection.vue';
import ProcessVerificationEquipementSelection from './views/Verification/ProcessVerificationEquipementSelection.vue';
import Scanner from './views/Scanner/Scanner.vue';

import Equipements from './views/Equipement/Equipements.vue';
import Equipement from './views/Equipement/Equipement.vue';
import EquipementsRapport from './views/Equipement/EquipementsRapport.vue';
import RapportEquipementPdf from './views/Equipement/RapportEquipementPdf.vue';

import CategoriesEquipements from './views/Categories/CategoriesEquipements.vue';
import CategoriesLieux from './views/Categories/CategoriesLieux.vue';
import Taches from './views/Taches/Taches.vue';
import Lieux from './views/Lieux/Lieux.vue';
import Sites from './views/Lieux/Sites.vue';
import Lieu from './views/Lieux/Lieu.vue';
//import ShowPiece from './views/Lieux/components/ShowPiece.vue';
import Demandeintervention from './views/Demandeintervention/Demandeintervention.vue';
import Parametres from './views/Parametres/Parametres.vue';
import Tiers from './views/Tiers/Tiers.vue';
import Tier from './views/Tiers/Tier.vue';
import Contrats from './views/Contrat/Contrats.vue';
import Contrat from './views/Contrat/Contrat.vue';
import Planification from './views/Planification/Planification.vue';
import Dashboard from './views/Dashboard/Dashboard.vue';
import Calendrier from './views/Calendrier/Calendrier.vue';
import RecensementEquipement from "src/views/Equipement/RecensementEquipement.vue";
import HistoriqueCompteur from 'src/views/Verification/Historique/Compteur/Compteur.vue';
import HistoriqueGrandeursPhysiques from 'src/views/Verification/Historique/GrandeursPhysiques/GrandeursPhysiques.vue';
import deplacementEqp from 'src/views/Equipement/components/Deplacement/deplacementEquipement.vue';


// import VgSelector from 'src/components/Vg/Categorie/Selector.vue';
import VgInventaires from 'src/views/Inventaire/Inventaires.vue';
import VgInventaire from 'src/views/Inventaire/Inventaire.vue';
import InventaireLieu from 'src/views/Inventaire/InventaireLieu.vue';

import Interventions from 'src/views/Intervention/Interventions.vue';
import Intervention from 'src/views/Intervention/Intervention.vue';

import BonsDeCommande from 'src/views/BonsDeCommande/BonsDeCommande.vue';
import BonDeCommande from 'src/views/BonsDeCommande/BonDeCommande.vue';

import Maintenances from 'src/views/Maintenance/Maintenances.vue';
import Maintenance from 'src/views/Maintenance/Maintenance.vue';
import MaintenanceOperations from 'src/views/MaintenanceOperation/MaintenanceOperations.vue';
import RapportMaintenances from 'src/views/Maintenance/RapportMaintenances.vue';
import PrintFm from 'src/views/Maintenance/PrintFm.vue';
import Reserves from 'src/views/Reserves/Reserves.vue';
import Reserve from 'src/views/Reserves/Reserve.vue';
import PrintReserve from 'src/views/Reserves/PrintReserve.vue';
import Site from 'src/views/Lieux/Site.vue';
import Batiment from 'src/views/Lieux/Batiment.vue';

import IfcImporter from 'src/views/IFC/IfcImporter.vue';

import IntegrationsDonnees from 'src/views/Integrations/IntegrationsDonnees.vue';
//import PlanInteractif from 'src/views/PlanInteractif/PlanInteractif.vue'
import PlanMaintenancePreventive from 'src/views/PlanMaintenancePreventive/PlanMaintenancePreventive.vue';
import SyntheseMaintenancePreventive from 'src/views/SyntheseMaintenancePreventive/SyntheseMaintenancePreventive.vue';
import PlanInteractif from 'src/views/PlanInteractif/PlanInteractif.vue'
import MouvementsEquipements from 'src/views/MouvementsEquipements/MouvementsEquipements.vue'
import MouvementEquipements from 'src/views/MouvementsEquipements/MouvementEquipements.vue'
import MouvementEquipementsCreate from 'src/views/MouvementsEquipements/MouvementEquipementsCreate.vue'
import MouvementsConsommables from 'src/views/MouvementsConsommables/MouvementsConsommables.vue'
import Lancement from 'src/views/Lancement/Lancement.vue'
let router = new VueRouter({
  mode: 'history',
  routes : [
       // routing recensement
       { path: '/equipements', name:"_equipements", component: Equipements, props: true},
       { path: '/equipements/rapport', name:"_equipements_rapport", component: EquipementsRapport},
       { path: '/equipement/:id', name:"_equipement_id", component: Equipement, props: function(route){ return {target: route.params.target};}},
       { path: '/equipement/:id/pdf', name:"_equipement_id_pdf", component: RapportEquipementPdf},
       { path: '/lieux',name:"_lieux", component: Lieux, props: true},
       { path: '/sites',name:"_sites", component: Sites, props: true},
       { path: '/lieu/:id',name: "_lieu_id",component: Lieu},
       //{ path: '/lieu/:id', name:"_lieu_id", component: ShowPiece, props: function(route){// //console.log(route.params);return {target: route.params.target};}},

       { path: '/recenser-equipement',name:"_recensement_equipement", component: RecensementEquipement},
       { path: '/lieu/:idLieu/recenser-equipement',name:"_recensement_equipement_lieu", component: RecensementEquipement},
       { path: '/lieu/:idLieu/categorie/:idCategorie/recenser-equipement',name: "_recensement_equipement_lieu_categorie",component: RecensementEquipement},

       { path: '/categories/equipements' ,name:"_categories_equipements", component: CategoriesEquipements, props: true},
       { path: '/categories/lieux' ,name:"_categories_lieux", component: CategoriesLieux},

       { path: '/planification', name:"_planification", component: Planification, props: (route)=>{return {focus: "maintenance"};} },
       { path: '/dashboard', name:"_dashboard", component: Dashboard },
       { path: '/dashboard/:focus', name:"_dashboard_focus", component: Dashboard },
       { path: '/calendar', name:"_calendar", component: Calendrier },
       { path: '/genereqrcode', name:"_genereqrcode", component: GenereQrCode},

       //routing maintenance
       {
         path: '/demandeintervention',
         name:"_demande_intervention",
         component: Demandeintervention,
         children: [
          {
            // UserProfile will be rendered inside User's <router-view>
            // when /user/:id/profile is matched
            path: ':code',
            component: Demandeintervention,
          },
          {
            // UserPosts will be rendered inside User's <router-view>
            // when /user/:id/posts is matched
            path: 'lieu/:idLieu',
            children:[
              {
                path: 'equipement/:idEquipement',
                component: Demandeintervention,
              },
            ],
            component: Demandeintervention,
          },
        ],


      },
       { path: '/maintenance/activites', name:"_maintenance_activites", component: ActivitesMaintenance},
       { path: '/maintenances' ,name: "_maintenances", component: Maintenances, props: function(route){ return Object.assign(route.params, {openScanner: route.params.openScanner});}},
       { path: '/maintenance/operations' ,name: "_maintenance_operations", component: MaintenanceOperations},
       { path: '/maintenances/print' ,name: "_maintenances_print", component: RapportMaintenances},
       { path: '/maintenance/:id/print' ,name: "_maintenance_print", component: PrintFm},
       { path: '/maintenance/:id' ,name: "_maintenance", component: Maintenance, props: true},

       //routing Réserves
       { path: '/reserves' ,name: "_reserves", component: Reserves, props: true},
       { path: '/reserve/:id' ,name: "_reserve", component: Reserve},
       { path: '/reserve/:id/print' ,name: "_reserve_print", component: PrintReserve},

       // routing verification
       { path: '/verification', name:"_verification", component: Verification, props: true},
       { path: '/verification/print', name:"_verification_print", component: VerificationPrint, props:true},
       {path: '/progression',name:"_verification_progression",component: Progression, props: true},
       {path: '/progression/:tacheId',name:"_verification_progression_tache",component: Progression},
       { path: '/verification/historique/compteur', name:"_verification_historique_compteur", component: HistoriqueCompteur},
       { path: '/verification/historique/grandeurs-physiques', name:"_verification_historique_grandeur_physique", component: HistoriqueGrandeursPhysiques},

       { path: '/bons', name:"_bons", component: Bons, props: true},

       { path: '/consommables', name:"_consommables", component: Consommables, props: true},
       { path: '/consommable/:id', name:"_consommable_id", component: Consommable},

       { path: '/fichedemandeconsommables', name:"_fichedemandeconsommables", component: FicheDemandeConsommables, props: true},
       { path: '/fichedemandeconsommables/:id', name:"_fichedemandeconsommables_id", component: FicheDemandeConsommable},

       { path: '/demandeconsommables', name:"_demande_consommables", component: DemandeConsommables, props: true},

       {path: '/verifier/:type',name:"_process_verification_equipement_selection",component: ProcessVerificationEquipementSelection, props: true},
       {path: '/verifier/:type/:equipementId/taches',name:"_process_verification_taches_selection",component: ProcessVerificationTacheSelection, props: true},
       {path: '/verifier/:type/:equipementId/taches/:tacheId',name:"_process_verification",component: ProcessVerification, props: true},
       {path: '/verifier/:type/tache/:tacheId',name:"_process_verification_tache",component: ProcessVerificationEquipementSelection, props: true},

      // routing contrat
      { path: '/contrats', name:"_contrat", component: Contrats, props: true},
      { path: '/contrat/:id', name:"_contrat_id", component: Contrat},
       //routing
       { path: '/login', name:"_login", component: Login},
       { path: '/signup', name:"_signup", component: Signup},
       { path: '/tiers', name:"_tiers", component: Tiers, props: true},
       { path: '/tiers/:id', name:"_tiers_id", component: Tier},
       { path: '/taches', name:"_taches", component: Taches, props: true},
       { path: '/parametres', name:"_parametres", component: Parametres},
       { path: '/equipement/:uid/deplacement',name: "_equipement_id_deplacement",component: deplacementEqp},

       { path: '/site/:id',name: "_site_id",component: Site},
       { path: '/batiment/:id',name: "_batiment_id",component: Batiment},

      { path: '/inventaire',name: "_vg_inventaire",component: VgInventaires},
      { path: '/inventaire/:id',name: "_vg_inventaire_id",component: VgInventaire},
      { path: '/inventaire/:id/lieu/:lieuId',name: "_vg_inventaire_id_lieu_id",component: InventaireLieu},

      // page scanner | typeScanner = equipement || lieu
      { path: '/scanner/:typeScanner' ,name: "_scanner_type", component: Scanner},
      { path: '/scanner' ,name: "_scanner", component: Scanner},

      //{ path: '/planinteractif' ,name: "_plan_interactif", component: PlanInteractif},
      { path: '/planinteractif' ,name: "_plan_interactif", component: PlanInteractif},
      { path: '/planinteractif/etage/:etage' ,name: "_plan_interactif_etage", component: PlanInteractif},

      { path: '/interventions' ,name: "_interventions", component: Interventions, props: true},
      { path: '/intervention/:id' ,name: "_intervention", component: Intervention},

      { path: '/bonsdecommande' ,name: "_bons_de_commande", component: BonsDeCommande, props: true},
      { path: '/bondecommande/:id' ,name: "_bon_de_commande", component: BonDeCommande},

      { path: '/integration/:entite' ,name: "_integrations_donnees", component: IntegrationsDonnees},
      { path: '/integration' ,name: "_integrations_donnees", component: IntegrationsDonnees},

      { path: '/ifc/import' ,name: "_ifc_import", component: IfcImporter},
      { path: '/mouvements/equipements' ,name: "_mouvements_equipements", component: MouvementsEquipements},
      { path: '/mouvements/equipements/create/:type' ,name: "_mouvements_equipements_create_type", component: MouvementEquipementsCreate},
      { path: '/mouvement/:id' ,name: "_mouvement_equipements_id", component: MouvementEquipements},

      { path: "/plan/maintenance/preventive", name: "_plan_maintenance_preventive", component: PlanMaintenancePreventive},
      { path: "/synthese/maintenance/preventive", name: "_synthese_maintenance_preventive", component: SyntheseMaintenancePreventive},

      { path: "/lancement", name: "_lancement", component: Lancement},

      { path: "/mouvements/consommables", name: "_mouvements_consommables", component: MouvementsConsommables},

  ]

});

router.beforeEach((to, from, next) => {
  let isAuthenticated = JSON.parse(sessionStorage.getItem("user"));
  let userSelectedLandingpage = localStorage.getItem("personalParameters_landingPage");
  let defaultLandingPage = userSelectedLandingpage ? userSelectedLandingpage : "_maintenances";

  let loginPageAlias = "_login";

  if (to.name !== loginPageAlias && !isAuthenticated){
      if(to.name === '_signup'){
         next()
      }else{
        if(from.name !== loginPageAlias){
          next({ name: loginPageAlias });
        }

      }

  }
  else if ((to.name === loginPageAlias || null === to.name) && isAuthenticated){
    next({ name: defaultLandingPage });
  }
  else next()
})

router.afterEach((to, from, next) => {
  let loginPageAlias = "_login";
  console.log({to});
  if (to.name !== loginPageAlias && to.name !== '_signup' ) localStorage.setItem("personalParameters_fallbackpage", window.location.pathname);
})

export default router;
