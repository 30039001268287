<template>
<div id="verification" class="full-height-inherit">
	<vg-app-layout
		:title="$t('tache.desktop.Header-Title')"
		:icon="'tache-de-verification.png'"
		@action-export="actionExport"
        :isLoadingState="isLoadingTache"
        >
		<vg-button type="success" slot="create-button" @click="openCreateTacheForm">{{labelBtnNouveltache}}</vg-button>

		<vg-text-filter slot="search"
            v-model="searchQuery">
        </vg-text-filter>

		<vg-tabs slot="header-bottom-left"
            v-if="taches"
            :tabs="tabs"
            :color="'gris'"
            @tabClicked="handleTabClicked">
		</vg-tabs>
        <template #primary>
            <tag-grid
    			:idTableau="'taches-tableau-recap'"
    			v-model="gridOptions"
    			:columnDefs="columnDefs"
    			:rowData="groupedTaches"
				:showLoadingOverlay="showLoadingOverlay"
				:overlayLoadingText="$t('overlay-loading-text')"
				:overlayNoRowsText="$t('overlay-no-rows-text')"
    			@ag-click="handleClickTache"
    			@ag-dbl-click="handleDblClickTache" />
        </template>

        <template #secondary>
            <div v-if="!getSelectedTache" style="display: flex;justify-content: center;">
                <div style="margin-top: 50%">
                    <h3 style="color: #cccccc;">
                        {{$t('selection-tache')}}
                    </h3>
                </div>
            </div>

            <vg-button
                style="margin-bottom: 5px;"
                v-show="getSelectedTache && getSelectedTache.checkpoints"
                type="info"
                size="xs"
                @click="isEditionModeCheckpoints = !isEditionModeCheckpoints"
            >
                {{$t('update-checkpoint')}}
            </vg-button>
            <vg-checkpoints-form
                v-if="getSelectedTache && getSelectedTache.checkpoints"
                v-model="getSelectedTache"
                :isEditionMode="isEditionModeCheckpoints"
                @deleted="isEditionModeCheckpoints = false"/>
            <br>
            <div v-if="getSelectedTache">
                <vg-button size="small" @click="isUploaderVisible?isUploaderVisible=false:isUploaderVisible=true;">
                    <i :class="{'el-icon-arrow-down':!isUploaderVisible, 'el-icon-arrow-up':isUploaderVisible}"></i> {{ $t("attacher-document") }}
                </vg-button>
                <vg-files-uploader v-if="isUploaderVisible"
                    :tagsList="VgFilesMixins.tags"
                    @upload-done="VgFilesMixins_fetchFilesByUid(); isUploaderVisible=!isUploaderVisible;">
                </vg-files-uploader>
                <vg-files-viewer-list-section
                    :files="VgFilesMixins_filesListSection"
                    @delete="VgFilesMixins_fetchFilesByUid()">
                </vg-files-viewer-list-section>
            </div>

            <div v-show="isLoadingCheckpoints" class="loading">
                {{$t('loading')}}
            </div>
        </template>

	</vg-app-layout>

	<!-- CREATE TACHE AND CHECKPOINTS -->
	<vg-tache-form v-if="showCreateTache"
        v-model="getSelectedTache"
        :typeTache="typeTache"
        @close="showCreateTache = false"
        @created="onCreateTache"
        @updated="onUpdateTache" />

</div>
</template>

<script>
import TachesMixins from 'src/mixins/TachesMixins.js';
import CheckpointMixins from 'src/mixins/CheckpointMixins.js';
import CategoriesMixins from 'src/mixins/CategorieMixins.js';
import TagGridMixins from 'src/mixins/TagGridMixins.js';

import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgTacheForm from "src/components/Vg/Forms/VgTacheForm.vue";
import VgCheckpointsForm from "src/components/Vg/Forms/VgCheckpointsForm.vue";

import OuiNonCellRender from 'src/components/Vg/TagGrid/OuiNonCellRender.vue';
import TagCellRender from 'src/components/Vg/TagGrid/TagCellRender.vue';
import CategorieQuantiteEquipementsCellRender from 'src/components/Vg/TagGrid/CategorieQuantiteEquipementsCellRender.vue';

import VgFilesViewerListSection from "src/components/Vg/Files/VgFilesViewerListSection.vue";
import VgFilesUploader from "src/components/Vg/Files/VgFilesUploader.vue";

import { mapGetters, mapActions } from 'vuex';
import Metadatas from "src/services/Metadatas.js";

export default {
    name: 'taches',
    i18n:    { "locale":navigator.language,
  "messages": {
    "fr": {
        "aucun-lieu": "Aucune pièce",
        "aucun-equipement": "Aucun équipement",
        "aucun-compteur": "Aucun compteur",
        "aucun-Grandeur_physique": "Aucun équipement",
      "overlay-loading-text": "Chargement des tâches de vérification...",
      "overlay-no-rows-text": "Aucune tâche correspondant au filtrage",
      "loading": "Chargement en cours...",
      "update-checkpoint": "Modifier les points de vérifications",
      "selection-tache": "Veuillez sélectionner une tâche pour afficher ses points de vérifications",
      "attacher-document": "Attacher un document",
      "liste-taches": "tasks",
      "tache": {
        "desktop": {
          "Header-Title": "Gérer tâches récurrentes",
          "RightPanel-DefaultMessage": "Selectionner une Tache pour afficher le detail",
          "Modal-Create-Header": "Créer une tache de vérification ",
          "Modal-Create-Step-Task": "Tache de vérification",
          "Modal-Create-Step-Checkpoint": "Asssocier les points de vérification",
          "Modal-Create-NomTache": "Libellé tache",
          "Modal-Create-Gestion": "Gestion ",
          "Modal-Create-Categorie": "Catégorie des équipements associée",
          "Modal-Create-Periodicite": "Périodicité en nombre de jours",
          "Modal-Create-Active": "Active ",
          "Modal-Create-Tag": "Tag",
          "Modal-Create-Plh-Tag": "Tags de la tache",
          "Modal-Create-Norme": "Norme",
          "Modal-Create-Commentaire": "Commentaire",
          "Modal-Create-BtnAnnule": "Annuler",
          "Modal-Create-UpdateTask": "Modifier la tache",
          "Modal-Create-BtnSuite": "Ajouter les points de verification",
          "Modal-Update-Header": "Modifier la tache de vérification",
          "Modal-Create-DateButoire": "date butoir ( à terminer pour date suivante)",
          "Modal-Create-BtnFermer": "Fermer",
          "Modal-Create-BtnBack": "Retour détails tache",
          "Modal-Create-BtnSave": "Sauvegarder",
          "LeftPanel-AgGrid-Intitule": "Intitule",
          "LeftPanel-AgGrid-Progression": "Progression",
          "LeftPanel-AgGrid-quantite-eq-lieu": "Nb d'équipements/pièces",
          "LeftPanel-AgGrid-Gestion": "Gestion",
          "LeftPanel-AgGrid-Active": "Active",
          "LeftPanel-Tooltip-TacheActive": "Tache active oui/non",
          "LeftPanel-AgGrid-Periodicite": "Periodicite",
          "LeftPanel-AgGrid-Date_butoire": "Date butoir",
          "LeftPanel-AgGrid-OuvertureFM": "Ouverture fiche curative?",
          "LeftPanel-Tooltip-OuvertureFM": "Ouverture d'une fiche curative oui/non",
          "LeftPanel-AgGrid-NbCheckpoint": "Nb de points de verification",
          "LeftPanel-AgGrid-Tag": "Etiquette",
          "LeftPanel-AgGrid-Norme": "Norme",
          "LeftPanel-AgGrid-Comment": "Commentaire",
          "LeftPanel-AgGrid-createdAt": "create At",
          "LeftPanel-AgGrid-typeTache": "Type",
          "LeftPanel-AgGrid-isGEP": "is GEP",
          "Header-Button-piece": "Créer tâche check lieu",
          "Modal-Create-Form-Libelle": "Veuillez renseigner un libellé à la tache",
          "Modal-Create-Form-Libelle3": "Libellé tache doit contenir plus de 3 caractères",
          "Modal-Create-Form-Categorie": "Veuillez sélectionner une catégorie",
          "Modal-Create-Form-Gestion": "Veuillez saisir une gestion interne ou externe",
          "Modal-Create-Form-Periodicite": "Veuillez saisir une périodicité",
          "Modal-Create-Form-dButoir": "Veuillez saisir la prochaine date butoire",
          "TabPanel-Onglet-piece": "check lieu",
          "TabPanel-Onglet-equip": "équipement",
          "TabPanel-Onglet-R-Compteur": "relevé compteur",
          "TabPanel-Onglet-R-Grandeur": "releve Grandeur",
          "Header-Button-equip": "Créer tâche équipement",
          "Header-Button-R-Compteur": "Créer tâche releve compteur",
          "Header-Button-R-Grandeur": "Créer tâche relevé grandeur",
          "Modal-Create-input-messageOnVerifError": "Défectueux",
          "Modal-temps-effectif": "durée moyenne en min",
          "requiert-scan": "Scan équipement requis",
          "programmation-session": "Programmation session",
          "LeftPanel-Tooltip-affectation": "Affectation programmée",
          "LeftPanel-Tooltip-scann-eq-requis": "Scan équipement requis"
        }
      },
      "categorie": {
        "desktop": {
          "Modal-CreateCat-Footer-BtnValide": "Créer"
        }
      }
    },
    "en": {
        "aucun-lieu": "No room",
        "aucun-equipement": "No equipment",
        "aucun-compteur": "No meter",
        "aucun-Grandeur_physique": "No equipment",
      "loading": "Loading...",
      "update-checkpoint": "Update checkpoints",
      "selection-tache": "Select a task to display its checkpoints",
      "attacher-document": "Attach file",
      "liste-taches": "tasks",
      "tache": {
        "desktop": {
          "Header-Title": "Manage recurrent tasks",
          "RightPanel-DefaultMessage": "Select a task to display the detail",
          "Modal-Create-Header": "Create a new task of ",
          "Modal-Create-Step-Task": "Task",
          "Modal-Create-Step-Checkpoint": "Associate checkpoint",
          "Modal-Create-NomTache": "Name of the task",
          "Modal-Create-Gestion": "Management",
          "Modal-Create-Categorie": "Category of associated equipment",
          "Modal-Create-Periodicite": "Frequency in number of days",
          "Modal-Create-Active": "Active ",
          "Modal-Create-Tag": "Tag",
          "Modal-Create-Plh-Tag": "Tags of the task",
          "Modal-Create-Norme": "Standard",
          "Modal-Create-Commentaire": "Comment",
          "Modal-Create-BtnAnnule": "Cancel",
          "Modal-Create-UpdateTask": "Update task",
          "Modal-Create-BtnSuite": "Add checkpoint",
          "Modal-Update-Header": "Modify the task",
          "Modal-Create-DateButoire": "deadline (to complete for next date)",
          "Modal-Create-BtnFermer": "Close",
          "Modal-Create-BtnBack": "Back details task",
          "Modal-Create-BtnSave": "Save",
          "LeftPanel-AgGrid-Intitule": "Title",
          "LeftPanel-AgGrid-Progression": "Progress",
          "LeftPanel-AgGrid-quantite-eq-lieu": "Number of equipments/rooms",
          "LeftPanel-AgGrid-Gestion": "Management",
          "LeftPanel-AgGrid-Active": "Active",
          "LeftPanel-Tooltip-TacheActive": "Active task on/off",
          "LeftPanel-AgGrid-Periodicite": "Frequency",
          "LeftPanel-AgGrid-Date_butoire": "Cutoff date",
          "LeftPanel-AgGrid-OuvertureFM": "Opening curative sheet?",
          "LeftPanel-Tooltip-OuvertureFM": "Open curative sheet on/off",
          "LeftPanel-AgGrid-NbCheckpoint": "Number of verification points",
          "LeftPanel-AgGrid-Tag": "Tag",
          "LeftPanel-AgGrid-Norme": "Standard",
          "LeftPanel-AgGrid-Comment": "Commentary",
          "LeftPanel-AgGrid-createdAt": "create At",
          "LeftPanel-AgGrid-typeTache": "Type",
          "LeftPanel-AgGrid-isGEP": "is GEP",
          "Header-Button-piece": "Create task check place",
          "Modal-Create-Form-Libelle": "Please fill in a label to the task",
          "Modal-Create-Form-Libelle3": "Label must contain more than 3 characters",
          "Modal-Create-Form-Categorie": "Please select a category",
          "Modal-Create-Form-Gestion": "Please enter an internal or external management",
          "Modal-Create-Form-Periodicite": "Please enter a periodicity",
          "Modal-Create-Form-dButoir": "Please enter the next deadline",
          "TabPanel-Onglet-piece": "Task check place",
          "TabPanel-Onglet-equip": "Task equipment",
          "TabPanel-Onglet-R-Compteur": "Task meter reading",
          "TabPanel-Onglet-R-Grandeur": "Task read values",
          "Header-Button-equip": "Create task equipment ",
          "Header-Button-R-Compteur": "Create task meter reading",
          "Header-Button-R-Grandeur": "Create task read values",
          "Modal-Create-input-messageOnVerifError": "faulty",
          "Modal-temps-effectif": "average time per equipment",
          "requiert-scan": "Equipment scan required"
        }
      },
      "categorie": {
        "desktop": {
          "Modal-CreateCat-Footer-BtnValide": "Create"
        }
      }
    },
    "th": {
      "histoVerif": {
        "desktop": {
          "AgGrid-Header-date": "วันที่การตรวจสอบ",
          "AgGrid-Header-tag": "แท็ก",
          "AgGrid-Header-tache": "งาน",
          "AgGrid-Header-operateur": "ผู้ปฏิบัติงาน",
          "AgGrid-Header-equipementName": "ชื่ออุปกรณ์",
          "AgGrid-Header-emplacement": "ตำแหน่งที่ตั้ง",
          "AgGrid-Header-piece": "ห้อง",
          "AgGrid-Header-nonConforme": "ไม่สอดคล้อง",
          "AgGrid-Header-pointVerification": "จุดตรวจสอบ",
          "AgGrid-Header-valeur": "มูลค่า",
          "AgGrid-Header-commentaire": "ความคิดเห็น",
          "TabPanel-synthese": "การสรุปการตรวจสอบโดยรวม",
          "TabPanel-nonConf": "รายละเอียด: ไม่สอดคล้อง",
          "Filter-head-Verification": "การตรวจสอบ",
          "Filter-date": "เมื่อใด?",
          "Filter-onlynoConf": "แสดงที่ไม่สอดคล้องเท่านั้น",
          "Filter-tache": "งาน",
          "Filter-site": "ไซต์"
        },
        "mobile": {
          "tableau-header-date": "วันที่",
          "tableau-header-tache": "งาน/ไซต์",
          "tableau-header-lieu": "ตำแหน่งที่ตั้ง",
          "tableau-header-equipement": "อุปกรณ์",
          "tableau-header-qrCode": "QR โค้ด ",
          "tableau-header-categorie": "หมวดหมู่",
          "filtre-recherche": "ค้นหา ...",
          "TabPanel-synthese": "การสรุปการตรวจสอบโดยรวม"
        }
      },
      "verifications": {
        "desktop": {
          "rapport-header-titre": "รายงานการตรวจสอบตามระยะ"
        }
      },
      "block-filtres-titre": "ตัวกรอง",
      "block-filtres-message-revision": "ตัวกรองภายใต้การแก้ไข กรุณาใช้ตัวกรองในหัวตาราง"
    }
  }
},
    mixins: [
        TachesMixins,
        CheckpointMixins,
        CategoriesMixins,
        TagGridMixins
    ],
    components: {
        TagGrid,
        VgTabs,
        VgTextFilter,
        OuiNonCellRender,
		TagCellRender,
		VgButton,
        VgTacheForm,
        VgCheckpointsForm,
        VgFilesViewerListSection,
        VgFilesUploader
    },
    data: function() {
        return {
			showLoadingOverlay: false,
            selectedTab: "equipement", // lieu || equipement || compteur ||Grandeur_physique
            searchValue: "",
            gridOptions: {
                editType: 'fullRow',
                isFullWidthCell: function(rowNode) {
                    return rowNode.data.isSection;
                },
                fullWidthCellRenderer:(params) => {
                    return '<div>'+"<h4 style='margin-left: 5px;color: #42454a;'>"+params.data.categorie.libelleCatgorie+"</h4>"+"</div>";
                },
            },
            columnDefs: [{
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Intitule"),
                    field: "libel_tache",
                    width: 200,
                    pinned: 'left',
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Progression"),
                    field: "nbEquipementChecked",
                    width: 120,
                    pinned: 'left',
                    cellRenderer: (params) => {
                        if (params.data.nbEquipementChecked!=null && params.data.nbEquipementToCheck!=null && params.data.nbEquipementToCheck!=0) {
                            var total = params.data.nbEquipementToCheck;
                            var value = params.data.nbEquipementChecked;
                            return '<div data-toggle="tooltip" title="' + value + ' vérifiés sur ' + total + '" style="display:flex;align-items:center;gap:5px;">' +
                                '<progress style="width:50px;cursor:pointer;" max="' + total + '" value="' + value + '">' +
                                '</progress><small>'+params.data.nbEquipementChecked+'/'+params.data.nbEquipementToCheck+'</small>' +
                                '</div>';
                        } else if(params.data.nbEquipementToCheck==0){
                            return '<small style="color:red;">'+this.$t("aucun-"+this.selectedTab)+'</small>';
                        }else {
                            return '-';
                        }
                    },
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-quantite-eq-lieu"),
                    field: "nbEquipementToCheck",
                    width: 120,
                    pinned: 'left',
                    cellRenderer: (params) => new CategorieQuantiteEquipementsCellRender({propsData: {
                            params: params,
                            attributeQuantite: "nbEquipementToCheck",
                            libelleCategorie: params.data.categorie.libelleCatgorie,
                            typeEquipement: params.data.type_tache,
                            isGep: params.data.categorie.isGe
                        }})
                        .$on("open-page-equipements", this.openPageEquipements)
                        .$on("open-page-lieux", this.openPageLieux)
                        .$mount().$el,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Tag"), // string split ,
                    field: "tags",
                    width: 150,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Gestion"),
                    field: "intex",
                    width: 80,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Active"),
                    field: "isActive",
                    width: 80,
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.isActive && params.data.isActive == "1",
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-TacheActive")
                        }
                    }).$mount().$el,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Periodicite"),
                    field: "periodicite",
                    width: 80,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Date_butoire"),
                    field: "dateprochaineVerif",
                    getQuickFilterText: (params) => {
                        //// //console.log("getQuickFilterText", params.value);
                        return this.$vgutils.getDate(params.data.dateprochaineVerif);
                    },
                    width: 100,
                    cellRenderer: (params) => {
                        return this.$vgutils.getDate(params.data.dateprochaineVerif);
                    },
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-OuvertureFM"),
                    field: "openMaintenanceOnError",
                    width: 80,
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.openMaintenanceOnError && params.data.openMaintenanceOnError == "1",
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-OuvertureFM")
                        }
                    }).$mount().$el,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-NbCheckpoint"),
                    field: "nbCheckpoints",
                    width: 80,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Norme"),
                    field: "norme",
                    width: 150,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-Comment"),
                    field: "commentaire_tache",
                    width: 150,
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-createdAt"),
                    field: "created_at",
                    hide: true,
                    cellRenderer: (params) => {
                        return this.$vgutils.getDate(params.data.created_at);
                    },
                    cellClass: ["vg-cell-checkbox"] // ATTENTION HACK
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-typeTache"),
                    field: "type_tache",
                    colId: "type_tache",
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    hide: true,
                },
                {
                    headerName: this.$t("tache.desktop.LeftPanel-AgGrid-isGEP"),
                    field: "categorie",
                    hide: true,
                    cellRenderer: (params) => {
                        return params.categorie.isGe;
                    },
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    isColumnShowable: true,
                },
                {
                    headerName: this.$t("tache.desktop.Modal-temps-effectif"),
                    field: "averageTime",
                    hide: false,
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    isColumnShowable: false,
                },
                {
                    headerName: this.$t("tache.desktop.requiert-scan"),
                    field: "isScanRequired",
                    hide: false,
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    isColumnShowable: false,
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.isScanRequired,
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-scann-eq-requis")
                        }
                    }).$mount().$el
                },
                {
                    headerName: this.$t("tache.desktop.programmation-session"),
                    field: "affectation",
                    cellClass: ["vg-cell-checkbox"], // ATTENTION HACK
                    cellRenderer: (params) => new OuiNonCellRender({
                        propsData: {
                            params: params,
                            isValid: params.data.hasOwnProperty("affectation") && params.data.affectation && params.data.affectation.id,
                            tooltipTitle: this.$t("tache.desktop.LeftPanel-Tooltip-affectation")
                        }
                    }).$mount().$el
                }
            ],
            groupedTaches: null,
            tache:null,
            showCreateTache: false,
            showUpdateTache: false,
            searchQuery: '',
            agfilters: {
                "typeTache": {attr:"t.type_tache", colId: "typeTache", value: "Verification_equipement", action:"equals"}
            },
			metadatasTaches: new Metadatas(),
            isLoadingCheckpoints:false,
            isLoadingTache:false,
            isEditionModeCheckpoints:false,
            isUploaderVisible:false
        };
    },
    watch: {
        searchValue: function(value) {
            //// //console.log("SEARCH VALUE", value);
            this.gridOptions.api.setQuickFilter(value);
        },
        searchQuery: {
            handler: function(query) {
                this.TagGridMixins_setQuickFilter(this.gridOptions, query);
            }
        },
		agfilters: {
			handler: function(newfilters){
				this.fetch();
			},
			deep: true
		},
        taches:{
            handler:function(taches){
                console.log("tache changed",this.groupTachesByCategorie(taches))
                this.groupedTaches = this.groupTachesByCategorie(taches);
            },
            deep:true
        }
    },
    computed: {
        ...mapGetters({
            checkpoints: 'CheckpointsStore/getCollection',
            getSelectedTache: 'TachesStore/getSelectedItem',
            taches: 'TachesStore/getCollection'

        }),
        counters: function() {
            var taches = this.groupedTaches;
            var counters = {
                lieu: 0,
                equipement: 0,
                compteur: 0,
                Grandeur_physique: 0
            };
            if (taches) taches.forEach(function(tache) {
                if (!tache.isSection && tache.typeTache == "Verification_Lieu") counters.lieu++;
                if (!tache.isSection && tache.typeTache == "Verification_equipement") counters.equipement++;
                if (!tache.isSection && tache.typeTache == "Relever_compteur") counters.compteur++;
                if (!tache.isSection && tache.typeTache == "Relever_Grandeur_physique") counters.Grandeur_physique++;
            });
            return counters;
        },
        tabs: function() {
            var counters = this.counters;
			/*Verification_equipement
			Relever_compteur
			Relever_Grandeur_physique
			Verification_Lieu*/
            return [
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-equip'),
                    name: "equipement",
                    //counter: counters.equipement,
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Verification_equipement", action: "equals"}
					}
                },
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-R-Compteur'),
                    name: "compteur",
                    //counter: counters.compteur,
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Relever_compteur", action: "equals"}
					}
                },
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-R-Grandeur'),
                    name: "Grandeur_physique",
                    //counter: counters.Grandeur_physique,
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Relever_Grandeur_physique", action: "equals"}
					}
                },
                {
                    label: this.$t('tache.desktop.TabPanel-Onglet-piece'),
                    name: "lieu",
                    //counter: counters.lieu,
					filters:{
						"typeTache": {attr: "t.type_tache",value: "Verification_Lieu", action: "equals"}
					}
                }
            ];
        },
        /**
         * @deprecated
         */
        typeTache: function() {
            if (this.selectedTab == "lieu") return "Verification_Lieu";
            else if (this.selectedTab == "equipement") return "Verification_equipement";
            else if (this.selectedTab == "compteur") return "Relever_compteur";
            else if (this.selectedTab == "Grandeur_physique") return "Relever_Grandeur_physique";
            else return "default";
        },
        labelBtnNouveltache: function() {
            if (this.selectedTab == "lieu") return this.$t("tache.desktop.Header-Button-piece");
            else if (this.selectedTab == "equipement") return this.$t("tache.desktop.Header-Button-equip");
            else if (this.selectedTab == "compteur") return this.$t("tache.desktop.Header-Button-R-Compteur");
            else if (this.selectedTab == "Grandeur_physique") return this.$t("tache.desktop.Header-Button-R-Grandeur");
            else return "default";
        },

    },
    methods: {
        openPageEquipements: function(datas){
            this.$router.push({ name: '_equipements', params: { defaultFilters: datas.filters }});
        },
        openPageLieux: function(datas){
            this.$router.push({ name: '_lieux', params: { defaultFilters: datas.filters }});
        },
        onCreateTache: function(tache){
            this.showCreateTache = false;
            this.fetch();
        },
        onUpdateTache: function(tache){
            this.showCreateTache = false;
            this.$store.dispatch("TachesStore/setSelectedItem", null);
            this.fetch();
        },
		actionExport: function(event){
            let metadatas = this.metadatasTaches; // Attention écrit en dur commun au tableau equipements

            switch (event.name) {
                case "askForXLS":
                    this.tachesMixins_getExcelFile(metadatas,this.$t('liste-taches'),"xlsx").then((datas)=>{});
                    break;
                case "askForCSV":
                    this.tachesMixins_getExcelFile(metadatas,this.$t('liste-taches'),"csv").then((datas)=>{});
                    break;
                case "gestionColonnesEventId":
                    window.dispatchEvent(new CustomEvent("gestionColonnesEventId", {
                        "detail": {"idTableau":"taches-tableau-recap"}
                    }));
                    break;
                default:
            }
		},
        handleTabClicked: function(e) {
			this.agfilters = Object.assign(this.agfilters, e.filters);
            this.$store.dispatch("TachesStore/setSelectedItem", null);
            this.selectedTab = e.name;
        },
        handleClickTache: function(e) {
            this.isLoadingCheckpoints = true;
            this.$store.dispatch("TachesStore/setSelectedItem", null);
            this.tachesMixins_getTache(e.data.id).then(()=>{
                this.isLoadingCheckpoints = false;
                this.VgFilesMixins_init(this.getSelectedTache.uid, "tache");
            });
        },
        handleDblClickTache: function(e) {
            this.isLoadingTache = true;
            this.tachesMixins_getTache(e.data.id).then(()=>{
                this.isLoadingTache = false;
                this.showCreateTache = true;
                this.VgFilesMixins_init(this.getSelectedTache.uid, "tache");
                this.getSelectedTache.assignation = e.data.assignation;
            });
        },
        /**
         *
         */
        openCreateTacheForm: function(e) {
            this.$store.dispatch("TachesStore/setSelectedItem", null);
            this.showCreateTache = true;
        },
        /**
         *@param {array} taches
         *@return {array} taches
         */
        groupTachesByCategorie: function(taches) {
            var result = [];
            var tmpCategorie = null;
            var tacheslen = taches.length;
            for (var index = 0; index < tacheslen; index++) {
                if (!tmpCategorie || tmpCategorie.categorie.libelleCatgorie != taches[index].categorie.libelleCatgorie) {
                    tmpCategorie = taches[index];
                    taches[index]["libelleCatgorie"] = tmpCategorie.categorie.libelleCatgorie;
                    //console.log("tmpCategorie",tmpCategorie)
                    result.push(Object.assign({
                        isSection: true
                    }, tmpCategorie));
                    result.push(taches[index]);
                } else {
                    result.push(taches[index]);
                }
            }
            return result;
        },
        fetch: function() {
            return new Promise((resolve, reject)=>{
				this.showLoadingOverlay = true;
                this.metadatasTaches.setFilters(this.agfilters);
    			this.tachesMixins_getTaches(this.metadatasTaches).then((taches)=>{
    				this.groupedTaches = this.groupTachesByCategorie(taches);
					this.showLoadingOverlay = false;
                    resolve(this.groupedTaches);
                    //this.setTypeFilter();
    			});
            });


        },
    },
    created: function() {
        this.fetch();
    }
};
</script>

<style lang="scss">
    .loading{
        font-weight: 600;
        letter-spacing: 0.1rem;
    }

</style>
