var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipText),expression:"tooltipText"}],class:['vg-button',{
    'vg-button-default' : _vm.type == 'default',
    'vg-button-success' : _vm.type == 'success',
    'vg-button-danger' : _vm.type == 'danger',
    'vg-button-default-danger' : _vm.type == 'default-danger',
    'vg-button-info' : _vm.type == 'info',
    'vg-button-default-info' : _vm.type == 'default-info',
    'vg-button-grey' : _vm.type == 'grey',
    'vg-button-blue' : _vm.type == 'blue',
    'vg-button-locked' : _vm.type == 'locked',
    'vg-button-xs': _vm.size=='xs',
    'vg-button-sm': _vm.size=='sm',
    'vg-button-md': _vm.size=='md',
    'vg-button-lg': _vm.size=='lg',
    'vg-button-default-round': _vm.type == 'default-round',
    'vg-button-default-link vg-button-link': _vm.type == 'default-link',
    'vg-button-success-link vg-button-link' : _vm.type == 'success-link',
    'vg-button-danger-link vg-button-link' : _vm.type == 'danger-link',
    'vg-button-info-link vg-button-link' : _vm.type == 'info-link',
    'vg-button-pulse': _vm.pulse,
    'vg-button-lighter-pulse': _vm.pulseLight
    }],attrs:{"disabled":_vm.disabled,"type":"button"},on:{"click":function($event){return _vm.handleClick($event.target)},"mouseup":function($event){return _vm.handleHover($event.target)},"mouseout":function($event){return _vm.handleHoverOut($event.target)}}},[(_vm.type=='locked')?_c('i',{staticClass:"fas fa-lock"}):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [_vm._v("Button")]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }