import { render, staticRenderFns } from "./VgLieuForm.vue?vue&type=template&id=2a65d3cc&scoped=true&"
import script from "./VgLieuForm.vue?vue&type=script&lang=js&"
export * from "./VgLieuForm.vue?vue&type=script&lang=js&"
import style0 from "./VgLieuForm.vue?vue&type=style&index=0&id=2a65d3cc&prod&lang=scss&"
import style1 from "./VgLieuForm.vue?vue&type=style&index=1&id=2a65d3cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a65d3cc",
  null
  
)

/* custom blocks */
import block0 from "./VgLieuForm.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports