<template>
    <span v-if="params.data.flag=='consommation-fm' && params.data.operation" class="content">
        {{$t("consommation-fm")}} <b class="link" @click="onClickMaintenance">{{params.data.operation.ficheSav_id}}</b>
    </span>
    <span v-else-if="params.data.flag=='bon-de-commande-livraison'" class="content">
        {{$t("bon-de-commande-livraison")}} <b class="link" @click="onClickBonDeCommande">{{params.data.bonDeCommande.numero}}</b>
    </span>
    <span v-else-if="params.data.flag=='inventaire-moins'" class="content">
        {{$t("inventaire-moins")}}
    </span>
    <span v-else-if="params.data.flag=='inventaire-plus'" class="content">
        {{$t("inventaire-plus")}}
    </span>
    <span v-else-if="params.data.flag=='bon-de-sortie'" class="content">
        {{$t("bon-de-sortie")}} <vg-files-viewer-document :file="params.data.bonDeSortie.document" noDownload />
    </span>
    <span v-else-if="params.data.flag=='bon-dentree'" class="content">
        {{$t("bon-dentree")}}
    </span>
    <span v-else-if="params.data.flag=='consommation-verification'" class="content">
        {{$t("consommation-verification")}}
    </span>
    <span v-else-if="params.data.flag=='livraison'" class="content">
        {{$t("livraison")}}
    </span>
    <span v-else class="content">
        {{params.data.flag}}
    </span>
</template>

<script>
    import Vue from "vue";
    import VgOperationFlag from "src/components/Vg/Operation/VgOperationFlag.vue";
    import VgFilesViewerDocument from "src/components/Vg/Files/VgFilesViewerDocument.vue";
    import LangMixins from "src/components/Vg/TagGrid/LangMixins";
    export default Vue.extend({
        name: "flag-consommable-mouvements-cell-render",
        mixins:[LangMixins],
		props: {
			params: {
				type: Object,
				required: true,
				default: {}
			}
		},
        components:{
            VgOperationFlag,
            VgFilesViewerDocument
        },
        data: function() {
            return {

			};
        },
        i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "consommation-fm": "Consommation fiche n°",
            "inventaire-plus": "Ajustement + inventaire physique",
            "inventaire-moins": "Ajustement - inventaire physique",
            "bon-de-sortie": "Bon de sortie",
            "bon-dentree": "Bon d'entrée",
            "consommation-verification": "Consommation à partir d'une vérification",
            "bon-de-commande-livraison": "Livraison à partir du bon de commande n° ",
            "livraison": "Livraison"
        },
        "en": {
            "inventaire-plus": "Adjustment + physical inventory",
            "inventaire-moins": "Adjustment - physical inventory",
            "consommation-fm": "Consumption sheet n°",
            "bon-de-sortie": "Exit voucher",
            "bon-dentree": "Entrance voucher",
            "consommation-verification": "Consumption from verification",
            "bon-de-commande-livraison": "Delivery from purchase order n° ",
            "livraison": "Delivery"
        },
        "th": {
            "inventaire-plus": "การปรับเพิ่มสินค้าคงคลังทางกายภาพ",
            "inventaire-moins": "การปรับลดสินค้าคงคลังทางกายภาพ"
        }
    }
},
        methods:{
            onClickMaintenance: function(){
                this.$emit("go-to-maintenance",this.params.data.operation.ficheSav_id);
            },
            onClickBonDeCommande: function(){
                this.$emit("go-to-bon-de-commande",this.params.data.bonDeCommande_id);
            }
        }
    });
</script>

<style lang="scss" scoped>
.link{
    font-weight: bold;
    color:#3999ff;
}
.link:hover{
    cursor: pointer;
    text-decoration: underline dotted;
}
.content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}
</style>
