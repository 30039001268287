<template>
	<div class="vg-tree-lieux">
		<div class="header">
			<div class="header-title">
				<span class="title">
					<img src="static/assets/icone/lieu.png" height="15" alt="icone_lieu"/>
					<b class="only-desktop">{{$t("title")}}</b>
					<b class="only-mobile">{{$t("title-mobile")}}</b>
				</span>
				<div class="options">
					<vg-button type="grey"
						:disabled="isCollapseAll"
						@click="onCollapseAll">
						{{$t("close-all")}}
					</vg-button>
					<vg-button type="grey"
						@click="showTiroirPlans=true;">
						<i class="far fa-map"></i> {{ $t("plans") }}
					</vg-button>
					<vg-button type="info"
                        v-if="$vgutils.isMobile()"
						@click="displayQrScan=true;">
						<i class="fas fa-qrcode"></i>
					</vg-button>
				</div>
			</div>
			<vg-text-filter v-model="queryFilter">
			</vg-text-filter>
			<div v-if="queryFilter && queryFilter.length!=0"
				class="filter-infos">
				<span>
					{{$t("filter-infos")}} <b>{{queryFilter}}</b> - <small><i>{{datas.length}} {{$t("filter-infos-resultats")}}</i></small>
				</span>
			</div>
			<div v-if="nodeSelected"
				class="selection-infos">
				<div>
					<img :src="getIcon"/>
					<vg-lieu-path-viewer v-model="nodeSelected.path"
						:showTooltip="true"
						:type="nodeSelected.type_lieu"
						style="font-size:12px;display:flex;justify-content:flex-start;align-items:flex-start;">
					</vg-lieu-path-viewer>
				</div>
				<vg-button :type="'default-danger'" :size="'sm'" @click="onRemoveNodeSelected">{{$t("deselect")}}</vg-button>
			</div>
		</div>
		<div class="body">
			<vg-tree
				@node-open="onNodeOpen">
				<template v-slot:node="{node}">
					<vg-tree-node-lieu
						v-model="node"
						:showPlanInteractifDatas="showPlanInteractifDatas"
						@select="onSelectNode"
						@context-menu="onContextMenu"
						@show-plans="onShowPlans"
						@select-famille="onSelectFamille">
					</vg-tree-node-lieu>
				</template>
			</vg-tree>
		</div>

        <vg-modal v-if="displayQrScan"
            :title='$t("scan")'
            @close="displayQrScan=false;">
            <template #body>
                <zxing-scanner
                    @scanSuccess="handleScanLieu"
                    :code="'qrcode'"
               ></zxing-scanner>
            </template>
            <template #footer>
                -
            </template>
        </vg-modal>

		<!-- CONTEXT MENU -->
		<div v-if="showContextMenu && !readOnly"
			class="context-menu"
			:style="contextMenuStyle"
			@mouseleave="hideContextMenu">
			<ul class="context-menu-options">
				<li class="context-menu-option"
					@click="showLieuDetailModal=true">
					<i class="far fa-eye"></i> {{$t("context-menu-detail")}}
				</li>
				<li v-if="(!contextMenu.isGenerique && contextMenu.node.type_lieu!='Piece' && isAdmin) || (contextMenu.isGenerique && contextMenu.node.type_lieu=='Etage' && isAdmin)"
					class="context-menu-option"
					@click="showLieuAddModal=true">
					<i class="fas fa-plus"></i> {{$t("context-menu-create")}} {{$t(getChildrenTypeLieu)}}
				</li>
				<li class="context-menu-option"
					@click="onShowPlans">
					<i class="far fa-map"></i> {{$t("context-menu-plans")}}
				</li>
			</ul>
		</div>
		<vg-lieu-detail-modal v-if="showLieuDetailModal"
			v-model="contextMenu.node"
			@close="showLieuDetailModal=false"
			@update="showLieuUpdateModal=true"
			>
		</vg-lieu-detail-modal>
		<vg-lieu-form v-if="showLieuUpdateModal"
			v-model="contextMenu.node"
			@close="showLieuUpdateModal=false"
			@updated="onLieuUpdated"
			@deleted="onLieuDeleted">
		</vg-lieu-form>
		<vg-lieu-form v-if="showLieuAddModal"
			:parent="contextMenu.node"
			@close="showLieuAddModal=false"
			@created="onLieuCreated">
		</vg-lieu-form>
		<vg-files-plans v-if="showTiroirPlans"
			:etage="focusedEtage"
			@close="showTiroirPlans=false;plansFilters=null;focusedEtage=null;">
		</vg-files-plans>
	</div>
</template>
<script>
	import {mapActions, mapGetters} from "vuex";

	import VgButton from "src/components/Vg/VgButton.vue";

	import VgTree from "src/components/Vg/Tree/VgTree.vue";
	import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
	import VgTreeNodeLieu from "src/components/Vg/Tree/VgTreeNodeLieu.vue";

	import VgLieuPathViewer from "src/components/Vg/Lieu/VgLieuPathViewer.vue";
	import VgLieuDetailModal from "src/components/Vg/Lieu/VgLieuDetailModal.vue";
	import VgLieuForm from "src/components/Vg/Forms/VgLieuForm.vue";
	import VgFilesPlans from "src/components/Vg/Files/VgFilesPlans.vue";

	import LieuMixins from "src/mixins/LieuMixins.js";
    import zxingScanner from 'src/components/QrCode/Zxing/zxingScanner.vue';

	import Metadatas from "src/services/Metadatas";
    export default {
        name: 'vg-tree-lieux',
		mixins: [LieuMixins],
		components: {
			VgButton,
			VgTree,
			VgTextFilter,
			VgTreeNodeLieu,
			VgLieuDetailModal,
			VgLieuForm,
			VgFilesPlans,
			VgLieuPathViewer,
            zxingScanner
		},
		i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Arborescence des lieux",
            "title-mobile": "Lieux",
            "close-all": "Tout replier",
            "filter-infos": "Filtrage de l'arborescence par",
            "filter-infos-resultats": "résultats",
            "context-menu-detail": "Détail du lieu",
            "context-menu-create": "Ajouter",
            "site": "Site / L2",
            "batiment": "Batiment / L3",
            "etage": "Etage / L4",
            "piece": "Pièce / L5",
            "context-menu-plans": "Voir les plans",
            "plans": "Plans",
            "deselect": "Déselectionner"
        },
        "en": {
            "title": "Tree view",
            "title-mobile": "Locations",
            "close-all": "Hide all",
            "filter-infos": "Filter tree view by",
            "filter-infos-resultats": "Result",
            "context-menu-detail": "Location details",
            "context-menu-create": "Add",
            "site": "Site / L2",
            "batiment": "Building / L3",
            "etage": "Floor / L4",
            "piece": "Room / L5",
            "context-menu-plans": "See floor plans",
            "plans": "Floor plans",
            "deselect": "Deselect"
        },
        "th": {
            "title": "มุมมองต้นไม้",
            "close-all": "ซ่อนทั้งหมด",
            "filter-infos": "กรองมุมมองต้นไม้ตาม",
            "filter-infos-resultats": "ผลลัพธ์",
            "context-menu-detail": "รายละเอียดที่ตั้ง",
            "context-menu-create": "เพิ่ม",
            "site": "หน้างาน / L2",
            "batiment": "สิ่งปลูกสร้าง / L3",
            "etage": "พื้น / L4",
            "piece": "ห้อง / L5",
            "context-menu-plans": "ดูแผนงานพื้น",
            "plans": "แผนงานพื้น"
        }
    }
},
        props: {
			/**
			* @model
			*/
			value:{
				type: String,
				default: null
			},
			readOnly: {
				type: Boolean,
				default: false
			},
			showPlanInteractifDatas: {
				type: Boolean,
				default: false
			}
        },
		watch:{
			queryFilter: {
				handler: function(query){
					//console.log("QUERY", query);
					this.fetch(query);
				}
			},
			value: {
				handler: function(query){
					//console.log("QUERY", query);
					this.queryFilter = query;
				}
			}
		},
        data: function() {
            return {
				lieux: [],
				queryFilter: null,
				showContextMenu: false,
				contextMenu: null,
				showLieuDetailModal: false,
				showLieuAddModal: false,
				showLieuPlansModal: false,
				showLieuUpdateModal: false,
				showTiroirPlans: false,
				plansFilters: null,
                displayQrScan:false,
				focusedEtage: null,
				metadatasChildren: new Metadatas(),
				filtersChildren: {
					idLieuParent_id: {attr: "idLieuParent_id", value: null, action: "equals"},
					famille: {attr: "famille", value: null, action: "equals"}
				}
            };
        },
		created: function(){
			this.fetch().then(()=>{
				if(this.value){
					this.queryFilter = this.getLastChildInPath(this.value);
				}
			});
		},
		mounted: function(){

		},
		methods: {
			...mapActions([
				'VgTreeStore/updateDatas',
				'VgTreeStore/updateNodePending',
				'VgTreeStore/updateIsCollapseAll',
				'VgTreeStore/updateNodeSelected'
			]),
			/**
			* callback création lieu
			* @param object lieu lieu créé
			*/
			onLieuCreated: function(lieu){
				//console.log("ON LIEU CREATED", lieu);
				this.fetchChildren(this.contextMenu.node);
				this.showLieuAddModal = false;
			},
			/**
			* callback suppression lieu
			* @param object lieu lieu supprimé
			*/
			onLieuDeleted: function(lieu){
				//console.log("ON LIEU DELETED", lieu);
				this.fetchChildren({id: this.contextMenu.node.idLieuParent_id, parents: this.contextMenu.node.parents.slice(0,-1)});
				this.showLieuDetailModal = false;
				this.contextMenu = null;
			},
			/**
			* callback modification lieu, fetch les children du parent du node modifié
			* @param object lieu lieu mis à jour
			*/
			onLieuUpdated: function(lieu){
				//console.log("ON LIEU UPDATED", lieu, this.contextMenu);
				this.contextMenu.node = Object.assign(this.contextMenu.node, lieu);
				this.fetchChildren({
					id: this.contextMenu.node.idLieuParent_id,
					parents: this.contextMenu.node.parents.splice(0,this.contextMenu.node.parents.length-1)
				});
				this.showLieuDetailModal = false;
			},
			/**
			* ouvre la modal des plans prefiltré avec le lieu
			* @param object data - lieu sélectionné
			*/
			onShowPlans: function(data){
				let lieu = this.contextMenu?this.contextMenu.node:data;
				if(!this.showContextMenu) this.contextMenu = null;
				//console.log("SHOW PLANS", data, this.contextMenu);
				this.plansFilters = {};
				this.plansFilters[lieu.type_lieu] = lieu.libel_lieu;
				let nbParents = lieu.parents.length-1; // skip l'organisation
				let pathSplit = lieu.path.split("/");
				let levelsName = ["Site", "Batiment", "Etage"];
				for(var i=0; i<=nbParents; i++) this.plansFilters[levelsName[i]] = pathSplit[i+1];
				this.plansFilters.lieu = lieu;
				if(lieu.type_lieu=="Etage") this.focusedEtage = lieu;
				this.showTiroirPlans = true;
			},
			/**
			* supprime le contextMenu affiché
			* @param object e
			*/
			hideContextMenu: function(e){
				//console.log("HIDE CONTEXT MENU", e);
				this.showContextMenu = false;
			},
			/**
			* ouvre le contextMenu à l'endroit du click droit & déclenche un fetch des children si le node n'est pas déployé
			* @param object data {event:..., node:...}
			*/
			onContextMenu: function(data){
				//console.log("onContextMenu", data);
				if(data.node.type_lieu!="Piece" && (!data.node.children || data.node.children.length==0)){
					this.$store.dispatch("VgTreeStore/updateNodePending", data.node);
					this.fetchChildren(data.node);
				}
				let contextMenu = {
					left: data.event.pageX,
					top: 0,
					node: data.node,
					isGenerique: data.isGenerique
				};
                let posY = data.event.pageY;
                let heightScreen = window.innerHeight;
                let heightContextMenu = 150;
                // pour resoudre probleme du clique en bas de la fenetre. remet au dessus du click souris
                if (posY >= heightScreen-heightContextMenu ) {
                    contextMenu.top = posY - heightContextMenu;
                }
                else {
                    contextMenu.top = posY;
                }
            	//if(!data.isGenerique || (data.isGenerique && data.node.type_lieu=="Piece" && data.node.libel_lieu!="-")){
					this.contextMenu = contextMenu;
					this.showContextMenu = true;
				//}
			},
			/**
			* collapse tous les nodes de l'Arborescence
			*/
			onCollapseAll: function(){
				if(!this.isCollapseAll) this.$store.dispatch("VgTreeStore/updateIsCollapseAll", true);
			},
			/**
			* callback node ouvert, déclenche le fetch des children du node
			* @param object node
			*/
			onNodeOpen: function(node){
				//console.log("NODE OPEN", node);
				this.fetchChildren(node);
			},
			/**
			* selection du node, stock le node dans le store & met à jour le v-model
			* @param object node
			*/
			onSelectNode: function(node){
				this.$store.dispatch("VgTreeStore/updateNodeSelected", node);
				this.$emit("input",node.path);
				if(node.type_lieu=="Piece") this.$emit("select-piece", node);
				else this.$emit("select-node", node);
			},
			onSelectFamille: function(datas){
				this.filtersChildren.famille.value = datas.famille;
				this.fetchChildren(datas.node);
			},

            /**
             * handleScanLieu -
             *
             * @param  {string} code scanner result
             */
            handleScanLieu:function(code){
                this.displayQrScan = false;
                this.queryFilter = code;
            },
			/**
			* associe les children au lieu dans le set de données
			* lieu.children = children
			* @param object lieu
			* @param array children
			*/
			addLieuChildren: function(lieu, children){
				// retrouve le lieu dans le set de données
				let parents = lieu.parents || [];
				//console.log(lieu, "PARENTS", parents);
				let idLieu = lieu.id;
				let formatedChildren = children.map((child)=>Object.assign(child,{parents:parents.concat([idLieu])}));
				let indexOrganisation, indexSite, indexBatiment, indexEtage = null;
				switch(parents.length){
					case 0:	// recupere les children d'une organisation
						indexOrganisation = this.lieux.findIndex((org)=>org.id==idLieu);
						this.lieux[indexOrganisation].children = formatedChildren;
						break;
					case 1:	// recupere les children d'un site
						indexOrganisation = this.lieux.findIndex((org)=>org.id==parents[0]);
						indexSite = this.lieux[indexOrganisation].children.findIndex((site)=>site.id==idLieu);
						this.lieux[indexOrganisation].children[indexSite].children = formatedChildren;
						break;
					case 2:	// recupere les children d'un batiment
						indexOrganisation = this.lieux.findIndex((org)=>org.id==parents[0]);
						indexSite = this.lieux[indexOrganisation].children.findIndex((site)=>site.id==parents[1]);
						indexBatiment = this.lieux[indexOrganisation].children[indexSite].children.findIndex((batiment)=>batiment.id==idLieu);
						this.lieux[indexOrganisation].children[indexSite].children[indexBatiment].children = formatedChildren;
						break;
					case 3:	// recupere les children d'un etage
						indexOrganisation = this.lieux.findIndex((org)=>org.id==parents[0]);
						indexSite = this.lieux[indexOrganisation].children.findIndex((site)=>site.id==parents[1]);
						indexBatiment = this.lieux[indexOrganisation].children[indexSite].children.findIndex((batiment)=>batiment.id==parents[2]);
						indexEtage = this.lieux[indexOrganisation].children[indexSite].children[indexBatiment].children.findIndex((etage)=>etage.id==idLieu);
						this.lieux[indexOrganisation].children[indexSite].children[indexBatiment].children[indexEtage].children = formatedChildren;
						break;
					default:
						break;
				}
				this.$store.dispatch("VgTreeStore/updateDatas", this.lieux);
				this.$store.dispatch("VgTreeStore/updateNodePending", null);
			},
			/**
			* récupère la liste des lieux enfant
			* @param object lieu
			*/
			fetchChildren: function(lieu){
				this.filtersChildren.idLieuParent_id.value = lieu.id;
				if(lieu.type_lieu!="Organisation") this.filtersChildren.famille.value = null;
				if(lieu.type_lieu=="Batiment") this.filtersChildren.type_lieu = {attr: "l.type_lieu", value: "Etage", action: "equals"};
				else this.filtersChildren.type_lieu = {attr: "l.type_lieu", value: null, action: "equals"};
				this.metadatasChildren.setFilters(this.filtersChildren);
				this.$store.dispatch("VgTreeStore/updateNodePending", lieu);
				this.LieuMixins_getLieux(this.metadatasChildren).then((datas)=>{
					//console.log("SUCCESS GET LIEUX CHILDREN", lieux);
					this.addLieuChildren(lieu, datas.lieux);
				});
			},
			getLastChildInPath:function(path){
				let pathArray = path.split("/");
				return pathArray[pathArray.length-1];
			},
			/**
			* fetch lieux
			* @param string queryFilter
			*/
			fetch: function(queryFilter=null){
				return new Promise((resolve,reject)=>{
					let metadatas = new Metadatas();
					if(queryFilter){
						metadatas.setFilters([
							{attr:"libel_lieu",value:queryFilter,action:"contains",openParenthesis:true},
							{attr:"service",value:queryFilter,action:"contains",logicalOperator:"OR"},
							{attr:"c.libelleCatgorie",value:queryFilter,action:"contains",logicalOperator:"OR"},
							{attr:"codeTrois",value:queryFilter,action:"contains",logicalOperator:"OR"},
							{attr:"codeUn",value:queryFilter,action:"contains",logicalOperator:"OR",closeParenthesis:true}
						]);
						this.LieuMixins_getLieux(metadatas).then((datas)=>{
							this.lieux = datas.lieux;
							this.$store.dispatch("VgTreeStore/updateDatas", this.lieux);
						});
					}else{
						metadatas.setFilters([{attr:"type_lieu",value:["Site"],action:"equals"}]);
						let organisations = [];
						this.LieuMixins_getOrganisations().then((datas)=>{
							organisations = datas;
							this.LieuMixins_getLieux(metadatas).then((datas)=>{
								let sites = datas.lieux;
								this.lieux = organisations.map((org)=>{
									return Object.assign(org, {
										children: sites.filter(
											(child)=>child.type_lieu=="Site" && child.idLieuParent_id==org.id
										).map(
											(child)=>Object.assign(child, {parents:[org.id]})
										),	// site a un parent
										parents: []	// organisation n'a aucun parent
									});
								});
								this.$store.dispatch("VgTreeStore/updateDatas", this.lieux);
								resolve();
							});
						});
					}
				});
				
			},
			/**
			* supprime la selection d'un node
			*/
			onRemoveNodeSelected: function(){
				this.$store.dispatch("VgTreeStore/updateNodeSelected", null);
				this.$emit("input", null);
				this.$emit("remove-selected-node", null);
			}
		},
        computed: {
			...mapGetters({
				datas: 'VgTreeStore/getDatas',
				isCollapseAll: 'VgTreeStore/getIsCollapseAll',
				nodeSelected: 'VgTreeStore/getNodeSelected'
			}),
			getIcon: function(){
				if(this.nodeSelected.type_lieu=="Organisation") return "/static/assets/icone/lieux/organisation-mauve.png";
				else if(this.nodeSelected.type_lieu=="Site") return "/static/assets/icone/lieux/site-mauve.png";
				else if(this.nodeSelected.type_lieu=="Batiment") return "/static/assets/icone/lieux/batiment-mauve.png";
				else if(this.nodeSelected.type_lieu=="Etage") return "/static/assets/icone/lieux/etage-mauve.png";
				else return "/static/assets/icone/lieux/piece-mauve.png";
			},
			contextMenuStyle: function(){
				return "top:"+this.contextMenu.top+"px;left:"+this.contextMenu.left+"px;";
			},
			getChildrenTypeLieu: function(){
				if(this.contextMenu.node.type_lieu=="Organisation") return "site";
				else if(this.contextMenu.node.type_lieu=="Site") return "batiment";
				else if(this.contextMenu.node.type_lieu=="Batiment") return "etage";
				else if(this.contextMenu.node.type_lieu=="Etage") return "piece";
			},
			isAdmin: function(){
				return this.$app.role=="ROLE_ADMIN";
			}
		},
		destroyed: function(){
			this.$store.dispatch("VgTreeStore/updateNodeSelected", null);
		}
    };

</script>
<style lang="scss" scoped>
$toggler-disabled-color: #cacaca;
$node-selected-color: rgb(233,222,237);
$title-bg-color: #E8EAEE;
$context-menu-bg-color: GhostWhite;
$context-menu-border-color: #BDBDBD;
$filter-infos-bg-color: rgba(233,222,237,.4);
$context-menu-option-hover: rgba(0, 0, 0, 0.2);
.vg-tree-lieux{
	.header{
		.header-title{
            height:40px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px;
			background-color: $title-bg-color;
            font-size: 13px;
            padding:0 20px;
            img{
                margin-bottom:0;
                margin-right: 10px;
            }
			.title{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 5px;
			}
			.options{
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
			}
			.toggler{
				margin-right: 10px;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
			.disabled{
				color: $toggler-disabled-color;
			}
			.only-desktop{
				display: block;
			}
			.only-mobile{
				display: none;
			}
		}
		.filter-infos{
			background-color: $filter-infos-bg-color;
			text-align: center;
			padding: 5px 0px;
		}
		.selection-infos{
			background-color: $node-selected-color;
			text-align: center;
			padding: 5px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 5px 10px 5px 20px;
			>div{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				img{
					margin-right:10px;
					height: 20px;
					width: 20px;
				}
			}
			>i{
				cursor: pointer;
			}
		}
	}
	.context-menu{
		display: block !important;
		position: fixed !important;
		z-index: 2 !important;
		background: $context-menu-bg-color;
		border: 1px solid $context-menu-border-color;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
		list-style: none !important;
		margin: 0 !important;
		padding: 0 !important;
		width: 300px !important;
		.context-menu-options{
			list-style: none;
			margin-block-start: 0 !important;
			margin-block-end: 0 !important;
			padding-inline-start: 0 !important;
			.context-menu-option{
				font-weight: 500;
				font-size: 14px;
				padding: 10px 40px 10px 20px;
				cursor: pointer;
				&:hover {
					background: $context-menu-option-hover;
				}
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			}
		}
	}
}

@media only screen and (max-width: 1200px){
	.vg-tree-lieux{
		.header{
			.header-title{
				padding:0 5px;
				.only-desktop{
					display: none;
				}
				.only-mobile{
					display: block;
				}
			}
		}
	}
}

</style>
<docs>
    # Exemple
	## Appel du composant
	```javascript
	<vg-tree-lieux
		v-model="agfilters.path.value">
	</vg-tree-lieux>
	```
	NB: agfilters: { path: {attr:"path", value: null, action:"contains"} }
</docs>
