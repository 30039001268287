<template>
    <form id="bc-item-form" v-if="getDefaultDepotId"
        ref="bc-item-form"
        :class="{'vg-bon-de-commande-items-form-livraison': isLivraison, 'vg-bon-de-commande-items-form-non-livraison': !isLivraison}"
        onSubmit="return false;">

        <div class="bc-item-row header">
            <span>{{ $t("header-bc-item-ref") }}</span>
            <span>{{ $t("header-bc-item-designation") }}</span>
            <span>{{ $t("header-bc-item-quantite") }}</span>
            <span v-if="isLivraison">{{ $t("header-bc-item-quantite-livree") }}</span>
            <span>{{ $t("header-bc-item-unite") }}</span>
            <span>{{ $t("header-bc-item-pu-ht") }}</span>
            <span>{{ $t("header-bc-item-total-ligne-ht") }}</span>
            <span>{{ $t("header-bc-item-taux-tva") }}</span>
        </div>
        <div class="bc-item-row" v-for="(item, index) in bonDeCommandeItems" :key="'bc-item-'+index">
            <vg-consommable-datalist v-model="item.ref" :key="'consommable-datalist-ref-'+index"
                :inputDisplayLabel="'refExterne'"
                :depotId="getDefaultDepotId"
                :fournisseurId="fournisseurId"
                :placeholder="$t('saisir-ref')"
                @select="onSelectConsommable($event, index)"/>
            <vg-consommable-datalist v-model="item.description" :key="'consommable-datalist-designation-'+index"
                :placeholder="$t('saisir-designation')"
                :depotId="getDefaultDepotId"
                :fournisseurId="fournisseurId"
                @select="onSelectConsommable($event, index)"/>
            <vg-input v-model="item.quantite" :inputType="'float'" @input="onChangeQuantite($event, item, index)" :key="'bc-item-quantite-'+index"/>
            <vg-input v-if="isLivraison" v-model="item.quantiteLivree" :inputType="'float'" :key="'bc-item-quantite-livree-'+index"/>
            <vg-input>
                <vg-unite-selector v-model="item.unite"  :key="'bc-item-unite-'+index"/>
            </vg-input>
            <vg-input v-model="item.puHT" :inputType="'financial'" @input="onChangePuHT($event,item, index)"  :key="'bc-item-prix-unitaire-'+index"/>
            <vg-input v-model="item.totalHT" :inputType="'financial'" disabled  :key="'bc-item-total-'+index"/>
            <vg-input>
                <vg-tva-selector v-model="item.tva_id"
                    @select="onSelectTva($event, item, index)"  :key="'bc-item-tva-'+index"/>
            </vg-input>
            <vg-button type="grey" @click="removeBonDeCommandeItem(item, index)">
                <i class="fa fa-times" style="color: #333;"/> 
            </vg-button>
        </div>
        <div class="bc-item-footer">
            <vg-button type="info"
                :disabled="isAddProduitDisabled"
                @click="addBonDeCommandeItem">
                {{ $t("ajouter-produit") }}
            </vg-button>
        </div>
        <input
          v-show="false"
          ref="bc-item-submit"
          type="submit"
        >
    </form>
    <div v-else>
        <span style="color:#35b1ea;">{{ $t("chargement-des-donnees") }}</span>
    </div>
</template>
<script>

import VgModal from "src/components/Vg/VgModal.vue";
import VgButton from "src/components/Vg/VgButton.vue";
import VgInput from "src/components/Vg/VgInput.vue";
import VgSelect from "src/components/Vg/VgSelect.vue";

import VgConsommableDatalist from "src/components/Vg/Datalists/VgConsommableDatalist.vue";
import VgTvaSelector from "src/components/Vg/Selectors/VgTvaSelector.vue";
import VgUniteSelector from "src/components/Vg/Utils/VgUniteSelector.vue";

import BonsDeCommandeMixins from "src/mixins/BonsDeCommandeMixins.js";
import BonDeCommandeItemsMixins from "src/mixins/BonDeCommandeItemsMixins.js";
import StocksMixins from "src/mixins/StocksMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from "vuex";

export default {
    name: 'vg-bon-de-commande-items-form',
    components:{
        VgModal,
        VgButton,
        VgInput,
        VgSelect,
        VgConsommableDatalist,
        VgTvaSelector,
        VgUniteSelector
    },
    mixins: [BonsDeCommandeMixins, BonDeCommandeItemsMixins, StocksMixins],
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "header-bc-item-ref": "Réf.",
            "header-bc-item-designation": "Désignation",
            "header-bc-item-quantite": "Qté",
            "header-bc-item-quantite-livree": "Qté livrée",
            "header-bc-item-unite": "Unité",
            "header-bc-item-pu-ht": "Prix unitaire",
            "header-bc-item-total-ligne-ht": "Total ligne",
            "header-bc-item-taux-tva": "Taux TVA",
            "saisir-designation": "Saisir désignation",
            "saisir-ref": "Saisir référence",
            "nouvelle-reference": "Nouvelle référence",
            "aucune-reference": "Aucune référence",
            "chargement-des-donnees": "Chargement des données...",
            "ajouter-produit": "Ajouter produit"
        },
        "en": {
            "header-bc-item-ref": "Ref.",
            "header-bc-item-designation": "Designation",
            "header-bc-item-quantite": "Qty",
            "header-bc-item-quantite-livree": "Delivered Qty",
            "header-bc-item-unite": "Unit",
            "header-bc-item-pu-ht": "Unit Price",
            "header-bc-item-total-ligne-ht": "Total line",
            "header-bc-item-taux-tva": "VAT rate",
            "saisir-designation": "Enter designation",
            "saisir-ref": "Enter reference",
            "nouvelle-reference": "New reference",
            "aucune-reference": "No reference",
            "chargement-des-donnees": "Loading data...",
            "ajouter-produit": "Add product"
        }
    }
},
    props: {
        /**
        * bonDeCommande.items
        * @model
        */
        value: {
            type: Array,
            required: true
        },
        isLivraison: {
            type: Boolean,
            default: false
        },
        fournisseurId:{
            type: String | Number,
            default: null
        }
    },
    data: function() {
        return {
            bonDeCommandeItems: this.value?this.value:[],
            bonDeCommandeItem: {
                ref: null,
                description: null,
                quantite: 1,
                quantiteLivree: 0,
                unite: "u.",
                puHT: 0,
                totalHT: 0,
                bonDeCommande_id: null,
                consommable_id: null,
                tva_id: null,
                tva: null,
                userId: this.$app.appID
            }
        };
    },
    watch:{
        value: {
            handler: function(items){
                this.bonDeCommandeItems = items;
            },
            deep: true
        },
        bonDeCommandeItems: {
            handler: function(items){
                console.log("WATCH ------------------------- BC items", items);
                this.$emit("change", this.bonDeCommandeItems);
            },
            deep: true
        },
    },
    computed:{
        ...mapGetters({
            bonsDeCommande: 'BonsDeCommandeStore/getCollection',
            bonDeCommande: 'BonsDeCommandeStore/getSelectedItem',
            bonDeCommandeItemsStored: "BonDeCommandeItemsStore/getCollection",
            depotDefault: "StocksStore/getDepotDefault",
            depotFlottant: "StocksStore/getDepotFlottant"
        }),
        isBonDeCommandeItemValid: function(){
            return this.bonDeCommandeItem.description!=null && this.bonDeCommandeItem.description.length!=0 && this.bonDeCommandeItem.quantite!=null && this.bonDeCommandeItem.puHT!=null && this.bonDeCommandeItem.tva && this.bonDeCommandeItem.tva.id;
        },
        getDefaultDepotId: function(){
            return this.depotDefault && this.depotDefault.id;
        },
        lastBonDeCommandeItemsIsValid: function(){
            let lastItem = this.bonDeCommandeItems.at(-1);
            return lastItem.description!=null && lastItem.description.length!=0 && lastItem.quantite!=null && lastItem.puHT!=null && lastItem.tva && lastItem.tva.id;
        },
        isAddProduitDisabled: function(){
            if(this.bonDeCommandeItems && this.bonDeCommandeItems.length==0) return false;
            else if(this.bonDeCommandeItems && this.bonDeCommandeItems.length!=0) return !this.lastBonDeCommandeItemsIsValid;
            else return true;
        },
    },
    created: function() {
        this.fetchDepots();
        if(this.bonDeCommandeItems && this.bonDeCommandeItems.length==0) this.addBonDeCommandeItem();
    },
    methods: {
        removeBonDeCommandeItem: function(item, index){
            console.log("REMOVE BC ITEM", item, index);
            if(item.id){
                this.BonDeCommandeItemsMixins_delete(item).then((data)=>{
                    console.log("AFTER DELETE BC ITEM", data);
                    //this.bonDeCommandeItems.splice(index, 1);
                    this.updateBonDeCommande();
                });
            }else{ 
                this.bonDeCommandeItems.splice(index, 1);
                if(item) this.$emit("input", this.bonDeCommandeItems);
            }
        },
        addBonDeCommandeItem: function(){
            this.bonDeCommandeItems.push(Object.assign({}, {}, this.bonDeCommandeItem));
        },
        fetchDepots: function(){
            return new Promise((resolve, reject)=>{
                let metadatasDepots = new Metadatas();
                this.StocksMixins_getDepots(metadatasDepots).then((datas)=>{
                    resolve(datas);
                });
            });
        },
        /**
        * @param Object tva
        * @param Object item
        * @param Number index
        */
        onSelectTva: function(tva, item=null, index=null){
            if(item) {
                this.bonDeCommandeItems[index].tva_id = tva.id;
                this.bonDeCommandeItems[index].tva = tva;
                this.$emit("input", this.bonDeCommandeItems);
            }else{
                this.bonDeCommandeItem.tva_id = tva.id;
                this.bonDeCommandeItem.tva = tva;
            }
        },
        /**
        * converti un string en valeur float 2 decimale
        */
        convertStringToFinancial: function(cout){
            if(typeof(cout)=="string"){
                let financial = cout.replaceAll(",",".");
                return Number.parseFloat(financial).toFixed(2);
            }else return cout;
        },
        // @TODO maintenir le changement inputs description ref etc affiche un bouton validation par ligne
        onSelectConsommable: function(consommable, index){
            console.log("onSelectConsommable", consommable);
            if(consommable){
                let coutUnitaireConsommable = consommable.coutUnitaire?this.convertStringToFinancial(consommable.coutUnitaire):0;
                this.bonDeCommandeItems[index] = Object.assign(this.bonDeCommandeItems[index], {
                    ref: consommable.refExterne,
                    description: consommable.name,
                    puHT: coutUnitaireConsommable,
                    totalHT: coutUnitaireConsommable*this.bonDeCommandeItems[index].quantite,
                    consommable_id: consommable.id,
                    unite: consommable.unite?consommable.unite:"u."
                });
                if(consommable.tva_id){
                    this.bonDeCommandeItems[index].tva_id = consommable.tva_id;
                    this.bonDeCommandeItems[index].tva = consommable.tva;
                }
            }
        },
        bonDeCommandeMontants: function(){
            let montants = {
                montantHT: 0,
                montantTVA: 0,
                montantTTC: 0
            };
            this.bonDeCommandeItems.forEach((item)=>{
                montants.montantHT = Number.parseFloat(Number(montants.montantHT) + Number(item.totalHT));
                montants.montantTVA = Number.parseFloat(Number(montants.montantTVA) + Number(item.totalHT)*Number(item.tva.value)/100);
            });
            montants.montantHT = montants.montantHT.toFixed(2);
            montants.montantTVA = montants.montantTVA.toFixed(2);
            montants.montantTTC = Number.parseFloat(Number(montants.montantHT) + Number(montants.montantTVA)).toFixed(2);
            return montants;
        },
        updateBonDeCommande: function(){
            let bonDeCommande = Object.assign({}, this.bonDeCommande, this.bonDeCommandeMontants());
            this.BonsDeCommandeMixins_update(bonDeCommande).then((data)=>{
                console.log("AFTER UPDATE BC montants", data);
            });
        },
        onUpdateItem: function(item, index){
            console.log("UPDATE ITEM", item, this.bonDeCommandeMontants());
            this.BonDeCommandeItemsMixins_update(item).then((data)=>{
                console.log("AFTER UPDATE BC ITEM", data);
                // update BC - recalcul les montants HT TVA et TTC à partir des BC items.
                this.updateBonDeCommande();
            });
        },
        onChangeQuantite: function(quantite, item, index){
            // recalcul item.totalHT
            this.bonDeCommandeItems[index].totalHT = Number.parseFloat(Number(quantite) * Number(this.bonDeCommandeItems[index].puHT)).toFixed(2);
        },
        onChangePuHT: function(pu, item=null, index=null){
            if(item && item.id){
                // recalcul item.totalHT
                this.bonDeCommandeItems[index].totalHT = Number.parseFloat(Number(this.bonDeCommandeItems[index].quantite) * Number(pu)).toFixed(2);
            }else{
                if(!item && this.bonDeCommandeItem.quantite){
                    this.bonDeCommandeItem.totalHT = Number(pu) * Number(this.bonDeCommandeItem.quantite);
                }else if(item && this.bonDeCommandeItems[index].quantite){
                    this.bonDeCommandeItems[index].totalHT = Number(pu) * Number(this.bonDeCommandeItems[index].quantite);
                }
                if(item) this.$emit("input", this.bonDeCommandeItems);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.vg-bon-de-commande-items-form-livraison{
    .bc-item-row{
        display: grid;
        grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 30px;
        justify-items: center;
    }
    .header{
        font-weight: bold;
        margin-bottom:5px;
        font-size: 11px;
        color: #9e9da9;
    }
}
.vg-bon-de-commande-items-form-non-livraison{
    .bc-item-row{
        display: grid;
        grid-template-columns: 2fr 3fr 1fr 1fr 1fr 1fr 1fr 30px;
        justify-items: center;
    }
    .header{
        font-weight: bold;
        margin-bottom:5px;
        font-size: 11px;
        color: #9e9da9;
    }
}
.bc-item-footer{
    width:100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
}
.item-unite{
    color: #9e9da9;
}
</style>
<docs>

    ### vg-bon-de-commande-items-form
    formulaire bon de commande items

    #### basic usage
    ```html static
        <vg-bon-de-commande-items-form
            @close="..."/>
    ```
</docs>
