<template>
	<div class="vg-equipements-table">
		<tag-grid v-if="!isCheckLieu && !isEquipementPicker"
			:idTableau="id"
			v-model="gridOptions"
			:columnDefs="columnDefs"
			:rowData="equipements"
			:isModalShowHideColumnsOpened="TagGridMixins.isModalShowHideColumnsOpened"
			:isExportXLS="TagGridMixins.isExportXLS"
			:showLoadingOverlay="showLoadingOverlay"
			:overlayLoadingText="$t('overlay-loading-text')"
			:overlayNoRowsText="$t('overlay-no-rows-text')"
            deltaRowDataMode="true"
            @ag-multiple-selection-row-changed="handleMultipleSelectionRowChanged"
			@grid-feeded="setDefaultFilter()"
			@on-grid-ready="setDefaultFilter()"
			@ag-click="onClickRow"
			@ag-dbl-click="onDoubleClickRow"
            @filter-changed="handleFilterChanged" />
        <div v-else-if="isEquipementPicker" class="vg-equipements-picker">
            <div v-if="showLoadingOverlay" class="loading-overlay">
                <span class="text">{{$t("overlay-loading-text")}}</span>
            </div>
            <div v-if="counters.filtered==0" class="loading-overlay">
                <span class="text">{{$t("overlay-no-rows-text")}}</span>
            </div>
            <equipement-mobile-cell-render v-for="(equipement, index) in equipements" :key="equipement.id"
				class="equipement-row"
                :params="{data: equipement}"
				:selectedEquipements="selectedEquipements"
				showCheckbox
                @select-equipement="addSelectedEquipement"
                @deselect-equipement="removeSelectedEquipement"
            />
        </div>
		<div v-else class="check-lieu-equipements">
			<equipement-check-lieu-cell-render v-for="(equipement, index) in equipementsFiltered"
				:params="{data: equipement}"
				:key="equipement.id"
				:isDefaultMaintenance="isDefaultMaintenance(equipement)"
				class="check-lieu-equipement"/>
		</div>
		<vg-pagination v-show="paginate && !displayEtiquetage"
			:totalItems="counters.filtered"
			:offset="offset"
			:limit="limit"
			@pagination-change="handlePaginationChange" />
        <vg-equipement-etiquetage
            v-if="displayEtiquetage"
            @close="displayEtiquetage = false" 
            @save="displayEtiquetage = false" />
	</div>
</template>
<script>
	import TagGrid from 'src/components/Grid/TagGrid.vue';
	import TagGridMixins from 'src/mixins/TagGridMixins.js';
	import equipementsMixins from 'src/mixins/equipementsMixins.js';

    import LibelEquipementCellRender from "src/components/Vg/TagGrid/LibelEquipementCellRender.vue";
    import DatetimeCellRender from "src/components/Vg/TagGrid/DatetimeCellRender.vue";
    import PathCellRender from "src/components/Vg/TagGrid/PathCellRender.vue";
    import EquipementMobileCellRender from "src/components/Vg/TagGrid/EquipementMobileCellRender.vue";
    import EquipementCheckLieuCellRender from "src/components/Vg/TagGrid/EquipementCheckLieuCellRender.vue";

	import VgPagination from "src/components/Vg/VgPagination.vue";

	import Metadatas from "src/services/Metadatas.js";
    import VgEquipementEtiquetage from "src/components/Vg/Equipements/VgEquipementEtiquetage.vue";
    import StatutDescriptionFmCellRender from "src/components/Vg/TagGrid/StatutDescriptionFmCellRender.vue";
    import InterventionExterneCellRender from "src/components/Vg/TagGrid/InterventionExterneCellRender.vue";
    import TagCellRender from "src/components/Vg/TagGrid/TagCellRender.vue";

	import { mapGetters } from 'vuex';
    export default {
        name: 'vg-equipements-table',
		components: {
			TagGrid,
			VgPagination,
            VgEquipementEtiquetage,
			EquipementCheckLieuCellRender,
			EquipementMobileCellRender
		},
		mixins: [ TagGridMixins, equipementsMixins ],
        props: {
            /**
            * This should be a unique id otherwise if 2 instances of
            * this component are called anywhere on app they will share filters
            * and ag-grid stored configs.
            */
            id:{
                type: String,
                default: "vg-equipements-table"
            },
			/**
			* liste des columns à hide (column colId)
			*/
			hiddenColumns: {
				type: Array,
				default: function(){
					return ["sortieEquipement.dateSortie", "sortieEquipement.type"];
				}
			},
			/**
			* liste des columns pined left (column colId)
			*/
			pinnedColumns: {
				type: Array,
				default: function(){
					return ["id", "libel_equipement"];
				}
			},
			/**
			* liste des columns non showable (column colId)
			*/
			notShowableColumns: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* affiche le tableau en mode impression
			* default false
			*/
			showPrint : {
				type: Boolean,
				default: false
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: null
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
			/**
			* filtres externes appliqué sur le tableau en local
			*/
			localFilters: {
				type: Array,
				default: function(){
					return [];
				}
			},
			/**
			* paginate
			*/
			paginate: {
				type: Boolean,
				default: false
			},
			offset: {
				type: Number,
				default: 0
			},
			limit: {
				type: Number,
				default: 25
			},
			clearCollectionAfterDestroyed: {
				type: Boolean,
				default: false
			},
			clearSelectedItemsOnDestroy: {
				type: Boolean,
				default: false
			},
			isCheckLieu:{
				type: Boolean,
				default: false
			},
			isReadOnly:{
				type: Boolean,
				default: false
			},
			isEquipementPicker:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				displayEquipementTable:true,
				showLoadingOverlay:false,
                displayEtiquetage:false,
				gridOptions: {
					rowHeight: this.$vgutils.isMobile()?114:40,
					rowSelection:"multiple", // works only for desktop, event not deispatched for mobile devices
					getRowClass: function(params) {
	                    if (params.data.dateSortie && params.data.dateSortie.length != 0) {
	                        return 'equipement-sorti';
	                    }
	                }
				},
				columnDefs: [
                    {
						headerName: '',
                        colId: 'checkbox',
						width: 50,
						maxWidth: 50,
						checkboxSelection: true,
                        headerCheckboxSelection: true,
						suppressSorting: true,
						cellClass: ["vg-cell-checkbox"],
						pinned: 'left'
					},
					{
						headerName: '#',
						width: 80,
						field: 'id',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						pinned: 'left',
						hide: true
					},
					{
						headerName: this.$t('column-libelEquipement'),
						width: 230,
						field: 'libelEquipement',
                        colId:'e.libel_equipement',
						cellRenderer: (params) => new LibelEquipementCellRender({propsData: {params: params}}).$mount().$el,
						cellClass: ["vg-cell-ballam-theme vg-important-cell vg-font-weight-bold"],
						cellStyle: {
							fontWeight: 'bold'
						},
						pinned: 'left',
					},
					{
						headerName: this.$t('column-emplacement'),//AppTranslate[lang].equipements.desktop['column-emplacement'],
						width: 200,
						field: 'path',
                        colId:'l.path',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						cellRenderer: (params) => new PathCellRender({propsData: {path: params.data.path}}).$mount().$el,
					},
					{
						headerName: this.$t('column-categorie'),//AppTranslate[lang].equipements.desktop['column-categorie'],
						width: 200,
						field: 'categorie.libelle',
                        colId:'c.libelCatgorie',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-tag-categorie'),
						width: 200,
						field: 'tags',
                        colId:'c.tags',
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-sortie-date'),
						width: 120,
						field: 'sortieEquipement.dateSortie',
                        colId:'sortieEquipement.dateSortie',
						cellClass: ["vg-cell-ballam-theme"],
						hide: this.columnIsHidden("sortieEquipement.dateSortie")
					},
					{
						headerName: this.$t('column-sortie-type'),
						width: 120,
						field: 'sortieEquipement.type',
                        colId:'sortieEquipement.type',
						cellClass: ["vg-cell-ballam-theme"],
						hide: this.columnIsHidden("sortieEquipement.type")
					},
					{
						headerName: this.$t('column-statut'),
						width: 120,
						field: 'statut',
                        colId:'statut',
						cellClass: ["vg-cell-ballam-theme"],
						hide: this.columnIsHidden("statut"),
						cellRenderer: (params) =>{
							return this.$t(params.data.statut);
						}
					},
					{
						headerName: this.$t('column-tagsEquipements'),
						field: 'tagsEquipements',
                        colId:'tagsEquipements',
                        cellRenderer: (params) => {
                            if(params.data.tagsEquipements && params.data.tagsEquipements.length!=0){
                                let tags = params.data.tagsEquipements.map( x => x.label);
                                return new TagCellRender({propsData: {tags: tags, icon:"tag.png",color:"blue"}}).$mount().$el;
                            }else{
                                return "-";
                            }
                        },
					},
					{
						headerName: this.$t('column-nb-maintenances'),//AppTranslate[lang].equipements.desktop['column-emplacement'],
						width: 200,
						field: 'maintenances',
                        colId:'e.maintenances',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return params.data.maintenances?params.data.maintenances.length:"";
                        }
					},
					{
						headerName: this.$t('last-maintenance'),//AppTranslate[lang].equipements.desktop['column-emplacement'],
						width: 200,
						field: 'maintenances',
                        colId:'e.maintenances',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            let maintenances = params.data.maintenances;
                            if(maintenances && maintenances.length){
                                let lastMaintenance = maintenances[maintenances.length-1];
                                return new StatutDescriptionFmCellRender({propsData: {maintenance: lastMaintenance}})
                                    .$on("click", this.handleClickShowLastMaintenance)
                                    .$mount().$el
                            }else{
                                return "-";
                            }
                        }
					},
					{
						headerName: this.$t('last-intervention'),//AppTranslate[lang].equipements.desktop['column-emplacement'],
						width: 200,
						field: 'lastInterventionExterne',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
                        cellRenderer: (params) => {
                            return new InterventionExterneCellRender({propsData: {params: params}})
								.$on("open-intervention", this.openLastInterventionExterne)
								.$mount().$el
						}
					},
					{
	                    headerName: this.$t('column-dateMiseEnService'),
	                    width: 180,
	                    name: 'miseEnService',
	                    field: 'miseEnService',
	                    colId: 'miseEnService',
                        /*getQuickFilterText: (params) => moment(params.data.miseEnService).format("DD/MM/YYYY"),
                        //headerComponent: headerRenderer,*/
                        cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.miseEnService, hideTime:true}}).$mount().$el,
                        cellClass: ["vg-cell-ballam-theme"],
	                },
					{
						headerName: this.$t('column-dateVerification'),
						width: 230,
						field: 'dateVerif',
                        colId:'dateVerif',
						getQuickFilterText: (params) => moment(params.data.dateVerif).format("DD/MM/YYYY"),
						//headerComponent: headerRenderer,
						cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateVerif}}).$mount().$el,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true
					},
					{
						headerName: this.$t('column-etat'),//AppTranslate[lang].equipements.desktop['column-etat'],
						width: 120,
						field: 'tagEquipement',
                        colId:'e.tagEquipement',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-numImmobilisation'),//AppTranslate[lang].equipements.desktop['column-numImmobilisation'],
						width: 150,
						field: 'numeroImmobilisation',
                        colId:'numeroImmobilisation',
						//headerComponent: headerRenderer,
						cellRenderer: function(params) {
							return params.data.numeroImmobilisation?params.data.numeroImmobilisation: params.data.numeroImmobilisationDefault ;
						},
						cellClass: ["vg-cell-ballam-theme"],

					},
					{
						headerName: this.$t('column-batchNumber'),//AppTranslate[lang].equipements.desktop['column-numImmobilisation'],
						width: 150,
						field: 'batchNumber',
                        colId:'batchNumber',
						cellClass: ["vg-cell-ballam-theme"]

					},
					{
						headerName: this.$t('column-QRcode'),//AppTranslate[lang].equipements.desktop['column-QRcode'],
						width: 200,
						field: 'qrCode',
                        colId:'e.qrCode',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-caracteristiques'),//AppTranslate[lang].equipements.desktop['column-caracteristiques'],
						width: 230,
						field: 'carac_techniques',
                        colId:'e.carac_techniques',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-numSerie'),//AppTranslate[lang].equipements.desktop['column-numSerie'],
						width: 200,
						field: 'numSerie',
						colId: 'e.numSerie',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-ref-document-externe'),//AppTranslate[lang].equipements.desktop['column-numSerie'],
						width: 200,
						field: 'ReferenceDocumentExterne',
						colId: 'e.ReferenceDocumentExterne',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-refConstructeur'),//AppTranslate[lang].equipements.desktop['column-refConstructeur'],
						width: 250,
						field: 'refConstructeur',
                        colId:'e.refConstructeur',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-miseEnService'),//AppTranslate[lang].equipements.desktop['column-miseEnService'],
						width: 120,
						field: 'miseEnService',
                        colId:'e.miseEnService',
						getQuickFilterText: (params) => moment(params.data.dateRecensement).format("DD/MM/YYYY"),
						//headerComponent: headerRenderer,
						cellRenderer: (params) => new DatetimeCellRender({propsData: {datetime: params.data.dateRecensement}}).$mount().$el,
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-tag'),//AppTranslate[lang].equipements.desktop['column-tag'],
						width: 200,
						field: 'accountingTag',
                        colId:'c.libelCatgorie',
						cellRenderer: "tagsComptableCellRenderer",
						cellClass: ["vg-cell-ballam-theme"]
					},
					{
						headerName: this.$t('column-gep'),//AppTranslate[lang].equipements.desktop['column-gep'],
						width: 200,
						field: 'isGroupEqp',
                        colId:'e.isGroupEqp',
						filter: 'number',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true,
						filter: 'agTextColumnFilter'
					},

					{
						headerName: this.$t('column-nonConformite'),//AppTranslate[lang].equipements.desktop['column-nonConformite'],
						width: 230,
						field: 'reponsesNegative',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true
					},
					{
						headerName: this.$t('column-autre'),//AppTranslate[lang].equipements.desktop['column-autre'],
						width: 200,
						field: 'champOptionel',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true
					},
					{
						headerName: this.$t('column-sourceFinancement'),//AppTranslate[lang].equipements.desktop['column-sourceFinancement'],
						width: 260,
						field: 'sourceFinancement',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true
					},
					{
						headerName: this.$t('column-typeCategorie'),//AppTranslate[lang].equipements.desktop['column-typeCategorie'],
						width: 230,
						field: 'typeCategorie',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true
					},
					{
						headerName: this.$t('column-marque'),//AppTranslate[lang].equipements.desktop['column-marque'],
						width: 200,
						field: 'marque',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true
					},
					{
						headerName: this.$t('column-valeurAchat'),//AppTranslate[lang].equipements.desktop['column-valeurAchat'],
						width: 200,
						field: 'valeurAchat',
						//headerComponent: headerRenderer,
						cellClass: ["vg-cell-ballam-theme"],
						hide: true
					},
					/*{
						headerName: 'paths',
						field: 'paths',
						cellClass: ["vg-cell-ballam-theme"],
						hide: true,
						isColumnShowable:true,

					},
					{
						headerName: 'pathb',
						field: 'pathb',
						cellClass: ["vg-cell-ballam-theme"],
						hide: true,
						isColumnShowable:true,

					},
					{
						headerName: 'pathe',
						field: 'pathe',
						cellClass: ["vg-cell-ballam-theme"],
						hide: true,
						isColumnShowable:true,

					},*/
					{
						headerName: 'type',
						field: 'type',
						cellClass: ["vg-cell-ballam-theme"],
						hide: true,
						isColumnShowable:true,

					},
					{
						headerName: 'isSortie',
						field: 'isSortie',
						cellClass: ["vg-cell-ballam-theme"],
						hide: true,
						isColumnShowable:true,
					}
				]
            };
        },
	    i18n:    { "locale":navigator.language,
    "messages": {
    "fr": {
		"overlay-loading-text": "Chargement des équipements...",
		"overlay-no-rows-text": "Aucun équipement correspondant au filtrage",
        "column-libelEquipement": "Equipement",
        "column-dateMiseEnService": "Date mise en service",
        "column-dateVerification": "Date dernière vérification interne",
        "column-emplacement": "Emplacement",
        "column-numImmobilisation": "Numero comptable",
        "column-etat": "Etat",
        "column-QRcode": "QR Code",
        "column-caracteristiques": "Caractéristiques",
        "column-numSerie": "N° série",
        "column-refConstructeur": "Ref. constructeur",
        "column-miseEnService": "Date Recensement",
        "column-tag-categorie": "étiquettes catégorie",
        "column-tag": "compte comptable",
        "column-gep": "GEP",
        "column-nonConformite": "Non-conformité",
        "column-autre": "Autre",
        "column-sourceFinancement": "Source financement",
        "column-typeCategorie": "Type catégorie",
        "column-marque": "Marque",
        "column-valeurAchat": "Valeur achat",
        "column-categorie": "Catégorie",
        "column-batchNumber": "N° de lot",
        "last-maintenance":"Dernière fiche curative",
        "last-intervention":"Dernière intervention externe",
        "column-tagsEquipements":"Etiquettes",
        "column-ref-document-externe":"Référence document externe",
        "column-nb-maintenances":"Total fiches curatives",
		"column-sortie-date": "Date sortie",
		"column-statut": "Statut",
		"pret": "Sortie temporairement",
		"column-sortie-type": "Type de sortie"
    },
    "en": {
		"overlay-loading-text": "Loading equipments...",
		"overlay-no-rows-text": "No equipment matching filtering",
        "column-libelEquipement": "Equipment",
        "column-dateVerification": "Last internal verification date",
        "column-emplacement": "Location",
        "column-numImmobilisation": "Account number",
        "column-etat": "Status",
        "column-QRcode": "QR Code",
        "column-caracteristiques": "Characteristics",
        "column-numSerie": "Serial n°",
        "column-refConstructeur": "Manufacturer ref.",
        "column-miseEnService": "Listing date",
        "column-tag-categorie": "Category labels",
        "column-tag": "Bookkeeping account",
        "column-gep": "GEP",
        "column-nonConformite": "Non compliance",
        "column-autre": "Other",
        "column-sourceFinancement": "Funding source",
        "column-typeCategorie": "Category type",
        "column-marque": "Brand",
        "column-valeurAchat": "Purchase value",
        "column-categorie": "Category",
        "column-dateMiseEnService": "Commissioning date",
        "column-batchNumber": "Batch N°",
        "last-maintenance":"Last maintenance sheet",
        "column-tagsEquipements":"equipment's tag",
        "column-ref-document-externe":"External document reference",
        "column-nb-maintenances":"Curative sheets qty",
		"column-sortie-date": "Output date",
		"column-sortie-type": "Output type",
        "last-intervention":"Last external intervention",
        "last-maintenance":"Last curative sheet"
    },
    "th": {
        "column-libelEquipement": "อุปกรณ์",
        "column-dateVerification": "วันที่การตรวจสอบ",
        "column-emplacement": "ตำแหน่งที่ตั้ง",
        "column-numImmobilisation": "หมายเลขบัญชี",
        "column-etat": "สถานะ",
        "column-QRcode": "รหัสคิวอาร์",
        "column-caracteristiques": "คุณสมบัติ",
        "column-numSerie": "หมายเลขชุด",
        "column-refConstructeur": "การอ้างอิงผู้ผลิต",
        "column-miseEnService": "วันที่ทำรายการ",
        "column-tag-categorie": "ฉลากของหมวดหมู่",
        "column-tag": "การทำบัญชี",
        "column-gep": "GEP",
        "column-nonConformite": "ไม่มีคำร้องเรียน",
        "column-autre": "อื่น ๆ",
        "column-sourceFinancement": "แหล่งกองทุน",
        "column-typeCategorie": "ประเภทหมวดหมู่",
        "column-marque": "ยี่ห้อ",
        "column-valeurAchat": "มูลค่าการสั่งซื้อ",
        "column-categorie": "หมวดหมู่"
        }
    }
},
		watch: {
			searchQuery: {
				handler: function(query){
					console.log("SEARCH QUERY", query);
					//this.TagGridMixins_setQuickFilter(this.gridOptions, query);
				}
			},
			filters: {
				handler: function(newfilters){
                    let filters = Object.assign({}, this.metadatas && this.metadatas[this.id]?this.metadatas[this.id].getFilters():{}, newfilters);
                    this.$store.dispatch("EquipementsStore/addMetadatasObject", new Metadatas(this.id).setFilters(filters));
					// reinitialiser pagination
					this.reinitPagination();
					this.fetch();
				},
				deep: true
			},
			localFilters: {
				handler: function(newfilters){
					//console.log("LOCAL FILTERS WATCH", newfilters, this.gridOptions);
					if(newfilters && this.gridOptions) this.TagGridMixins_setFilters(this.gridOptions, newfilters);
				},
				deep: true
			},
			hiddenColumns: {
				handler: function(cols){
					console.log("HIDDEN COLUMNS", cols);
					this.columnDefs[3].hide = false;
				}
			}
		},
		created: function(){
			if(this.$vgutils.isMobile()){
                this.columnDefs.map((columnDef)=>{
                    columnDef.hide = true;
                })
                this.columnDefs.unshift({
					cellClass: ["vg-cell-mobile-card"],
                    cellRenderer: (params) => new EquipementMobileCellRender({propsData: {
							params: params,
							selectedEquipements: this.selectedEquipements,
							showCheckbox: this.isEquipementPicker
						}})
						.$on("show-etiquetage", this.onClickOpenEtiquetage)
						.$mount().$el
				});
            }
            this.reinitFilters();
			if(this.paginate) this.metadatas[this.id].setLimit(this.offset, this.limit);
			this.fetch();
		},
		mounted: function(){

		},
		methods: {
			openLastInterventionExterne: function(intervention){
				this.$router.push({ name: '_intervention', params: { id: intervention.id } });
			},
			isDefaultMaintenance: function(equipement){
				return this.selectedEquipements.findIndex((eq)=>eq.id==equipement.id)!=-1;
			},
            handleClickShowLastMaintenance:function(datas){
                this.$router.push({ name: '_maintenance', params: { id: datas.id } });
            },
			reinitPagination: function(){
				this.offset = 0;
				this.limit = 25;
				this.metadatas[this.id].setLimit(this.offset, this.limit);
			},
            reinitFilters:function(){
                this.$store.dispatch("EquipementsStore/addMetadatasObject", new Metadatas(this.id).setFilters(this.filters));
            },
            /**
			* column is hidden
			* @param String colId
			* @return Boolean
			*/
			columnIsHidden: function(colId){
				return this.hiddenColumns.indexOf(colId)!=-1;
			},
			/**
			* column is pinned to left
			* @param String colId
			* @return Boolean
			*/
			columnIsPinnedToLeft: function(colId){
				return this.pinnedColumns.indexOf(colId)!=-1;
			},
			/**
			* column is not showable
			* @param String colId
			* @return Boolean
			*/
			columnIsNotShowable: function(colId){
				return !(this.notShowableColumns.indexOf(colId)!=-1);
			},
			handlePaginationChange: function(pagination){
	            this.metadatas[this.id].setLimit(pagination.offset, pagination.numberOfRowsToDisplay);
	            this.fetch();
	        },
			fetch: function(){
				this.showLoadingOverlay = true;
				//this.metadatas[this.id].setFiltersFromArray(this.filters);
				this.equipementsMixins_getEquipements(this.metadatas[this.id]).then((datas)=>{
					this.showLoadingOverlay = false;
					this.$emit("fetch-success", {equipements: datas});
				});
			},
			setDefaultFilter: function(){
				if(this.showPrint) this.gridOptions.domLayout= "print";
				this.$emit("grid-feeded", this.gridOptions);
			},
			removeSelectedEquipement(equipement){
				console.log("REMOVE SELECTED EQUIPEMENT", this.$store.getters["EquipementsStore/getSelectedItemById"](equipement.id));
				if(this.$store.getters["EquipementsStore/getSelectedItemById"](equipement.id)){
					this.$store.dispatch("EquipementsStore/removeSelectedItems", [equipement]);
					return true;
				}
				return false;

			},
			addSelectedEquipement(equipement){
				if(this.removeSelectedEquipement(equipement)) return;
				console.log("ADD SELECTED EQUIPEMENT", equipement);
				this.$store.dispatch("EquipementsStore/addSelectedItems", [equipement]);

			},
			onClickRow: function(row){
				if(this.isReadOnly){
					this.addSelectedEquipement(row.data);
					this.$emit("row-click", row);
				};
                if(!this.isReadOnly && row.event.target.name != "equipement-etiquetage"){
                    this.$emit("row-click", row);
                    if(this.$vgutils.isMobile()) this.$router.push({ name: '_equipement_id', params: { id: row.data.id } });
                }

			},
			onDoubleClickRow: function(row){
				if(this.isReadOnly){
					this.$emit("row-double-click", row.data);
					this.$store.dispatch("EquipementsStore/addSelectedItems", row.data);
					return;
				}
				this.$router.push({ name: '_equipement_id', params: { id: row.data.id } });
			},

            /**
             * handleMultipleSelectionRowChanged.
             *
             * @param  {type} gridOptions aggrid gridoptions
             * @return {type}             description
             */
            handleMultipleSelectionRowChanged: function(gridOptions){
                var selectedNodes = gridOptions.api.getSelectedNodes();
                let equipements = selectedNodes.map((node)=>{
                    return node.data;
                });
                this.$store.dispatch("EquipementsStore/setSelectedItems", equipements);
			},
            /**
            * Fetch data with filters.
            *
            * @param Object filters.
            * @event fetch-success
            */
            handleFilterChanged:function(filters){
                if(!Object.values(filters).length){
                    this.reinitFilters();
                    return;
                }
                this.$store.dispatch("EquipementsStore/addMetadatasObject",
                 new Metadatas(this.id)
                 .setFilters(Object.assign(this.metadatas[this.id].getFilters(), filters)));
				this.fetch();
            },
            onClickOpenEtiquetage: function(equipementId){
                try {
                    this.equipements.forEach((equipement, i) => {
                        if(equipement.id == equipementId) {
                            this.$store.dispatch("EquipementsStore/setSelectedItem", equipement);
                            this.displayEtiquetage = true;
                        }
                    });

                } catch (e) {
                    console.log(e)
                } finally {

                }

            },
		},
		computed: {
			...mapGetters({
				equipements: 'EquipementsStore/getCollection',
				counters: "EquipementsStore/getCounters",
                metadatas: 'EquipementsStore/getMetadatas',
				selectedEquipements: "EquipementsStore/getSelectedItems"
            }),
			equipementsFiltered: function(){
				if(this.searchQuery && this.searchQuery.length!=0) return this.equipements.filter((equipement)=>equipement.libel_equipement.toLowerCase().search(this.searchQuery.toLowerCase())!=-1);
				else return this.equipements;
			}
		},
		destroyed: function(){
			if(this.clearCollectionAfterDestroyed) this.$store.dispatch("EquipementsStore/clearCollection");
			if(this.clearSelectedItemsOnDestroy) this.$store.dispatch("EquipementsStore/deleteSelectedItems");
		}
    };

</script>
<style lang="scss" scoped>
.vg-equipements-table{
	height: 90%;
    width: 100%;
	.check-lieu-equipements{
		width: 95%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		.check-lieu-equipement{
			border-bottom: 1px solid #d7d7d9;
			padding: 5px 0;
			width:100%;
			height: inherit;
		}
	}
	.vg-equipements-picker{
		margin-top:10px;
		.equipement-row{
			border-bottom: 1px solid #CACACB;
		}
	}
	
}
</style>
